import React from 'react';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { GlobalTimeParameters } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { IRdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/IRdp21ConfigService';
import { ISlr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/ISlr22ConfigService';
import { IRomConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RomSwitches/IRomConfigService';
import { IRopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/IRopConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { LedTimeType } from '../../../device-details/types';
import TimeForm from '../common/time';

type ComponentProps = {
  device: IDevice;
  timeParams: GlobalTimeParameters | null;
  led?: LedTimeType;
};

const EnablingTimeForm: React.FC<ComponentProps> = ({ device, timeParams, led }) => {
  const { configService: serviceName } = useConfigurationService(device);

  const saveChanges = async (params: GlobalTimeParameters) => {
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
      IRdp21ConfigService | IRopConfigService | IRomConfigService | ISlr22ConfigService
    >(serviceName);

    return await configService.SetTurnOnTimeAsync(device, params);
  };

  return <TimeForm timeParams={timeParams} saveChanges={saveChanges} paramsTitle="EnableTimeParams" led={led} />;
};

export default EnablingTimeForm;
