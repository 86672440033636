import React from 'react';
import classNames from 'classnames';
import './index.scss';

type ControlWrapperProps = {
  className?: string;
  children: React.ReactNode;
};

export const ControlWrapper: React.FC<ControlWrapperProps> = ({ children, className }) => (
  <div className={classNames('control-wrapper', { [className as string]: className })}>{children}</div>
);
