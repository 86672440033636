import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, IconInitial } from '../../../components';
import { useProfile } from '../../../hooks';

type UserInitialProps = {
  setUseUserInitial: (value: boolean) => void;
  useUserInitial: boolean;
  disabled: boolean;
};

const UserInitial: React.FC<UserInitialProps> = ({ setUseUserInitial, useUserInitial, disabled }) => {
  const { t } = useTranslation('profile');
  const { userInitial } = useProfile();

  return (
    <div className="edit-avatar__option">
      <span className="edit-avatar__option-header">{t('avatar.optionName.header')}</span>
      <div className="edit-avatar__input edit-avatar__input--checkbox">
        <Checkbox
          isFullWidth
          onChange={(event) => setUseUserInitial(event.target.checked)}
          checked={useUserInitial}
          disabled={disabled}
        >
          <div className="edit-avatar__input--left">
            <IconInitial initial={userInitial} />
            <span className="m-l-24">{t('avatar.optionName.label')}</span>
          </div>
        </Checkbox>
      </div>
    </div>
  );
};
export default UserInitial;
