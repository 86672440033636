import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../components';
import IpInput from '../../../../../components/ip-input';
import { useAddDns } from '../hooks/use-add-dns';
import './index.scss';

const ExalusAddDns: React.FC = () => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { handleChange, handleSubmit } = useAddDns();

  return (
    <Page
      isStickyHeader
      contentClassName="exalus-add-dns"
      header={
        <>
          <NavHeader />
          <Header title={t('exalusNetwork.dnsAdd')} isUnderline />
        </>
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="max-width-desktop">
          <IpInput label={t('exalusNetwork.addressDns')} handleChange={handleChange} />
        </div>
        <SubmitButton>{tc('buttons.add')}</SubmitButton>
      </form>
    </Page>
  );
};

export default ExalusAddDns;
