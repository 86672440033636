import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Header, NavHeader, Page, SubmitButton } from '../../../components';
import { availableGroupIcons } from '../../../components/action/edit-icon/available-icons';
import { EditIcon } from '../../../components/edit-icon';
import { UserPermissionType } from '../../../data-access/gql-types/graphql';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import GroupFormChannels from '../components/form-channels';
import GroupFormName from '../components/form-name';
import { useCreateGroup } from '../hooks/use-create-group';
import '../index.scss';

export const Create: React.FC = () => {
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const { permissions } = usePermissionsContext();
  const { onSubmit, handleChange, createGroupLoading, isPublic, defaultChannels, form, handleChangeIcon } =
    useCreateGroup();
  const { iconName } = form.watch();

  return (
    <>
      <Page
        isStickyHeader
        header={
          <>
            <NavHeader />
            <Header title={t('create')} />
          </>
        }
      >
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            <GroupFormName />
            <EditIcon currentIcon={iconName} iconList={availableGroupIcons} handleChangeIcon={handleChangeIcon} />
            {permissions[UserPermissionType.PublicGroupAdd] && (
              <Checkbox checked={isPublic} onChange={handleChange}>
                <p>{t('isPublic')}</p>
              </Checkbox>
            )}
            <hr className="m-t-24 m-b-28" />
            <GroupFormChannels defaultChannels={defaultChannels} />
            <SubmitButton disabled={createGroupLoading}>
              {createGroupLoading ? tc('buttons.loading') : tc('buttons.create')}
            </SubmitButton>
          </form>
        </FormProvider>
      </Page>
    </>
  );
};
