import {
  LimitSwitchConfiguration,
  TypeOfGateChannel,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigParams';

export enum Rob21ParamsEnum {
  WORKING_MODE = 'WorkingMode',
  PULSE_TIME = 'PulseTimeParams',
  LIMITING_SWITCHES = 'LimitingSwitches',
  TIME_BETWEEN_PULSES = 'TimeBetweenPulsesParams',
  OPENING_TIME = 'OpeningTimeParams',
}

export type Rob21ConfigParams = {
  [Rob21ParamsEnum.WORKING_MODE]: TypeOfGateChannel | null;
  [Rob21ParamsEnum.PULSE_TIME]: number | null;
  [Rob21ParamsEnum.LIMITING_SWITCHES]: LimitSwitchConfiguration[] | null;
  [Rob21ParamsEnum.TIME_BETWEEN_PULSES]: number | null;
  [Rob21ParamsEnum.OPENING_TIME]: number | null;
};

export const initialConfig: Rob21ConfigParams = {
  [Rob21ParamsEnum.WORKING_MODE]: null,
  [Rob21ParamsEnum.PULSE_TIME]: null,
  [Rob21ParamsEnum.LIMITING_SWITCHES]: null,
  [Rob21ParamsEnum.TIME_BETWEEN_PULSES]: null,
  [Rob21ParamsEnum.OPENING_TIME]: null,
};
