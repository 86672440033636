import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconConnectionError } from '../../../../../components';
import { IconCalibrateInfo } from '../../../../../components/icons/calibrate-info';
import { useSomethingWrong } from '../../channel-list/hooks/use-something-wrong';

type ComponentProps = {
  channel: IDeviceChannel;
};

export const ChannelDetailsErrorIcons: React.FC<ComponentProps> = ({ channel }) => {
  const { connectionIcon, calibrationNeed } = useSomethingWrong(channel);

  return connectionIcon ? <IconConnectionError size={9} details /> : calibrationNeed ? <IconCalibrateInfo /> : null;
};
