import React from 'react';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconChevron, IconPause } from '../../../../../../../../../components';
import { useGatePreciseControl } from '../../../../../hooks/use-gate-precise-control';

type ComponentProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
};

const GatePreciseControls: React.FC<ComponentProps> = ({ channel, isListItem }) => {
  const { open, close, stop } = useGatePreciseControl(channel);

  return (
    <div
      className={classNames('cover-control-box', {
        'cover-control-box--list-view': isListItem,
      })}
    >
      <div className="cover-control-box__controls">
        <div className="cover-control-box__control cover-control-box__control--cover" onClick={close}>
          <IconChevron withCircle direction="down" />
        </div>
        <div className="cover-control-box__control cover-control-box__control--cover" onClick={stop}>
          <IconPause />
        </div>
        <div className="cover-control-box__control cover-control-box__control--cover" onClick={open}>
          <IconChevron withCircle direction="up" />
        </div>
      </div>
    </div>
  );
};

export default GatePreciseControls;
