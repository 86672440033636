import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isSupported } from 'firebase/messaging';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { NotificationsServiceErrorCode } from 'lavva.exalushome/build/js/Services/Notifications/IControllerNotificationsService';
import { NativeContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { useMutation } from '@apollo/client';
import {
  IntegrationType,
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables,
  UserProfile,
} from '../../../data-access/gql-types/graphql';
import { UPDATE_NOTIFICATION_SETTINGS } from '../../../data-access/mutations/user';
import { useBackdropContext, useInstallation, useNative, useProfile } from '../../../hooks';
import { useNativeFunctionsContext } from '../../../hooks/native/native-functions';
import { toastError } from '../../../utils/toast';
import { useExalusNotifications } from '../../exalus/hooks/notifications.hooks';
import { NotificationSettings } from '../types';

export const useNotifications = () => {
  const { t: tc } = useTranslation('common');
  const { userProfileData, user, refetchUserProfile } = useProfile();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { registerTokenForWeb } = useNative();
  const { selectedInstallationId, integrationType } = useInstallation();
  const [permissionInfoDialog, setPermissionInfoDialog] = useState<boolean>(false);
  const [notificationsSupported, setNotificationsSupported] = useState<boolean | null>(null);
  const [controllerNotificationsSupported, setControllerNotificationsSupported] = useState<boolean>(false);
  const { enableControllerNotifications, disableControllerNotifications, getNotificationTokenState } =
    useExalusNotifications();
  const [updateNotificationSettings] = useMutation<
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables
  >(UPDATE_NOTIFICATION_SETTINGS);
  const { nativeCtx, checkNotificationPermission, notificationApiSupported } = useNativeFunctionsContext();
  const [settings, updateSettings] = useState<NotificationSettings>({
    push: false,
    email: false,
    installation: false,
    shareForwardInstallation: false,
    devicePush: null,
    controllerPush: false,
  });
  const webNotificationPermission = nativeCtx === NativeContext.None ? Notification.permission : null;

  const getNotificationPermissions = async () => {
    if (nativeCtx === null) return false;
    const firebaseSupported = await isSupported();

    switch (nativeCtx) {
      case NativeContext.AndroidGecko:
      case NativeContext.IosWebkit: {
        setNotificationsSupported(firebaseSupported);

        return await checkNotificationPermission();
      }
      default: {
        setNotificationsSupported(firebaseSupported && notificationApiSupported);
        return notificationApiSupported ? Notification.permission === 'granted' : false;
      }
    }
  };

  const setInputSettings = async (data: UserProfile) => {
    turnOnBackdrop();
    let exalusNotificationState = false;

    if (integrationType === IntegrationType.Exalus) {
      const state = await getNotificationTokenState(user.profile.email);
      if ((state as ResponseResult<NotificationsServiceErrorCode>).Type) {
        if (
          (state as ResponseResult<NotificationsServiceErrorCode>).Type !==
          NotificationsServiceErrorCode.FeatureUnsupported
        ) {
          setControllerNotificationsSupported(true);
        }
      } else {
        setControllerNotificationsSupported(true);
        exalusNotificationState = state as boolean;
      }
    }

    const devicePush = await getNotificationPermissions();
    turnOffBackdrop();

    updateSettings({
      push: data.pushNotificationsEnabled,
      email: data.emailNotificationsEnabled,
      installation: !!data.currentInstallationScopedNotificationsPermission,
      shareForwardInstallation: !!data.appScopedNotificationsPermission,
      devicePush,
      controllerPush: exalusNotificationState,
    });
  };

  useEffect(() => {
    if (userProfileData?.userProfile && nativeCtx !== null) {
      const data = userProfileData.userProfile;
      setInputSettings(data);
    }
  }, [userProfileData, nativeCtx, webNotificationPermission]);

  const updateNotifications = (newSettings: NotificationSettings) => {
    if (userProfileData?.userProfile) {
      const data = userProfileData.userProfile;

      updateNotificationSettings({
        variables: {
          input: {
            installationId: selectedInstallationId,
            installationScopedPermissions: newSettings.installation ? data.defaultInstallationScopedValue : 0,
            appScopedPermissions: newSettings.shareForwardInstallation ? data.defaultAppScopedValue : 0,
            emailNotificationsEnabled: newSettings.email,
            pushNotificationsEnabled: newSettings.push,
          },
        },
        onCompleted: (data) => {
          if (data.updateNotificationSettings.result?.ok) {
            refetchUserProfile();
          } else {
            toastError({ content: tc('errors.somethingWentWrong') });
          }
        },
      });
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  const handleTogglePushNotifications = async () => {
    switch (nativeCtx) {
      case NativeContext.None: {
        if (Notification.permission !== 'default') {
          setPermissionInfoDialog(true);
          return;
        }

        Notification.requestPermission()
          .then((permission) => {
            registerTokenForWeb();
            updateSettings((prev) => ({
              ...prev,
              devicePush: permission === 'granted',
            }));
          })
          .catch((error) => console.log('Notification error:', error));

        break;
      }
      default: {
        setPermissionInfoDialog(true);
        return;
      }
    }
  };

  const onControllerNotificationsChangedSuccess = () => {
    updateSettings((prev) => ({ ...prev, controllerPush: !prev.controllerPush }));
  };

  const toggleControllerNotifications = (value: boolean, showError?: boolean) => {
    if (integrationType !== IntegrationType.Exalus || !controllerNotificationsSupported) return;
    const lavvaUserName = user.profile.email;

    const payload = {
      lavvaUserName,
      onSuccess: onControllerNotificationsChangedSuccess,
      showError: !!showError,
    };

    if (value) disableControllerNotifications(payload);
    else enableControllerNotifications(payload);
  };

  const handleToggle = (settingsKey: keyof NotificationSettings) => {
    switch (settingsKey) {
      case 'devicePush': {
        handleTogglePushNotifications();
        break;
      }
      case 'controllerPush': {
        toggleControllerNotifications(settings.controllerPush, true);
        break;
      }
      default: {
        if (settingsKey === 'push') toggleControllerNotifications(settings.push);

        const newSettings: NotificationSettings = { ...settings, [settingsKey]: !settings[settingsKey] };
        updateNotifications(newSettings);
      }
    }
  };

  return {
    settings,
    userEmail: user.profile.email,
    permissionInfoDialog,
    handleToggle,
    setPermissionInfoDialog,
    controllerNotificationsSupported,
    notificationsSupported,
  };
};
