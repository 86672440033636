import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Switch } from '../../../components';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { useGateSupportedFeatures } from '../../channel-details/gate/hooks/use-gate-supported';
import { ChannelGeneralConfiguration } from '../types';

const GateConfigurationGeneralPersonalization: React.FC = () => {
  const { setValue, watch } = useFormContext<ChannelGeneralConfiguration>();
  const { t } = useTranslation('configuration');
  const { channel } = useDevicesAndChannelsContext();
  const { partialControlModeSupported, dailyModeModeSupported } = useGateSupportedFeatures(channel);
  const { isPartialControlModeVisible, isDailyModeVisible } = watch();

  const handleChangePartialMode = () => setValue('isPartialControlModeVisible', !isPartialControlModeVisible);

  const handleChangeDailyMode = () => setValue('isDailyModeVisible', !isDailyModeVisible);

  if (!channel) return null;

  return (
    <>
      {partialControlModeSupported && (
        <div className="row_container m-t-8">
          <p>{t('partialMode')}</p>
          <Switch checked={!!isPartialControlModeVisible} onChange={handleChangePartialMode} />
        </div>
      )}
      {dailyModeModeSupported && (
        <div className="row_container m-t-8">
          <p>{t('dailyMode')}</p>
          <Switch checked={!!isDailyModeVisible} onChange={handleChangeDailyMode} />
        </div>
      )}
    </>
  );
};

export default GateConfigurationGeneralPersonalization;
