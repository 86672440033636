import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, CarouselItem, CustomBackdrop, EmptyStateBox } from '../../../../../../components';
import { ROUTES } from '../../../../../../routes';
import { useExalusContext } from '../../../../context';
import { useExalusStorageContext } from '../../../../context/storage';
import { useExalusGroupChannels } from '../../../../hooks';
import { useLoggedUser } from '../../../../hooks/common/logged-user';
import { ExalusGroup } from '../group';

type ComponentProps = {
  isDraggable?: boolean;
};

export const ExalusGroups: React.FC<ComponentProps> = ({ isDraggable }) => {
  const { t } = useTranslation('groups');
  const { allGroups, groupsFetched } = useExalusContext();
  const { setActiveGroup, activeGroupIndex } = useExalusStorageContext();
  const { data: groupChannels, isFetched } = useExalusGroupChannels();
  const { canAddEditGroup } = useLoggedUser();

  return (
    <>
      {groupsFetched ? (
        <>
          {allGroups.length > 0 ? (
            <Carousel setActiveIndex={setActiveGroup} activeIndex={activeGroupIndex} preventSwipeEvent={isDraggable}>
              {allGroups.map((group) => (
                <CarouselItem key={group.Guid} className={'m-t-8'}>
                  {
                    <ExalusGroup
                      groupChannels={groupChannels}
                      group={group}
                      isFetched={isFetched}
                      isDraggable={isDraggable}
                    />
                  }
                </CarouselItem>
              ))}
            </Carousel>
          ) : (
            <EmptyStateBox
              header={t('groups')}
              content={t('descriptionEmptyList')}
              {...(canAddEditGroup ? { btnText: t('create') } : {})}
              {...(canAddEditGroup ? { linkTo: ROUTES.GroupCreate() } : {})}
            />
          )}
        </>
      ) : (
        <CustomBackdrop loading />
      )}
    </>
  );
};
