import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import './index.scss';

type IpInputProps = {
  label: string;
  handleChange: (val: string) => void;
  className?: string;
};

const IpInput: React.FC<IpInputProps> = ({ label, handleChange, className }) => {
  const [val, setVal] = useState<string[]>(new Array(4).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value.length > 3) return;
    if (value && parseInt(value) > 255) return;

    const newVal = [...val];
    newVal[index] = value;
    setVal(newVal);

    if (value.length === 3 && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }

    const ipAddress = newVal.join('.');
    handleChange(ipAddress);
  };

  const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && !val[index]) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div className={classNames('ip-input--container', className)}>
      <label>{label}</label>
      <div className="ip-input">
        {val.map((_, index) => (
          <React.Fragment key={index}>
            <input
              type="text"
              maxLength={3}
              value={val[index]}
              onChange={(e) => onChange(e, index)}
              onKeyDown={(e) => handleBackspace(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
            />
            {index < 3 && <div className="separator">.</div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default IpInput;
