import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/IRdp21ConfigService';
import { MinLightLevel } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { Rdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { MinLevelParams } from '../../../device-details/types';

type ComponentProps = {
  device: IDevice;
  minLevel: MinLightLevel | null;
  channel: IDeviceChannel;
};

const MinLevelForm: React.FC<ComponentProps> = ({ device, minLevel, channel }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<MinLevelParams>();

  useEffect(() => {
    if (minLevel !== null) form.setValue('minLevel', minLevel);
  }, [minLevel]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRdp21ConfigService>(
      Rdp21ConfigService.ServiceName,
    );

    const result = await configService.SetMinLevelAsync(device, channel.Number, values.minLevel);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <ConfigurationInput
          name="minLevel"
          label={t('exalus.params.OnOffParams.MinLevel')}
          placeholder={t('exalus.params.OnOffParams.MinLevel')}
          inputType="number"
          min={1}
          max={98}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default MinLevelForm;
