import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { WindThresholdStateEnum } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { IconToastWarning2 } from '../../../../../../../components/icons/icon-toast-warning-2';
import { ChannelBox } from '../../../box';
import { ChannelBoxIcon } from '../../../box-icon';
import { useWindControl } from '../../../hooks/use-wind-control';
import { PowerState } from '../../../power';
import SensorDetailsWrapper from '../../../sensor-details-wrapper';
import WindControl from '../controls';

type ComponentProps = {
  channel: IDeviceChannel;
};

export const WindDetailsControls: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { windThreshold, time } = useWindControl(channel);

  return (
    <SensorDetailsWrapper channel={channel}>
      <div className="details-controls grid-list-16">
        <div>
          <ChannelBox channel={channel} isListItem className="device-box--details">
            <div className="device-box__head">
              <ChannelBoxIcon channel={channel} />
              <WindControl channel={channel} isListItem details />
            </div>
            {windThreshold === WindThresholdStateEnum.Exceeded && (
              <div className="device-box__warning">
                <IconToastWarning2 size={24} colorError />
                <span className={'m-l-8'}>{t('exalus.windThresholdExceeded.heading')}</span>
              </div>
            )}
          </ChannelBox>
          {windThreshold === WindThresholdStateEnum.Exceeded && (
            <div className="details-controls__additional-text">{t('exalus.windThresholdExceeded.text')}</div>
          )}
          {time && <div className="details-controls__detection-time">{`${t('exalus.checked')} ${time}`}</div>}
        </div>
        <PowerState channel={channel} />
      </div>
    </SensorDetailsWrapper>
  );
};
