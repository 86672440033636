import React, { ChangeEvent, PointerEvent } from 'react';
import { ControlWrapper, IconInitiate, InitiateButton, LightSlider } from '../../../../../../components';

type CurrentlyComponentProps = {
  isOn: boolean;
  handleControlOn: (status: boolean) => void;
  brightness?: number;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBrightness?: (event: PointerEvent<HTMLInputElement>) => void;
};

const CurrentlyComponent: React.FC<CurrentlyComponentProps> = ({
  brightness,
  isOn,
  handleChange,
  handleBrightness,
  handleControlOn,
}) => {
  return (
    <>
      <ControlWrapper className="control-wrapper--medium">
        <InitiateButton isOn={isOn} onClickControl={handleControlOn}>
          <IconInitiate />
        </InitiateButton>
      </ControlWrapper>
      {brightness !== undefined && handleBrightness && handleChange && (
        <div className="details-page__controls-slider p-l-24 p-r-24">
          <LightSlider value={brightness} onChange={handleChange} onPointerUp={handleBrightness} whiteLight />
        </div>
      )}
    </>
  );
};

export default CurrentlyComponent;
