import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UserGroup } from '../../../data-access/gql-types/graphql';
import { GroupItem } from '../item';

type DeviceBoxSortableProps = {
  sortedGroupId: string;
  groups: UserGroup[];
};

export const GroupItemSortable: React.FC<DeviceBoxSortableProps> = ({ groups, sortedGroupId }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({ id: sortedGroupId });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  return (
    <GroupItem
      groups={groups}
      id={sortedGroupId}
      isDraggable
      setNodeRef={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
    />
  );
};
