import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, QRCodeReader } from '../../../../../../components';
import './index.scss';

type ControllerQrReaderProps = {
  setQrPageOpen: Dispatch<SetStateAction<boolean>>;
  handleScan: (data: string) => void;
};

export const ControllerQrReader: React.FC<ControllerQrReaderProps> = ({ setQrPageOpen, handleScan }) => {
  const { t } = useTranslation('installation');

  const onClose = () => {
    setQrPageOpen && setQrPageOpen(false);
  };

  return (
    <div className="integration-exalus">
      <div className="integration-exalus__header">
        <NavHeader onClick={onClose} />
        <Header title={t('exalus.scanQr')} />
      </div>
      <QRCodeReader onHandleScan={handleScan} />
    </div>
  );
};
