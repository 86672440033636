import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dialog } from '../../../../../../components/dialog/base';

type AdvanceDelayDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  onSave: (data: number) => void;
  time: number;
  min?: number;
  max?: number;
  loop?: boolean;
  type?: 'minutes' | 'seconds';
};

export const AdvanceDelayDialog: React.FC<AdvanceDelayDialogProps> = ({
  open,
  setOpen,
  title,
  onSave,
  time,
  min = 1,
  max = 120,
  loop = true,
  type = 'minutes',
}) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const [selectedTime, setSelectedTime] = useState<number>(time);

  useEffect(() => {
    setSelectedTime(time);
  }, [time]);

  const values = useMemo(() => {
    const list: number[] = [];
    for (let i = min; i <= max; i++) {
      list.push(i);
    }

    return list;
  }, [min, max]);

  const onClose = useCallback(() => setOpen(false), []);

  const onSubmit = useCallback(() => {
    onSave(selectedTime);
    onClose();
  }, [selectedTime]);

  const initialSlide = useMemo(() => {
    return values.findIndex((value) => value === selectedTime);
  }, [values, selectedTime]);

  return (
    <Dialog setShow={setOpen} show={open} className="dialog--full-width">
      <div className="dialog-time-picker">
        <div className="dialog-time-picker__header">
          <h3 className="dialog-time-picker__heading">{title}</h3>
        </div>
        <div className="dialog-time-picker__time-wrapper">
          <div className="time-picker-wrapper">
            <div className="picker">
              <Swiper
                initialSlide={initialSlide}
                slidesPerView={3}
                direction="vertical"
                grabCursor
                centeredSlides
                loop={loop}
                slideToClickedSlide
                onSlideChange={(e) => {
                  setSelectedTime(values[e.realIndex]);
                }}
              >
                {values.map((value) => {
                  return (
                    <SwiperSlide key={value}>
                      <div className="time">{value}</div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="time-picker-unit">{t(`trigger.${type}UnitShort`)}</div>
          </div>
        </div>
        <div className="dialog-time-picker__buttons">
          <button className="dialog-time-picker__button" onClick={onClose}>
            {tc('buttons.cancel')}
          </button>
          <button onClick={onSubmit} className="dialog-time-picker__button dialog-time-picker__button--active">
            {tc('buttons.save')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
