import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { IconChevron, IconClose } from '../icons';
import './index.scss';

type NavHeaderProps = {
  title?: string;
  onClick?: () => void;
  isIconClose?: boolean;
  hasLineBelow?: boolean;
  className?: string;
  noBackButton?: boolean;
};

export const NavHeader: React.FC<NavHeaderProps> = ({
  isIconClose = false,
  title,
  children,
  hasLineBelow,
  className,
  onClick,
  noBackButton,
}) => {
  const history = useHistory();

  return (
    <>
      <div className={classNames('nav-header', `${className ? className : ''}`)}>
        <div className="nav-header__left">
          {!noBackButton && (
            <button className="nav-header__nav" type="button" onClick={onClick ? onClick : history.goBack}>
              {!isIconClose ? <IconChevron direction="left" noSpace /> : <IconClose noSpace />}
            </button>
          )}
          {title && <h2 className="nav-header__title">{title}</h2>}
        </div>
        {children && <div className="nav-header__children">{children}</div>}
      </div>
      {hasLineBelow && <hr className="nav-header__divider" />}
    </>
  );
};
