import React from 'react';
import { useHistory } from 'react-router-dom';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { IconEdit, IconSortHandle } from '../../../../../../components';
import { ROUTES } from '../../../../../../routes';
import './index.scss';

type ComponentProps = {
  group: IChannelsGroup;
  isDraggable?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
};

export const GroupItem: React.FC<ComponentProps> = ({
  group,
  isDraggable,
  setNodeRef,
  style,
  attributes,
  listeners,
}) => {
  const history = useHistory();

  return (
    <div
      className="group-item"
      style={style}
      ref={setNodeRef}
      onClick={() => history.push(ROUTES.GroupEdit(group.Guid))}
    >
      <div className="group-item__head">
        <span className="group-item__title">{group.Name}</span>
      </div>
      {isDraggable ? (
        <div className="group-item__draggable">
          <div className="group-item__draggable-handle" {...attributes} {...listeners}>
            <IconSortHandle />
          </div>
        </div>
      ) : (
        <IconEdit />
      )}
    </div>
  );
};
