import axios, { AxiosInstance } from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { useInstallation } from '../../hooks';
import { useGraphqlContext } from '../../utils/graphql/graphql.context';
import { isLocalApp } from '../../utils/helpers/local-app';

type RestClientInterface = {
  noInstallationIdHeader?: boolean;
};

export const useRestClient = (params?: RestClientInterface): AxiosInstance => {
  return !isLocalApp ? useRest(params) : useRestLocal();
};

export const useRest = (params?: RestClientInterface): AxiosInstance => {
  const { keycloak } = useKeycloak();
  const { selectedInstallationId } = useInstallation();
  const { LAVVA_MANAGER } = useGraphqlContext();

  return axios.create({
    baseURL: LAVVA_MANAGER,
    headers: {
      'Content-type': 'application/json',
      ...(!params?.noInstallationIdHeader ? { installationId: selectedInstallationId || '' } : {}),
      ...(keycloak.token ? { Authorization: `Bearer ${keycloak.token}` } : {}),
    },
  });
};

export const useRestLocal = (): AxiosInstance => {
  return axios.create();
};
