import React from 'react';

type IconWallSconceProps = {
  isOn: boolean;
};

export const IconWallSconce: React.FC<IconWallSconceProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-switch icon-wall-sconce"
      width="32"
      height="42"
      viewBox="0 0 32 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93899 6.6623V3.251C4.93899 2.00781 3.93118 1 2.68799 1H1V12.3246H2.68799C3.93118 12.3246 4.93899 11.3168 4.93899 10.0736V6.6623ZM4.93899 6.6623H10.3551C13.1452 6.6623 19.008 8.31575 19.008 13.9432V16.1942"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      {isOn ? (
        <>
          <path
            d="M18.2446 38.6558L18.2446 41.0126"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.2446 38.6558L18.2446 41.0126"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.3555 38.4819L22.6889 36.8154"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.1343 38.4819L13.8008 36.8154"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : null}
      <path
        d="M18.8137 31.1757H6.62744C6.62744 27.4008 9.95096 19.8511 18.8137 19.8511C27.6764 19.8511 30.2614 27.4008 30.9999 31.1757H18.8137Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <mask id="path-7-inside-1_11228_102277" fill="white">
        <path d="M23.5084 31.8549C23.525 32.3818 23.4054 32.9059 23.1569 33.3963C22.9084 33.8866 22.5359 34.3331 22.0615 34.7094C21.587 35.0857 21.0203 35.3842 20.3949 35.5871C19.7695 35.79 19.0982 35.8932 18.4205 35.8907C17.7429 35.8881 17.0729 35.7798 16.45 35.5722C15.8272 35.3646 15.2643 35.0619 14.7945 34.682C14.3248 34.3021 13.9579 33.8528 13.7155 33.3606C13.4731 32.8685 13.3602 32.3435 13.3833 31.8167L15.1028 31.8625C15.0875 32.2103 15.162 32.557 15.3221 32.8819C15.4822 33.2069 15.7244 33.5036 16.0346 33.7545C16.3448 34.0053 16.7165 34.2052 17.1277 34.3423C17.539 34.4794 17.9815 34.5509 18.4289 34.5526C18.8763 34.5543 19.3197 34.4861 19.7326 34.3521C20.1456 34.2181 20.5198 34.0211 20.833 33.7726C21.1463 33.5241 21.3923 33.2292 21.5564 32.9055C21.7205 32.5817 21.7994 32.2356 21.7884 31.8877L23.5084 31.8549Z" />
      </mask>
      <path
        d="M23.5084 31.8549C23.525 32.3818 23.4054 32.9059 23.1569 33.3963C22.9084 33.8866 22.5359 34.3331 22.0615 34.7094C21.587 35.0857 21.0203 35.3842 20.3949 35.5871C19.7695 35.79 19.0982 35.8932 18.4205 35.8907C17.7429 35.8881 17.0729 35.7798 16.45 35.5722C15.8272 35.3646 15.2643 35.0619 14.7945 34.682C14.3248 34.3021 13.9579 33.8528 13.7155 33.3606C13.4731 32.8685 13.3602 32.3435 13.3833 31.8167L15.1028 31.8625C15.0875 32.2103 15.162 32.557 15.3221 32.8819C15.4822 33.2069 15.7244 33.5036 16.0346 33.7545C16.3448 34.0053 16.7165 34.2052 17.1277 34.3423C17.539 34.4794 17.9815 34.5509 18.4289 34.5526C18.8763 34.5543 19.3197 34.4861 19.7326 34.3521C20.1456 34.2181 20.5198 34.0211 20.833 33.7726C21.1463 33.5241 21.3923 33.2292 21.5564 32.9055C21.7205 32.5817 21.7994 32.2356 21.7884 31.8877L23.5084 31.8549Z"
        fill="#15141D"
        stroke="#15141D"
        strokeWidth="3"
        mask="url(#path-7-inside-1_11228_102277)"
      />
      <mask id="path-8-inside-2_11228_102277" fill="white">
        <path d="M13.3804 21.0489C13.3804 20.3289 13.5239 19.6162 13.8026 18.9524C14.0812 18.2885 14.4894 17.6869 15.0032 17.1826C15.517 16.6784 16.1262 16.2816 16.7952 16.0155C17.4641 15.7494 18.1794 15.6193 18.8992 15.6328C19.619 15.6464 20.3289 15.8033 20.9874 16.0943C21.6459 16.3854 22.2397 16.8048 22.7343 17.328C23.2288 17.8512 23.614 18.4678 23.8675 19.1416C24.121 19.8155 24.2376 20.5331 24.2105 21.2525L22.5123 21.1886C22.5309 20.6949 22.4508 20.2024 22.2769 19.74C22.1029 19.2775 21.8385 18.8544 21.4992 18.4953C21.1598 18.1363 20.7522 17.8484 20.3003 17.6487C19.8484 17.4489 19.3613 17.3413 18.8673 17.332C18.3733 17.3227 17.8824 17.412 17.4233 17.5946C16.9642 17.7772 16.5462 18.0495 16.1935 18.3956C15.8409 18.7416 15.5608 19.1545 15.3696 19.6101C15.1784 20.0657 15.0799 20.5548 15.0799 21.0489H13.3804Z" />
      </mask>
      <path
        d="M13.3804 21.0489C13.3804 20.3289 13.5239 19.6162 13.8026 18.9524C14.0812 18.2885 14.4894 17.6869 15.0032 17.1826C15.517 16.6784 16.1262 16.2816 16.7952 16.0155C17.4641 15.7494 18.1794 15.6193 18.8992 15.6328C19.619 15.6464 20.3289 15.8033 20.9874 16.0943C21.6459 16.3854 22.2397 16.8048 22.7343 17.328C23.2288 17.8512 23.614 18.4678 23.8675 19.1416C24.121 19.8155 24.2376 20.5331 24.2105 21.2525L22.5123 21.1886C22.5309 20.6949 22.4508 20.2024 22.2769 19.74C22.1029 19.2775 21.8385 18.8544 21.4992 18.4953C21.1598 18.1363 20.7522 17.8484 20.3003 17.6487C19.8484 17.4489 19.3613 17.3413 18.8673 17.332C18.3733 17.3227 17.8824 17.412 17.4233 17.5946C16.9642 17.7772 16.5462 18.0495 16.1935 18.3956C15.8409 18.7416 15.5608 19.1545 15.3696 19.6101C15.1784 20.0657 15.0799 20.5548 15.0799 21.0489H13.3804Z"
        fill="#15141D"
        stroke="#15141D"
        strokeWidth="3"
        mask="url(#path-8-inside-2_11228_102277)"
      />
    </svg>
  );
};
