import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';
import {
  DeviceStore,
  SupportedDevice,
  IntegrationType as IntegrationTypeLib,
  Language,
} from 'lavva.webbluetooth/build/js/DeviceStore';
import { useInstallation } from '..';
import { IntegrationType } from '../../data-access/gql-types/graphql';

const integration = {
  [IntegrationType.Lavva]: IntegrationTypeLib.Lavva,
  [IntegrationType.Exalus]: IntegrationTypeLib.Exalus,
};

export const useSupportedDevices = (): UseQueryResult<SupportedDevice[]> => {
  const { i18n } = useTranslation();
  const { integrationType } = useInstallation();

  return useQuery<SupportedDevice[]>(
    ['supported-devices', integrationType],
    async () => {
      const deviceStore = new DeviceStore();
      const supportedDevices = await deviceStore.GetSupportedDevicesAsync(
        integration[integrationType],
        i18n.language as Language,
      );
      return supportedDevices;
    },
    {
      retry: false,
      enabled: integrationType !== IntegrationType.Undefined,
    },
  );
};

export const useSupportedDeviceById = (id: string): UseQueryResult<SupportedDevice | null> => {
  const { i18n } = useTranslation();
  const { integrationType } = useInstallation();

  return useQuery<SupportedDevice | null>(
    ['supported-device-by-id', id, integrationType],
    async () => {
      const deviceStore = new DeviceStore();
      const supportedDevices = await deviceStore.GetSupportedDevicesAsync(
        integration[integrationType],
        i18n.language as Language,
      );

      return supportedDevices.find((x) => x.ModelGuid === id) || null;
    },
    {
      retry: false,
      enabled: !!id && integrationType !== IntegrationType.Undefined,
    },
  );
};
