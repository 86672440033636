import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconEco } from '../../../../../../components';
import { ecoLevels } from '../../utils';
import { IndicatorElement } from './element';
import './index.scss';

type PropsInterface = {
  value: number;
};

export const EcoIndicator: React.FC<PropsInterface> = ({ value }) => {
  const { t } = useTranslation('channel-details');

  const tooltipRef = useRef<HTMLDivElement>(null);
  const matchedEcoLevel = useMemo(() => ecoLevels.findIndex((level) => value <= level.maxValue), [ecoLevels, value]);

  const style = useMemo(() => {
    const position = (100 / ecoLevels.length) * matchedEcoLevel + (100 / ecoLevels.length) * 0.5;

    return {
      '--position': `${position || 10}%`,
    } as React.CSSProperties;
  }, [matchedEcoLevel]);

  return (
    <div className="eco-indicator p-l-24 p-r-24">
      <div className="eco-indicator__heading">
        <IconEco />
        <h3 className="m-l-16">{t('ecoIndicator')}</h3>
      </div>
      <div className="indicator">
        <div ref={tooltipRef} style={style} className="indicator__tooltip">
          <div className="indicator__tooltip-box">
            {t(`ecoLevels.${ecoLevels[matchedEcoLevel === -1 ? 0 : matchedEcoLevel].name}`)}
            <svg
              className="indicator__tooltip-handler"
              width="7"
              height="11"
              viewBox="0 0 7 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.5 11L3.5 6" stroke="#CBCBD1" />
              <circle cx="3.5" cy="3" r="2.5" transform="rotate(-90 3.5 3)" stroke="#CBCBD1" />
            </svg>
          </div>
        </div>
        <ul className="indicator__list">
          {ecoLevels.map((ecoLevel) => (
            <IndicatorElement ecoLevel={ecoLevel} key={ecoLevel.name} />
          ))}
        </ul>
      </div>
    </div>
  );
};
