import React from 'react';
import classNames from 'classnames';
import './index.scss';

type PropsInterface = {
  size?: number;
  big?: boolean;
  details?: boolean;
  iconToastError?: boolean;
};

export const IconConnectionError: React.FC<PropsInterface> = ({ size = 24, big, details, iconToastError }) => {
  return (
    <div
      className={classNames('icon-connection-error-wrapper', {
        big,
        details,
        'icon-toast': iconToastError,
      })}
    >
      <svg
        className="icon-connection-error"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 8.63018C3.70083 5.77866 7.52296 3.99997 11.7605 3.99997C16.0342 3.99997 19.8853 5.80907 22.5898 8.70323"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.73145 12.4244C6.45475 10.5193 8.96531 9.31946 11.7606 9.31946C14.6197 9.31946 17.181 10.5748 18.9066 12.5561"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.91107 16.1588C9.59571 15.2374 10.7023 14.639 11.9508 14.639C13.1993 14.639 14.3059 15.2374 14.9905 16.1588"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.9508" cy="19.1986" r="0.759931" fill="white" />
      </svg>
    </div>
  );
};
