import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { CoverButton, ControlWrapper, Slider, IconVent, IconChevron } from '../../../components';
import { IconWoodenBlind } from '../../../components/icons';
import { defaultFavouritePositionList } from '../../../const';
import {
  Action,
  FavouritePositionType,
  SetFavouriteActionPositionsMutation,
  SetFavouriteActionPositionsMutationVariables,
} from '../../../data-access/gql-types/graphql';
import { SET_FAVOURITE_ACTION_POSITIONS } from '../../../data-access/mutations/action';
import { useCoverAction } from '../../../hooks';
import { ChannelInterface, FavouritePosition } from '../../../types';
import { toastError, toastSuccess } from '../../../utils/toast';

type CoverActionProps = {
  channels: ChannelInterface[];
  action: Action;
  favouritePositions: FavouritePosition | null;
};

const CoverAction: React.FC<CoverActionProps> = ({ channels, favouritePositions, action }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { handleAction } = useCoverAction(channels);
  const [sliderValue, setSliderValue] = useState<number>(50);
  const [favouritePositionList, setFavouritePositionList] = useState<number[]>([]);

  const [setFavouritePositions] = useMutation<
    SetFavouriteActionPositionsMutation,
    SetFavouriteActionPositionsMutationVariables
  >(SET_FAVOURITE_ACTION_POSITIONS);

  useEffect(() => {
    setFavouritePositionList(
      favouritePositions?.length || favouritePositions !== null
        ? favouritePositions.map((position) => (position > 1 ? position : Math.round(position * 100)))
        : defaultFavouritePositionList,
    );
  }, [defaultFavouritePositionList, favouritePositions, setFavouritePositionList]);

  const onFavouriteHold = useCallback(
    (fieldIndex: number) => {
      const newFavouritePositionList = favouritePositionList.map((el, index) =>
        index !== fieldIndex ? el : sliderValue,
      );

      if (newFavouritePositionList.length) {
        setFavouritePositionList(newFavouritePositionList);
        setFavouritePositions({
          variables: {
            input: {
              favouritePositions: newFavouritePositionList.map((position) => ({
                favouritePositionType: FavouritePositionType.Position,
                value: position / 100,
              })),
              actionId: action?.id,
            },
          },
          onCompleted: (data) => {
            if (data.setFavouriteActionPositions.ok) {
              toastSuccess({ content: t('favouriteSetSuccess') });
            } else {
              toastError({ content: tc('errors.somethingWentWrong') });
              // TODO errors
            }
          },
        });
      }
    },
    [sliderValue, setFavouritePositionList],
  );

  const onChange = useCallback(
    (value: string | number) => {
      setSliderValue(typeof value === 'number' ? value : value === 'close' ? 100 : 0);
      handleAction(value);
    },
    [setSliderValue, handleAction],
  );

  const onFavouriteClick = useCallback(
    (favoritePositionIndex) => onChange(favouritePositionList[favoritePositionIndex]),
    [favouritePositionList],
  );

  const handleSliderEvent = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => handleAction(Number((event.target as HTMLInputElement).value)),
    [handleAction],
  );

  return (
    <>
      <div className="cover-detail-view">
        <ControlWrapper>
          <Slider
            value={sliderValue}
            onPointerUp={handleSliderEvent}
            onChange={(e) => setSliderValue(Number(e.target.value))}
          />
        </ControlWrapper>
        <div className="cover-detail-view__controls p-l-24 p-r-24">
          <div className="cover-detail-view__controls-down" onClick={() => onChange('close')}>
            <IconChevron withCircle direction="down" />
          </div>
          <button className="cover-detail-view__controls-stop" onClick={() => onChange('stop')}>
            {tc('status.stop')}
          </button>
          <div className="cover-detail-view__controls-up" onClick={() => onChange('open')}>
            <IconChevron withCircle direction="up" />
          </div>
        </div>
      </div>
      <div className="favourite p-l-24 p-r-24">
        <div className="favourite__buttons favourite__buttons--cover">
          {[0, 1, 2].map((index) => (
            <CoverButton
              key={index}
              lastPosition={sliderValue}
              position={favouritePositionList[index]}
              onClick={() => onFavouriteClick(index)}
              onHold={() => onFavouriteHold(index)}
            >
              <IconWoodenBlind position={favouritePositionList[index]} />
              <span>{favouritePositionList[index]}%</span>
            </CoverButton>
          ))}
          <CoverButton lastPosition={sliderValue} position={99} onClick={() => onChange(99)}>
            <IconVent />
            <span>{t('microventilation')}</span>
          </CoverButton>
        </div>
      </div>
    </>
  );
};

export default CoverAction;
