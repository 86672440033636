import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

type CurrentValueProps = {
  unit: string;
  currentValue: string | ReactNode;
};

export const CurrentValue: React.FC<CurrentValueProps> = ({ unit, currentValue }) => {
  const { t } = useTranslation('channel-details');

  return (
    <div className="current-value m-t-32">
      <span className="current-value__label m-r-8">{t('now')}</span>
      <span className="current-value__value m-r-4">{currentValue}</span>
      {<span className="current-value__unit">{unit}</span>}
    </div>
  );
};
