import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Api } from 'lavva.exalushome';
import { DeviceTasksInfo } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { IDeviceUpdateInfo } from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import { IUpdatesService, UpdateErrorCode } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { UpdatesService } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';

type ExalusDevicesProviderInterface = {
  deviceTasks: DeviceTasksInfo[];
  setDevicesTasks: Dispatch<SetStateAction<DeviceTasksInfo[]>>;
  checkUpdatesLoading: boolean;
  checkDevicesUpdates: (
    reloadCache?: boolean,
  ) => Promise<IDeviceUpdateInfo[] | ResponseResult<UpdateErrorCode> | undefined>;
  updatesAvailability: IDeviceUpdateInfo[];
};

const initialState: ExalusDevicesProviderInterface = {
  deviceTasks: [],
  setDevicesTasks: () => null,
  checkUpdatesLoading: false,
  checkDevicesUpdates: async () => undefined,
  updatesAvailability: [],
};

export const ExalusDevicesContext = createContext<ExalusDevicesProviderInterface>(initialState);

export const useExalusDevicesContext = (): ExalusDevicesProviderInterface => useContext(ExalusDevicesContext);

export const ExalusDevicesContextProvider: React.FC = ({ children }) => {
  const [deviceTasks, setDevicesTasks] = useState<DeviceTasksInfo[]>([]);
  const [checkUpdatesLoading, setCheckUpdatesLoading] = useState<boolean>(false);
  const [updatesAvailability, setUpdatesAvailability] = useState<IDeviceUpdateInfo[]>([]);

  const checkDevicesUpdates = async (reloadCache?: boolean) => {
    if (checkUpdatesLoading) return;

    try {
      console.log('CHECK DEVICES');
      setCheckUpdatesLoading(true);
      const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
      const result = await updateService.CheckDeviceUpdatesBulkAsync(reloadCache);

      if (Array.isArray(result)) {
        setUpdatesAvailability(result);
        setCheckUpdatesLoading(false);
      }

      return result;
    } catch (error) {
      console.error('Error fetching updates:', error);
    }
  };

  const values: ExalusDevicesProviderInterface = {
    deviceTasks,
    setDevicesTasks,
    checkUpdatesLoading,
    checkDevicesUpdates,
    updatesAvailability,
  };

  return <ExalusDevicesContext.Provider value={values}>{children}</ExalusDevicesContext.Provider>;
};
