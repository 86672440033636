import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconHeartProps = {
  isEmpty?: boolean;
  onClick?: () => void;
};

export const IconHeart: React.FC<IconHeartProps> = ({ isEmpty = false, onClick }) => {
  return (
    <div
      className={classNames('icon-heart', { clickable: onClick, 'icon-heart--is-empty': isEmpty })}
      onClick={onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M21.1877 4.21607C20.677 3.70508 20.0705 3.29972 19.4031 3.02316C18.7356 2.7466 18.0202 2.60425 17.2977 2.60425C16.5752 2.60425 15.8598 2.7466 15.1924 3.02316C14.5249 3.29972 13.9185 3.70508 13.4077 4.21607L12.3477 5.27607L11.2877 4.21607C10.256 3.18438 8.85675 2.60478 7.39771 2.60478C5.93868 2.60478 4.53941 3.18438 3.50771 4.21607C2.47602 5.24777 1.89642 6.64704 1.89642 8.10607C1.89642 9.56511 2.47602 10.9644 3.50771 11.9961L4.56771 13.0561L12.3477 20.8361L20.1277 13.0561L21.1877 11.9961C21.6987 11.4853 22.1041 10.8789 22.3806 10.2114C22.6572 9.54397 22.7995 8.82856 22.7995 8.10607C22.7995 7.38359 22.6572 6.66818 22.3806 6.00072C22.1041 5.33326 21.6987 4.72683 21.1877 4.21607V4.21607Z"
          fill="#ffffff"
          stroke="#ffffff"
        />
      </svg>
    </div>
  );
};
