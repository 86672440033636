import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { LightSlider, SubmitButton } from '../../../../components';
import RgbPicker from '../../../channel-details/light/components/rgb/picker';

type ComponentProps = {
  rgbValue: string;
  sliderColor: string;
  activeColor: string;
  handleColorPicked: (hue: any, saturation: any) => void;
  handleSubmit: () => void;
  wValue?: number;
  handleChangeW?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleWValue?: (event: React.PointerEvent<HTMLInputElement>) => void;
  handleBrightness?: (event: React.PointerEvent<HTMLInputElement>) => void;
  handleChangeBrightness?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const RgbwChannelSettings: React.FC<ComponentProps> = ({
  rgbValue,
  sliderColor,
  activeColor,
  wValue,
  handleWValue,
  handleChangeW,
  handleColorPicked,
  handleSubmit,
  handleBrightness,
  handleChangeBrightness,
}) => {
  const { t } = useTranslation('action');

  return (
    <>
      <RgbPicker
        rgbValue={rgbValue}
        handleColorPicked={handleColorPicked}
        sliderColor={sliderColor}
        activeColor={activeColor}
        pickerCircleRadius={24}
        handleBrightness={handleBrightness}
        handleChange={handleChangeBrightness}
        innerRadius={70}
        brightnessLabel={t('action.create.tasks.states.brightnessColor')}
      />
      {handleWValue && handleChangeW && wValue !== undefined && (
        <div className="details-page__controls-slider p-l-24 p-r-24">
          <LightSlider
            value={wValue}
            onChange={handleChangeW}
            onPointerUp={handleWValue}
            label={t('action.create.tasks.states.wLed')}
          />
        </div>
      )}
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
