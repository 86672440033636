import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeActivationButton } from '../../../../components';
import { SetActivationTimeDialog } from '../set-activation-time-dialog';

type PropsInterface = {
  value: number;
  index: number;
  onTimeChange: (value: number, index: number) => void;
  onBtnClick: (value: number) => void;
};

export const TimeActivation: React.FC<PropsInterface> = ({ value, index, onTimeChange, onBtnClick }) => {
  const { t } = useTranslation('channel-details');
  const [showActivationTimeDialog, setShowActivationTimeDialog] = useState<boolean>(false);

  const onHold = useCallback(() => {
    setShowActivationTimeDialog(true);
  }, [value]);

  return (
    <>
      <TimeActivationButton value={value} onHold={onHold} onClick={() => onBtnClick(value)} />
      <SetActivationTimeDialog
        open={showActivationTimeDialog}
        setOpen={setShowActivationTimeDialog}
        title={t('timeActivationDialog')}
        onSave={(value) => onTimeChange(value, index)}
        miliseconds={value}
      />
    </>
  );
};
