import React from 'react';
import './index.scss';

type PropsInterface = {
  isOn?: boolean;
};

export const IconNotification: React.FC<PropsInterface> = ({ isOn = false }) => {
  return (
    <svg
      className="icon-notification"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      {isOn ? (
        <>
          <path
            d="M18.1682 9.04863C18.4404 7.48079 18.0787 5.86902 17.1626 4.56788C16.2464 3.26675 14.851 2.38283 13.2831 2.11058C11.7153 1.83833 10.1035 2.20005 8.80239 3.11617C7.50126 4.03229 6.61734 5.42777 6.34509 6.9956C5.14749 13.8924 1.84955 15.3497 1.84955 15.3497L19.5842 18.4292C19.5842 18.4292 16.9706 15.9454 18.1682 9.04863Z"
            stroke="#15141D"
            vectorEffect="non-scaling-stroke"
          />
          <circle cx="17" cy="4" r="4" fill="#FF4016" />
          <path
            d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
            stroke="#15141D"
            vectorEffect="non-scaling-stroke"
          />
        </>
      ) : (
        <>
          <path
            d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
            stroke="#15141D"
            vectorEffect="non-scaling-stroke"
          />
          <path
            d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
            stroke="#15141D"
            vectorEffect="non-scaling-stroke"
          />
        </>
      )}
    </svg>
  );
};
