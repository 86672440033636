import { ReactNode } from 'react';

export type ConditionDetails = {
  description: string;
  title?: string;
  secondLine?: string;
  icon?: string;
};

export enum SetParametersType {
  Edit,
  Duplicate,
}

export type DeviceConditionState = {
  icon: ReactNode | null;
  state?: string;
};
