import React from 'react';
import classNames from 'classnames';
import './index.scss';

type PropsInterface = {
  children: React.ReactNode;
  className?: string;
};

export const IconWrapper: React.FC<PropsInterface> = ({ children, className }) => (
  <div className={classNames('icon-wrapper', { [className as string]: className })}>{children}</div>
);
