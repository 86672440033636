import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import { ActionBox, ChannelBox } from '../../../components';
import { DashboardItemType, UserAction, UserGroup, UserGroupItem } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { ChannelInterface } from '../../../types';

type ComponentProps = {
  group: UserGroup;
  isListView: boolean;
};

export const DashboardItems: React.FC<ComponentProps> = ({ group, isListView }) => {
  const { channelList } = useDevicesAndChannelsContext();

  const items = useMemo(() => {
    return orderBy(group.userGroupItems, 'order').map((userItem: UserGroupItem) => {
      if (userItem) {
        const item = group.group.groupItems.find((x) => x.id === userItem.itemId);

        if (item) {
          if (item.resourceType === DashboardItemType.Channel) {
            const channel = channelList.find((device) => device && device.id === userItem.itemId) as ChannelInterface;
            return channel && channel.isVisible ? (
              <ChannelBox key={userItem.itemId} channel={channel} isListItem={isListView} />
            ) : null;
          }
        }

        const newAction = group.group.groupItems.find((x) => x.id === userItem.itemId)?.payload as UserAction;

        return (
          <ActionBox key={userItem.itemId} isListItem={isListView} actionData={newAction} actionId={userItem.itemId} />
        );
      }
    });
  }, [group, channelList, isListView]);

  return <>{items}</>;
};
