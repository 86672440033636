import React, { ReactNode, useMemo } from 'react';
import { lightOrDarkOnGradient } from '../../../../../../../../utils/helpers';
import './index.scss';

type PropsInterface = {
  colors: string[];
  icon: ReactNode;
  name: string;
};

export const RgbSceneItem: React.FC<PropsInterface> = ({ colors, icon, name }) => {
  const style = useMemo(() => {
    return {
      '--gradient-background': `linear-gradient(to right, ${colors.join()})`,
      '--color': lightOrDarkOnGradient(colors) === 'light' ? '#000000' : '#FFFFFF',
      '--reverse-color': lightOrDarkOnGradient(colors) === 'light' ? '255,255,255' : '0,0,0',
    } as React.CSSProperties;
  }, [colors]);

  return (
    <div className="rgb-scene" style={style}>
      <div className="rgb-scene__icon rgb-scene__icon--custom-size">{icon}</div>
      <div className="rgb-scene__name-wrapper" style={style}>
        <h4 className="rgb-scene__name" style={style}>
          {name}
        </h4>
      </div>
    </div>
  );
};
