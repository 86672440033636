import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SelectInputController, SelectOptionInterface, TabInterface, Tabs } from '../../../../../components';
import { CheckboxesOptions, CheckboxSelect } from '../../../../../components/checkbox-select';
import { ChannelTypeInternal, InputTriggerInternal, UserChannel } from '../../../../../data-access/gql-types/graphql';
import { ActionBindingType, actionOptions, DeviceBindingsForm } from '../../types';
import { CloudAction } from '../cloud-action';
import { GateAction } from '../gate-action';
import { LightAction } from '../light-action';
import { PositionAction } from '../position-action';
import { SetOnTimeAction } from '../set-on-time-action';
import './index.scss';

type ComponentProps = {
  channels: UserChannel[];
  index: number;
};

export const InputConfiguration: React.FC<ComponentProps> = ({ channels, index }) => {
  const { t } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeChannelIndex, setActiveChannelIndex] = useState<number>(0);
  const [options, setOptions] = useState<Record<number, CheckboxesOptions<string>[]>>({ 0: [], 1: [] });
  const form = useFormContext<DeviceBindingsForm>();
  const { fields, update } = useFieldArray({
    control: form.control,
    name: 'bindings',
  });
  const { bindings } = form.watch();

  useEffect(() => {
    if (!options[activeTabIndex].length && bindings.length) {
      const list: CheckboxesOptions<string>[] = channels.map((ch) => ({
        label: `${ch.alias} (${tc(`types.${ch.channelType}`)})`,
        value: ch.id,
        checked:
          activeTabIndex === 0
            ? !!bindings[index].single.find((x) => x.channelId === ch.id)
            : !!bindings[index].double.find((x) => x.channelId === ch.id),
        data: ch.channelType,
      }));

      setOptions((prev) => ({
        ...prev,
        [activeTabIndex]:
          activeTabIndex === 0
            ? [
                ...list,
                {
                  label: t('bindings.purposeOptions.cloud'),
                  value: 'cloud',
                  checked:
                    activeTabIndex === 0
                      ? !!bindings[index].single.find((x) => x.channelId === 'cloud')
                      : !!bindings[index].double.find((x) => x.channelId === 'cloud'),
                },
              ]
            : [...list],
      }));
    }
  }, [channels, bindings, activeTabIndex]);

  const listName = useMemo(() => (activeTabIndex === 0 ? 'single' : 'double'), [activeTabIndex]);

  const additionalBindingsByType = (i: number) => {
    if (!bindings.length) return null;
    const binding = bindings[index][listName][i];
    if (!binding.actionType || binding.actionType === ActionBindingType.Default) return null;

    switch (binding.actionType) {
      case ActionBindingType.Switch: {
        return binding.setOnTimeValue ? (
          <SetOnTimeAction
            value={binding.setOnTimeValue}
            bindingIndex={index}
            bindingListIndex={i}
            listName={listName}
          />
        ) : null;
      }
      case ActionBindingType.Blind: {
        return typeof binding.position === 'number' ? (
          <PositionAction value={binding.position} bindingIndex={index} bindingListIndex={i} listName={listName} />
        ) : null;
      }
      case ActionBindingType.Light: {
        return <LightAction bindingIndex={index} bindingListIndex={i} channels={channels} listName={listName} />;
      }
      case ActionBindingType.Gate: {
        return <GateAction bindingIndex={index} bindingListIndex={i} listName={listName} />;
      }
      case ActionBindingType.Cloud: {
        return <CloudAction bindingIndex={index} bindingListIndex={i} listName={listName} />;
      }
      default: {
        return null;
      }
    }
  };

  const handleChannelOptionsSave = (options: CheckboxesOptions<string>[]) => {
    const { bindings } = form.getValues();

    setOptions((prev) => ({
      ...prev,
      [activeTabIndex]: options,
    }));

    bindings[index][listName] = options
      .filter((x) => x.checked)
      .map((x) => {
        const found = bindings[index][listName].find((y) => y.channelId === x.value);

        return {
          ...(found ? { ...found } : {}),
          channelId: x.value,
          channelType: found ? found.channelType : (x.data as ChannelTypeInternal) || 'Cloud',
          inputTrigger: activeTabIndex === 0 ? InputTriggerInternal.SingleClick : InputTriggerInternal.DoubleClick,
          actionType: found ? found.actionType : x.value === 'cloud' ? ActionBindingType.Cloud : null,
          slot: found ? found.slot : x.value === 'cloud' ? 1001 : null,
        };
      });

    update(index, { ...bindings[index] });
  };

  const handleChangeActionType = (
    actionType: ActionBindingType,
    i: number,
    opts: SelectOptionInterface<ActionBindingType>[],
  ) => {
    bindings[index][listName][i].actionType = actionType;
    const slot = opts.find((x) => x.value === actionType)?.weight;

    if (slot !== undefined) bindings[index][listName][i].slot = slot;
    switch (actionType) {
      case ActionBindingType.Switch: {
        bindings[index][listName][i].setOnTimeValue = '00:00:00';
        break;
      }
      case ActionBindingType.Blind: {
        bindings[index][listName][i].position = 0;
        break;
      }
      case ActionBindingType.Default: {
        delete bindings[index][listName][i].setOnTimeValue;
        delete bindings[index][listName][i].position;
        delete bindings[index][listName][i].r;
        delete bindings[index][listName][i].g;
        delete bindings[index][listName][i].b;
        delete bindings[index][listName][i].brightness;
        delete bindings[index][listName][i].temperature;
        break;
      }
    }

    update(index, { ...bindings[index] });
  };

  const tabs: TabInterface[] = useMemo(
    () =>
      fields[index][listName]
        .filter((x) => x.channelId && x.channelType)
        .map((_, index) => ({ label: `${index + 1}` })),
    [fields[index][listName], options, activeTabIndex],
  );

  const handleChangeTab = (index: number) => {
    setActiveTabIndex(index);
    setActiveChannelIndex(0);
  };

  return (
    <div
      className={classNames('input-bindings-configuration', {
        'one-channel-selected': fields[index][listName].length === 1,
      })}
    >
      <Tabs
        tabList={[
          { label: t('bindings.triggerMethods.singleClick') },
          { label: t('bindings.triggerMethods.doubleClick') },
        ]}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={handleChangeTab}
        tabsType="pills"
        autoScrollDisabled
        isDivider={false}
        fullWidth
      />
      <CheckboxSelect
        options={options[activeTabIndex]}
        onSave={handleChannelOptionsSave}
        label={t('bindings.channels')}
        multipleLabel={t('bindings.channels')}
        showDialogLabel
      />
      {tabs.length > 1 ? (
        <Tabs
          tabList={tabs}
          activeTabIndex={activeChannelIndex}
          setActiveTabIndex={setActiveChannelIndex}
          tabsType="pills"
          autoScrollDisabled
          isDivider={false}
          tabsListClassName="p-b-8 item-flex"
        />
      ) : null}
      {fields[index][listName]
        .filter((x) => x.channelId && x.channelType)
        .map((ch, i) => {
          if (activeChannelIndex !== i) return null;
          const val = bindings[index][listName][i].actionType || '';
          const channelName = options[activeTabIndex].find((x) => x.value === ch.channelId)?.label || '';
          const opts: SelectOptionInterface<ActionBindingType>[] =
            ch.channelType !== 'Cloud'
              ? actionOptions[ch.channelType].map((x) => ({
                  label: t(x.label),
                  value: x.value,
                  weight: x.weight,
                }))
              : [];

          return (
            <div
              key={ch.channelId}
              className={classNames(`channel-config-box`, {
                first: activeChannelIndex === 0,
                last: activeChannelIndex === fields[index][listName].length - 1,
              })}
            >
              <p className="channel-name">{channelName}</p>
              <div className="grid-list-24">
                {ch.channelType === 'Cloud' ? (
                  <CloudAction
                    bindingIndex={index}
                    bindingListIndex={i}
                    listName={activeTabIndex === 0 ? 'single' : 'double'}
                  />
                ) : (
                  <>
                    <SelectInputController
                      name={`bindings[${index}][${listName}][${i}].actionType`}
                      value={val}
                      isRequired
                      label={t('bindings.purpose')}
                      options={opts}
                      handleChange={(actionType) => handleChangeActionType(actionType, i, opts)}
                      className="input--reverse"
                    />
                    {additionalBindingsByType(i)}
                  </>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
