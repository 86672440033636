import React, { useMemo } from 'react';
import { RgbSceneIcon } from '../../../../components/rgb-scene';
import { RgbSceneItem } from '../../../channel-details/light/components/rgb/scenes/components/scene-item';
import { RgbSceneColor, useRgbSceneFormContext } from '../../context';
import './index.scss';

type RgbSceneDetailsProps = {
  colors?: RgbSceneColor[];
};

export const RgbSceneDetails: React.FC<RgbSceneDetailsProps> = ({ colors }) => {
  const { colors: contextColors, icon, name } = useRgbSceneFormContext();

  const colorsToRender = useMemo(() => {
    return colors ? colors : contextColors;
  }, [colors, contextColors]);

  const summaryGradient = useMemo(() => {
    if (!colorsToRender.length) {
      return ['#BBBBBB', '#DDDDDD'];
    } else if (colorsToRender.length === 1) {
      return [String(colorsToRender[0].hex), String(colorsToRender[0].hex)];
    } else {
      return colorsToRender?.map((color) => String(color.hex));
    }
  }, [colorsToRender]);

  return (
    <div className="rgb-scene-details">
      <RgbSceneItem colors={summaryGradient} name={name} icon={<RgbSceneIcon iconName={icon} />} />
    </div>
  );
};
