import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IconQr } from '../../../../../../components';

type ComponentProps = {
  setQrPageOpen: Dispatch<SetStateAction<boolean>>;
};

export const QrButton: React.FC<ComponentProps> = ({ setQrPageOpen }) => {
  const { t } = useTranslation('installation');

  const handleClick = () => setQrPageOpen(true);

  return (
    <button className="button button--secondary" onClick={handleClick}>
      <IconQr />
      {t('exalus.scanQr')}
    </button>
  );
};
