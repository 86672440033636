import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { DashboardItemsQueryVariables, Query } from '../../data-access/gql-types/graphql';
import { DASHBOARD } from '../../data-access/queries/dashboard';
import { sortByOrder } from '../../utils/helpers';
import { useInstallation } from '../installation';

export const useGroupAndAction = () => {
  const { t: tg } = useTranslation('groups');
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { data: dashboardData, loading: dashboardLoading } = useQuery<Query, DashboardItemsQueryVariables>(DASHBOARD, {
    variables: { id: selectedInstallationId },
    fetchPolicy: 'network-only',
    // onError: () => toastError({ content: t('errors.downloadData') }),
    skip: skipLavvaFetch,
  });

  const groups = useMemo(() => {
    return dashboardData?.allUserGroups
      .map((userGroup) => ({
        ...userGroup,
        group: {
          ...userGroup.group,
          name: userGroup.group.isDefaultGroup ? tg('favourites.header') : userGroup.group.name,
          iconName: userGroup.group.iconName,
        },
      }))
      .sort(sortByOrder);
  }, [dashboardData?.allUserGroups, tg]);

  const favouriteGroup = useMemo(
    () => dashboardData?.allUserGroups.find((userGroup) => userGroup.group.isDefaultGroup),
    [dashboardData?.allUserGroups],
  );

  return { groups: groups || [], favouriteGroup, dashboardLoading };
};
