import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogConfirmation, PopUpNav } from '../../../../../../components';
import { IconCkeckMarks, IconTimeDelay } from '../../../../../../components/popup-nav/icons';
import { pathnameWithParameters } from '../../../../../../utils/location';
import { useExalusDevicesContext } from '../../../../context/devices';
import { useDeviceListContext } from '../../context';
import { useDevices } from '../../hooks/use-devices';
import { useDevicesUpdates } from '../../hooks/use-devices-updates';

export const UpdateDevices: React.FC = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { t } = useTranslation('device-list');
  const { t: tc } = useTranslation('common');
  const { updateMode, setUpdateMode, showUpdateAllDialog, setShowUpdateAllDialog } = useDeviceListContext();
  const { updatesAvailability, checkDevicesUpdates } = useExalusDevicesContext();
  const { devices } = useDevices({ updateMode, updatesAvailability });
  const { handleUpdateAll, setUpdateResultDialog, updateResultDialog } = useDevicesUpdates(devices);

  useEffect(() => {
    const updates = new URLSearchParams(search).get('updates');
    setUpdateMode(!!updates);
  }, [search]);

  const showUpdateView = () => {
    if (updateMode) history.goBack();
    else {
      history.push(
        pathnameWithParameters(pathname, search, {
          key: 'updates',
          value: true,
        }),
      );
    }
  };

  const onUpdateAll = () => {
    handleUpdateAll(async () => {
      await checkDevicesUpdates();
      setShowUpdateAllDialog(false);
    });
  };

  const handleResultDialogClose = () => setUpdateResultDialog([]);

  return (
    <>
      <PopUpNav
        links={[
          {
            label: !updateMode ? t('updates') : t('updateCancel'),
            onClick: showUpdateView,
            icon: <IconCkeckMarks />,
          },
          ...(updateMode
            ? [
                {
                  label: t('checkUpdates'),
                  onClick: () => checkDevicesUpdates(true),
                  icon: <IconTimeDelay />,
                },
              ]
            : []),
        ]}
      />
      <DialogConfirmation
        show={showUpdateAllDialog}
        setShow={setShowUpdateAllDialog}
        header={t('updateAllContent')}
        primaryBtnText={t('update')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onUpdateAll}
        secondaryBtnAction={() => setShowUpdateAllDialog(false)}
      />
      <DialogConfirmation
        show={!!updateResultDialog.length}
        setShow={handleResultDialogClose}
        header={t('updateSummary')}
        primaryBtnText={tc('buttons.understand')}
        primaryBtnAction={handleResultDialogClose}
      >
        <ul>
          {updateResultDialog.map((upd, i) => (
            <li key={`${upd.name}-${i}`} className="m-b-24">
              <span style={{ textDecoration: 'none' }}>{`${upd.name}: `}</span>
              <span style={{ color: upd.color, textDecoration: 'none' }}>{upd.status}</span>
            </li>
          ))}
        </ul>
      </DialogConfirmation>
    </>
  );
};
