import React from 'react';
import classNames from 'classnames';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { IconCallButtonCircle, IconDoorEntryCircle, IconGateEntryCircle } from '../../../../../components';

type ComponentProps = {
  call: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  isListItem?: boolean;
  openGateway?: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  openGate?: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
};

export const EntryPhoneControlButtons: React.FC<ComponentProps> = ({ openGateway, openGate, call, isListItem }) => (
  <div
    className={classNames('cover-control-box', {
      'cover-control-box--list-view': isListItem,
    })}
  >
    <div className={classNames('cover-control-box__controls', { center: !openGateway && !openGate })}>
      {openGateway && (
        <div className="cover-control-box__control cover-control-box__control--cover" onClick={openGateway}>
          <IconDoorEntryCircle />
        </div>
      )}
      <div className="cover-control-box__control cover-control-box__control--cover" onClick={call}>
        <IconCallButtonCircle />
      </div>
      {openGate && (
        <div className="cover-control-box__control cover-control-box__control--cover" onClick={openGate}>
          <IconGateEntryCircle />
        </div>
      )}
    </div>
  </div>
);
