import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { DialogConfirmation, IconPhoto, PopUpNav } from '../../../../../../components';
import { IconUserEdit, IconInfo, IconPadlock, IconDelete } from '../../../../../../components/popup-nav/icons';
import { ROUTES } from '../../../../../../routes';
import { useExalusServicesContext } from '../../../../context/services';
import { useUsers } from '../../hooks/use-users';
import './index.scss';

type ComponentProps = {
  user: IUser;
  image: string;
};

const UserListItem: React.FC<ComponentProps> = ({ user, image }) => {
  const history = useHistory();
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const { usersApi } = useExalusServicesContext();
  const { deleteUser } = useUsers();

  const canEdit = useMemo(() => usersApi.CanEditUser(user), [user]);

  const handleDelete = () => deleteUser(user.Guid, setDeletePopup);

  return (
    <div key={user.Guid} className="user-item">
      <div className="user-item--data">
        <div className="image-container">{image ? <img src={image} alt={''} /> : <IconPhoto />}</div>
        <p>{user.Email}</p>
      </div>
      <PopUpNav
        dark
        links={[
          {
            onClick: () => history.push(ROUTES.UserDetails(user.Guid)),
            label: t('exalus.userDetails'),
            icon: <IconInfo />,
          },
          ...(canEdit
            ? [
                {
                  onClick: () => history.push(ROUTES.UserEdit(user.Guid)),
                  label: t('exalus.editUser'),
                  icon: <IconUserEdit />,
                },
              ]
            : []),
          ...(canEdit
            ? [
                {
                  onClick: () => history.push(ROUTES.ChangeUserPassword(user.Guid)),
                  label: t('exalus.changePassword'),
                  hasLineBelow: true,
                  icon: <IconPadlock />,
                },
              ]
            : []),
          ...(canEdit
            ? [
                {
                  onClick: () => setDeletePopup(true),
                  label: t('exalus.deleteUser'),
                  icon: <IconDelete />,
                },
              ]
            : []),
        ]}
      />
      <DialogConfirmation
        show={deletePopup}
        setShow={setDeletePopup}
        header={t('exalus.deleteTitle')}
        content={t('exalus.deleteConfirmation')}
        primaryBtnText={t('exalus.deleteUser')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleDelete}
        secondaryBtnAction={() => setDeletePopup(false)}
      />
    </div>
  );
};

export default UserListItem;
