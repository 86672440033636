import { ReactNode } from 'react';
import { BlindStateDirectionInternal } from '../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../types';
import {
  BlindStateTask,
  DimStateTask,
  GateStateTask,
  MicroventilationStateTask,
  OnOffStateTask,
  RGBWStateTask,
} from '../../exalus/pages/action-create/types';

export enum ActionType {
  Undefined = 'undefined',
  Action = 'action',
  Automation = 'automation',
}

export type NewActionForm = {
  name: string;
  icon_name: string;
};

export enum SceneTaskType {
  Unknown = 'Unknown',
  ChannelState = 'ChannelState',
}

export type ActionTaskOption = {
  title: string;
  icon: ReactNode;
  type: SceneTaskType;
};

export enum TaskVariant {
  Undefined = 'undefined',
  OnOff = 'onOff',
  Dim = 'dim',
  SetRGBW = 'setRGBW',
  SetBlind = 'setBlind',
  SetGate = 'setGate',
  SetVentilation = 'setVentilation',
}

export type ActionTaskVariantOption = {
  title: string;
  icon: ReactNode;
  variant: TaskVariant;
};

export type DeviceTaskParams = {
  channel: ChannelInterface;
  state: TaskState;
  variant: TaskVariant;
};

export type TaskState =
  | OnOffStateTask
  | DimStateTask
  | MicroventilationStateTask
  | RGBWStateTask
  | BlindStateTask<BlindStateDirectionInternal>
  | GateStateTask;

export type TaskParams = DeviceTaskParams;

export type Task = {
  id: string;
  taskType: SceneTaskType;
  taskParams: TaskParams;
};

export enum GateActionInternal {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Stopped = 'STOPPED',
  Tilt = 'TILT',
  Unknown = 'UNKNOWN',
}
