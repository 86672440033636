import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UserInstallation } from '../../../data-access/gql-types/graphql';
import { InstallationItem } from '../installation-item';

type InstallationItemSortableProps = {
  installation: UserInstallation;
};

export const InstallationItemSortable: React.FC<InstallationItemSortableProps> = ({ installation }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: installation.installationId,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  return (
    <InstallationItem
      installation={installation}
      isDraggable
      setNodeRef={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
    />
  );
};
