import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import { AvailableLanguage } from '../../types';

const options = {
  order: ['querystring', 'localStorage', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n
  .use(LanguageDetector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    debug: false,
    detection: options,
    fallbackLng: AvailableLanguage.EN,
    whitelist: [
      AvailableLanguage.PL,
      AvailableLanguage.EN,
      AvailableLanguage.DE,
      AvailableLanguage.FR,
      AvailableLanguage.LT,
      AvailableLanguage.ES,
      AvailableLanguage.IT,
      AvailableLanguage.HU,
      AvailableLanguage.RO,
      AvailableLanguage.NO,
      AvailableLanguage.FI,
      AvailableLanguage.SV,
      AvailableLanguage.NL,
      AvailableLanguage.PT,
      AvailableLanguage.CZ,
    ],

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/locales/add/{{lng}}/{{ns}}',
    },
  });

export default i18n;
