import {
  MultisensorChannelOffsetParams,
  MultisensorDetectionThresholdParams,
  MultisensorIntervalParams,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigParams';

export enum Rcm21ParamsEnum {
  HYSTERESIS = 'HysteresisParameters',
  MULTISENSOR_INTERVAL = 'MultisensorIntervalParameters',
  OFFSET = 'OffsetParameters',
}

export type Rcm21ConfigParams = {
  [Rcm21ParamsEnum.HYSTERESIS]: MultisensorDetectionThresholdParams | null;
  [Rcm21ParamsEnum.MULTISENSOR_INTERVAL]: MultisensorIntervalParams | null;
  [Rcm21ParamsEnum.OFFSET]: MultisensorChannelOffsetParams | null;
};

export const initialConfig: Rcm21ConfigParams = {
  [Rcm21ParamsEnum.HYSTERESIS]: null,
  [Rcm21ParamsEnum.MULTISENSOR_INTERVAL]: null,
  [Rcm21ParamsEnum.OFFSET]: null,
};
