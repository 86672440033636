import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  GlobalTimeParameters,
  IInputOutputSwitchParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { IRopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/IRopConfigService';
import { RopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/RopConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import EnablingTimeForm from '../on-off/enable-time';
import GlobalTimeForm from '../on-off/global-time';
import InputsModeForm from '../on-off/inputs-mode';
import TurnOnBehaviourForm from '../on-off/turn-behaviour';
import { initialConfig, RopConfigParams, RopParamsEnum } from './types';

type ComponentProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const RopConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<RopConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetSwitchParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: RopParamsEnum) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRopConfigService>(
      RopConfigService.ServiceName,
    );

    switch (panel) {
      case RopParamsEnum.TURN_ON_BEHAVIOUR: {
        if (channel) {
          const data = await configService.GetTurnOnBehaviourAsync(device, channel.Number);

          handleGetSwitchParamsResponse<StateOfTurnOnSwitchDeviceParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as StateOfTurnOnSwitchDeviceParameters });
            setOpen(true);
          });
        }
        break;
      }
      case RopParamsEnum.GLOBAL_TIME: {
        if (channel) {
          const data = await configService.GetGlobalTimeSettingsAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as GlobalTimeParameters });
            setOpen(true);
          });
        }
        break;
      }
      case RopParamsEnum.ENABLE_TIME: {
        if (channel) {
          const data = await configService.GetTurnOnTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as GlobalTimeParameters });
            setOpen(true);
          });
        }
        break;
      }
      case RopParamsEnum.INPUT_MODE: {
        if (channel) {
          const data = await configService.GetSwitchInputOutputParamAsync(device, channel.Number);

          handleGetSwitchParamsResponse<IInputOutputSwitchParameters[]>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as IInputOutputSwitchParameters[] });
            setOpen(true);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: RopParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const activeConfigName = useMemo(() => Object.keys(config).find((key) => config[key] !== null) || null, [config]);

  const renderCorrentForm = () => {
    if (!activeConfigName) return null;

    switch (activeConfigName) {
      case RopParamsEnum.TURN_ON_BEHAVIOUR: {
        return <TurnOnBehaviourForm device={device} turnOnBehaviour={config[activeConfigName]} />;
      }
      case RopParamsEnum.GLOBAL_TIME: {
        return <GlobalTimeForm device={device} timeParams={config[activeConfigName]} />;
      }
      case RopParamsEnum.ENABLE_TIME: {
        return <EnablingTimeForm device={device} timeParams={config[activeConfigName]} />;
      }
      case RopParamsEnum.INPUT_MODE: {
        return <InputsModeForm device={device} inputs={config[activeConfigName]} />;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.OnOffParams.InputModeParams')}
                onClick={() => handleOpen(RopParamsEnum.INPUT_MODE)}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.TurnOnBehaviourParams')}
                onClick={() => handleOpen(RopParamsEnum.TURN_ON_BEHAVIOUR)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.GlobalTimeParams')}
                onClick={() => handleOpen(RopParamsEnum.GLOBAL_TIME)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.EnableTimeParams')}
                onClick={() => handleOpen(RopParamsEnum.ENABLE_TIME)}
              />
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default RopConfigForm;
