import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FieldChangeResultType } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { toastSuccess } from '../../../../../utils/toast';
import {
  useExalusChannelChangeIcon,
  useExalusChannelChangeName,
  useExalusChannelChangeVisibility,
} from '../../../hooks';
import { ChannelConfigurationHook, ChannelConfigurationForm } from '../types';

export const useChannelConfiguration = (
  channel: IDeviceChannel | undefined,
  editGroups: boolean,
): ChannelConfigurationHook => {
  const { t: td } = useTranslation('channel-settings');
  const { mutate: editName } = useExalusChannelChangeName(channel);
  const { mutate: editVisibility } = useExalusChannelChangeVisibility(channel);
  const { mutate: editIcon } = useExalusChannelChangeIcon(channel);
  const form = useForm<ChannelConfigurationForm>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      icon_name: '',
      hidden: false,
    },
  });

  const { setValue, watch } = form;
  const values = watch();

  useEffect(() => {
    if (channel) {
      setValue('name', channel.Name);
      setValue('hidden', channel.IsHidden);
      setValue('icon_name', channel.IconName || '');
    }
  }, [channel]);

  const handleChangeVisibility = () => form.setValue('hidden', !values.hidden);

  const onSubmit = useCallback(
    (formData: ChannelConfigurationForm) => {
      if (!editGroups) {
        if (values.name !== channel?.Name) {
          editName(formData.name, {
            onSuccess: async (data) => {
              if (data?.Type === FieldChangeResultType.Changed) {
                toastSuccess({ content: td('toast.editNameSuccess') });
              }
            },
          });
        }

        if (values.hidden !== channel?.IsHidden) {
          editVisibility(formData.hidden, {
            onSuccess: async (data) => {
              if (data?.Type === FieldChangeResultType.Changed) {
                toastSuccess({ content: td('toast.editVisibilitySuccess') });
              }
            },
          });
        }

        if (values.icon_name !== channel?.IconName) {
          editIcon(formData.icon_name, {
            onSuccess: async (data) => {
              if (data?.Type === FieldChangeResultType.Changed) {
                toastSuccess({ content: td('toast.editIconSuccess') });
              }
            },
          });
        }
      }
    },
    [channel, values, editGroups],
  );

  const submitActive = useMemo(() => (
    !!channel && (
      values.name !== channel.Name ||
      values.hidden !== channel.IsHidden ||
      values.icon_name !== channel.IconName
    )
  ), [channel, values]);

  return {
    form,
    onSubmit,
    submitActive,
    handleChangeVisibility,
    values,
  };
};
