import { StateCreateInstallationType } from '../modules/installation-create/context';

export const ROUTES = {
  Root: (): string => '/',
  Notification: (): string => '/notification',
  Channel: (): string => `/channel`,
  ChannelsVisibility: (): string => `/channel/visibility`,
  ChannelDetails: (channelId = ':channelId'): string => `/channel/${channelId}`,
  Device: (): string => `/device`,
  DeviceSettings: (deviceId = ':deviceId'): string => `/device/${deviceId}/settings`,
  DeviceWifiConfiguration: (): string => `/device/wifi-configuration`,
  DeviceAddBluetooth: (): string => '/device-add/bluetooth',
  DeviceAddBluetoothIdentify: (): string => '/device-add/bluetooth/identify',
  DeviceAddBluetoothNetwork: (): string => '/device-add/bluetooth/network',
  DeviceAddBluetoothSuccess: (deviceId = ':deviceId'): string => `/device-add/bluetooth/success/${deviceId}`,
  DeviceUpdateBluetoothSuccess: (): string => `/device-add/bluetooth/update-success`,
  DeviceAddBluetoothSetWifiSuccess: (): string => '/device-add/bluetooth/wifi-success/',
  DeviceAddBluetoothUpdate: (): string => '/device-add/bluetooth/update',
  DeviceAddBluetoothWisniowskiStart: (): string => '/device-add/bluetooth/wisniowski-start',
  DeviceAddBluetoothWisniowskiTutorialStep0: (type = ':type'): string =>
    `/device-add/bluetooth/wisniowski-tutorial-0/${type}`,
  DeviceAddBluetoothWisniowskiTutorialStep1: (): string => '/device-add/bluetooth/wisniowski-tutorial-1',
  DeviceAddBluetoothWisniowskiTutorialStep2: (type = ':type'): string =>
    `/device-add/bluetooth/wisniowski-tutorial-2/${type}`,
  DeviceAddBluetoothWisniowskiSuccess: (): string => '/device-add/bluetooth/wisniowski-success',
  ChannelSettings: (deviceId = ':deviceId', channelId = ':channelId'): string =>
    `/device/${deviceId}/settings/${channelId}`,
  Settings: (): string => '/settings',
  Group: (): string => `/group`,
  GroupSort: (): string => `/group-sort`,
  GroupSortChannels: (isListView: boolean | undefined = undefined): string =>
    `/group-sort-channels${isListView !== undefined ? `?is-list-view=${isListView}` : ''}`,
  FavouritesForm: (groupId = ':groupId'): string => `/group/${groupId}/favourites-edit`,
  GroupEdit: (groupId = ':groupId'): string => `/group/${groupId}/edit`,
  GroupCreate: (): string => '/group-create',
  Terms: (): string => '/terms',
  Privacy: (): string => '/privacy',
  Support: (): string => '/support',
  Rodo: (): string => '/rodo',
  Migration: (): string => '/migration',
  CreateAccountOffer: (): string => '/create-account-offer',
  Installation: (): string => '/installation',
  InstallationSettings: (installationId = ':installationId'): string => `/installation/${installationId}`,
  InstallationForwardSummary: (installationId = ':installationId'): string =>
    `/installation/${installationId}/forward-summary`,
  InstallationRemove: (installationId = ':installationId', name = ':name'): string =>
    `/installation/${installationId}/remove/${name}`,
  InstallationShared: (installationId = ':installationId'): string => `/installation/${installationId}/shared`,
  SharedUser: (userId = ':userId'): string => `/user/${userId}/shared`,
  InstallationShareEmail: (): string => '/share-user',
  InstallationShareSubject: (): string => '/share-subject',
  InstallationShareRole: (): string => '/share-role',
  InstallationShareRestriction: (): string => '/share-restriction',
  InstallationGroupsShare: (): string => '/share-groups',
  UserSharedGroups: (userId = ':userId'): string => `/user/${userId}/shared-groups`,
  UserSharedChannels: (userId = ':userId'): string => `/user/${userId}/shared-channels`,
  InstallationChannelsShare: (): string => '/share-channels',
  InstallationSummaryShare: (): string => '/share-summary',
  InvitationSummary: (status = ':status', id = ':id'): string => `/invitation-summary/${status}/${id}`,
  InstallationShareAccessCount: (): string => '/share-access-count',
  InstallationShareAccessTime: (): string => '/share-access-time',
  InstallationCreate: (): string => '/installation-create',
  InstallationCreated: (): string => '/installation-created',
  IntegrationsList: (): string => '/integrations-list',
  IntegrationSettings: (type = ':type', module = ':module', integrationId = ''): string =>
    `/integration-settings/${type}/${module}${integrationId ? `?integrationId=${integrationId}` : ''}`,
  ActionList: (): string => '/action',
  ActionEditChannels: (actionId = ':actionId'): string => `/action/${actionId}/edit-devices`,
  ActionEditName: (actionId = ':actionId'): string => `/action/${actionId}/edit-name`,
  ActionChannelsSort: (actionId = ':actionId'): string => `/action/${actionId}/devices/sort`,
  ActionCreateBasic: (): string => `/action-create/basic`,
  ActionCreate: (): string => `/action-create`,
  ActionCreateTasks: (): string => '/action-create/tasks',
  TriggerCreate: (): string => `/action-create/advanced/automatic`,
  ActionDetails: (actionId = ':actionId'): string => `/action/${actionId}`,
  TriggerDetails: (triggerId = ':triggerId'): string => `/trigger/${triggerId}`,
  TriggerEdit: (triggerId = ':triggerId'): string => `/trigger/${triggerId}/edit`,
  InvitationReceived: (notificationId = ':notificationId'): string => `/invitation-received/${notificationId}`,
  InvitationAccepted: (notificationId = ':notificationId'): string => `/invitation-accepted/${notificationId}`,
  ChannelConfiguration: (channelType = ':channelType', channelId = ':channelId'): string =>
    `/channel-configuration/${channelType}/${channelId}`,
  ConfigurationDetails: (deviceId = ':deviceId'): string => `/channel-configuration-details/${deviceId}`,
  CalibrateChannel: (channelId = ':channelId'): string => `/calibrate-channel/${channelId}`,
  AddSuccess: (channelId = ':channelId'): string => `/add-success/${channelId}`,
  MeasurementDetails: (
    channelId = ':channelId',
    measurementGroup = ':measurementGroup',
    measurement = ':measurement',
    kind = ':kind',
    phase = '',
  ): string => `/measurement/${channelId}/${measurementGroup}/${measurement}/${kind}${phase ? `?phase=${phase}` : ''}`,
  RgbSceneCreate: (): string => `/rgb-scene/create`,
  OptimizerSchedule: (channelId = ':channelId'): string => `/optimizer-schedule/${channelId}`,
  OptimizerScheduleEdit: (channelId = ':channelId', scheduleId = ':scheduleId'): string =>
    `/optimizer-schedule/${channelId}/edit/${scheduleId}`,
  ExalusIntegrationCreate: (state?: StateCreateInstallationType): string =>
    `/integration/exalus/controller${state ? `?state=${encodeURIComponent(JSON.stringify(state))}` : ''}`,
  ExalusAuthorization: (serialNumber = '', pin = ''): string =>
    `/integration/exalus/auth${serialNumber && pin ? `?serialNumber=${serialNumber}&pin=${pin}` : ''}`,
  ExalusLogin: (serialNumber = '', pin = '', installationId = ''): string =>
    `/exalus/login${
      serialNumber && pin ? `?serialNumber=${serialNumber}&pin=${pin}&installationId=${installationId}` : ''
    }`,
  ExalusQRCodeAuthorization: (serialNumber = '', pin = ''): string =>
    `/exalus/qr-auth${serialNumber && pin ? `?serialNumber=${serialNumber}&pin=${pin}` : ''}`,
  UserManual: (modelId = ':modelId'): string => `/user-manual/${modelId}`,
  CentralAction: (type = ':type', groupId = ':groupId'): string => `/central-action/${type}/${groupId}`,
  CentralActionByType: (type = ':type'): string => `/central-action/${type}`,
  AddExalusDevices: (): string => '/add-devices',
  FacadeCalibration: (channelId = ':channelId'): string => `/facade-calibration/${channelId}`,
  DeviceRemotes: (channelId = ':channelId'): string => `/device-remotes/${channelId}`,
  Srp22Calibration: (channelId = ':channelId'): string => `/srp22-calibration/${channelId}`,
  DeviceUpdate: (deviceId = ':deviceId'): string => `/device-update/${deviceId}`,
  BlindAutoCalibration: (channelId = ':channelId'): string => `/blind-auto-calibration/${channelId}`,

  ExalusUsersList: (): string => '/users',
  UserDetails: (userId = ':userId'): string => `/users/details/${userId}`,
  UserEdit: (userId = ':userId'): string => `/users/edit/${userId}`,
  ChangeUserPassword: (userId = ':userId'): string => `/users/change-password/${userId}`,
  UserCreate: (): string => '/users/create',

  ExalusControllerSettings: (): string => '/exalus/controller-settings',
  ExalusNetworkSettings: (): string => '/exalus/controller-settings/network',
  ExalusDnsAdd: (): string => '/exalus/dns-add',
  ExalusNetworkStaticSettings: (): string => '/exalus/network-settings/static',

  ExalusActionCreateConditions: (): string => '/action-create/conditions',
  ExalusActionConditionsTypes: (): string => '/action-create/condition-types',
  ActionTasksTypes: (): string => '/action-create/task-types',
  ActionTasksVariants: (): string => '/action-create/task-variants',
  DeviceVariants: (): string => '/action-create/task-variants/device',
  ActionChannelSettings: (): string => '/action-create/task-variants/channel-settings',
  ExalusActionTaskDelay: (): string => '/action-create/task-variants/task-delay',
  ExalusActionTaskLock: (): string => '/action-create/task-variants/task-lock',
  ExalusActionTaskNotify: (): string => '/action-create/task-variants/task-notify',
  ExalusActionSources: (): string => '/action-create/condition-sources',
  ExalusDeviceSource: (): string => '/action-create/condition-sources/device',
  ExalusScheduleSource: (): string => '/action-create/condition-sources/schedule',
  ExalusScenarioSource: (): string => '/action-create/condition-sources/scenario',
  ExalusDeviceParameters: (): string => '/action-create/condition-sources/device-parameters',

  ExalusActionDetails: (actionId = ':actionId'): string => `/action-details/${actionId}`,
  ActionEdit: (actionId = ':actionId'): string => `/action-edit/${actionId}`,
  ActionEditConditions: (actionId = ':actionId'): string => `/action-edit/conditions/${actionId}`,
  ActionEditTasks: (actionId = ':actionId'): string => `/action-edit/tasks/${actionId}`,
  Store: (): string => '/store',
  StoreDeviceDetails: (modelId = ':modelId'): string => `/store/${modelId}`,

  Chat: (): string => '/chat',
};
