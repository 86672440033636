import { Api } from 'lavva.exalushome';
import {
  IRemoteStorageService,
  RemoteStorageDataEntry,
} from 'lavva.exalushome/build/js/Services/IRemoteStorageService';
import { RemoteStorageService } from 'lavva.exalushome/build/js/Services/RemoteStorageService';

export type ExalusRemoteStorage = {
  isDashboardGridView: boolean;
  isChannelsListView: boolean;
  activeGroupIndex: number;
  activeChannelTabIndex: number;
  readingChat: boolean;
  iconName: string;
  hexColor: string;
  name: string;
};

export const defaultState: ExalusRemoteStorage = {
  isDashboardGridView: true,
  isChannelsListView: false,
  activeGroupIndex: 0,
  activeChannelTabIndex: 0,
  readingChat: false,
  iconName: '',
  hexColor: '',
  name: '',
};

export const useExalusRemoteStorage = () => {
  const saveExalusDataStorage = async <Key extends keyof ExalusRemoteStorage>(
    key: Key,
    value: ExalusRemoteStorage[Key],
  ) => {
    const remoteStorageApi = Api.Get<IRemoteStorageService>(RemoteStorageService.ServiceName);

    return await remoteStorageApi.SaveAsync(key, false, value);
  };

  const readExalusDataStorage = async <T,>(key: keyof ExalusRemoteStorage) => {
    const remoteStorageApi = Api.Get<IRemoteStorageService>(RemoteStorageService.ServiceName);

    const data = await remoteStorageApi.ReadAsync(key, false, false);
    if ((data as RemoteStorageDataEntry<T>).ResourceGuid) {
      return (data as RemoteStorageDataEntry<T>).Data;
    }

    return undefined;
  };

  const removeExalusDataStorage = async <Key extends keyof ExalusRemoteStorage>(key: Key) => {
    const remoteStorageApi = Api.Get<IRemoteStorageService>(RemoteStorageService.ServiceName);
    return await remoteStorageApi.RemoveAsync(key, false);
  };

  return {
    saveExalusDataStorage,
    readExalusDataStorage,
    removeExalusDataStorage,
  };
};
