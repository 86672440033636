import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, IconError } from '../../../components';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { GateFeatureTypeInternal, GateModeInternal, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ChannelInterface } from '../../../types';
import { ChannelGateInterface } from '../../../types/channel/gate';
import { GateHistory } from './components/gate-history';
import { GateRoll } from './components/gate-roll';
import { GateStepByStep } from './components/gate-step-by-step';

type ChannelDetailsGateProps = {
  channel: ChannelInterface;
};

const ChannelDetailsGate: React.FC<ChannelDetailsGateProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [preventSwipeViews, setPreventSwipeViews] = useState<boolean>(false);
  const { permissions } = usePermissionsContext();

  const gateMode = useMemo(() => {
    const supported = (channel.data as ChannelGateInterface).supportedGateFeatures;

    if (supported.includes(GateFeatureTypeInternal.StepByStep) && supported.includes(GateFeatureTypeInternal.SetDir)) {
      return GateModeInternal.RollUp;
    }

    return (channel.data as ChannelGateInterface).gateMode;
  }, [(channel.data as ChannelGateInterface).gateMode, (channel.data as ChannelGateInterface).supportedGateFeatures]);

  const historyVisible = useMemo(() => permissions[UserPermissionType.GateHistory], [permissions]);

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={preventSwipeViews}
      {...(gateMode !== GateModeInternal.Unknown
        ? {
            tabs: [{ label: t('control') }, ...(historyVisible ? [{ label: t('history') }] : [])],
            additionalTabs: [...(historyVisible ? [<GateHistory key={1} channelId={channel.id} />] : [])],
          }
        : {})}
    >
      {gateMode !== GateModeInternal.Unknown ? (
        <>
          {gateMode === GateModeInternal.RollUp ? (
            <GateRoll channel={channel} setPreventSwipeViews={setPreventSwipeViews} />
          ) : (
            <GateStepByStep channel={channel} />
          )}
        </>
      ) : (
        <ControlWrapper>
          <IconError />
          <p style={{ marginLeft: 12, textTransform: 'uppercase' }}>{t('unknowMode')}</p>
        </ControlWrapper>
      )}
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsGate;
