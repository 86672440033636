import React from 'react';
import { IconPadlock } from '../../../../../components';
import { IconSignal } from '../../../../../components/icons/icon-signal';
import './index.scss';

type ComponentProps = {
  signal: number;
  remembered?: boolean;
};

export const NetworkState: React.FC<ComponentProps> = ({ signal, remembered }) => (
  <div className="network-state">
    {remembered && (
      <div className="remembered">
        <IconPadlock isOn={false} />
      </div>
    )}
    <IconSignal signal={signal} />
  </div>
);
