import {
  BrightnessThresholdStep,
  WindThresholdStep,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigParams';

export enum WszBidiParamsEnum {
  WIND_THRESHOLD = 'WindThreshold',
  BRIGHTNESS_THRESHOLD = 'BrightnessThreshold',
}

export type WszBidiConfigParams = {
  [WszBidiParamsEnum.WIND_THRESHOLD]: WindThresholdStep | null;
  [WszBidiParamsEnum.BRIGHTNESS_THRESHOLD]: BrightnessThresholdStep | null;
};

export const initialConfig: WszBidiConfigParams = {
  [WszBidiParamsEnum.WIND_THRESHOLD]: null,
  [WszBidiParamsEnum.BRIGHTNESS_THRESHOLD]: null,
};
