import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Header, Page, PopUpNav, IconAdd, Tabs, ChannelBox, Carousel, CarouselItem, ActionBox } from '../../components';
import { Tip } from '../../components/tip';
import { LearningScenarios } from '../../data-access/gql-types/graphql';
import { useInstallation } from '../../hooks';
import { ChannelInterface } from '../../types';
import { isLocalApp } from '../../utils/helpers/local-app';
import CheckContentWrapper, { PageType } from '../common/check-content-wrapper';
import { useAddDevice } from '../device-add/hooks/use-add-device';
import { useCentralAction } from './hooks/use-central-action';
import { useChannelList } from './hooks/use-channel-list';

const ChannelList: React.FC = () => {
  const { t } = useTranslation('channel-list');
  const { t: td } = useTranslation('device-list');
  const { selectedInstallation, installationList } = useInstallation();
  const { addDevice } = useAddDevice();
  const { createCentralAction } = useCentralAction();
  const { channelGroups, isListView, popUpNavLinks, tabs, activeTabIndex, handleSetActiveTabIndex } = useChannelList();

  return (
    <Page
      isStickyHeader
      containerClassName="page__container--flex-height"
      carouselContent={installationList.length !== 0 || selectedInstallation !== undefined}
      header={
        <>
          <Header title={t('channels')} installationLogoTitleHeight>
            {!isLocalApp && (
              <button onClick={addDevice}>
                <IconAdd />
              </button>
            )}
            <PopUpNav links={popUpNavLinks} />
          </Header>
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={handleSetActiveTabIndex}
            tabsType="pills"
            isDivider={false}
            moreButton="normal"
          />
        </>
      }
    >
      <CheckContentWrapper page={PageType.Channels}>
        <Carousel setActiveIndex={handleSetActiveTabIndex} activeIndex={activeTabIndex}>
          {channelGroups.map((x) => {
            const central = createCentralAction(x.type, x.channels);

            return (
              <CarouselItem key={x.label} className={'m-t-8'}>
                <div
                  className={classNames('p-b-24 dashboard__device-list', {
                    'grid grid--2 grid--small-gap': !isListView,
                    'grid-list-16': isListView,
                  })}
                >
                  {central && (
                    <ActionBox key={x.label} isListItem={isListView} actionData={central} actionId={x.type} />
                  )}

                  {x.channels.map((channel: ChannelInterface) => (
                    <ChannelBox key={channel.id} channel={channel} isListItem={isListView} />
                  ))}
                </div>
              </CarouselItem>
            );
          })}
        </Carousel>
      </CheckContentWrapper>
      <Tip tip={td('DeviceAddTip')} learningKey={LearningScenarios.AddDevice} point={<IconAdd />} top={40} right={70} />
    </Page>
  );
};

export default ChannelList;
