import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IconInitiate2 } from '../icons';
import './index.scss';

type PropsInterface = {
  isOn: boolean;
  onClickControl: (status: boolean) => void;
  isSmall?: boolean;
  isChannelItem?: boolean;
  active?: boolean;
  children?: ReactNode;
  headerButton?: boolean;
  className?: string;
  checkStatus?: boolean;
};
export const InitiateButton: React.FC<PropsInterface> = ({
  isOn,
  onClickControl,
  isSmall,
  isChannelItem,
  active,
  children,
  className,
  headerButton,
  checkStatus = true,
}) => {
  const [deviceOn, setDeviceOn] = useState<boolean>(isOn);
  const deviceRef = useRef(isOn);

  useEffect(() => {
    setDeviceOn(isOn);
    deviceRef.current = isOn;
  }, [isOn, deviceRef, setDeviceOn]);

  const handleClick = () => {
    setDeviceOn(!deviceOn);
    onClickControl(!deviceOn);

    if (checkStatus) {
      const timeout = setTimeout(() => {
        setDeviceOn(deviceRef.current);
      }, 1500);

      return () => clearTimeout(timeout);
    }
  };

  return (
    <>
      {!isSmall ? (
        <div className={classNames('initiate-button', className)}>
          <div className={classNames('initiate-button__bg-element', { 'turn-on': isOn })} />
          <button onClick={handleClick} className={classNames('initiate-button__button', { active: active })}>
            <div
              className={classNames('initiate-button__button-icon', { 'initiate-button__button-icon--on': deviceOn })}
            >
              {children}
            </div>
          </button>
        </div>
      ) : (
        <div className="initiate-button initiate-button--small">
          <button
            onClick={handleClick}
            className={classNames('initiate-button--small__button-icon', className, {
              'initiate-button--small__button-icon--on': deviceOn && !headerButton,
              'channel-item': isChannelItem,
            })}
          >
            {children || (
              <IconInitiate2
                className={classNames({
                  'icon-initiate-2--color-primary': isChannelItem,
                })}
              />
            )}
          </button>
        </div>
      )}
    </>
  );
};
