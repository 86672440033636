import { gql } from '@apollo/client';

export const CHANGE_NAME = gql`
  mutation ChangeUserName($input: ChangeUserNameInput!) {
    changeUserName(input: $input) {
      result {
        ok
      }
      errors {
        ... on UserServiceError {
          message
        }
      }
    }
  }
`;

export const REMOVE_PROFILE_IMAGE = gql`
  mutation RemoveProfileImage {
    removeProfileImage {
      ok
    }
  }
`;

export const MARK_ACTVITY = gql`
  mutation MarkActivity {
    markActivity {
      ok
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation UpdateNotificationPermissions($input: UpdateNotificationPermissionsRequestInput!) {
    updateNotificationPermissions(input: $input) {
      ok
    }
  }
`;

export const DEREGISTER_PUSH_NOTIFICATION_RECEIVERBY_NAME = gql`
  mutation DeregisterPushNotificationReceiverByName($name: String!) {
    deregisterPushNotificationReceiverByName(name: $name) {
      id
    }
  }
`;

export const UPDATE_USER_LANGUAGE = gql`
  mutation UpdateUserLanguage($input: UpdateUserLanguageInput!) {
    updateUserLanguage(input: $input) {
      result {
        ok
      }
      errors {
        ... on UserProfileError {
          error
          message
        }
      }
    }
  }
`;

export const MARK_LEARNING_SCENARION_AS_VIEWED = gql`
  mutation MarkLearningScenarioAsViewed($input: MarkLearningScenarioAsViewedInput!) {
    markLearningScenarioAsViewed(input: $input) {
      result {
        ok
      }
      errors {
        ... on UserProfileError {
          error
          message
        }
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings($input: UpdateNotificationSettingsInput!) {
    updateNotificationSettings(input: $input) {
      result {
        ok
      }
    }
  }
`;