import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import * as duration from 'duration-fns';
import { IconDayTime, IconTime } from '../../../components';
import { DEFAULT_TIME_ZONE } from '../../../const';
import { DayOfWeekInternal, TriggerTimeConditionResponse } from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';
import { convertHoursToMinutes, leadingZero, sortedDays } from '../../../utils/helpers';
import ActionConditionItemDetails from '../condition-item-details';

type PropsInterface = {
  timeCondition: TriggerTimeConditionResponse;
  removeTimeCondition?: () => void;
  handleTimeConditionClick?: () => void;
};

export const TimeConditionDetails: React.FC<PropsInterface> = ({
  timeCondition,
  removeTimeCondition,
  handleTimeConditionClick,
}) => {
  const { t } = useTranslation('action');
  const { selectedInstallation } = useInstallation();

  const shortDayOfWeek = (day: DayOfWeekInternal) => {
    return t(`trigger.shortenedDaysOfWeek.${day.toLowerCase()}`);
  };

  const condition = useMemo(() => {
    return {
      icon: timeCondition.atSunset || timeCondition.atSunrise ? <IconDayTime /> : <IconTime size={30} />,
      title:
        timeCondition.atSunset || timeCondition.atSunrise
          ? t('trigger.conditions.time.types.daytime')
          : t('trigger.type.time.header'),
    };
  }, [timeCondition]);

  const repeatOption = useMemo(() => {
    if (timeCondition.daysOfWeek?.length) {
      return [...timeCondition.daysOfWeek]
        ?.sort((a, b) => sortedDays[a] - sortedDays[b])
        .map((day) => shortDayOfWeek(day))
        .join(', ');
    } else if (timeCondition.everyYear) {
      return t('trigger.conditions.time.repeat.yearly');
    } else if (timeCondition.everyMonth) {
      return t('trigger.conditions.time.repeat.monthly');
    } else if (timeCondition.targetExecutionCount === 1) {
      return t('trigger.conditions.time.repeat.once');
    } else {
      return t('trigger.conditions.time.repeat.daily');
    }
  }, [timeCondition]);

  const parsedTimeOfDay = (time: string) => {
    const { hours, minutes } = duration.parse(time);
    const timeRepeatAsDate = new Date();
    timeRepeatAsDate.setHours(Number(leadingZero(hours)));
    timeRepeatAsDate.setMinutes(Number(leadingZero(minutes)));

    return formatInTimeZone(
      `${format(timeRepeatAsDate, 'yyyy-MM-dd')}T${leadingZero(hours)}:${leadingZero(minutes)}Z`,
      selectedInstallation?.payload?.timeZone || DEFAULT_TIME_ZONE,
      'HH:mm',
    );
  };

  const parsedAdvanceDelayValue = (time: string) => {
    const { hours, minutes } = duration.parse(time);
    return convertHoursToMinutes({ hours, minutes });
  };

  const firstLine = useMemo(() => {
    if (timeCondition.atSunset) {
      return t('trigger.sunActionOptionsShort.sunset');
    } else if (timeCondition.atSunrise) {
      return t('trigger.sunActionOptionsShort.sunrise');
    } else if (timeCondition.timeOfDay) {
      return parsedTimeOfDay(timeCondition.timeOfDay);
    }
  }, [timeCondition, t]);

  const secondLine = useMemo(() => {
    const delayModification = timeCondition.sunriseDelay || timeCondition.sunsetDelay;
    const advanceModification = timeCondition.sunriseAdvance || timeCondition.sunsetAdvance;
    if (delayModification) {
      return (
        <p className="action-condition__text">
          <span>{t('trigger.sunActionModificationOptions.delay')} </span>
          {parsedAdvanceDelayValue(delayModification)} {t('trigger.minutesUnitShort')}
        </p>
      );
    } else if (advanceModification) {
      return (
        <p className="action-condition__text">
          <span>{t('trigger.sunActionModificationOptions.advance')} </span>
          {parsedAdvanceDelayValue(advanceModification)} {t('trigger.minutesUnitShort')}
        </p>
      );
    }
    return null;
  }, [timeCondition, t]);

  const handleClick = () => handleTimeConditionClick?.();

  return (
    <ActionConditionItemDetails
      removeCondition={removeTimeCondition}
      handleConditionClick={handleClick}
      title={condition.title}
      icon={condition.icon}
      repeatOption={repeatOption}
      firstLine={firstLine}
      secondLine={secondLine}
    />
  );
};
