import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useMeterSetTariff } from '../../../api/modules/meter/meter.hooks';
import { MeterTariffConfig, TimeZoneKindInternal } from '../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../hooks';
import { getEnumKeyByValue, leadingZero } from '../../../utils/helpers';
import { TariffBody } from '../../channel-details/optimizer/configuration/types';
import { prepareOperatorAndTariffForRequest } from '../../channel-details/optimizer/configuration/utils';
import { tariffKind } from '../../channel-details/optimizer/configuration/utils/tariff-kind';
import { useMeterTariffErrors } from './use-meter-tariff-validation';

export const useMeterTariffConfiguration = (form: UseFormReturn<TariffBody>) => {
  const { channelId } = useParams<{ channelId: string }>();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate } = useMeterSetTariff();
  const { handleMeterTariffErrors } = useMeterTariffErrors();
  const { setValue } = form;

  const setMeterTariffData = (tariffData: MeterTariffConfig | undefined) => {
    if (tariffData) {
      setValue('wholeWeekendCheaper', tariffData.wholeWeekendCheaper);
      setValue('wholeHolidaysCheaper', tariffData.wholeHolidaysCheaper);
      setValue('ignoreSummerTimeChanges', tariffData.ignoreSummerTimeChanges);
      setValue(
        'hoursRanges',
        tariffData.hoursRanges.map((x) => ({
          ...x,
          from: `${leadingZero(x.from)}:00`,
          to: `${leadingZero(x.to)}:00`,
        })),
      );

      const tariff = tariffKind[tariffData.kind];
      setValue('operator', tariff.operator);
      setValue('tariffName', tariff.tariff);
    }
  };

  const handleSubmitTariff = (values: TariffBody, onSuccess: () => void) => {
    const { wholeWeekendCheaper, wholeHolidaysCheaper, ignoreSummerTimeChanges, hoursRanges, operator, tariffName } =
      values;

    turnOnBackdrop();

    mutate(
      {
        channelId,
        tariff: {
          wholeWeekendCheaper,
          wholeHolidaysCheaper,
          ignoreSummerTimeChanges,
          hoursRanges: hoursRanges.map((range) => ({
            from: parseInt(range.from.replace(':00', '')),
            to: parseInt(range.to.replace(':00', '')),
            timeZoneKind: getEnumKeyByValue(TimeZoneKindInternal, range.timeZoneKind),
            pricingTier: range.pricingTier,
          })),
          kind: prepareOperatorAndTariffForRequest(operator, tariffName),
        },
      },
      {
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: AxiosError) => {
          turnOffBackdrop();
          handleMeterTariffErrors(error);
        },
      },
    );
  };

  return {
    setMeterTariffData,
    handleSubmitTariff,
  };
};
