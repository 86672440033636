import React from 'react';
import classNames from 'classnames';
import { IconNoConnection } from '../icons/icon-no-connection';
import './index.scss';

type ComponentProps = {
  signal: number;
};

export const SignalStrength: React.FC<ComponentProps> = ({ signal }) => {
  return (
    <div className="signal-strength">
      {signal > 0 ? (
        [1, 2, 3, 4, 5].map((index) => (
          <div
            key={index}
            className={classNames(`signal-strength__bar signal-strength__bar--${index}`, {
              background: signal >= index,
            })}
          ></div>
        ))
      ) : (
        <IconNoConnection unknown={signal < 0} />
      )}
    </div>
  );
};
