import React from 'react';

type IconCeilingLampProps = {
  isOn: boolean;
};

export const IconCeilingLamp: React.FC<IconCeilingLampProps> = ({ isOn = true }) => {
  return isOn ? (
    <svg
      className="icon-switch icon-ceiling-lamp"
      width="21"
      height="29"
      viewBox="0 0 21 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5456 16.439C5.69406 16.439 1.75347 19.9823 1.68832 23.7266C1.68742 23.7785 1.72962 23.8207 1.78158 23.8207H19.3096C19.3616 23.8207 19.4038 23.7785 19.4029 23.7266C19.3377 19.9823 15.3972 16.439 10.5456 16.439Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M13.4982 26.7736C12.969 27.6563 12.1762 28.25 10.5455 28.25C8.91474 28.25 8.21899 27.6563 7.59277 26.7736"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.5457 0.9375V10.5338V14.9628M10.5457 14.9628L10.1766 14.5937C9.76799 14.1851 9.06934 14.4745 9.06934 15.0524V15.0524C9.06934 15.4106 9.35973 15.701 9.71796 15.701H11.3734C11.7316 15.701 12.022 15.4106 12.022 15.0524V15.0524C12.022 14.4745 11.3234 14.1851 10.9148 14.5937L10.5457 14.9628Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
    </svg>
  ) : (
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5456 16.439C5.69406 16.439 1.75347 19.9823 1.68832 23.7266C1.68742 23.7785 1.72962 23.8207 1.78158 23.8207H19.3096C19.3616 23.8207 19.4038 23.7785 19.4029 23.7266C19.3377 19.9823 15.3972 16.439 10.5456 16.439Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M10.5457 0.9375V10.5338V14.9628M10.5457 14.9628L10.1766 14.5937C9.76799 14.1851 9.06934 14.4745 9.06934 15.0524V15.0524C9.06934 15.4106 9.35973 15.701 9.71796 15.701H11.3734C11.7316 15.701 12.022 15.4106 12.022 15.0524V15.0524C12.022 14.4745 11.3234 14.1851 10.9148 14.5937L10.5457 14.9628Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
    </svg>
  );
};
