import { formatInTimeZone } from 'date-fns-tz';
import * as duration from 'duration-fns';
import { DEFAULT_TIME_ZONE } from '../../../const';
import { TriggerDateTimeResponse, TriggerExecutionInfoResponse } from '../../../data-access/gql-types/graphql';
import { leadingZero } from '../../../utils/helpers';

export const getCurrentDate = () => {
  const current = new Date();
  current.setHours(current.getUTCHours(), current.getMinutes(), current.getSeconds());

  return current;
};

export const getPlannedExecutionDate = (item: TriggerExecutionInfoResponse) => {
  const date = item.plannedExecutionAt?.date;
  const time = duration.parse(item.plannedExecutionAt?.time);

  const plannedDate = new Date(date); // Converts the date string to a Date object
  plannedDate.setHours(time.hours, time.minutes, time.seconds * 1000); // Set hours, minutes, and seconds

  return plannedDate;
};

export const prepareExecution = (executedAt: TriggerDateTimeResponse, timeZone: string) => {
  const { hours, minutes } = duration.parse(executedAt.time);
  const parsedTime = `${leadingZero(hours)}:${leadingZero(minutes)}`;

  return formatInTimeZone(`${executedAt.date}T${parsedTime}Z`, timeZone || DEFAULT_TIME_ZONE, 'dd.MM.yyyy, HH:mm');
};

export const formatExecution = (item: TriggerExecutionInfoResponse, timeZone: string) => {
  let execution = '';

  if (item.executedAt) {
    execution = prepareExecution(item.executedAt, timeZone);
  } else if (item.plannedExecutionAt) {
    execution = prepareExecution(item.plannedExecutionAt, timeZone);
  }

  return {
    execution,
    date: getPlannedExecutionDate(item),
    success: item.isSuccessful,
  };
};
