import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import './index.scss';

type SwitchProps = {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  disabled?: boolean;
};

export const Switch: React.FC<SwitchProps> = ({ id, checked = false, onChange, disabled = false }) => {
  const [deviceOn, setDeviceOn] = useState<boolean>(checked);
  const deviceRef = useRef(checked);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setDeviceOn(checked);
    deviceRef.current = checked;

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [checked, setDeviceOn, deviceRef, timeoutRef]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDeviceOn(!deviceOn);
      onChange(event);

      timeoutRef.current = setTimeout(() => {
        setDeviceOn(deviceRef.current);
      }, 3000);
    },
    [setDeviceOn, deviceOn, deviceRef, onChange],
  );

  return (
    <div className="switch">
      <input type="checkbox" id={id} checked={deviceOn} onChange={handleChange} disabled={disabled} />
      <svg
        className="switch__icon"
        width="42"
        height="32"
        viewBox="0 0 42 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.75" y="4.75" width="40.5" height="22.5" rx="11.25" stroke="#CBCBD1" strokeWidth="1.5" />
        <circle cx="12" cy="16" r="9" fill="#CBCBD1" />
      </svg>
    </div>
  );
};
