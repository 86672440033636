import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useHold } from '../../hooks';
import './button.scss';

type CoverButtonProps = {
  lastPosition?: number;
  children: ReactNode;
  position?: number;
  hidden?: boolean;
  onClick?: () => void;
  onHold?: () => void;
  isOn?: boolean;
};

export const CoverButton: React.FC<CoverButtonProps> = ({
  lastPosition = 0,
  children,
  position = 50,
  hidden = false,
  onClick,
  onHold,
  isOn,
}) => {
  const holdAttributes = useHold({ holdMillisecond: 1000, onClick, onHold });

  return (
    <button
      {...holdAttributes}
      className={classNames('cover-button', 'button', 'button--invert', {
        inactive: lastPosition === position,
        hidden: hidden,
        'is-on': isOn,
      })}
    >
      {children}
    </button>
  );
};
