import React from 'react';

type IconWoodenGateProps = {
  position?: number; // In percents from 0 to 100
};

export const IconWoodenGate: React.FC<IconWoodenGateProps> = ({ position = 100 }) => {
  return (
    <>
      {position === 100 && (
        <svg
          className="icon-cover icon-wooden-gate"
          width="38"
          height="34"
          viewBox="0 0 38 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.89746 7.25625L18.9391 1.1665L18.9391 32.8332L3.89746 32.8332L3.89746 7.25625Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.0835 5.125L11.0835 32.2243"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.981 7.25625L18.9393 1.1665L18.9393 32.8332L33.981 32.8332L33.981 7.25625Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.917 4.33301L26.917 32.833"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.8976 15.7817H1.5835"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2951 15.7817H33.981"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.8976 24.3076H1.5835"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2951 24.3076H33.981"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {position > 0 && position < 100 && (
        <svg
          className="icon-cover"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.89746 9.25625L14.1891 3.1665L14.1891 34.8332L3.89746 34.8332L3.89746 9.25625Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 6.33301L9.5 34.0413"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.981 9.25625L23.6893 3.1665L23.6893 34.8332L33.981 34.8332L33.981 9.25625Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.5 6.33301L28.5 34.833"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.8976 17.7817H1.5835"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2951 17.7817H33.981"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.8976 26.3076H1.5835"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2951 26.3076H33.981"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {position === 0 && (
        <svg
          className="icon-cover"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.89746 9.25625L9.43913 3.1665L9.43913 34.8332L3.89746 34.8332L3.89746 9.25625Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.981 9.25625L28.4393 3.1665L28.4393 34.8332L33.981 34.8332L33.981 9.25625Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.8976 17.7817H1.5835"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2951 17.7817H33.981"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.8976 26.3076H1.5835"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2951 26.3076H33.981"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
