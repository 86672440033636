import React from 'react';
import './index.scss';

type IconPauseProps = {
  size?: number;
  channelNumber?: number;
};

export const IconPause: React.FC<IconPauseProps> = ({ size = 32, channelNumber }) => (
  <div className="icon-pause--container">
    <svg
      className="icon-pause"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="16" cy="16" r="15.25" stroke="#15141D" vectorEffect="non-scaling-stroke" />
      <path d="M13 11L13 22" stroke="#15141D" vectorEffect="non-scaling-stroke" />
      <path d="M19 11L19 22" stroke="#15141D" vectorEffect="non-scaling-stroke" />
    </svg>
    {channelNumber && <div className="channel-number">{channelNumber}</div>}
  </div>
);
