import React, { createContext, useContext, useState } from 'react';
import { OptimizerModeInternal } from '../../../../data-access/gql-types/graphql';
import { ConfigurationError, OptimizerConfigurationStateInterface } from '../configuration/types';

const initialState: OptimizerConfigurationStateInterface = {
  selectedWorkMode: OptimizerModeInternal.TestMode,
  changeSelectedWorkMode: () => null,
  errors: {},
  clearErrors: () => null,
  addNewError: () => null,
};

export const OptimizerConfigurationContext = createContext(initialState);

export const useOptimizerConfigurationContext = (): OptimizerConfigurationStateInterface =>
  useContext(OptimizerConfigurationContext);

const OptimizerConfigurationProvider: React.FC = ({ children }) => {
  const [selectedWorkMode, setSelectedWorkMode] = useState<OptimizerModeInternal>(initialState.selectedWorkMode);
  const [errors, setErrors] = useState<ConfigurationError>(initialState.errors);

  const changeSelectedWorkMode = (mode: OptimizerModeInternal) => {
    clearErrors();
    setSelectedWorkMode(mode);
  };

  const clearErrors = () => setErrors({});

  const addNewError = (propertyName: string, message: string, propertySubName?: string) => {
    setErrors((prev) => {
      const tempErrors = { ...prev };

      if (propertySubName) {
        if (tempErrors[propertyName]) {
          tempErrors[propertyName][propertySubName] = message;
        } else {
          tempErrors[propertyName] = {
            [propertySubName]: message,
          };
        }
      } else {
        tempErrors[propertyName] = message;
      }

      return tempErrors;
    });
  };

  const values: OptimizerConfigurationStateInterface = {
    selectedWorkMode,
    changeSelectedWorkMode,
    errors,
    clearErrors,
    addNewError,
  };

  return <OptimizerConfigurationContext.Provider value={values}>{children}</OptimizerConfigurationContext.Provider>;
};

export default OptimizerConfigurationProvider;
