import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { DialogConfirmation } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import {
  ChangeTimeZoneMutation,
  ChangeTimeZoneMutationVariables,
  InstallationAttachmentError,
  InstallationError,
  InstallationModelResponse,
  UserInstallation,
} from '../../../../data-access/gql-types/graphql';
import { CHANGE_TIMEZONE } from '../../../../data-access/mutations/installations';
import { useBackdropContext, useInstallation } from '../../../../hooks';
import { useInstallationAttachementsErrors } from '../../../../hooks/backend-errors/use-installation-attachement-errors';
import { useMutationErrors } from '../../../../hooks/backend-errors/use-mutation-errors';
import { MutationErrorType } from '../../../../types';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { useTimeZones } from '../../hooks/use-time-zones';
import { CreateInstallationControlForm } from '../../types';
import TimeZonesList from '../time-zones-list';

type ComponentProps = {
  updateInstallation?: (key: keyof UserInstallation, value: string | InstallationModelResponse) => void;
};

const TimeZoneEdit: React.FC<ComponentProps> = ({ updateInstallation }) => {
  const { setValue, watch } = useFormContext<CreateInstallationControlForm>();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const [open, setOpen] = useState<boolean>(false);
  const [timezoneTemp, setTimeZoneTemp] = useState<string>('');
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const { isLoading, timeZonesOptions } = useTimeZones();
  const { selectedInstallationId, selectedInstallation } = useInstallation();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const installationErrors = useMutationErrors(MutationErrorType.Installation);
  const installationAttachementsErrors = useInstallationAttachementsErrors();
  const [changeTimezone] = useMutation<ChangeTimeZoneMutation, ChangeTimeZoneMutationVariables>(CHANGE_TIMEZONE);
  const { time_zone } = watch();

  useEffect(() => {
    setTimeZoneTemp(time_zone);
  }, [time_zone]);

  const handleTimeZoneChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setTimeZoneTemp(target.value);
    },
    [selectedInstallationId, selectedInstallation],
  );

  const handleSubmit = useCallback(() => {
    if (updateInstallation && time_zone !== timezoneTemp && selectedInstallationId) {
      turnOnBackdrop();

      changeTimezone({
        variables: {
          input: {
            installationId: selectedInstallationId,
            zone: timezoneTemp,
          },
        },
        onCompleted: (data) => {
          turnOffBackdrop();
          if (!data || !data.changeTimeZone) {
            toastError({ content: tc('errors.somethingWentWrong') });
            return;
          }

          if (data.changeTimeZone.result?.ok) {
            updateInstallation('payload', {
              ...selectedInstallation?.payload,
              timeZone: timezoneTemp,
            } as InstallationModelResponse);
            setValue('time_zone', timezoneTemp);
            setConfirmDialog(false);
            setOpen(false);
            toastSuccess({ content: t('timezoneEditSuccess') });
          } else {
            installationErrors.handleErrors((data.changeTimeZone.errors || []) as InstallationError[]);
            installationAttachementsErrors.handleErrors(
              (data.changeTimeZone.errors || []) as InstallationAttachmentError[],
            );
          }
        },
        onError: () => turnOffBackdrop(),
      });
    } else {
      setOpen(false);
    }
  }, [selectedInstallation, timezoneTemp, time_zone, selectedInstallationId]);

  const handleSave = useCallback(() => {
    if (updateInstallation) {
      setConfirmDialog(true);
    } else {
      setValue('time_zone', timezoneTemp);
      setOpen(false);
    }
  }, [timezoneTemp]);

  const timeZoneLabel = useMemo(
    () => timeZonesOptions.find((x) => x.value === time_zone)?.label || '',
    [timeZonesOptions, time_zone],
  );

  return (
    <>
      {!open ? (
        <ArrowButton label={t('time_zone')} onClick={() => setOpen(true)} title={timeZoneLabel} disabled={isLoading} />
      ) : (
        <TimeZonesList
          onChange={handleTimeZoneChange}
          value={timezoneTemp}
          handleClose={() => setOpen(false)}
          handleSave={handleSave}
        />
      )}
      <DialogConfirmation
        show={confirmDialog}
        setShow={setConfirmDialog}
        header={t('timezoneConfirmation.header')}
        content={t('timezoneConfirmation.content')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleSubmit}
        secondaryBtnAction={() => setConfirmDialog(false)}
      />
    </>
  );
};

export default TimeZoneEdit;
