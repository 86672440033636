import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BlindRemoteButtonStateEnum } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { InputSelect } from '../../../../../../components';
import { useActionParameterOptions } from '../../utils/use-action-parameter-options';

type ComponentProps = {
  triggerMethodButton: BlindRemoteButtonStateEnum;
  setTriggerMethodButton: Dispatch<SetStateAction<BlindRemoteButtonStateEnum>>;
};

export const RemoteTriggerButtonState: React.FC<ComponentProps> = ({ triggerMethodButton, setTriggerMethodButton }) => {
  const { t } = useTranslation('action');
  const { triggerButtonMethodOptions } = useActionParameterOptions();

  const onChangeTriggerButtonMethod = (method: BlindRemoteButtonStateEnum) => {
    setTriggerMethodButton(method);
  };

  return (
    <InputSelect
      options={triggerButtonMethodOptions}
      value={triggerMethodButton}
      onChange={onChangeTriggerButtonMethod}
      label={t('action.create.conditions.sources.schedule.triggerMethod')}
    />
  );
};
