import { useState, useRef, useMemo } from 'react';
import ease, { easeFunctionMap, EaseNameUnion } from './ease';

type AnimateParams = [number, (newValue: number, newDuration?: number) => void];

export const useAnimate = (
  initValue: number,
  initDuration = 1000,
  easeName: EaseNameUnion = 'linear',
  fps = 60,
): AnimateParams => {
  const [currentValue, setCurrentValue] = useState<number>(initValue);
  const startValue = useRef(initValue);
  const endValue = useRef(initValue);
  const duration = useRef(initDuration);
  const currentTime = useRef(0);
  const lastTime = useRef(0);
  const easeFunction = useMemo(() => easeFunctionMap[easeName], [easeName]);
  const fpsInMs = useMemo(() => 1000 / fps, [fps]);

  const animationLoop = (timestamp: number) => {
    let newCurrentValue = currentValue;
    let shouldContinue = true;

    if (lastTime.current === 0) {
      lastTime.current = timestamp;
    }

    currentTime.current += timestamp - lastTime.current;

    if (timestamp - lastTime.current >= fpsInMs) {
      lastTime.current = timestamp;
      newCurrentValue = ease(easeFunction, startValue.current, endValue.current, currentTime.current, duration.current);
      setCurrentValue(newCurrentValue);
      shouldContinue = newCurrentValue !== endValue.current;
    }

    if (shouldContinue) {
      window.requestAnimationFrame(animationLoop);
    }
  };

  const setNewValue = (newValue: number, newDuration: number = initDuration) => {
    if (newValue !== endValue.current) {
      startValue.current = currentValue;
      endValue.current = newValue;
      duration.current = newDuration;
      currentTime.current = 0;
      lastTime.current = 0;
      window.requestAnimationFrame(animationLoop);
    }
  };

  return [currentValue, setNewValue];
};
