import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  BlindTurnOnState,
  StateOfTurnOnBlindDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { ISrp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp22ConfigService';
import { ISrp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp30ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type ComponentProps = {
  device: IDevice;
  turnOnBehaviour: StateOfTurnOnBlindDeviceParameters | null;
};

const TurnOnBehaviourForm: React.FC<ComponentProps> = ({ device, turnOnBehaviour }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { configService: serviceName, handleSubmitResponse } = useConfigurationService(device);
  const form = useForm<StateOfTurnOnBlindDeviceParameters>();

  const { State } = form.watch();

  useEffect(() => {
    if (turnOnBehaviour !== null) {
      form.setValue('State', turnOnBehaviour.State);
      form.setValue('BlindPosition', turnOnBehaviour.BlindPosition);
    }
  }, [turnOnBehaviour]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (turnOnBehaviour) {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
        ISrp30ConfigService | ISrp22ConfigService
      >(serviceName);

      const result = await configService.SetTurnOnBehaviourAsync(device, {
        State: values.State,
        BlindPosition: values.BlindPosition,
        Channel: turnOnBehaviour.Channel,
      });
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const onChangeState = (value: BlindTurnOnState) => form.setValue('State', value);

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeState}
          value={State}
          options={[
            { label: t('exalus.params.SrpParams.TurnOnBehaviourSteps.OpenBlind'), value: BlindTurnOnState.OpenBlind },
            { label: t('exalus.params.SrpParams.TurnOnBehaviourSteps.CloseBlind'), value: BlindTurnOnState.CloseBlind },
            { label: t('exalus.params.SrpParams.TurnOnBehaviourSteps.DoNothing'), value: BlindTurnOnState.DoNothing },
            {
              label: t('exalus.params.SrpParams.TurnOnBehaviourSteps.OpenPercentage'),
              value: BlindTurnOnState.OpenPercentage,
            },
          ]}
          label={t('exalus.params.SrpParams.TurnOnBehaviour')}
        />
        {State === BlindTurnOnState.OpenPercentage && (
          <ConfigurationInput
            name="BlindPosition"
            label={t('exalus.params.SrpParams.BlindPosition')}
            placeholder={t('exalus.params.SrpParams.BlindPosition')}
            inputType="number"
            min={0}
            max={100}
          />
        )}
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default TurnOnBehaviourForm;
