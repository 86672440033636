import React from 'react';
import { ChannelInterface, CoverKindParam } from '../../../../types';
import { CoverActionControlBox } from './action';
import { CoverChannelControlBox } from './channel';

type CommonInterface = {
  isListItem?: boolean;
};

type ChannelParams = CommonInterface & {
  kind: CoverKindParam.Channel;
  channel: ChannelInterface;
};

type ActionParams = CommonInterface & {
  kind: CoverKindParam.Action;
  channels: ChannelInterface[];
};

type CoverControlBoxProps = ChannelParams | ActionParams;

export const CoverControlBox: React.FC<CoverControlBoxProps> = (props) => {
  return props.kind === CoverKindParam.Channel ? (
    <CoverChannelControlBox isListItem={props.isListItem} channel={props.channel} />
  ) : (
    <CoverActionControlBox isListItem={props.isListItem} channels={props.channels} />
  );
};
