import React, { ReactNode } from 'react';
import classNames from 'classnames';

type CarouselItemProps = {
  children?: ReactNode;
  width?: number;
  isActive?: boolean;
  className?: string;
};

export const CarouselItem: React.FC<CarouselItemProps> = ({ children, width, isActive = false, className }) => (
  <div
    className={classNames('carouselCustom-item', {
      'carouselCustom-item--active': isActive,
      [className as string]: className,
    })}
    style={{ width }}
  >
    {isActive ? children : null}
  </div>
);
