import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, CustomBackdrop, IconToastWarning, InputSelect, Summary } from '../../../../components';
import { CheckboxSelect } from '../../../../components/checkbox-select';
import { AggregateVectorParameterTypeInternal } from '../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../types';
import { PhasesButtonGroup } from '../../measurements/components/phases-button-group';
import { useMeterPrices } from '../common/hooks/use-meter-prices';
import { AnalysisChart } from './components/chart';
import { RangeHeader } from './components/chart/range-header';
import { useMeterAnalysis } from './hooks/use-meter-analysis';
import './index.scss';
import { getDataByRange } from './utils';

type MeterAnalysisProps = {
  channel: ChannelInterface;
};

export const MeterAnalysis: React.FC<MeterAnalysisProps> = ({ channel }) => {
  const { t: tch } = useTranslation('channel-details');
  const { t } = useTranslation('common');
  const {
    data,
    selectedPhases,
    isLoading,
    setSelectedPhases,
    mappedChartData,
    onClickActiveMeasurementRange,
    aggregatedParameters,
    activeMeasurementRange,
    page,
    setPage,
    timeRanges,
    handleSetAggregatedParameters,
    isStandalone,
  } = useMeterAnalysis({ channel });
  const { tariffPricesConfiguration, kind, dynamicPrices, emptyPrices } = useMeterPrices(selectedPhases);

  const chartData = useMemo(
    () =>
      getDataByRange(
        activeMeasurementRange,
        mappedChartData,
        page,
        kind,
        tariffPricesConfiguration,
        dynamicPrices,
        aggregatedParameters,
      ),
    [activeMeasurementRange, mappedChartData, page, tariffPricesConfiguration, dynamicPrices, aggregatedParameters],
  );

  const parametersAvailable = useMemo(
    () => aggregatedParameters.filter((x) => !x.disabled).length > 0,
    [aggregatedParameters],
  );

  const selectedParameters = useMemo(
    () =>
      aggregatedParameters.filter(
        (x) => !x.disabled && x.checked && x.value !== AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
      ),
    [aggregatedParameters],
  );

  const priceParameterSelected = useMemo(
    () => selectedParameters.find((x) => x.value === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice),
    [selectedParameters],
  );

  return (
    <div className="meter-analysis">
      <ControlWrapper className="control-wrapper--column  control-wrapper--reverse p-l-24 p-r-24 p-b-16">
        {parametersAvailable ? (
          <div className="grid-list-24">
            <CheckboxSelect
              options={aggregatedParameters}
              onSave={handleSetAggregatedParameters}
              className="input--reverse"
              multipleLabel={t('parameters')}
            />
            <InputSelect
              options={timeRanges}
              value={activeMeasurementRange}
              onChange={onClickActiveMeasurementRange}
              className="input--reverse"
              label={''}
            />
          </div>
        ) : null}
        {emptyPrices && priceParameterSelected && (
          <div className="empty-prices">
            <IconToastWarning /> <span>{tch('pricesEmpty')}</span>
          </div>
        )}
        <RangeHeader
          prevAvailable={chartData.prevAvailable}
          nextAvailable={chartData.nextAvailable}
          range={chartData.range}
          setPage={setPage}
        />
        <AnalysisChart
          data={chartData.result}
          activeMeasurementRange={activeMeasurementRange}
          isLoading={isLoading}
          aggregatedParameters={aggregatedParameters}
        />
        {selectedParameters.length &&
        selectedParameters.length === 1 &&
        selectedParameters[0].value !== AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy ? (
          <PhasesButtonGroup
            onSelectedPhases={setSelectedPhases}
            defaultPhases={[0, 1, 2, ...(isStandalone ? [3] : [])]}
            itemPrefix={tch(isStandalone ? 'phase' : 'outputShort')}
          />
        ) : null}
      </ControlWrapper>
      <Summary summaryData={chartData.result} activeMeasurementRange={activeMeasurementRange} />
      <CustomBackdrop loading={isLoading && !data} />
    </div>
  );
};
