import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconLogoWisniowscyProps = {
  className?: string;
};

export const IconLogoWisniowscy: React.FC<IconLogoWisniowscyProps> = ({ className }) => (
  <div className={classNames('icon-logo-wisniowski', { [className as string]: className })}>
    <svg width="232" height="98" viewBox="0 0 232 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25789_62327)">
        <path
          d="M0 72.0974H5.95113L11.183 89.3428L16.8516 72.0197H21.6125L27.281 89.3428L32.5129 72.0974H38.327L29.7043 97.7497H24.8663L19.1635 81.0912L13.4607 97.7497H8.62272L0 72.0974Z"
          fill="#3C3C3B"
        />
        <path d="M40.9131 72.0974H46.4703V97.5684H40.9131V72.0974Z" fill="#3C3C3B" />
        <path
          d="M50.1951 93.8569L53.4832 89.8952C55.7609 91.7854 58.1328 92.9852 61.027 92.9852C63.3047 92.9852 64.6747 92.0789 64.6747 90.5857V90.508C64.6747 89.0924 63.8099 88.3588 59.5884 87.2712C54.5021 85.9593 51.214 84.5437 51.214 79.4858V79.4167C51.214 74.799 54.896 71.7435 60.0594 71.7435C63.7414 71.7435 66.8754 72.9087 69.4356 74.9802L66.55 79.201C64.3065 77.6387 62.1059 76.6979 59.9823 76.6979C57.8588 76.6979 56.737 77.6818 56.737 78.9161V78.9852C56.737 80.6597 57.8245 81.2034 62.183 82.3341C67.3035 83.6806 70.1977 85.5363 70.1977 89.9728V90.0419C70.1977 95.0998 66.3702 97.9309 60.9242 97.9309C57.0967 97.9309 53.2349 96.5931 50.2036 93.8569H50.1951ZM62.7139 64.0271L67.4405 66.0986L62.534 69.7324H58.3468L62.7139 64.0271Z"
          fill="#3C3C3B"
        />
        <path
          d="M74.3848 72.0974H79.5139L91.3562 87.7805V72.0974H96.8364V97.5684H92.1097L79.8735 81.376V97.5684H74.3848V72.0974Z"
          fill="#3C3C3B"
        />
        <path d="M102.479 72.0974H108.037V97.5684H102.479V72.0974Z" fill="#3C3C3B" />
        <path
          d="M111.847 84.9063V84.8372C111.847 77.5955 117.516 71.6744 125.308 71.6744C133.1 71.6744 138.7 77.5265 138.7 84.7681V84.8458C138.7 92.0875 133.031 98.0086 125.239 98.0086C117.447 98.0086 111.847 92.1566 111.847 84.9149V84.9063ZM132.886 84.9063V84.8372C132.886 80.4698 129.709 76.836 125.231 76.836C120.752 76.836 117.653 80.4007 117.653 84.7595V84.8372C117.653 89.2046 120.83 92.8384 125.308 92.8384C129.786 92.8384 132.886 89.2823 132.886 84.9063Z"
          fill="#3C3C3B"
        />
        <path
          d="M139.017 72.0974H144.968L150.2 89.3428L155.868 72.0197H160.629L166.298 89.3428L171.53 72.0974H177.344L168.721 97.7497H163.883L158.18 81.0912L152.478 97.7497H147.64L139.017 72.0974Z"
          fill="#3C3C3B"
        />
        <path
          d="M177.524 93.8569L180.812 89.8951C183.081 91.7854 185.47 92.9851 188.356 92.9851C190.633 92.9851 192.003 92.0789 192.003 90.5856V90.508C192.003 89.0924 191.138 88.3588 186.917 87.2712C181.822 85.9593 178.543 84.5437 178.543 79.4858V79.4167C178.543 74.799 182.225 71.7435 187.388 71.7435C191.07 71.7435 194.213 72.9087 196.773 74.9802L193.887 79.2009C191.652 77.6387 189.452 76.6978 187.319 76.6978C185.187 76.6978 184.074 77.6818 184.074 78.9161V78.9851C184.074 80.6596 185.162 81.2034 189.529 82.3341C194.658 83.6806 197.543 85.5363 197.543 89.9728V90.0419C197.543 95.0998 193.716 97.9309 188.27 97.9309C184.451 97.9309 180.581 96.593 177.549 93.8569H177.524Z"
          fill="#3C3C3B"
        />
        <path
          d="M201.217 72.0974H206.774V83.2318L217.024 72.0974H223.737L213.444 82.9038L224.199 97.5684H217.52L209.685 86.7274L206.766 89.7829V97.5684H201.208V72.0974H201.217Z"
          fill="#3C3C3B"
        />
        <path d="M226.443 72.0974H232V97.5684H226.443V72.0974Z" fill="#3C3C3B" />
        <path
          d="M129.743 37.6671L116.051 23.857L102.359 37.6671L97.6585 30.4686L116.051 11.9285L134.444 30.4686L129.743 37.6671ZM129.683 1.82984L136.705 8.91615H148.547L139.145 23.2873L116.06 0L92.9661 23.2873L83.5642 8.91615H95.4065L102.428 1.82984H68.939L101.041 50.9334L116.06 35.7941L131.071 50.9334L163.172 1.82984H129.683Z"
          fill="#3C3C3B"
        />
      </g>
      <defs>
        <clipPath id="clip0_25789_62327">
          <rect width="232" height="98" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
