import { OptimizerModeInternal, RelayConfig, TimeZoneKindInternal } from '../../../../../data-access/gql-types/graphql';

export const initialRelay = {
  enabled: true,
  index: 0,
  timeOff: '00:00:00',
  timeOn: '00:00:00',
  thresholdValue: 0,
} as RelayConfigCustom;

export type TestFormBody = {
  phase1: number;
  phase2: number;
  phase3: number;
};

export type RelayConfigCustom = RelayConfig & {
  timeOn: string;
  timeOff: string;
};

export type EcoFormBody = {
  tempMax: number;
  voltageThreshold1: number;
  voltageThreshold2: number;
  voltageThreshold3: number;
  relayConfig1: Omit<RelayConfigCustom, '__typename'>;
  relayConfig2: Omit<RelayConfigCustom, '__typename'>;
  relayConfig3: Omit<RelayConfigCustom, '__typename'>;
};

type BaseConsumptionFormBody = {
  tempMax: number;
  tempMin: number;
  heaterPowerLimit: number;
  voltageThreshold1: number;
  voltageThreshold2: number;
  voltageThreshold3: number;
  relayConfig1: Omit<RelayConfigCustom, '__typename'>;
  relayConfig2: Omit<RelayConfigCustom, '__typename'>;
  relayConfig3: Omit<RelayConfigCustom, '__typename'>;
};

export type HoursRangeFront = {
  from: string;
  timeZoneKind: TimeZoneKindInternal;
  to: string;
  pricingTier: number;
  error?: string;
};

export type TariffBody = {
  operator: string;
  tariffName: string;
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
  hoursRanges: HoursRangeFront[];
};

export type ComfortFormBody = BaseConsumptionFormBody & TariffBody;

export type FullConsumptionFormBody = BaseConsumptionFormBody &
  TariffBody & {
    relayConfig4: Omit<RelayConfigCustom, '__typename'>;
    tempMinEnabled: boolean;
    tempSelfConsumption: number;
    from: string;
    to: string;
  };

export type LimitConsumptionFormBody = BaseConsumptionFormBody &
  TariffBody & {
    relayConfig4: Omit<RelayConfigCustom, '__typename'>;
    tempMinEnabled: boolean;
    tempSelfConsumption: number;
    powerLimit: number;
    from: string;
    to: string;
  };

export type RelaysConsumptionFormBody = {
  relayConfig1: Omit<RelayConfigCustom, '__typename'>;
  relayConfig2: Omit<RelayConfigCustom, '__typename'>;
  relayConfig3: Omit<RelayConfigCustom, '__typename'>;
  relayConfig4: Omit<RelayConfigCustom, '__typename'>;
  from: string;
  to: string;
};

export type PrepareRelaysParams = {
  relayConfig1: Omit<RelayConfigCustom, '__typename'>;
  relayConfig2: Omit<RelayConfigCustom, '__typename'>;
  relayConfig3: Omit<RelayConfigCustom, '__typename'>;
  relayConfig4?: Omit<RelayConfigCustom, '__typename'>;
};

export type TariffFormDefault = {
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
  hoursRanges: HoursRangeFront[];
};

export type ConfigurationError = {
  tempMin?: string;
  tempMax?: string;
  tempSelfConsumption?: string;
  from?: string;
  to?: string;
  relayConfig1?: Record<string, string>;
  relayConfig2?: Record<string, string>;
  relayConfig3?: Record<string, string>;
  relayConfig4?: Record<string, string>;
};

export type OptimizerConfigurationStateInterface = {
  selectedWorkMode: OptimizerModeInternal;
  changeSelectedWorkMode: (mode: OptimizerModeInternal) => void;
  errors: ConfigurationError;
  clearErrors: () => void;
  addNewError: (propertyName: string, message: string, propertySubName?: string) => void;
};
