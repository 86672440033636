import {
  GlobalTimeParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';

export enum Rog21ParamsEnum {
  TURN_ON_BEHAVIOUR = 'TurnOnBehaviourParams',
  GLOBAL_TIME = 'GlobalTimeParams',
}

export type Rog21ConfigParams = {
  [Rog21ParamsEnum.TURN_ON_BEHAVIOUR]: StateOfTurnOnSwitchDeviceParameters | null;
  [Rog21ParamsEnum.GLOBAL_TIME]: GlobalTimeParameters | null;
};

export const initialConfig: Rog21ConfigParams = {
  [Rog21ParamsEnum.TURN_ON_BEHAVIOUR]: null,
  [Rog21ParamsEnum.GLOBAL_TIME]: null,
};
