import React, { useEffect, useMemo, useState } from 'react';
import { LineChart, Line, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { TimeItem } from '../../../../../types';
import { sunTimeArray } from '../../../../../utils/time';
import './index.scss';

const CustomizedDot = (props) => (
  <circle
    r={(props.payload as TimeItem).hour === props.activeHour ? 8 : 0}
    strokeWidth="3"
    stroke="#FCAF00"
    fill="#FCAF00"
    width="292"
    height="120"
    cx={props.cx}
    cy={props.cy}
    className="recharts-dot recharts-line-dot"
  ></circle>
);

type ComponentProps = {
  time?: number;
};

const SunChart: React.FC<ComponentProps> = ({ time }) => {
  const [calculatedTime, setCalculatedTime] = useState<number | null>(null);
  const valuesArray = sunTimeArray();

  useEffect(() => {
    if (time) {
      const date = new Date(time);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const t = `${hours}.${((minutes * 100) / 60).toFixed(2)}`;
      setCalculatedTime(parseFloat(t));
    }
  }, [time]);

  const activeHour = useMemo(() => {
    return valuesArray.find((x) => x.hour === calculatedTime)?.hour;
  }, [valuesArray, calculatedTime]);

  return (
    <div className="astronomical-chart">
      <ResponsiveContainer width="100%" height={160} className="m-t-16">
        <LineChart
          width={500}
          height={300}
          data={valuesArray}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
          <XAxis
            dataKey="hour"
            type="number"
            ticks={[0, 4, 8, 12, 16, 20, 24]}
            tickFormatter={(value) => value.toFixed(2)}
          />
          <Line
            type="monotone"
            dataKey="height"
            strokeWidth={3}
            stroke="#FCAF00"
            dot={<CustomizedDot activeHour={activeHour} />}
            activeDot={{ r: 10 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SunChart;
