import React, { useMemo } from 'react';

type IconRollerBlindProps = {
  position?: number; // In percents from 0 to 100
};

export const IconRollerBlind: React.FC<IconRollerBlindProps> = ({ position = 0 }) => {
  const svgClipPathHeight = useMemo(() => (position === 0 ? 11 : 11 + position * 0.25), [position]);

  return (
    <svg
      className="icon-cover icon-roller-blind"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.33301"
        y="4.75"
        width="25.3333"
        height="28.5"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.1665 4.75L34.8332 4.75"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.1665 33.25L34.8332 33.25"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="7" y1={svgClipPathHeight - 2} x2="32" y2={svgClipPathHeight - 2} stroke="#15141D" />
      <circle cx="19" cy={svgClipPathHeight} r="1.5" stroke="black" strokeWidth="1.5" />
    </svg>
  );
};
