import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSelect } from '../../../../../../components';
import { TriggerMethodType } from '../../types';
import { useActionParameterOptions } from '../../utils/use-action-parameter-options';

type ComponentProps = {
  triggerMethod: string;
  setTriggerMethod: Dispatch<SetStateAction<TriggerMethodType>>;
};

export const TriggerMethodSelect: React.FC<ComponentProps> = ({ triggerMethod, setTriggerMethod }) => {
  const { t } = useTranslation('action');
  const { triggerMethodsOptions } = useActionParameterOptions();

  const onChangeTriggerMethod = (method: string) => {
    setTriggerMethod(method as TriggerMethodType);
  };

  return (
    <InputSelect
      options={triggerMethodsOptions}
      value={triggerMethod}
      onChange={onChangeTriggerMethod}
      label={t('action.create.conditions.sources.schedule.triggerMethod')}
    />
  );
};
