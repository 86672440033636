import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useSubscription } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  DevicesGetAllQueryVariables,
  OnConfigurationTaintedSubscription,
  OnConfigurationTaintedSubscriptionVariables,
  OnDeviceNameChangedSubscription,
  OnDeviceNameChangedSubscriptionVariables,
  Query,
} from '../../data-access/gql-types/graphql';
import { DEVICES_GET_ALL } from '../../data-access/queries/devices';
import {
  ON_CONFIGURATION_TAINTED,
  ON_DEVICE_CONNECTION_STATE_CHANGE,
} from '../../data-access/subscriptions/lavva-devices';
import { ON_DEVICE_NAME_CHANGED } from '../../data-access/subscriptions/on-device-name-changed';
import { DeviceInterface } from '../../types';
import { useUpdateCommonLavvaState } from '../../utils/channels/subscriptions/common-lavva-state';
import { parseDevice } from '../../utils/devices';
import { convertErrorsToOneString } from '../../utils/helpers';
import { isDevelopment } from '../../utils/helpers/environment';
import { toastError, toastSuccess } from '../../utils/toast';
import { useDevicesAndChannels } from '../devices-and-channels';
import { useInstallation } from '../installation';

export const useDevicesGetAll = () => {
  const { t } = useTranslation('channel-settings');
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { setDeviceList, editDeviceName, channelList } = useDevicesAndChannels();
  const { onDeviceConnectionStateChange } = useUpdateCommonLavvaState();
  const { data: devicesData, loading: devicesLoading } = useQuery<Query, DevicesGetAllQueryVariables>(DEVICES_GET_ALL, {
    variables: { installationId: selectedInstallationId },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    skip: skipLavvaFetch,
  });
  const { data } = useSubscription<OnDeviceNameChangedSubscription, OnDeviceNameChangedSubscriptionVariables>(
    ON_DEVICE_NAME_CHANGED,
    {
      variables: { installationId: selectedInstallationId },
      skip: skipLavvaFetch,
    },
  );
  const { data: configurationTainted } = useSubscription<
    OnConfigurationTaintedSubscription,
    OnConfigurationTaintedSubscriptionVariables
  >(ON_CONFIGURATION_TAINTED, {
    variables: { installationId: selectedInstallationId },
    skip: skipLavvaFetch,
  });

  const { data: deviceConnection } = useSubscription(ON_DEVICE_CONNECTION_STATE_CHANGE, {
    variables: { installationId: selectedInstallationId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (deviceConnection) onDeviceConnectionStateChange(deviceConnection.onDeviceConnectionStateChange);
  }, [deviceConnection]);

  useEffect(() => {
    if (data?.onDeviceNameChanged) {
      const { deviceId, name } = data.onDeviceNameChanged;
      editDeviceName(deviceId, name);
      toastSuccess({ content: t('toast.editNameSuccess') });
    }
  }, [data]);

  useEffect(() => {
    if (configurationTainted?.onConfigurationTaintedPayload) {
      const { channelId, failures } = configurationTainted.onConfigurationTaintedPayload;
      const channel = channelList.find((x) => x.id === channelId);
      console.log('CONFIGURATION TAINTED', { channelId, failures });

      Sentry.captureMessage('Configuration tainted', {
        level: 'info',
        extra: {
          channelId,
          failures,
        },
      });

      if (isDevelopment) {
        toastError({
          content: `${t('configurationTainted', { alias: channel?.alias })}: \r\n${convertErrorsToOneString(failures)}`,
        });
      }
    }
  }, [configurationTainted]);

  useEffect(() => {
    if (devicesData?.allUserDevices) {
      if (!selectedInstallationId) return;
      const updatedDevices: DeviceInterface[] = [];

      for (const userDevice of devicesData.allUserDevices) {
        if (userDevice.installationId !== selectedInstallationId) return;

        const newDevice = parseDevice(userDevice);
        updatedDevices.push(newDevice);
      }

      setDeviceList(updatedDevices);
    }
  }, [devicesData]);

  return { devicesLoading };
};
