import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconSunDownProps = {
  className?: string;
};

export const IconSunDown: React.FC<IconSunDownProps> = ({ className }) => (
  <div
    className={classNames('icon-sun-down', {
      [className as string]: className,
    })}
  >
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.66699 9.00033C5.66699 7.15938 7.15938 5.66699 9.00033 5.66699C10.8413 5.66699 12.3337 7.15938 12.3337 9.00033M16.0003 9.00033L1.66699 9.00033L3.66698 9.00033M9.00033 3.66699V1.66699M14.3336 9.00033L16.3336 9.00033M3.81486 3.81486L5.22907 5.22907M14.1858 3.81486L12.7716 5.22907M11.667 13.3336L9.00032 16.0003M9.00032 16.0003L6.33365 13.3336M9.00032 16.0003L9.00032 11.0003"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
