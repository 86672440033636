import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconLocation2Props = {
  className?: string;
};

export const IconLocation2: React.FC<IconLocation2Props> = ({ className }) => (
  <div
    className={classNames('icon-location-2', {
      [className as string]: className,
    })}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3753 2C10.4809 2 8.66406 2.75255 7.32452 4.09209C5.98497 5.43164 5.23242 7.24845 5.23242 9.14286C5.23242 13.652 8.13703 17.5802 11.3268 20.9275C11.8983 21.5272 12.8522 21.5271 13.4284 20.932C16.6164 17.6398 19.5181 13.9717 19.5181 9.14286C19.5181 8.20484 19.3334 7.27602 18.9744 6.4094C18.6155 5.54279 18.0893 4.75537 17.426 4.09209C16.7628 3.42882 15.9753 2.90268 15.1087 2.54372C14.2421 2.18476 13.3133 2 12.3753 2Z"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <ellipse
        cx="12.3753"
        cy="9.1429"
        rx="3.33333"
        ry="3.33333"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinejoin="bevel"
      />
    </svg>
  </div>
);
