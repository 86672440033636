import React, { useState, useMemo, ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { uniq } from 'lodash';
import {
  Checkbox,
  CustomBackdrop,
  ChannelIcon,
  EmptyStateBox,
  Header,
  IconWarning,
  NavHeader,
  Page,
  SubmitButton,
  Tabs,
} from '../../../components';
import { ChannelTypeInternal } from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import { ChannelInterface } from '../../../types';
import { useAddDevice } from '../../device-add/hooks/use-add-device';
import { ShareChannel } from '../context/types';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import './index.scss';

type GroupItem = {
  label: string;
  checked: boolean;
  type: string;
  ids: string[];
};

const ChannelsShare: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('channel-list');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [shareChannels, setShareChannels] = useState<ShareChannel[]>([]);
  const [itemGroupsList, setItemGroupsList] = useState<GroupItem[]>([]);
  const { channelList, channelsLoading, methods } = useInstallationShare();
  const { userSharing, userSharingLoading } = useMember(true);
  const { addDevice } = useAddDevice();

  const channelListFilteredByType = useCallback(
    (types: ChannelTypeInternal[]) =>
      (channelList || []).filter(
        (channel) => channel && channel.data.type !== undefined && !types.includes(channel.data.type),
      ) as ChannelInterface[],
    [channelList],
  );

  const typeList: string[] = useMemo(
    () => uniq((channelListFilteredByType([]) as ChannelInterface[]).map((device) => device.data.type as string)),
    [channelList],
  );

  useEffect(() => {
    const items = typeList
      .map((type) => ({
        label: tc(`typesPlural.${type}`),
        type: type,
        checked: false,
        ids: (channelList.filter((channel) => channel && channel.data.type == type) as ChannelInterface[])
          .map((channel) => channel.id)
          .filter((id) => !userSharing?.sharingInfo?.channels.some((userChannel) => id === userChannel.id)),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setItemGroupsList(items.filter((x) => x.ids.length > 0));
  }, [typeList, channelList, userSharing]);

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    const channelId = e.target.value.split('_')[0];
    const type = e.target.value.split('_')[1];

    const updatedChannels = [...shareChannels];
    const channelIndex = updatedChannels.findIndex((x) => x.id === channelId);

    if (channelIndex !== -1) {
      updatedChannels.splice(channelIndex, 1);
    } else {
      const channel = channelList.find((x) => x?.id === channelId);

      if (channel) {
        updatedChannels.push({ id: channel.id, name: channel.alias });
      }
    }

    const updatedItems = [...itemGroupsList];
    const itemIndex = updatedItems.findIndex((x) => x.type === type);

    if (itemIndex !== -1) {
      if (updatedItems[itemIndex].ids.every((elem) => updatedChannels.find((x) => elem === x.id))) {
        updatedItems[itemIndex].checked = true;
      } else {
        updatedItems[itemIndex].checked = false;
      }
    }

    setShareChannels(updatedChannels);
    setItemGroupsList(updatedItems);
  };

  const selectAllItems = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedItems = [...itemGroupsList];
    const itemIndex = updatedItems.findIndex((x) => x.type === e.target.value);

    if (itemIndex !== -1) {
      updatedItems[itemIndex].checked = !updatedItems[itemIndex].checked;
      const updatedChannels = [...shareChannels];

      for (const channelId of updatedItems[itemIndex].ids) {
        const channel = channelList.find((channel) => channel && channel.id === channelId) as ChannelInterface;

        if (channel) {
          if (updatedItems[itemIndex].checked) {
            updatedChannels.push({ id: channel.id, name: channel.alias });
          } else {
            if (channel.data.type === e.target.value) {
              const channelIndex = updatedChannels.findIndex((x) => x.id === channel.id);

              if (channelIndex !== -1) {
                updatedChannels.splice(channelIndex, 1);
              }
            }
          }
        }
      }

      setShareChannels(updatedChannels);
    }

    setItemGroupsList(updatedItems);
  };

  const handleNext = () => {
    methods.handleUpdateChannels(shareChannels);
    history.push(ROUTES.InstallationShareRestriction());
  };

  return (
    <Page
      className="share-channels"
      header={
        <>
          <NavHeader />
          <Header title={t('selectShareChannels')} />
          {itemGroupsList.length > 0 && !channelsLoading && (
            <Tabs
              tabList={[{ label: tc('all') }, ...itemGroupsList]}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setActiveTabIndex}
              tabsType="pills"
            />
          )}
        </>
      }
    >
      {channelsLoading || userSharingLoading ? (
        <CustomBackdrop loading={channelsLoading} />
      ) : (
        <>
          {itemGroupsList.length > 0 ? (
            <>
              <ul className="m-t-24 page-list max-width-desktop">
                {itemGroupsList.map(
                  (item: { ids: string[]; label: string; checked: boolean; type: string }, typeIndex: number) => {
                    if (activeTabIndex !== 0 && activeTabIndex - 1 !== typeIndex) {
                      return null;
                    }

                    return (
                      <li key={typeIndex} className="page-list__item">
                        <Header
                          title={item.label}
                          subheader
                          checkbox
                          checkboxValue={item.type}
                          checked={item.checked}
                          handleToggle={selectAllItems}
                        />
                        <ul>
                          {item.ids.map((id) => {
                            const channel = channelList.find(
                              (channel) => channel && channel.id === id,
                            ) as ChannelInterface;
                            const checked = shareChannels.find((x) => x.id === channel.id);

                            return (
                              <div key={channel.id} className="share-channel-item">
                                <div className="left-section">
                                  <div className="icon">
                                    <ChannelIcon channel={channel} />
                                  </div>
                                  <p>{channel.alias}</p>
                                </div>
                                <Checkbox
                                  id={`${channel.id}_${channel.data.type}`}
                                  checked={!!checked}
                                  onChange={handleToggle}
                                />
                              </div>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  },
                )}
              </ul>
              <SubmitButton type="button" onClick={handleNext} disabled={shareChannels.length === 0}>
                {tc('buttons.next')}
              </SubmitButton>
            </>
          ) : (
            <EmptyStateBox
              header={t('devicesEmptyState.header')}
              btnText={t('devicesEmptyState.missingDevice.btnText')}
              content={t('devicesEmptyState.missingDevice.content')}
              icon={<IconWarning />}
              onClick={addDevice}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default ChannelsShare;
