import React, { useState } from 'react';
import classNames from 'classnames';
import * as uuid from 'uuid';
import './index.scss';

type IconSunProps = {
  isOn: boolean;
  big?: boolean;
  noState?: boolean;
  className?: string;
};

export const IconSun: React.FC<IconSunProps> = ({ isOn = true, big, noState, className }) => {
  const [svgClipPathId] = useState<string>(`switch-clip-path-${uuid.v4()}`);

  return (
    <svg
      className={classNames('icon-switch icon-sun', {
        'icon-switch--on': isOn,
        big: big,
        'no-state': noState,
        [className as string]: className,
      })}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <clipPath id={svgClipPathId}>
        <circle className="icon-switch__clip-path" cx="12" cy="12" r="5" />
      </clipPath>

      <circle cx="12" cy="12" r="5" stroke="#fff" vectorEffect="non-scaling-stroke" />

      <g clipPath={`url(#${svgClipPathId})`}>
        <path vectorEffect="non-scaling-stroke" stroke="#fff" d="M 12.00000 04.00000 L 12.00000 01.00000" />
        <path vectorEffect="non-scaling-stroke" stroke="#fff" d="M 12.00000 23.00000 L 12.00000 20.00000" />
        <path vectorEffect="non-scaling-stroke" stroke="#fff" d="M 20.00000 12.00000 L 23.00000 12.00000" />
        <path vectorEffect="non-scaling-stroke" stroke="#fff" d="M 01.00000 12.00000 L 04.00000 12.00000" />
        <path vectorEffect="non-scaling-stroke" stroke="#fff" d="M 17.65680 17.65690 L 19.77820 19.77820" />
        <path vectorEffect="non-scaling-stroke" stroke="#fff" d="M 04.22182 04.22186 L 06.34314 06.34318" />
        <path vectorEffect="non-scaling-stroke" stroke="#fff" d="M 06.34314 17.65690 L 04.22182 19.77820" />
        <path vectorEffect="non-scaling-stroke" stroke="#fff" d="M 19.77820 04.22186 L 17.65680 06.34318" />
      </g>
    </svg>
  );
};
