import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { useBrightnessScale } from '../../../../../hooks/use-brightness-scale';
import CustomizedTick from './customized-x-tick';

type ComponentProps = {
  current: string;
};

export const BrightnessConditionChart: React.FC<ComponentProps> = ({ current }) => {
  const { linearScaleMedium } = useBrightnessScale();

  const data = useMemo(
    () => Object.keys(linearScaleMedium).map((key) => ({ name: key, value: linearScaleMedium[key] })),
    [linearScaleMedium],
  );

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart
        width={500}
        height={350}
        data={data}
        margin={{
          top: 5,
          right: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          angle={90}
          textAnchor="start"
          height={250}
          tick={(props) => <CustomizedTick {...props} className={current === props.payload.value ? 'active' : ''} />}
        />
        <YAxis />
        <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};
