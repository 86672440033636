import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '@apollo/client';
import {
  OnDeviceErrorSubscription,
  OnDeviceErrorSubscriptionVariables,
  VersionedFeatureType,
} from '../../../../data-access/gql-types/graphql';
import { ON_DEVICE_ERROR } from '../../../../data-access/subscriptions/lavva-devices';
import { useDevicesAndChannels, useInstallation } from '../../../../hooks';
import { useVersionMap } from '../../../../hooks/api/version-map';
import { ChannelInterface } from '../../../../types';
import { toastError, toastInfo } from '../../../../utils/toast';
import { useConfiguration } from '../../hooks/use-configuration';

type ComponentProps = {
  channel: ChannelInterface;
};

const SwapIn: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('configuration');
  const { t: tb } = useTranslation('backend-validation');
  const { t: td } = useTranslation('device-settings');
  const { device } = useDevicesAndChannels({ deviceId: channel?.deviceId });
  const { checkSupport } = useVersionMap();
  const { swapInDevice } = useConfiguration({ channel });
  const { skipLavvaFetch, selectedInstallationId } = useInstallation();
  const { data: deviceErrorData } = useSubscription<OnDeviceErrorSubscription, OnDeviceErrorSubscriptionVariables>(
    ON_DEVICE_ERROR,
    {
      variables: { installationId: selectedInstallationId },
      skip: skipLavvaFetch,
    },
  );

  useEffect(() => {
    if (deviceErrorData?.onDeviceError) {
      toastError({ content: tb(deviceErrorData.onDeviceError.code) });
    }
  }, [deviceErrorData]);

  const handleSwapIn = () => {
    const supported = checkSupport({
      feature: VersionedFeatureType.DeviceBindingsV2,
      supportKey: 'Core',
      currentVersion: device?.payload.currentFirmwareVersion,
    });

    if (supported) swapInDevice();
    else toastInfo({ content: td('updateToUseFeature') });
  };

  return (
    <div className="row_container max-width-desktop">
      <button className="button button--secondary" onClick={handleSwapIn}>
        {t('reverseInputs')}
      </button>
    </div>
  );
};

export default SwapIn;
