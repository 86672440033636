import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalDocument } from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { Header, IconPdf } from '../../../../../components';

type ComponentProps = {
  documents: ExternalDocument[];
};

export const ManualsAndDocuments: React.FC<ComponentProps> = ({ documents }) => {
  const { t } = useTranslation('store');

  if (!documents.length) return null;

  return (
    <>
      <hr className="m-t-24 m-b-24" />

      <Header title={t('deviceManual')} />
      {documents.map((x) => (
        <div key={x.Id} className="m-b-24">
          <div className="store-device-details--pdf-box">
            <div className="upper">
              <IconPdf />
              <p>{x.Title}</p>
            </div>

            <div className="pdf-list">
              <a key={x.Id} href={documents[0].Link} target="_blank" rel="noopener noreferrer" className="m-b-12">
                {t('downloadPDF')}
              </a>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
