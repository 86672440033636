import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  CommunicationWay,
  DeviceResponseType,
  DeviceType,
  IDevice,
  IDeviceResponseTypeInfo,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceUpdateInfo } from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import { Checkbox, IconArrowLink } from '../../../../../../components';
import { ROUTES } from '../../../../../../routes';
import { DeviceConnectionQuality } from '../../../../components/device/connection-quality';
import { DeviceIcon } from '../../../../components/device/icon';
import { useDeviceListContext } from '../../context';
import './index.scss';

type ComponentProps = {
  device: IDevice;
  update?: IDeviceUpdateInfo;
};

const DeviceItem: React.FC<ComponentProps> = ({ device, update }) => {
  const history = useHistory();
  const { t } = useTranslation('common');
  const { updateMode, selectedDeviceIds, toggleDevice } = useDeviceListContext();

  const handleDeviceClick = () => {
    if (updateMode) return;

    history.push({ pathname: ROUTES.DeviceSettings(device.Guid), state: { back: 1 } });
  };

  const isChecked = useMemo(() => !!selectedDeviceIds.find((x) => x === device.Guid), [selectedDeviceIds, device.Guid]);

  return (
    <div className="driver m-b-24">
      <div className="driver__link" onClick={handleDeviceClick}>
        {updateMode ? (
          <Checkbox checked={isChecked} onChange={() => toggleDevice(device.Guid)} className="m-b-0 m-r-24" />
        ) : (
          <div className={classNames('driver__header-icon')}>
            <DeviceIcon iconType={device.IconType} />
          </div>
        )}

        <h4 className="driver__header">
          <div className="driver__header-title">
            <div className="driver__header-title-name text-ellipsis">{device.Name}</div>
            <div className="driver__header-title-type">{`${t(`types.exalus.${DeviceType[device.DeviceType]}`)} ${
              device.Model
            }`}</div>
          </div>
        </h4>
        <div className="signal-update-container">
          {device.AvailableResponseTypes?.any(
            (a: IDeviceResponseTypeInfo) => a.Type === DeviceResponseType.SignalStrength,
          ) && device.CommunicationWay !== CommunicationWay.OneWay ? (
            <DeviceConnectionQuality device={device} />
          ) : null}
          {update ? (
            <p className="exalus-version">
              <span>{update.CurrentResourceVersion}</span>
              <IconArrowLink size={10} className="icon-arrow-link--accent" /> <span>{update.NewResourceVersion}</span>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DeviceItem;
