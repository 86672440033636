import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './index.scss';

type PropsInterface = {
  isOn: boolean;
  onClickControl: (status: boolean) => void;
  children: ReactNode;
  className?: string;
};

export const InitiateButtonRectangle: React.FC<PropsInterface> = ({ isOn, onClickControl, children, className }) => {
  const [on, setOn] = useState<boolean>(isOn);
  const deviceRef = useRef(isOn);

  useEffect(() => {
    setOn(isOn);
    deviceRef.current = isOn;
  }, [isOn, deviceRef, setOn]);

  const handleClick = () => {
    setOn(!on);
    onClickControl(!on);
  };

  return (
    <div className={classNames('initiate-button-rectangle', className)}>
      <div className={classNames('initiate-button-rectangle__bg-element')} />
      <button onClick={handleClick} className={classNames('initiate-button-rectangle__button', { active: isOn })}>
        <div
          className={classNames('initiate-button-rectangle__button-content', {
            'initiate-button-rectangle__button-content--on': on,
          })}
        >
          {children}
        </div>
      </button>
    </div>
  );
};
