import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  UpdateUserLanguageMutation,
  UpdateUserLanguageMutationVariables,
  UserProfileError,
} from '../../../data-access/gql-types/graphql';
import { UPDATE_USER_LANGUAGE } from '../../../data-access/mutations/user';
import { useBackdropContext } from '../../../hooks';
import { useMutationErrors } from '../../../hooks/backend-errors/use-mutation-errors';
import { AvailableLanguage, MutationErrorType } from '../../../types';
import { isLocalApp } from '../../../utils/helpers/local-app';
import { toastError, toastSuccess } from '../../../utils/toast';

export const useLanguageSettings = () => {
  const { t, i18n } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const { handleErrors } = useMutationErrors(MutationErrorType.UserProfile);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [updateLanguage] = useMutation<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(
    UPDATE_USER_LANGUAGE,
  );

  const optionsLocale = [
    {
      value: AvailableLanguage.EN,
      label: 'English',
      icon: <img src="/images/languages/en-flag.svg" alt="English" />,
    },
    {
      value: AvailableLanguage.PL,
      label: 'Polski',
      icon: <img src="/images/languages/pl-flag.svg" alt="Polski" />,
    },
    {
      value: AvailableLanguage.DE,
      label: 'Deutsch',
      icon: <img src="/images/languages/de-flag.svg" alt="Deutsch" />,
    },
    {
      value: AvailableLanguage.FR,
      label: 'Français',
      icon: <img src="/images/languages/fr-flag.svg" alt="Français" />,
    },
    {
      value: AvailableLanguage.LT,
      label: 'Lietuvių',
      icon: <img src="/images/languages/lt-flag.svg" alt="Lietuvių" />,
    },
    {
      value: AvailableLanguage.ES,
      label: 'Español',
      icon: <img src="/images/languages/es-flag.svg" alt="Español" />,
    },
    {
      value: AvailableLanguage.IT,
      label: 'Italiano',
      icon: <img src="/images/languages/it-flag.svg" alt="Italiano" />,
    },
    {
      value: AvailableLanguage.HU,
      label: 'Magyar',
      icon: <img src="/images/languages/hu-flag.svg" alt="Magyar" />,
    },
    {
      value: AvailableLanguage.RO,
      label: 'Română',
      icon: <img src="/images/languages/ro-flag.svg" alt="Română" />,
    },
    {
      value: AvailableLanguage.NO,
      label: 'Norsk',
      icon: <img src="/images/languages/no-flag.svg" alt="Norsk" />,
    },
    {
      value: AvailableLanguage.FI,
      label: 'Suomi',
      icon: <img src="/images/languages/fi-flag.svg" alt="Suomi" />,
    },
    {
      value: AvailableLanguage.SV,
      label: 'Svenska',
      icon: <img src="/images/languages/sv-flag.svg" alt="Svenska" />,
    },
    {
      value: AvailableLanguage.NL,
      label: 'Nederlands',
      icon: <img src="/images/languages/nl-flag.svg" alt="Nederlands" />,
    },
    {
      value: AvailableLanguage.PT,
      label: 'Português',
      icon: <img src="/images/languages/pt-flag.svg" alt="Português" />,
    },
    {
      value: AvailableLanguage.CZ,
      label: 'Čeština',
      icon: <img src="/images/languages/cz-flag.svg" alt="Čeština" />,
    },
  ];

  const optionsLocaleDefaultIndex = useMemo(() => {
    const optionIndex = optionsLocale.findIndex((option) => option.value === i18n.language);
    return optionIndex < 0 ? 0 : optionIndex;
  }, [optionsLocale, i18n.language]);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);

    if (!isLocalApp) {
      turnOnBackdrop();
      updateLanguage({
        variables: {
          input: {
            language: lang,
          },
        },
        onCompleted: (data) => {
          turnOffBackdrop();
          if (!data || !data.updateUserLanguage) {
            toastError({ content: tc('errors.somethingWentWrong') });
            return;
          }

          if (data.updateUserLanguage.result?.ok) {
            toastSuccess({ content: t('editLanguageSuccess') });
          } else {
            handleErrors((data.updateUserLanguage.errors as UserProfileError[]) || []);
          }
        },
      });
    } else {
      toastSuccess({ content: t('editLanguageSuccess') });
    }
  };

  return {
    optionsLocale,
    optionsLocaleDefaultIndex,
    changeLanguage,
  };
};
