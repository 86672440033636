import React, { PointerEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BlindActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, CoverButton, IconChevron, Slider, SubmitButton } from '../../../../components';
import { defaultFavouritePositionList } from '../../../../const';
import { BlindStateDirectionInternal } from '../../../../data-access/gql-types/graphql';
import { BlindDetailsSimpleControlButtons } from '../../../exalus/components/control-buttons/blind-details/simple';
import './blind.scss';

type CommonProps = {
  sliderValue: number;
  handleSliderEvent: (event: PointerEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSliderValue: (value: React.SetStateAction<number>) => void;
  handleConstPercentageChange: (pos: number) => void;
  handleSubmit: () => void;
};

type LavvaBlindProps = CommonProps & {
  kind?: 'lavva';
  blindAction: BlindStateDirectionInternal;
  setBlindAction: (value: React.SetStateAction<BlindStateDirectionInternal>) => void;
};

type ExalusBlindProps = Omit<CommonProps, 'kind'> & {
  kind: 'exalus';
  setBlindAction: (value: React.SetStateAction<BlindActionEnum>) => void;
  blindAction: BlindActionEnum;
  isViewSimple: boolean;
};

type BlindChannelSettingsProps = LavvaBlindProps | ExalusBlindProps;

export const BlindChannelSettings: React.FC<BlindChannelSettingsProps> = (props) => {
  const { t } = useTranslation('common');
  const { sliderValue, handleSliderEvent, onChange, setSliderValue, handleConstPercentageChange, handleSubmit } = props;
  const isExalusBlind = props.kind === 'exalus';

  const viewSimple = useMemo(() => {
    if (isExalusBlind) return props.isViewSimple;
    return false;
  }, [isExalusBlind]);

  const isOpened = useMemo(() => {
    if (props.kind === 'exalus') return props.blindAction === BlindActionEnum.Open;
    return props.blindAction === BlindStateDirectionInternal.Open;
  }, [props.blindAction]);

  const isClosed = useMemo(() => {
    if (props.kind === 'exalus') return props.blindAction === BlindActionEnum.Close;
    return props.blindAction === BlindStateDirectionInternal.Closed;
  }, [props.blindAction]);

  const isStopped = useMemo(() => {
    if (props.kind === 'exalus') return props.blindAction === BlindActionEnum.Stop;
    return props.blindAction === BlindStateDirectionInternal.Stopped;
  }, [props.blindAction]);

  return (
    <>
      <ControlWrapper>
        {!viewSimple ? (
          <Slider value={sliderValue} onPointerUp={handleSliderEvent} onChange={onChange} />
        ) : (
          <BlindDetailsSimpleControlButtons
            open={() => {
              setSliderValue(0);
              isExalusBlind && props.setBlindAction(BlindActionEnum.Open);
            }}
            stop={() => {
              if (isExalusBlind) props.setBlindAction(BlindActionEnum.Stop);
            }}
            close={() => {
              setSliderValue(100);
              isExalusBlind && props.setBlindAction(BlindActionEnum.Close);
            }}
            isOpened={isOpened}
            isClosed={isClosed}
            isStopped={isStopped}
          />
        )}
      </ControlWrapper>
      {!viewSimple && (
        <>
          <div className="blind-direction-buttons p-l-24 p-r-24">
            <button className={classNames('button-action', { active: isOpened })}>
              <IconChevron
                withCircle
                direction="up"
                onClick={() => {
                  setSliderValue(0);
                  if (!isExalusBlind) props.setBlindAction(BlindStateDirectionInternal.Open);
                  else props.setBlindAction(BlindActionEnum.Open);
                }}
              />
            </button>
            <button
              className={classNames('button-action', { active: isStopped })}
              onClick={() => {
                if (!isExalusBlind) props.setBlindAction(BlindStateDirectionInternal.Stopped);
                else props.setBlindAction(BlindActionEnum.Stop);
              }}
            >
              {t('status.stop').toUpperCase()}
            </button>
            <button className={classNames('button-action', { active: isClosed })}>
              <IconChevron
                withCircle
                direction="down"
                onClick={() => {
                  setSliderValue(100);
                  if (!isExalusBlind) props.setBlindAction(BlindStateDirectionInternal.Closed);
                  else props.setBlindAction(BlindActionEnum.Close);
                }}
              />
            </button>
          </div>
          <div className="blind-percentage-buttons p-l-24 p-r-24">
            {defaultFavouritePositionList.map((pos) => (
              <CoverButton key={pos} position={pos} onClick={() => handleConstPercentageChange(pos)}>
                <span>{pos}%</span>
              </CoverButton>
            ))}
          </div>
        </>
      )}

      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
