import React from 'react';
import { ChannelInterface, GateKindParam } from '../../../../types';
import { GateActionControlBox } from './action';
import { GateChannelControlBox } from './channel';

type CommonInterface = {
  isListItem?: boolean;
};

type ChannelParams = CommonInterface & {
  kind: GateKindParam.Channel;
  channel: ChannelInterface;
};

type ActionParams = CommonInterface & {
  kind: GateKindParam.Action;
  channels: ChannelInterface[];
};

type GateControlBoxProps = ChannelParams | ActionParams;

export const GateControlBox: React.FC<GateControlBoxProps> = (props) => {
  return props.kind === GateKindParam.Channel ? (
    <GateChannelControlBox isListItem={props.isListItem} channel={props.channel} />
  ) : (
    <GateActionControlBox isListItem={props.isListItem} channels={props.channels} />
  );
};
