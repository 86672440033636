import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeviceResponseType as ChannelResponseType,
  DeviceTaskType as ChannelTaskType,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { MeasuredEnergyDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { EmptyStateBox, IconWarning } from '../../../../../../../components';
import { energyParams } from '../../../../../pages/action-create/utils/energy-parameters';
import { canChannelDo } from '../../../../../utils';

type ChannelEnergyMeasurementListProps = {
  channel: IDeviceChannel;
};

export const ChannelEnergyMeasurementList: React.FC<ChannelEnergyMeasurementListProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');

  const state = useMemo(() => {
    return channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.MeasuredEnergy,
    ) as MeasuredEnergyDeviceState;
  }, [channel.States]);

  if (!canChannelDo(channel, [ChannelTaskType.GetPowerMeasurements]) || !state) {
    return (
      <div className="p-t-16 p-l-24 p-r-24">
        <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
      </div>
    );
  }

  const measurementItems = useMemo(() => {
    const items: any = [];

    state.Data.MeasurementParameters.forEach((value, key) => {
      const params = energyParams[key];

      if (!params.hidden) {
        items.push({
          key,
          value: value.toFixed(1),
          unit: params.unit || '',
          symbol: params.symbol || '',
        });
      }
    });

    return items;
  }, [state.Data]);

  return (
    <div className="list-measurement list-measurement--color-background p-l-24 p-r-24">
      <div className="list-measurement__wrapper grid-list-16">
        {measurementItems.map((measurement) => (
          <div className="measurement-item" key={measurement.key}>
            <span className="measurement-item__unit-icon" style={{ fontSize: measurement.symbol.length < 3 ? 20 : 12 }}>
              {measurement.symbol}
            </span>
            <span className="measurement-item__name">{t(`exalus.energyMeasurements.types.${measurement.key}`)}</span>
            <div className="measurement-item__value-wrapper">
              <span className="measurement-item__value">{measurement.value}</span>
              <span className="measurement-item__unit">{measurement.unit}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
