import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputPassword } from '../../../../../../components';

type ComponentProps = {
  disabled?: boolean;
};

export const Password: React.FC<ComponentProps> = ({ disabled = false }) => {
  const { control } = useFormContext();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');

  return (
    <Controller
      name="password"
      control={control}
      rules={{
        required: {
          value: true,
          message: tc('isRequired'),
        },
        minLength: {
          value: 4,
          message: tc('isRequired'),
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <InputPassword
          disabled={disabled}
          defaultValue={field.value}
          label={t('exalus.password')}
          onChange={field.onChange}
          name="password"
          hasError={!!error}
          {...(error ? { errorMessage: error.message } : {})}
        />
      )}
    />
  );
};
