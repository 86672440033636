import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconStateProps = {
  className?: string;
};

export const IconState: React.FC<IconStateProps> = ({ className }) => (
  <div
    className={classNames('icon-state', {
      [className as string]: className,
    })}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="6.5" r="5.75" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3.54541 6.5L9.4545 6.5" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6.5 9.45508L6.5 3.54599" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="17.5" cy="17.5" r="5.75" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M14.5454 17.5L20.4545 17.5" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  </div>
);
