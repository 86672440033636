import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { ROUTES } from '../../../../../../routes';
import { ChannelBoxIcon } from '../../../../components/channel/box-icon';
import { getPreciseChannelType } from '../../../../utils';

type ComponentProps = {
  channels: IDeviceChannel[];
};

export const DeviceChannels: React.FC<ComponentProps> = ({ channels }) => {
  const history = useHistory();

  const renderChannels = useCallback(
    () =>
      channels.map((channel) => {
        if (channel) {
          return (
            <ArrowButton
              key={channel.Number}
              title={channel.Name}
              icon={
                <ChannelBoxIcon
                  channel={channel}
                  className="device-box__head-icon device-box__head-icon--no-width m-b-0"
                />
              }
              onClick={() =>
                history.push(
                  `${ROUTES.ChannelConfiguration(
                    getPreciseChannelType(channel),
                    `${channel.GetDevice().Guid}_${channel.Number}`,
                  )}?redirect=true`,
                )
              }
              className={classNames('m-b-24 big-radius', { hidden: channel.IsHidden })}
            />
          );
        }
      }),
    [channels],
  );

  return <section className="device-settings__channels grid-list-24 m-t-32">{renderChannels()}</section>;
};
