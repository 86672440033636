import React from 'react';

type IconHandleProps = {
  position?: number; // In percents from 0 to 100
};

export const IconHandle: React.FC<IconHandleProps> = ({ position = 100 }) => {
  return (
    <>
      {position < 50 && (
        <svg
          className="icon-cover icon-handle"
          width="32"
          height="37"
          viewBox="0 0 32 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 21.5V29C15 32.866 11.866 36 8 36V36C4.13401 36 1 32.866 1 29V8C1 4.13401 4.13401 1 8 1H8.5C12.0899 1 15 3.91015 15 7.5V7.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <rect
            x="31.25"
            y="11.75"
            width="5.5"
            height="24.5"
            rx="2.75"
            transform="rotate(90 31.25 11.75)"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <circle cx="8" cy="25" r="1.75" stroke="#15141D" strokeWidth="1.5" />
          <path d="M8 27V30.5" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      )}
      {position >= 50 && (
        <svg
          className="icon-cover"
          width="32"
          height="37"
          viewBox="0 0 32 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 21.5V29C15 32.866 11.866 36 8 36V36C4.13401 36 1 32.866 1 29V8C1 4.13401 4.13401 1 8 1H8.5C12.0899 1 15 3.91015 15 7.5V7.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <rect
            x="31.25"
            y="11.75"
            width="5.5"
            height="24.5"
            rx="2.75"
            transform="rotate(90 31.25 11.75)"
            stroke="#15141D"
            strokeWidth="1.5"
          />
          <circle cx="8" cy="25" r="1.75" stroke="#15141D" strokeWidth="1.5" />
        </svg>
      )}
    </>
  );
};
