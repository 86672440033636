import React from 'react';
import './index.scss';

type IconInitialProps = {
  initial: string;
};

export const IconInitial: React.FC<IconInitialProps> = ({ initial }) => (
  <div className="icon-initial">
    <span className="icon-initial__text">{initial}</span>
  </div>
);
