import React from 'react';
import { Switch } from '../../../../components';
import { NotificationSettings } from '../../types';
import './index.scss';

type ComponentProps = {
  label: string;
  option: keyof NotificationSettings;
  checked: boolean;
  handleToggle: (option: keyof NotificationSettings) => void;
  children?: React.ReactNode;
};

export const NotificationOption: React.FC<ComponentProps> = ({ label, checked, option, handleToggle, children }) => (
  <div className="option m-b-24">
    <div className="m-r-16">
      <p>{label}</p>
      {children}
    </div>
    <Switch checked={checked} onChange={() => handleToggle(option)} />
  </div>
);
