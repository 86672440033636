import React from 'react';

type IconBedsideLampProps = {
  isOn: boolean;
};

export const IconBedsideLamp: React.FC<IconBedsideLampProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-switch icon-bedside-lamp"
      width="23"
      height="27"
      viewBox="0 0 23 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.2307 2.69645C5.44679 2.09139 6.01992 1.6875 6.6624 1.6875H15.3376C15.9801 1.6875 16.5532 2.09139 16.7693 2.69645L21.0852 14.7809C21.4388 15.771 20.7048 16.8125 19.6535 16.8125H2.34653C1.29521 16.8125 0.561227 15.771 0.914822 14.7809L5.2307 2.69645Z"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M11 19.9375C7.06502 19.9375 3.87505 22.4015 3.87505 25.4409V25.875L18.125 25.7702V25.4409C18.125 22.4015 14.9351 19.9375 11 19.9375Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path d="M11 17.5625V19.3438" stroke="#15141D" strokeWidth="1.13514" />
      {isOn ? (
        <path
          d="M22.1001 5.88731C22.1708 5.41191 22.063 4.82307 21.4831 3.81865C20.9032 2.81424 20.4816 2.48625 20.0001 2.25"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      ) : null}
    </svg>
  );
};
