import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/IRcr21ConfigService';
import {
  PirDetection,
  PirDetectionThreshold,
  PirPredefinedSettings,
  PirSensorParams,
  PirWindowTime,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigParams';
import { Rcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { Slider } from '@mui/material';
import { Checkbox, InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { detectionValues, sensitivityValues } from '../../../device-details/utils';

type ComponentProps = {
  device: IDevice;
  channel: IDeviceChannel;
  params: PirSensorParams | null;
};

const RipForm: React.FC<ComponentProps> = ({ device, channel, params }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<PirSensorParams>();

  const { WindowTime, Detection, DetectionThreshold, PredefinedSettings } = form.watch();

  useEffect(() => {
    if (params !== null) {
      form.setValue('WindowTime', params.WindowTime);
      form.setValue('Detection', params.Detection);
      form.setValue('DetectionThreshold', params.DetectionThreshold);
      form.setValue('PredefinedSettings', params.PredefinedSettings);
    }
  }, [params]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (channel) {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcr21ConfigService>(
        Rcr21ConfigService.ServiceName,
      );

      const result = await configService.SetPirSensorParamsAsync(
        {
          Detection: values.Detection,
          DetectionThreshold: values.DetectionThreshold,
          DeviceGuid: device.Guid,
          PredefinedSettings: values.PredefinedSettings,
          WindowTime: values.WindowTime,
        },
        channel.Number,
      );
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const onChangeWindowTime = (value: number) => form.setValue('WindowTime', value as PirWindowTime);

  const onChangeDetection = (event: Event, value: number | number[]) => {
    form.setValue('Detection', value as PirDetection);
  };

  const onChangeThreshold = (event: Event, value: number | number[]) => {
    form.setValue('DetectionThreshold', value as PirDetectionThreshold);
  };

  const onChangeSensitivity = (event: Event, value: number | number[]) => {
    form.setValue('PredefinedSettings', value as PirPredefinedSettings);
  };

  const valueLabelFormatDetection = (value: number) => {
    return detectionValues.findIndex((mark) => mark.value === value) + 1;
  };

  const valueLabelFormatSensitivity = (value: number) => {
    return sensitivityValues.findIndex((mark) => mark.value === value) + 1;
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <Checkbox
          checked={PredefinedSettings === 0}
          onChange={(e) => form.setValue('PredefinedSettings', (e.target.checked ? 0 : 1) as PirPredefinedSettings)}
          reverse
        >
          {t('exalus.params.Rcr21Params.advancedSettings')}
        </Checkbox>
        {PredefinedSettings === 0 ? (
          <>
            <label className="input__label">{t('exalus.params.Rcr21Params.DetectionCount')}</label>
            <Slider
              className="custom-slider"
              defaultValue={20}
              valueLabelFormat={valueLabelFormatDetection}
              step={1}
              min={1}
              max={4}
              valueLabelDisplay="auto"
              value={Detection || 0}
              onChange={onChangeDetection}
              marks={detectionValues}
            />
            <label className="input__label">{t('exalus.params.Rcr21Params.Threshhold')}</label>
            <Slider
              className="custom-slider"
              defaultValue={20}
              step={1}
              min={0}
              max={255}
              value={DetectionThreshold || 0}
              onChange={onChangeThreshold}
              valueLabelDisplay="on"
            />
            <InputSelect
              onChange={onChangeWindowTime}
              value={WindowTime}
              options={[
                { label: '2', value: 0 },
                { label: '4', value: 1 },
                { label: '6', value: 2 },
                { label: '8', value: 3 },
              ]}
              label={t('exalus.params.Rcr21Params.WindowTime')}
            />
          </>
        ) : (
          <>
            <label className="input__label">{t('exalus.params.Rcr21Params.Sensitivity')}</label>
            <Slider
              className="custom-slider"
              defaultValue={20}
              valueLabelFormat={valueLabelFormatSensitivity}
              step={1}
              min={1}
              max={10}
              valueLabelDisplay="auto"
              value={PredefinedSettings || 0}
              onChange={onChangeSensitivity}
              marks={sensitivityValues}
            />
          </>
        )}
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default RipForm;
