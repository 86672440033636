import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindRemoteButtonStateEnum,
  ChannelOnOffState,
  FacadeRemoteButtonStateEnum,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { Checkbox, IconChevron, IconConnectionError, IconPause, IconSortHandle } from '../../../../../components';
import { IconCalibrateInfo } from '../../../../../components/icons/calibrate-info';
import { IconToastWarning2 } from '../../../../../components/icons/icon-toast-warning-2';
import { ROUTES } from '../../../../../routes';
import { ChannelType } from '../../../enums';
import { ChannelBoxIcon } from '../box-icon';
import { ChannelBoxControls } from '../controls';
import { useChannelBox } from '../hooks/use-channel-box';
import './index.scss';

type ChannelBoxProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
  isCheckbox?: boolean;
  checked?: boolean;
  isChannelNumber?: boolean;
  className?: string;
  isDraggable?: boolean;
  disableRedirect?: boolean;
  hidden?: boolean;
  showIsHidden?: boolean;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
  style?: React.CSSProperties;
  name?: string;
  hideControls?: boolean;
  onChannelToggle?: (id: string, value: boolean) => void;
  setNodeRef?: (node: HTMLElement | null) => void;
  handleExalusChannelItemClick?: (channel: IDeviceChannel) => void;
};

export const ChannelBox: React.FC<ChannelBoxProps> = ({
  channel,
  isListItem = false,
  className,
  children,
  isCheckbox,
  checked,
  isChannelNumber,
  isDraggable,
  attributes,
  listeners,
  style,
  disableRedirect,
  hidden,
  showIsHidden,
  name,
  hideControls,
  onChannelToggle,
  setNodeRef,
  handleExalusChannelItemClick,
}) => {
  const history = useHistory();
  const {
    isWarning,
    isError,
    isTaskActive,
    remoteButtonState,
    percentageBadge,
    lightState,
    isRgb,
    type,
    rgbStyle,
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    channelOrientedWarning,
    externalLimitSwitchActive,
  } = useChannelBox(channel);

  const handleToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChannelToggle?.(channel.ChannelId, e.target.checked);
    },
    [channel, onChannelToggle],
  );

  const channelIcon = useMemo(() => {
    if (!isChannelNumber) return <ChannelBoxIcon channel={channel} />;

    if (remoteButtonState) {
      const rmtState = remoteButtonState.Data.State;

      switch (rmtState) {
        case BlindRemoteButtonStateEnum.OpenPressed:
        case FacadeRemoteButtonStateEnum.OpenPressed: {
          return <IconChevron withCircle direction="up" size={80} />;
        }
        case BlindRemoteButtonStateEnum.ClosePressed:
        case FacadeRemoteButtonStateEnum.ClosePressed: {
          return <IconChevron withCircle direction="down" size={80} />;
        }
        case BlindRemoteButtonStateEnum.StopPressed:
        case FacadeRemoteButtonStateEnum.StopPressed: {
          return <IconPause size={80} />;
        }
      }
    }

    return <ChannelBoxIcon channel={channel} />;
  }, [isChannelNumber, remoteButtonState, channel.States]);

  const handleRedirect = useCallback(() => {
    if (handleExalusChannelItemClick) handleExalusChannelItemClick(channel);
    else if (!disableRedirect) {
      history.push(ROUTES.ChannelDetails(channel.ChannelId));
    }
  }, [disableRedirect, channel.ChannelId, handleExalusChannelItemClick]);

  return (
    <div
      className={classNames('device-box device-box--exalus', {
        'device-box--list-item': isListItem,
        'device-box--hidden': hidden,
        'device-box--show-hidden': showIsHidden,
        'device-box--turn-on':
          lightState !== undefined &&
          !(lightState as IChannelState<ChannelOnOffState>)?.Data.State &&
          (type === ChannelType.Switch || type === ChannelType.Light),
        'task-active': isTaskActive,
        'is-error': channelOrientedError,
        'is-warning': channelOrientedWarning,
        [className as string]: className,
      })}
      ref={setNodeRef}
      style={style}
    >
      {children ? (
        <>{children}</>
      ) : (
        <>
          <div
            className={classNames('device-box__head', {
              'device-box__head--small-height': channelOrientedWarning,
            })}
            onClick={handleRedirect}
          >
            <div
              className={classNames('device-box__head-icon', {
                'device-box__head-icon--small-margin': channelOrientedWarning,
              })}
            >
              {connectionIcon ? <IconConnectionError size={9} /> : calibrationNeed ? <IconCalibrateInfo /> : null}
              {isError ? (
                <IconToastWarning2 colorError size={16} />
              ) : (
                isWarning && <IconToastWarning2 colorWarning size={16} />
              )}
              {channelIcon}
              {isChannelNumber && <div className="channel-number">{channel.Number}</div>}
              {percentageBadge && !connectionIcon && (
                <div className={classNames('channel-value-badge', { accent: externalLimitSwitchActive })}>
                  {percentageBadge}
                </div>
              )}
            </div>
            <div className="device-box__head-wrapper">
              {isRgb && rgbStyle && <div className="device-box__color" style={rgbStyle} />}
              <h4 className="device-box__name text-ellipsis">{name || channel.Name}</h4>
            </div>
          </div>
          {isDraggable ? (
            <div className="device-box__draggable">
              <div className="device-box__draggable-handle" {...attributes} {...listeners}>
                <IconSortHandle />
              </div>
            </div>
          ) : (
            <div className="device-box__controls">
              <>
                {!isCheckbox ? (
                  <>{!hideControls && <ChannelBoxControls channel={channel} isListItem={isListItem} />}</>
                ) : (
                  <Checkbox id={channel.ChannelId} checked={checked} onChange={handleToggle} />
                )}
              </>
            </div>
          )}
        </>
      )}
    </div>
  );
};
