import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ChannelBox } from '../../../components/channel/box';
import { GroupChannelsSortable } from '../types';

type DeviceBoxSortableProps = {
  channelSortable: GroupChannelsSortable;
  isListItem?: boolean;
};

export const ChannelItemSortable: React.FC<DeviceBoxSortableProps> = ({ channelSortable, isListItem }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: channelSortable.id,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  return (
    <ChannelBox
      channel={channelSortable.channel}
      isListItem={isListItem}
      setNodeRef={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
      isDraggable
      showIsHidden={channelSortable.channel.IsHidden}
      name={channelSortable.name}
    />
  );
};
