import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, IconLogoWisniowskiSmall, NavHeader, Page, SubmitButton } from '../../../../../components';
import { ROUTES } from '../../../../../routes';
import { useBluetoothContext } from '../../../context';
import { DriveFlowType } from '../../types';
import './index.scss';

const WisniowskiDriveAddTutorialStep1: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('drive-add');
  const { checkIfConnected } = useBluetoothContext();

  useEffect(() => {
    checkIfConnected();
  }, []);

  const handleNext = () => {
    history.push(ROUTES.DeviceAddBluetoothWisniowskiTutorialStep2(DriveFlowType.Drive));
  };

  return (
    <Page
      contentClassName="drive-add tutorial"
      containerClassName="p-t-0"
      isStickyHeader
      header={
        <>
          <NavHeader title={t('navbarHeader')} />
        </>
      }
    >
      <div className="tutorial--content">
        <Header title={t('tutorial.case')} center />
        <img src="/images/drive-case.png" alt="drive-case" />
        <IconLogoWisniowskiSmall className="m-t-48" />
        <SubmitButton onClick={handleNext}>{t('tutorial.nextStep')}</SubmitButton>
      </div>
    </Page>
  );
};

export default WisniowskiDriveAddTutorialStep1;
