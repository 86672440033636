import React from 'react';
import { DeviceConnectionState, UserAction } from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { ChannelInterface, DashboardUserActionInterface } from '../../../types';
import { Control } from '../base-control';

type ActionControlProps = {
  channels: ChannelInterface[];
  userAction: DashboardUserActionInterface | UserAction;
  isListItem: boolean;
};

export const ActionControl: React.FC<ActionControlProps> = ({ channels, userAction, isListItem }) => {
  const { channelState } = useChannelsState();

  if (
    !channels.length ||
    (channels.length &&
      channels.every((channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery))
  ) {
    return null;
  }

  return (
    <div className="action-control">
      <Control channels={channels} userAction={userAction} isListItem={isListItem} />
    </div>
  );
};
