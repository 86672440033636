import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { enableScroll, preventScroll } from '../../utils/helpers/scroll';
import { IconChevron } from '../icons';
import './index.scss';

export type TabInterface = {
  label: string;
  icon?: ReactNode;
  indicator?: boolean;
  addNew?: boolean;
  data?: number | string;
  onClick?: () => void;
};

type TabsProps = {
  tabList: TabInterface[];
  activeTabIndex: number;
  setActiveTabIndex?: (activeTabIndex: number) => void;
  tabsType?: 'default' | 'pills' | 'compact';
  tabsBgColor?: 'grey' | 'white' | 'transparent';
  isDivider?: boolean;
  autoScrollDisabled?: boolean;
  tabsListClassName?: string;
  biggerTabs?: boolean;
  fullWidth?: boolean;
  moreButton?: 'normal' | 'secondary';
  moreListFullHeight?: boolean;
};

export const Tabs: React.FC<TabsProps> = ({
  tabList,
  activeTabIndex,
  setActiveTabIndex,
  tabsType = 'default',
  tabsBgColor = 'white',
  isDivider = true,
  autoScrollDisabled = false,
  tabsListClassName,
  children,
  biggerTabs,
  fullWidth = true,
  moreButton,
  moreListFullHeight,
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const [moreOpen, setMoreOpen] = useState<boolean>(false);

  const setBgGradient = useCallback(() => {
    if (ref && ref.current) {
      const scrollWidth = ref.current.scrollWidth;
      const scrollLeft = ref.current.scrollLeft;

      ref?.current?.parentElement?.classList.toggle('tabs--gradient-left', scrollLeft > 20);
      ref?.current?.parentElement?.classList.toggle(
        'tabs--gradient-right',
        ref.current.offsetWidth + scrollLeft < scrollWidth,
      );
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener('resize', setBgGradient);

    return () => window.removeEventListener('resize', setBgGradient);
  }, []);

  useEffect(() => {
    if (ref && ref.current && !autoScrollDisabled) {
      const activeElement = ref?.current.children[activeTabIndex];
      if (activeElement) {
        activeElement.scrollIntoView({ block: 'nearest', inline: 'center' });
        setBgGradient();
      }
    }
  }, [ref?.current?.scrollWidth, activeTabIndex]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('scroll', setBgGradient);

      return () => ref.current?.removeEventListener('scroll', setBgGradient);
    }
  }, [ref]);

  const toggleMore = () => {
    setMoreOpen(!moreOpen);

    if (!moreOpen) preventScroll();
    else enableScroll();
  };

  const handleChangeTab = (tabIndex: number, tab: TabInterface) => {
    enableScroll();
    setMoreOpen(false);

    if (tab.onClick) tab.onClick();
    else if (setActiveTabIndex) {
      setActiveTabIndex(tabIndex);
    }
  };

  return (
    <>
      {tabList.length > 0 && (
        <div
          className={classNames(`tabs tabs--type-${tabsType} tabs--${tabsBgColor}`, {
            'bigger-tabs': biggerTabs,
            'more-btn': moreButton,
          })}
        >
          {tabList.length > 1 && moreButton && (
            <button onClick={toggleMore} className={classNames('tabs__more-btn', { open: moreOpen })}>
              <IconChevron />
            </button>
          )}
          {!moreOpen && (
            <ul
              ref={ref}
              className={classNames('tabs__list', {
                'full-width': fullWidth && !moreButton,
                'no-padding': moreButton,
                [tabsListClassName as string]: tabsListClassName,
              })}
            >
              {tabList.map((tab: TabInterface, tabIndex: number) => (
                <li key={tabIndex} onClick={() => handleChangeTab(tabIndex, tab)} className="tabs__list-item-wrapper">
                  <div
                    className={classNames('tabs__list-item', {
                      'tabs__list-item--active': tabIndex === activeTabIndex,
                      'tabs__list-item--bigger': biggerTabs,
                      'tabs__list-item--indicator': tab.indicator,
                      'tabs__list-item--add-new': tab.addNew,
                    })}
                  >
                    <div className="tabs__list-item-content">
                      {tab.icon}
                      <span className="text-ellipsis">{tab.label}</span>
                      {tab.indicator && <div className="indicator" />}
                    </div>
                  </div>
                </li>
              ))}
              {children}
            </ul>
          )}
          {moreOpen && (
            <div
              className={classNames('more-tabs-list', {
                secondary: moreButton === 'secondary',
                'full-height': moreListFullHeight,
              })}
            >
              <ul className="grid-list-24">
                {tabList.map((tab: TabInterface, tabIndex: number) => (
                  <li
                    key={tabIndex}
                    onClick={() => handleChangeTab(tabIndex, tab)}
                    className={classNames({ active: tabIndex === activeTabIndex, 'add-new': tab.addNew })}
                  >
                    <div className="item-content">
                      {tab.icon && <div className="icon">{tab.icon}</div>}
                      <span className="text-ellipsis">{tab.label}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      {isDivider && !moreOpen && <hr className="tabs__line" />}
    </>
  );
};
