import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconInitiate2Props = {
  className?: string;
};

export const IconInitiate2: React.FC<IconInitiate2Props> = ({ className }) => {
  return (
    <svg
      className={classNames('icon-initiate-2', { [className as string]: className })}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5705 12.4267C21.4094 13.2659 21.9807 14.335 22.212 15.4988C22.4434 16.6627 22.3245 17.869 21.8703 18.9652C21.4161 20.0614 20.6471 20.9984 19.6604 21.6576C18.6737 22.3168 17.5138 22.6686 16.3272 22.6686C15.1406 22.6686 13.9806 22.3168 12.994 21.6576C12.0073 20.9984 11.2383 20.0614 10.7841 18.9652C10.3299 17.869 10.211 16.6627 10.4423 15.4988C10.6737 14.335 11.2449 13.2659 12.0838 12.4267"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.3305 9.33333V16" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="16.3305" cy="16" r="15.25" stroke="#15141D" strokeWidth="1.5" />
    </svg>
  );
};
