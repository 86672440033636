import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconCalendarProps = {
  className?: string;
};

export const IconCalendar: React.FC<IconCalendarProps> = ({ className }) => (
  <div
    className={classNames('icon-calendar', {
      [className as string]: className,
    })}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 5H20C21.1 5 22 5.95625 22 7.125V12.5V19.875C22 21.0438 21.1 22 20 22H4C2.9 22 2 21.0438 2 19.875V12.5V7.125C2 5.95625 2.9 5 4 5Z"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 8V2" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 8V2" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 8V2" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.45094 18.84V12.3867L7 13.2667V12.024L8.45094 11.16H9.59135V18.84H8.45094Z" fill="#009EE3" />
      <path
        d="M14.3123 19C13.7876 19 13.3236 18.8862 12.9202 18.6587C12.5169 18.4276 12.1992 18.1111 11.9672 17.7093C11.7388 17.3076 11.6245 16.8453 11.6245 16.3227V13.6773C11.6245 13.1547 11.7388 12.6924 11.9672 12.2907C12.1992 11.8889 12.5169 11.5742 12.9202 11.3467C13.3236 11.1156 13.7876 11 14.3123 11C14.837 11 15.301 11.1156 15.7043 11.3467C16.1077 11.5742 16.4235 11.8889 16.652 12.2907C16.884 12.6924 17 13.1547 17 13.6773V16.3227C17 16.8453 16.884 17.3076 16.652 17.7093C16.4235 18.1111 16.1077 18.4276 15.7043 18.6587C15.301 18.8862 14.837 19 14.3123 19ZM14.3123 17.9333C14.5978 17.9333 14.8566 17.8658 15.0886 17.7307C15.3242 17.592 15.5116 17.4071 15.6508 17.176C15.79 16.9413 15.8596 16.68 15.8596 16.392V13.5973C15.8596 13.3129 15.79 13.0551 15.6508 12.824C15.5116 12.5893 15.3242 12.4044 15.0886 12.2693C14.8566 12.1307 14.5978 12.0613 14.3123 12.0613C14.0267 12.0613 13.7662 12.1307 13.5306 12.2693C13.2986 12.4044 13.113 12.5893 12.9738 12.824C12.8346 13.0551 12.765 13.3129 12.765 13.5973V16.392C12.765 16.68 12.8346 16.9413 12.9738 17.176C13.113 17.4071 13.2986 17.592 13.5306 17.7307C13.7662 17.8658 14.0267 17.9333 14.3123 17.9333Z"
        fill="#009EE3"
      />
    </svg>
  </div>
);
