import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconDevicesProps = {
  className?: string;
};

export const IconDevices: React.FC<IconDevicesProps> = ({ className }) => (
  <div
    className={classNames('icon-devices', {
      [className as string]: className,
    })}
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21387_1000)">
        <circle cx="14.5" cy="14.5" r="5.75" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M11.5454 14.5L17.4545 14.5" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M14.5 17.4545L14.5 11.5454" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" />
        <circle cx="25.5" cy="25.5" r="5.75" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M22.5454 25.5L28.4545 25.5" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_21387_1000">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
