import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { SelectOptionInterface } from '../../../../components';
import {
  ChannelTypeInternal,
  MeasurementChannelKind,
  MeterCommonMeasurementsQueryVariables,
  MeterTypeInternal,
  PeriodWeeklyPeriod,
  Query,
  VectorParameterTypeInternal,
} from '../../../../data-access/gql-types/graphql';
import { METER_COMMON_MEASUREMENTS } from '../../../../data-access/queries/meter';
import { useInstallation } from '../../../../hooks';
import { ChannelInterface } from '../../../../types';
import * as storage from '../../../../utils/storage/lavva';
import { useMeterPrices } from '../common/hooks/use-meter-prices';
import {
  extractNumberFromMeasurement,
  measurementNameParse,
  period1MeasurementByPeriod,
  period2MeasurementByPeriod,
  period3MeasurementByPeriod,
  sortedParameters,
} from '../utils';

export const useMeterMeasurements = (channel: ChannelInterface) => {
  const { t } = useTranslation('channel-details');
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const [activePeriod, setActivePeriod] = useState<PeriodWeeklyPeriod>(PeriodWeeklyPeriod.Total);
  const [advancedParameters, setAdvancedParameters] = useState<boolean>(!!storage.getItem('meterAdvancedParameters'));
  const { tariffPricesConfiguration } = useMeterPrices();
  const kind = MeasurementChannelKind.Meter;

  const isStandalone = useMemo(() => {
    return channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  const { data, loading } = useQuery<Query, MeterCommonMeasurementsQueryVariables>(METER_COMMON_MEASUREMENTS, {
    variables: {
      installationId: selectedInstallationId,
      input: {
        channelId: channel.id,
        deviceId: channel.deviceId,
        kind,
        indices: [],
        advancedCommonMeasurements: advancedParameters,
        periods: activePeriod,
      },
    },
    skip: skipLavvaFetch,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 60 * 1000,
  });

  const phaseTabs = useMemo(() => {
    const phases = groupBy(data?.meterCommonMeasurements.data?.vectorMeasurements, 'index');
    return Object.keys(phases).map((phase) => Number(phase));
  }, [data]);

  const vectorMeasurements = useMemo(() => {
    return data?.meterCommonMeasurements.data?.vectorMeasurements || [];
  }, [data?.meterCommonMeasurements.data?.vectorMeasurements]);

  const phaseMeasurementsGroupedByPhase = useMemo(() => {
    const vectorMeasurements = sortedParameters(data?.meterCommonMeasurements.data?.vectorMeasurements || []);
    return groupBy(vectorMeasurements, 'index');
  }, [data?.meterCommonMeasurements.data?.vectorMeasurements]);

  const phaseMeasurementsGroupedByType = useMemo(() => {
    const vectorMeasurements = data?.meterCommonMeasurements.data?.vectorMeasurements;
    return groupBy(vectorMeasurements || [], 'type');
  }, [data?.meterCommonMeasurements.data?.vectorMeasurements]);

  const handleControl = (e: ChangeEvent<HTMLInputElement>) => {
    storage.setItem('meterAdvancedParameters', e.target.checked);
    setAdvancedParameters(e.target.checked);
  };

  const timeRanges: SelectOptionInterface<PeriodWeeklyPeriod>[] = useMemo(
    () => [
      { value: PeriodWeeklyPeriod.Total, label: t('measurementPeriods.total') },
      { value: PeriodWeeklyPeriod.Daily, label: t('measurementPeriods.daily') },
      { value: PeriodWeeklyPeriod.Weekly, label: t('measurementPeriods.weekly') },
      { value: PeriodWeeklyPeriod.Monthly, label: t('measurementPeriods.monthly') },
    ],
    [t],
  );

  const tariffsQuantity = useMemo(() => {
    const period1 = !!vectorMeasurements.find((x) => x.type === period1MeasurementByPeriod[activePeriod]);
    const period2 = !!vectorMeasurements.find((x) => x.type === period2MeasurementByPeriod[activePeriod]);
    const period3 = !!vectorMeasurements.find((x) => x.type === period3MeasurementByPeriod[activePeriod]);
    return +period1 + +period2 + +period3;
  }, [vectorMeasurements, activePeriod]);

  const getMeasurementSubText = (measurement: VectorParameterTypeInternal) => {
    if (tariffsQuantity >= 2 && (measurement as VectorParameterTypeInternal).includes('_PERIOD')) {
      const priceZone = extractNumberFromMeasurement(measurement);

      return `${t(
        `energyMeasurements.subLabel.${measurementNameParse(measurement)}${tariffsQuantity === 2 ? '_OF_2' : ''}`,
      )} ${
        priceZone && tariffPricesConfiguration?.prices ? `${tariffPricesConfiguration.prices[priceZone]} zł/kWh` : ''
      }`;
    }

    if (tariffsQuantity === 0 && (measurement as VectorParameterTypeInternal).includes('FORWARD_ACTIVE_ENERGY')) {
      if (tariffPricesConfiguration?.prices?.[2]) {
        return `${t('price')} ${
          tariffPricesConfiguration?.prices ? `${tariffPricesConfiguration.prices[2]} zł/kWh` : ''
        }`;
      }
    }

    return undefined;
  };

  const getMeasurementSubValue = (measurement: VectorParameterTypeInternal, value: number) => {
    if (tariffsQuantity >= 2 && (measurement as VectorParameterTypeInternal).includes('_PERIOD')) {
      const priceZone = extractNumberFromMeasurement(measurement);

      if (priceZone && tariffPricesConfiguration?.prices) {
        return `${(value * tariffPricesConfiguration.prices[priceZone]).toFixed(2)} zł`;
      }
    }

    if (tariffsQuantity === 0 && (measurement as VectorParameterTypeInternal).includes('FORWARD_ACTIVE_ENERGY')) {
      if (tariffPricesConfiguration?.prices?.[2]) {
        return `${(value * tariffPricesConfiguration.prices[2]).toFixed(2)} zł`;
      }
    }

    return undefined;
  };

  return {
    timeRanges,
    activePeriod,
    advancedParameters,
    loading,
    data,
    setActivePeriod,
    isStandalone,
    kind,
    phaseTabs,
    phaseMeasurementsGroupedByPhase,
    phaseMeasurementsGroupedByType,
    handleControl,
    getMeasurementSubText,
    getMeasurementSubValue,
  };
};
