import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useAnimate } from '../../hooks';
import { hexToRgb } from '../../utils/helpers';
import { IconSun } from '../icons';
import './index.scss';

type LightSliderProps = {
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPointerUp?: (event: React.PointerEvent<HTMLInputElement>) => void;
  gradientColor?: string;
  isBasic?: boolean;
  showTooltip?: boolean;
  active?: boolean;
  whiteLight?: boolean;
  label?: string;
};

export const LightSlider: React.FC<LightSliderProps> = ({
  value,
  onChange,
  onPointerUp,
  isBasic,
  gradientColor,
  showTooltip = true,
  active,
  whiteLight,
  label,
}) => {
  const [animateValue, setAnimateValue] = useAnimate(value, 1000, 'easeOutSine');
  const [isAnimating, setIsAnimating] = useState<boolean>(true);

  useEffect(() => {
    // Calculate duration based on difference between old and new value to
    // achieve constant speed no matter how big or small is the change in value.
    const duration = isAnimating ? Math.abs(animateValue - value) * 10 : 0;
    setAnimateValue(value, duration);
  }, [value]);

  const handleOnPointerUp = debounce((event: React.PointerEvent<HTMLInputElement>) => {
    onPointerUp?.(event);
    setIsAnimating(true);
  }, 300);

  const handleOnPointerMove = () => setIsAnimating(false);

  const color = useMemo(
    () => (gradientColor?.includes('#') ? hexToRgb(gradientColor) : gradientColor),
    [gradientColor],
  );

  const style = useMemo(
    () =>
      ({
        '--slider-position': `${isAnimating ? animateValue : value}%`,
        '--slider-gradient-color': color,
      } as React.CSSProperties),
    [isAnimating, animateValue, value, color],
  );

  return (
    <>
      <h3>{label}</h3>
      <div
        className={classNames('light-slider', {
          'light-slider--gradient': color,
          'light-slider--basic': isBasic,
          'white-light': whiteLight,
          active: active,
        })}
      >
        <div
          className={classNames('light-slider__icon light-slider__icon--off', {
            'light-slider__icon--accent': animateValue < 10,
          })}
        >
          <IconSun isOn={false} />
        </div>
        <div className="light-slider__slider-element">
          <input
            style={style}
            className="light-slider__input"
            min="0"
            max="100"
            type="range"
            value={value}
            onChange={onChange}
            onPointerUp={handleOnPointerUp}
            onPointerMove={handleOnPointerMove}
          />
          <div style={style} className="light-slider__thumb">
            <div className='light-slider__thumb--content'></div>
          </div>
        </div>
        {showTooltip && (
          <div style={style} className="light-slider__value">
            <h2 className="light-slider__value-text">{value.toFixed()}%</h2>
            <svg
              className="light-slider__value-handle"
              width="19"
              height="6"
              viewBox="0 0 19 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3H13" />
              <circle cx="16" cy="3" r="2.5" />
            </svg>
          </div>
        )}
        <div
          className={classNames('light-slider__icon light-slider__icon--on', {
            'light-slider__icon--accent': animateValue < 95,
          })}
        >
          <IconSun isOn />
        </div>
      </div>
    </>
  );
};
