import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  ActivateTriggerMutation,
  ActivateTriggerMutationVariables,
  DeactivateTriggerMutation,
  DeactivateTriggerMutationVariables,
  Trigger,
} from '../../../data-access/gql-types/graphql';
import { ACTIVATE_TRIGGER, DEACTIVATE_TRIGGER } from '../../../data-access/mutations/trigger';
import { useInstallation } from '../../../hooks';
import { Switch } from '../../switch';

type PropsInterface = {
  trigger: Trigger;
  refetch?: () => void;
};

export const TriggerSwitch: React.FC<PropsInterface> = ({ trigger, refetch }) => {
  const { selectedInstallationId } = useInstallation();
  const [isActivated, setIsActivated] = useState<boolean>(trigger?.isActive || false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [activateTrigger] = useMutation<ActivateTriggerMutation, ActivateTriggerMutationVariables>(ACTIVATE_TRIGGER, {
    onCompleted: (data) => {
      if (data.activateTrigger) {
        setIsDisabled(false);
        setIsActivated(true);
        if (refetch) {
          refetch();
        }
      }
    },
  });

  const [deactivateTrigger] = useMutation<DeactivateTriggerMutation, DeactivateTriggerMutationVariables>(
    DEACTIVATE_TRIGGER,
    {
      onCompleted: (data) => {
        if (data.deactivateTrigger) {
          setIsDisabled(false);
          setIsActivated(false);
          if (refetch) {
            refetch();
          }
        }
      },
    },
  );

  return (
    <Switch
      disabled={isDisabled}
      checked={isActivated}
      onChange={() => {
        setIsDisabled(true);
        if (isActivated) {
          deactivateTrigger({
            variables: { input: { triggerId: trigger.triggerId, installationId: selectedInstallationId } },
          });
        } else {
          activateTrigger({
            variables: { input: { triggerId: trigger.triggerId, installationId: selectedInstallationId } },
          });
        }
      }}
    />
  );
};
