import React from 'react';
import { useParams } from 'react-router-dom';
import { ChannelTypeInternal } from '../../../data-access/gql-types/graphql';
import GateConfigurationGeneralPersonalization from './gate';

const ConfigurationGeneralPersonalization: React.FC = () => {
  const { channelType } = useParams<{ channelId: string; channelType: ChannelTypeInternal }>();

  switch (channelType) {
    case ChannelTypeInternal.Gate:
      return <GateConfigurationGeneralPersonalization />;
    default:
      return null;
  }
};

export default ConfigurationGeneralPersonalization;
