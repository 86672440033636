export const getParameterByName = (name: string): string => {
  name = name.replace(/\[]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(window.location.href);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const pathnameWithParameters = (
  pathname: string,
  search: string,
  newParam?: { key: string; value: string | number | boolean },
) => {
  const searchParams = new URLSearchParams(search);
  if (newParam) searchParams.set(newParam.key, newParam.value.toString());

  return `${pathname}?${`${searchParams.toString()}`}`;
};

export const getTimeZoneFromBrowser = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
