import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Navigation } from '../../components';
import { UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables } from '../../data-access/gql-types/graphql';
import { UPDATE_USER_LANGUAGE } from '../../data-access/mutations/user';
import { useProfile } from '../../hooks';
import { ROUTES } from '../../routes';
import { isLocalApp } from '../../utils/helpers/local-app';
import { enableScroll } from '../../utils/helpers/scroll';
import { hideSplashScreen } from '../../utils/helpers/splashscreen';

export const MainLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const [updateLanguage] = useMutation<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(
    UPDATE_USER_LANGUAGE,
  );
  const navigation = [ROUTES.Root(), ROUTES.Channel(), ROUTES.ActionList(), ROUTES.Settings(), ROUTES.Chat()];
  const { userProfileData } = useProfile();

  useEffect(() => {
    if (isLocalApp) hideSplashScreen();
  }, []);

  useEffect(() => {
    if (userProfileData?.userProfile?.userLanguage !== undefined) {
      if (userProfileData?.userProfile?.userLanguage === '') {
        updateLanguage({
          variables: {
            input: {
              language: i18n.language,
            },
          },
        });
      } else {
        i18n.changeLanguage(userProfileData.userProfile.userLanguage);
      }
    }
  }, [userProfileData?.userProfile?.userLanguage]);

  useEffect(() => {
    enableScroll();
  }, [location.pathname]);

  return (
    <>
      {navigation.indexOf(location?.pathname) > -1 ? <Navigation /> : null}
      {children}
    </>
  );
};
