import React, { useRef, ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { IconClose, IconToastError, IconToastSuccess, IconToastWarning } from '../../components';

type ComponentProps = {
  children: ReactNode;
};

const CustomSnackbarProvider: React.FC<ComponentProps> = ({ children }) => {
  const closeSnackbar = (key: number | string) => {
    if (notistackRef.current !== null) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  const notistackRef = useRef<SnackbarProvider>(null);

  return (
    <SnackbarProvider
      autoHideDuration={3000}
      ref={notistackRef}
      maxSnack={3}
      iconVariant={{
        success: <IconToastSuccess />,
        error: <IconToastError />,
        info: <IconToastWarning />,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      action={(key) => (
        <button className="close_toast" onClick={() => closeSnackbar(key)}>
          <IconClose />
        </button>
      )}
      classes={{
        variantSuccess: 'toast_success',
        variantError: 'toast_error',
        variantInfo: 'toast_info',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
