import { RefObject, useEffect, useState } from 'react';

export const useScroll = (ref: RefObject<HTMLElement>, topMargin: number) => {
  const [scrollDown, setScrollDown] = useState<boolean>(false);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  useEffect(() => {
    if (ref?.current) setIsScrollable(ref.current.scrollHeight > ref.current.clientHeight);
  }, [ref?.current?.scrollHeight]);

  useEffect(() => {
    const listener = (e: Event) => {
      const currentScrollY = (e.target as HTMLElement).scrollTop;

      setScrollDown(currentScrollY > topMargin);
    };

    ref.current?.addEventListener('scroll', listener);

    return () => ref.current?.removeEventListener('scroll', listener);
  }, [ref]);

  return {
    scrollDown,
    isScrollable,
  };
};
