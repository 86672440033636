import React, { useEffect, useMemo, useState } from 'react';
import { DeviceResponseType as ChannelResponseType, IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import {
  IDeviceState,
  IDeviceStateData,
  LinkQualityEnum,
  SignalStrenghtDeviceState,
  SignalStrenghtState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { SignalStrength } from '../../../../../components';
import './index.scss';

type DeviceConnectionQualityProps = {
  device: IDevice;
};

export const DeviceConnectionQuality: React.FC<DeviceConnectionQualityProps> = ({ device }) => {
  const [signalState, setSignalState] = useState<number | null>(null);

  useEffect(() => {
    device.OnDeviceStateRefreshedOrChangedEvent().Subscribe(updateSignalStrength);

    return () => {
      device.OnDeviceStateRefreshedOrChangedEvent().Unsubscribe(updateSignalStrength);
    };
  }, []);

  const updateSignalStrength = (state: IDeviceState<IDeviceStateData>) => {
    if (state.TypeAsEnum === ChannelResponseType.SignalStrength) {
      setSignalState((state.Data as SignalStrenghtState).Quality);
    }
  };

  useEffect(() => {
    if (device) {
      const channel = device.Channels.find((x) => x.GetDevice().Guid === device.Guid);

      if (channel?.States) {
        const signalStrength = channel.States?.find(
          (state) => state.TypeAsEnum === ChannelResponseType.SignalStrength,
        ) as SignalStrenghtDeviceState;

        if (signalStrength) {
          setSignalState(signalStrength.Data.Quality);
        }
      }
    }
  }, [device]);

  const signal = useMemo(() => {
    switch (signalState) {
      case LinkQualityEnum.Excellent:
        return 5;
      case LinkQualityEnum.Good:
        return 4;
      case LinkQualityEnum.Acceptable:
        return 3;
      case LinkQualityEnum.Low:
        return 2;
      case LinkQualityEnum.Terrible:
        return 1;
      case LinkQualityEnum.NoLink: {
        return 0;
      }
      case LinkQualityEnum.Unknown: {
        return -1;
      }
      default:
        return -1;
    }
  }, [signalState]);

  return <SignalStrength signal={signal} />;
};
