import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { StateHistoryErrorCode } from 'lavva.exalushome/build/js/Services/StatesHistory/IStatesHistoryService';
import { IAvailableStatePerChannel } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';
import { useBackdropContext } from '../../../../../hooks';
import { toastError } from '../../../../../utils/toast';
import { useExalusServicesContext } from '../../../context/services';

export const useHistoryState = (channel: IDeviceChannel) => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('channel-details');
  const [isHistoryEnabled, setIsHistoryEnabled] = useState<boolean>(false);
  const [stateType, setStateType] = useState<DeviceResponseType | null>(null);
  const { historyStatesApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const handleHistoryError = (data: ResponseResult<StateHistoryErrorCode>) => {
    turnOffBackdrop();

    switch ((data as ResponseResult<StateHistoryErrorCode>).Type) {
      case StateHistoryErrorCode.CannotFindDevice: {
        toastError({ content: t('exalus.getParams.cannotFindDevice') });
        break;
      }
      case StateHistoryErrorCode.ResponseTypeNotSupported: {
        toastError({ content: t('exalus.getParams.responseTypeNotSupported') });
        break;
      }
      case StateHistoryErrorCode.InvalidChannelNumber: {
        toastError({ content: t('exalus.getParams.invalidChannelNumber') });
        break;
      }
      case StateHistoryErrorCode.IncorrectLimitValue: {
        toastError({ content: t('exalus.getParams.incorrectLimitValue') });
        break;
      }
      case StateHistoryErrorCode.IncorrectOffsetValue: {
        toastError({ content: t('exalus.getParams.incorrectOffsetValue') });
        break;
      }
      case StateHistoryErrorCode.OtherError: {
        toastError({ content: t('exalus.getParams.otherErrorHistory') });
        break;
      }
      case StateHistoryErrorCode.FatalError: {
        toastError({ content: t('exalus.getParams.fatalError') });
        break;
      }
      case StateHistoryErrorCode.NoData: {
        toastError({ content: t('exalus.getParams.noData') });
        break;
      }
      case StateHistoryErrorCode.FunctionalityNotSupported: {
        toastError({ content: t('exalus.getParams.functionalityNotSupported') });
        break;
      }
    }
  };

  const getAvailableStates = async () => {
    if (channel) {
      turnOnBackdrop();
      const data = await historyStatesApi.GetAvailableStatesPerChannelAsync(channel.GetDevice(), channel.Number);

      if ((data as ResponseResult<StateHistoryErrorCode>).Type) {
        handleHistoryError(data as ResponseResult<StateHistoryErrorCode>);
      } else {
        turnOffBackdrop();

        if ((data as IAvailableStatePerChannel).AvailableStates.length > 0) {
          setStateType((data as IAvailableStatePerChannel).AvailableStates[0].StateResponseType);
        }
      }
    }
  };

  useEffect(() => {
    historyStatesApi.IsFunctionalitySupportedAsync().then((data) => {
      setIsHistoryEnabled(data);
    });
  }, []);

  const tabs = useMemo(
    () => [
      { label: tc('currently') },
      ...(isHistoryEnabled
        ? [
            {
              label: tc('history'),
            },
          ]
        : []),
    ],
    [isHistoryEnabled, tc],
  );

  return {
    tabs,
    isHistoryEnabled,
    stateType,
    getAvailableStates,
    handleHistoryError,
  };
};
