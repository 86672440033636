import { BluetoothProviderInterface } from '../../types';
import { BluetoothData } from '../types';
import { FindWebBluetoothDevicesView } from './FindWebBluetoothDevicesView';

export const initialBluetoothDataState: BluetoothData = {
  ssid: '',
  password: '',
  name: '',
  deviceName: '',
  mac: '',
  type: '',
  identifyIsAvailable: false,
  isAlreadyAdded: false,
  diagnostic: null,
  subTypes: [],
};

export const initialContextState: BluetoothProviderInterface = {
  bluetoothData: initialBluetoothDataState,
  deviceConnected: false,
  identifing: false,
  wifiList: [],
  typeOptions: [],
  wifiLoading: false,
  isAlreadyAdded: false,
  redoRegisteringDisabled: false,
  checkList: [],
  registerErrorMessage: '',
  updateBluetoothData: () => null,
  checkIfConnected: () => null,
  searchBluetooth: () => null,
  getWifiNetworks: () => null,
  getDeviceSettings: () => null,
  identifyDevice: () => null,
  registerDevice: () => null,
  releaseLock: () => null,
  resetBluetoothData: () => null,
  disconnect: () => null,
  setRedoRegisteringDisabled: () => null,
  getKnowsWifiNetworks: () => null,
  updateByWifi: () => null,
  setWifi: () => null,
  removeWifi: () => null,
  updatePayload: { version: '', progress: 0, status: null, errorCode: '' },
  percentage: 0,
  isCustomBluetooth: false,
  foundDevices: [],
  dialogScanning: false,
  scanning: false,
  setDialogScanning: () => null,
  handleDeviceConnect: () => null,
  WebBluetooth: new FindWebBluetoothDevicesView(),
  updateWifiData: () => null,
  returnToStartPage: () => null,
  errorCode: '',
  advancedSettings: null,
};
