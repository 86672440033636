import React from 'react';
import { ControlWrapper, OnOffControl } from '../../../components';
import { SwitchStateInternal } from '../../../data-access/gql-types/graphql';
import { useSwitchAction } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import { ActionTimesActivations } from '../components/action-times-activation';

type SwitchActionProps = {
  channels: ChannelInterface[];
};

const SwitchAction: React.FC<SwitchActionProps> = ({ channels }) => {
  const { handleControlsOn } = useSwitchAction(channels);

  return (
    <>
      <ControlWrapper>
        <OnOffControl
          actionOn={() => handleControlsOn(SwitchStateInternal.On)}
          actionOff={() => handleControlsOn(SwitchStateInternal.Off)}
        />
      </ControlWrapper>
      <ActionTimesActivations channels={channels} />
    </>
  );
};

export default SwitchAction;
