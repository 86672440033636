import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  IControllerAvailableUpdateInfo,
  IDeviceUpdateInfo,
} from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import {
  DownloadProgressInfo,
  UpdateProgressInfo,
  UpdateStatus,
} from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { useBackdropContext } from '../../../../../hooks';

export const langCode = {
  pl: 'pl-PL',
  en: 'en-US',
};

type IUpdateProcess = {
  checkForControllerUpdates: () => Promise<void>;
};

export const useUpdateProcess = ({ checkForControllerUpdates }: IUpdateProcess) => {
  const history = useHistory();
  const { t, i18n } = useTranslation('device-info');
  const { t: ti } = useTranslation('installation');
  const { turnOnBackdropPopup, turnOffBackdropPopup } = useBackdropContext();

  const onUpdateDeviceProgress = (updateProgress: UpdateProgressInfo, deviceUpdate?: IDeviceUpdateInfo | null) => {
    turnOnBackdropPopup({
      header: t('exalus.params.Version.update'),
      message: (
        <p>
          {t('exalus.params.Version.updateInProgress')} <span>{deviceUpdate?.NewResourceVersion || '-'}</span>
        </p>
      ),
      updateContent: {
        progress: updateProgress.Status === UpdateStatus.Upgraded ? 100 : updateProgress.PercentageTotal,
        status: updateProgress.Status,
        description: deviceUpdate?.UpdateDescription.get(langCode[i18n.language]),
        updateNumber: updateProgress.UpdateNumber,
        updatedResourceName: updateProgress.UpdatedResourceName.get(langCode[i18n.language]) || '',
        availableUpdates: [deviceUpdate?.UpdatedResourceName || ''],
        handleClose: () => {
          turnOffBackdropPopup();
          history.goBack();
        },
      },
    });
  };

  const onUpdateControllerProgress = (
    updateProgress: UpdateProgressInfo,
    controllerUpdate?: IControllerAvailableUpdateInfo | null,
  ) => {
    turnOnBackdropPopup({
      header: ti('exalus.controllerUpdate.updateProcessHeader'),
      message: (
        <p>
          {ti('exalus.controllerUpdate.updateProcessInfo')}
          <span>{controllerUpdate?.NewResourceVersion || '-'}</span>
        </p>
      ),
      updateContent: {
        progress: updateProgress.Status === UpdateStatus.Upgraded ? 100 : updateProgress.PercentageTotal,
        status: updateProgress.Status,
        description: controllerUpdate?.UpdateDescription.get(langCode[i18n.language]),
        updateNumber: updateProgress.UpdateNumber,
        updatedResourceName: updateProgress.UpdatedResourceName.get(langCode[i18n.language]) || '',
        availableUpdates: [controllerUpdate?.UpdatedResourceName.get(langCode[i18n.language]) || ''],
        warning: `${ti('exalus.controllerUpdate.updateProcessWarning')}`,
        handleClose: () => {
          turnOffBackdropPopup();
          checkForControllerUpdates();
        },
      },
    });
  };

  const onUpdateAllControllerProgresses = (
    updateProgress: UpdateProgressInfo,
    controllerUpdates?: IControllerAvailableUpdateInfo[] | null,
  ) => {
    if (!controllerUpdates) return;
    if (controllerUpdates?.length === 0) return;
    const controllerUpdate = controllerUpdates[updateProgress.UpdateNumber - 1];

    if (controllerUpdate) {
      turnOnBackdropPopup({
        header: ti('exalus.controllerUpdate.updateProcessHeader'),
        message: ti('exalus.controllerUpdate.updateProcessInfoShort'),
        updateContent: {
          progress: updateProgress.Status === UpdateStatus.Upgraded ? 100 : updateProgress.PercentageTotal,
          status: updateProgress.Status,
          description: controllerUpdate?.UpdateDescription.get(langCode[i18n.language]),
          updateNumber: updateProgress.UpdateNumber,
          updatedResourceName: updateProgress.UpdatedResourceName.get(langCode[i18n.language]) || '',
          availableUpdates: controllerUpdates.map(
            (update) => update.UpdatedResourceName.get(langCode[i18n.language]) as string,
          ),
          warning: `${ti('exalus.controllerUpdate.updateProcessWarning')}`,
          handleClose: () => {
            turnOffBackdropPopup();
            checkForControllerUpdates();
          },
        },
      });
    }
  };

  const onDownloadControllerProgress = (downloadProgress: DownloadProgressInfo) => {
    turnOnBackdropPopup({
      header: ti('exalus.controllerUpdate.updateProcessHeader'),
      message: ti(`exalus.controllerUpdate.downloadProcessInfo.${downloadProgress.CurrentTask}`),
      downloadContent: {
        progress: downloadProgress.PercentageTotal,
        speedMbps: downloadProgress.SpeedMbps,
        updatedResourceName: downloadProgress.UpdatedResourceName.get(langCode[i18n.language]) || '',
      },
    });
  };

  return {
    onUpdateDeviceProgress,
    onUpdateControllerProgress,
    onUpdateAllControllerProgresses,
    onDownloadControllerProgress,
  };
};
