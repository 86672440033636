import {
  GlobalTimeParameters,
  IInputOutputSwitchParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';

export enum RomParamsEnum {
  INPUT_MODE = 'InputModeParams',
  TURN_ON_BEHAVIOUR = 'TurnOnBehaviourParams',
  GLOBAL_TIME = 'GlobalTimeParams',
  ENABLE_TIME = 'EnableTimeParams',
}

export type RomConfigParams = {
  [RomParamsEnum.INPUT_MODE]: IInputOutputSwitchParameters[] | null;
  [RomParamsEnum.TURN_ON_BEHAVIOUR]: StateOfTurnOnSwitchDeviceParameters | null;
  [RomParamsEnum.GLOBAL_TIME]: GlobalTimeParameters | null;
  [RomParamsEnum.ENABLE_TIME]: GlobalTimeParameters | null;
};

export const initialConfig: RomConfigParams = {
  [RomParamsEnum.INPUT_MODE]: null,
  [RomParamsEnum.TURN_ON_BEHAVIOUR]: null,
  [RomParamsEnum.GLOBAL_TIME]: null,
  [RomParamsEnum.ENABLE_TIME]: null,
};
