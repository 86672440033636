import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTariffs } from '../../../../api/modules/optimizer/optimizer.hooks';
import { Accordion, IconToastError, IconToastSuccess } from '../../../../components';
import { ChannelTypeInternal, TimeZoneKindInternal } from '../../../../data-access/gql-types/graphql';
import { leadingZero } from '../../../../utils/helpers';
import './index.scss';

type ComponentProps = {
  type: ChannelTypeInternal;
};

export const TariffHelp: React.FC<ComponentProps> = ({ type }) => {
  const { t } = useTranslation('channel-details');
  const { data: allTariffs } = useGetTariffs(type);

  const tariffs = useMemo(() => {
    return (allTariffs || []).map((x) => (
      <Accordion key={x.operator} kind="custom" title={x.operator}>
        <ul className="tarrifs-list">
          {x.tariffs.map((tariff, index) => (
            <Fragment key={tariff.name}>
              <li className="tariff">
                <span className="name">{tariff.name}</span>
                {tariff.hoursRanges.length ? (
                  <div className="hours">
                    <span>{`${t('optimizer.configuration.tariff.hoursCheaper')}: `}</span>
                    {tariff.hoursRanges.map((hour, index) => (
                      <div key={index}>
                        <span>{`${leadingZero(hour.from)}.00 - ${leadingZero(hour.to)}.00${
                          [TimeZoneKindInternal.Winter, TimeZoneKindInternal.Summer].includes(hour.timeZoneKind)
                            ? ` (${t(
                                `optimizer.configuration.tariff.${
                                  hour.timeZoneKind === TimeZoneKindInternal.Summer ? 'summer' : 'winter'
                                }Time`,
                              )})`
                            : ''
                        }`}</span>
                      </div>
                    ))}
                  </div>
                ) : null}
                <div className="details">
                  <div className="details-item">
                    <span>{`${t('optimizer.configuration.tariff.wholeWeekendCheaper')}: `}</span>
                    {tariff.wholeWeekendCheaper ? <IconToastSuccess /> : <IconToastError />}
                  </div>
                  <div className="details-item">
                    <span>{`${t('optimizer.configuration.tariff.wholeHolidaysCheaper')}*: `}</span>
                    {tariff.wholeHolidaysCheaper ? <IconToastSuccess /> : <IconToastError />}
                  </div>
                  <div className="details-item">
                    <span>{`${t('optimizer.configuration.tariff.ignoreSummerTimeChanges')}: `}</span>
                    {tariff.ignoreSummerTimeChanges ? <IconToastSuccess /> : <IconToastError />}
                  </div>
                </div>
              </li>

              {index !== x.tariffs.length - 1 && <hr className="m-t-8 m-b-8" />}
            </Fragment>
          ))}
        </ul>
        <p className="m-t-48">{t('optimizer.configuration.tariff.wholeHolidaysCheaperDescription')}</p>
      </Accordion>
    ));
  }, [allTariffs]);

  return <ul className="tariffs-help">{tariffs}</ul>;
};
