import React from 'react';
import classNames from 'classnames';
import PopoverCustom from '../../popover';
import './index.scss';

type PropsInterface = {
  size?: number;
  colorError?: boolean;
  colorWarning?: boolean;
  text?: string;
};

export const IconToastWarning2: React.FC<PropsInterface> = ({
  size = 24,
  colorError = false,
  colorWarning = false,
  text,
}) => {
  const icon = (
    <div
      className={classNames('icon-toast-warning-2', {
        'color-error': colorError,
        'color-warning': colorWarning,
      })}
    >
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11" fill="#FF4016" />
        <path d="M12 8V14" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        <circle cx="12" cy="17" r="0.5" transform="rotate(-180 12 17)" stroke="white" strokeLinecap="round" />
      </svg>
    </div>
  );

  return !text ? (
    icon
  ) : (
    <PopoverCustom id={text} buttonContent={<IconToastWarning2 colorError size={16} />}>
      {text}
    </PopoverCustom>
  );
};
