import React from 'react';
import { IconBinaryState, IconCircleMeter, IconSquareMeter, IconThunder } from '../../../../../components/icons';
import { AvailableIcon, ChannelIconTypeEnum } from '../../../../../types';

export const meterIcons = (isOn?: boolean): AvailableIcon[] => [
  {
    iconName: ChannelIconTypeEnum.METER,
    component: <IconSquareMeter isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_33',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_CIRCLE_METER,
    component: <IconCircleMeter isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_34',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_THUNDER,
    component: <IconThunder isOn={isOn !== undefined ? isOn : true} />,
    oldIconName: 'DEVICE_ICON_35',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BINARY_STATE,
    component: <IconBinaryState isOn={isOn !== undefined ? isOn : false} />,
    oldIconName: 'DEVICE_ICON_32',
  },
];
