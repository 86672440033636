import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ActionBox } from '../box';

type ChannelBoxSortableProps = {
  isListItem?: boolean;
  actionId: string;
};

export const ActionBoxSortable: React.FC<ChannelBoxSortableProps> = ({ actionId, isListItem = false }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: actionId,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  return (
    <ActionBox
      isDraggable
      actionId={actionId}
      isListItem={isListItem}
      setNodeRef={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
    />
  );
};
