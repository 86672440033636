import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PopUpNavLinkInterface } from '../../../components';
import { DashboardHeader } from '../../../components/dashboard-header';
import {
  IconAdd,
  IconChannelsOrder,
  IconEdit,
  IconGridView,
  IconGroups,
  IconListView,
  IconOrder,
} from '../../../components/popup-nav/icons';
import { InstallationAccessType, UserGroup, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useApi, useInstallation } from '../../../hooks';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import { useAddDevice } from '../../device-add/hooks/use-add-device';

type ComponentProps = {
  isListView: boolean;
  toggleListView: () => void;
  activeGroup: UserGroup;
};

export const DashboardLavvaHeader: React.FC<ComponentProps> = ({ isListView, toggleListView, activeGroup }) => {
  const history = useHistory();
  const { t } = useTranslation('dashboard');
  const { t: tg } = useTranslation('groups');
  const { dashboardLoading } = useApi();
  const { selectedInstallation } = useInstallation();
  const { permissions } = usePermissionsContext();
  const { addDevice } = useAddDevice();

  const popUpNavLinks = useMemo((): PopUpNavLinkInterface[] => {
    const navLinks: PopUpNavLinkInterface[] = [
      { onClick: () => history.push(ROUTES.Group()), label: tg('allGroups'), hasLineBelow: true, icon: <IconGroups /> },
      {
        onClick: () => history.push(ROUTES.GroupSortChannels(isListView)),
        label: t('channelsOrder'),
        icon: <IconChannelsOrder />,
      },
      {
        onClick: () => history.push(ROUTES.GroupSort()),
        label: t('groupsOrder'),
        hasLineBelow: true,
        icon: <IconOrder />,
      },
      {
        onClick: toggleListView,
        label: isListView ? t('gridView') : t('listView'),
        icon: isListView ? <IconGridView /> : <IconListView />,
      },
      { onClick: addDevice, label: t('addDevice'), icon: <IconAdd /> },
    ];

    if (activeGroup && selectedInstallation) {
      const { accessType } = selectedInstallation;

      if (permissions[UserPermissionType.GroupCreate]) {
        navLinks.unshift({
          onClick: () => history.push(ROUTES.GroupCreate()),
          label: t('newGroup'),
          icon: <IconAdd />,
        });
      }
      if (
        [InstallationAccessType.Owner, InstallationAccessType.Admin].includes(accessType) ||
        (accessType === InstallationAccessType.User && !activeGroup.group.isPublicGroup)
      ) {
        if (
          (activeGroup.group.isPublicGroup && permissions[UserPermissionType.PublicGroupEdit]) ||
          (!activeGroup.group.isPublicGroup && permissions[UserPermissionType.GroupEdit])
        )
          navLinks.unshift({
            onClick: () =>
              history.push(
                activeGroup.group.isDefaultGroup
                  ? ROUTES.FavouritesForm(activeGroup.id)
                  : ROUTES.GroupEdit(activeGroup.id),
              ),
            label: t('groupEdit', { groupName: activeGroup.group.name }),
            hasLineBelow: true,
            icon: <IconEdit />,
          });
      }
    }

    return navLinks;
  }, [activeGroup, toggleListView, isListView, selectedInstallation?.accessType, permissions]);

  return <DashboardHeader navLinks={popUpNavLinks} loading={dashboardLoading} />;
};
