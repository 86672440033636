import React from 'react';

type IconBarrierProps = {
  position?: number; // In percents from 0 to 100
};

export const IconBarrier: React.FC<IconBarrierProps> = ({ position = 100 }) => {
  return (
    <>
      {position === 100 ? (
        <svg
          width="37"
          height="27"
          viewBox="0 0 37 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon-cover icon-barrier"
        >
          <path
            d="M14.5 5.50018H34C34.8284 5.50018 35.5 6.17176 35.5 7.00018V7.00018C35.5 7.82861 34.8284 8.50018 34 8.50018H14.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <ellipse
            cx="12.3036"
            cy="6.97644"
            rx="2.5"
            ry="2.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.8007 23.2016H3.11454C2.48613 23.2016 1.92592 23.5976 1.71629 24.1901L1 26.2144H15.9247L15.3198 24.2486C15.1284 23.6263 14.5534 23.2016 13.9022 23.2016H12.2167M4.8007 23.2016V3C4.8007 1.89543 5.69613 1 6.8007 1H10.2167C11.3213 1 12.2167 1.89543 12.2167 3V4M4.8007 23.2016H12.2167M12.2167 23.2016V9.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="34"
          height="35"
          viewBox="0 0 34 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon-cover icon-barrier"
        >
          <path
            d="M13.4659 12.5899L29.6321 1.68564C30.3189 1.22239 31.2512 1.40361 31.7145 2.09041V2.09041C32.1777 2.77721 31.9965 3.70951 31.3097 4.17276L15.1435 15.077"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <ellipse
            cx="12.4706"
            cy="15.0423"
            rx="2.5"
            ry="2.5"
            transform="rotate(-34 12.4706 15.0423)"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.8007 31.2016H3.11454C2.48613 31.2016 1.92592 31.5976 1.71629 32.1901L1 34.2144H15.9247L15.3198 32.2486C15.1284 31.6263 14.5534 31.2016 13.9022 31.2016H12.2167M4.8007 31.2016V11C4.8007 9.89543 5.69613 9 6.8007 9H10.2167C11.3213 9 12.2167 9.89543 12.2167 11V12M4.8007 31.2016H12.2167M12.2167 31.2016V17.5"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
