import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { useSubscription } from '@apollo/client';
import { Checkbox, IconPadlock } from '../../../../components';
import { IconSignal } from '../../../../components/icons/icon-signal';
import {
  OnDeviceConnectedToSsidUpdatedSubscription,
  OnDeviceConnectedToSsidUpdatedSubscriptionVariables,
  OnRequestAckReceivedSubscription,
  OnRequestAckReceivedSubscriptionVariables,
  RequestAckResultType,
} from '../../../../data-access/gql-types/graphql';
import {
  ON_DEVICE_CONNECTED_TO_SSID_UPDATED,
  ON_REQUEST_ACK_RECEIVED,
} from '../../../../data-access/subscriptions/lavva-devices';
import { useInstallation } from '../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { AvailableDevice } from '../../types';
import './index.scss';

type ComponentProps = {
  device: AvailableDevice;
  toggleChecked: (deviceId: string) => void;
  setConnectedList: Dispatch<SetStateAction<string[]>>;
  setAvailableDevices: Dispatch<SetStateAction<AvailableDevice[]>>;
  selectedNetwork: string;
};

const DeviceListItem: React.FC<ComponentProps> = ({
  device,
  toggleChecked,
  setConnectedList,
  setAvailableDevices,
  selectedNetwork,
}) => {
  const { t } = useTranslation('device-list');
  const { skipLavvaFetch } = useInstallation();
  const { setDeviceList, updateDeviceDetails } = useDevicesAndChannelsContext();
  const { data } = useSubscription<
    OnDeviceConnectedToSsidUpdatedSubscription,
    OnDeviceConnectedToSsidUpdatedSubscriptionVariables
  >(ON_DEVICE_CONNECTED_TO_SSID_UPDATED, {
    variables: {
      deviceId: device.deviceId,
    },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription<
    OnRequestAckReceivedSubscription,
    OnRequestAckReceivedSubscriptionVariables
  >(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: device.deviceId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (data) {
      console.log('UPDATED SSID', data);

      setConnectedList((prevIds) => [...prevIds, data.onDeviceConnectedToSsidUpdated.deviceId]);

      setDeviceList((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((x) => x.id === device.deviceId);
        if (index !== -1) tempList[index].payload.connectedToSsid = data.onDeviceConnectedToSsidUpdated.connectedToSsid;

        return tempList;
      });

      updateDeviceDetails((prev) => {
        const temp = cloneDeep(prev);
        if (temp) temp.payload.connectedToSsid = data.onDeviceConnectedToSsidUpdated.connectedToSsid;
        return temp;
      });

      setAvailableDevices((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((x) => x.deviceId === device.deviceId);
        if (index !== -1) {
          tempList[index].ssid = data.onDeviceConnectedToSsidUpdated.connectedToSsid;
          tempList[index].connected = true;
        }

        return tempList;
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      ackReceived?.onRequestAckReceived.status === RequestAckResultType.Timeout &&
      device.deviceId === ackReceived.onRequestAckReceived.deviceId
    ) {
      setAvailableDevices((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((x) => x.deviceId === device.deviceId);
        if (index !== -1) {
          tempList[index].connected = true;
        }

        return tempList;
      });
    }
  }, [ackReceived]);

  return (
    <div className={classNames('device-list-item', { disabled: device.ssid === selectedNetwork })}>
      <div className="left-side text-ellipsis">
        <div className="icon">
          {device.remembered && (
            <div className="remembered">
              <IconPadlock isOn={false} />
            </div>
          )}
          {device.deviceIcon}
        </div>
        <div className="details">
          <span className="text-ellipsis">{device.deviceName}</span>
          <span className="ssid text-ellipsis">{`${t('wifiConfigurattion.network')}: ${device.ssid}`}</span>
        </div>
      </div>
      <div className="right-side">
        <IconSignal signal={device.signal} />
        <Checkbox
          checked={device.checked}
          onChange={() => toggleChecked(device.deviceId)}
          disabled={device.ssid === selectedNetwork}
        />
      </div>
    </div>
  );
};

export default DeviceListItem;
