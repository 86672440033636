import {
  IconBarrier,
  IconDoor,
  IconHorizontalDoubleGate,
  IconHorizontalGate,
  IconMissing,
  IconVerticalGate,
  IconWoodenGate,
} from '../../../../../components/icons';
import { GateKindInternal } from '../../../../../data-access/gql-types/graphql';
import { AvailableIcon, ChannelIconTypeEnum } from '../../../../../types';

const defaultIcon = {
  [GateKindInternal.Sectional]: ChannelIconTypeEnum.ICON_VERTICAL_GATE,
  [GateKindInternal.OnePieceDoor]: ChannelIconTypeEnum.MISSING,
  [GateKindInternal.MultiBox]: ChannelIconTypeEnum.MISSING,
  [GateKindInternal.RollUp]: ChannelIconTypeEnum.ICON_VERTICAL_GATE,
  [GateKindInternal.SideOpeningDoor]: ChannelIconTypeEnum.ICON_HORIZONTAL_GATE,
  [GateKindInternal.Sliding]: ChannelIconTypeEnum.ICON_HORIZONTAL_GATE,
  [GateKindInternal.DoubleLeaf]: ChannelIconTypeEnum.ICON_WOODEN_GATE,
  [GateKindInternal.Barrier]: ChannelIconTypeEnum.ICON_BARRIER,
  [GateKindInternal.Wicket]: ChannelIconTypeEnum.ICON_DOOR,
  [GateKindInternal.Doors]: ChannelIconTypeEnum.ICON_DOOR,
};

const allGateIcons = (position?: number): AvailableIcon[] => [
  {
    iconName: ChannelIconTypeEnum.ICON_VERTICAL_GATE,
    component: <IconVerticalGate position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_41',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_HORIZONTAL_GATE,
    component: <IconHorizontalGate position={position !== undefined ? position : 50} />,
    oldIconName: 'DEVICE_ICON_18',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_WOODEN_GATE,
    component: <IconWoodenGate position={position !== undefined ? position : 50} />,
    oldIconName: 'DEVICE_ICON_16',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_HORIZONTAL_DOUBLE_GATE,
    component: <IconHorizontalDoubleGate position={position !== undefined ? position : 33} />,
  },
  {
    iconName: ChannelIconTypeEnum.ICON_BARRIER,
    component: <IconBarrier position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_21',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_DOOR,
    component: <IconDoor position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_21',
  },
];

export const gateIcons = (kind: GateKindInternal, position?: number): AvailableIcon[] => {
  let allIcons = allGateIcons(position);
  if ([GateKindInternal.Unknown, GateKindInternal.OnePieceDoor, GateKindInternal.MultiBox].includes(kind)) {
    allIcons = [
      ...allIcons,
      {
        iconName: ChannelIconTypeEnum.MISSING,
        component: <IconMissing />,
      },
    ];
  }

  const defaultIconIndex = allIcons.findIndex((x) => x.iconName === defaultIcon[kind]);

  if (defaultIconIndex !== -1) {
    const defaultIcon = allIcons[defaultIconIndex];
    defaultIcon.iconName = ChannelIconTypeEnum.GATE;
    allIcons.splice(defaultIconIndex, 1);

    return [defaultIcon, ...allIcons];
  }

  return allIcons;
};
