import React from 'react';

type IconHorizontalDoubleGateProps = {
  position?: number; // In percents from 0 to 100
};

export const IconHorizontalDoubleGate: React.FC<IconHorizontalDoubleGateProps> = ({ position = 100 }) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-cover icon-horizontal-double-gate"
    >
      {position === 0 && (
        <g clipPath="url(#clip0_25518_34820)">
          <path
            d="M3.8974 6.08972L9.43907 6.08971L9.43907 31.6666L3.8974 31.6666L3.8974 6.08972Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.9808 6.08972L28.4391 6.08971L28.4391 31.6666L33.9808 31.6666L33.9808 6.08972Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.89742 14.6154H1.58331"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2949 14.6154H33.9808"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.89742 23.141H1.58331"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2949 23.141H33.9808"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      )}
      {position > 0 && position < 100 && (
        <g clipPath="url(#clip0_25518_34829)">
          <path
            d="M3.8974 6.08972L14.1891 6.08971L14.1891 31.6666L3.8974 31.6666L3.8974 6.08972Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.49994 6.33325L9.49994 30.8749"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.9808 6.08972L23.6891 6.08971L23.6891 31.6666L33.9808 31.6666L33.9808 6.08972Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.5 6.33325L28.5 31.6666"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.89742 14.6154H1.58331"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2949 14.6154H33.9808"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.89742 23.141H1.58331"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2949 23.141H33.9808"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      )}
      {position === 100 && (
        <g clipPath="url(#clip0_25518_34820)">
          <path
            d="M3.8974 6.08972L18.9391 6.08971L18.9391 31.6666L3.8974 31.6666L3.8974 6.08972Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.0833 6.33325L11.0833 31.0576"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.9808 6.08972L18.9391 6.08971L18.9391 31.6666L33.9808 31.6666L33.9808 6.08972Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.9167 6.33337L26.9167 31.6667"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.89742 14.6154H1.58331"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2949 14.6154H33.9808"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.89742 23.141H1.58331"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.2949 23.141H33.9808"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      )}
      <defs>
        <clipPath id="clip0_25518_34820">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
