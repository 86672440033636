import { FacadeDetection } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import { ReactionWindParams } from '../../../device-details/types';

export enum ZfBidiParamsEnum {
  FACADE_DETECTION = 'FacadeDetencion',
  REACTION_WIND = 'ReactionWind',
}

export type ZfBidiConfigParams = {
  [ZfBidiParamsEnum.FACADE_DETECTION]: FacadeDetection | null;
  [ZfBidiParamsEnum.REACTION_WIND]: ReactionWindParams | null;
};

export const initialConfig: ZfBidiConfigParams = {
  [ZfBidiParamsEnum.FACADE_DETECTION]: null,
  [ZfBidiParamsEnum.REACTION_WIND]: null,
};
