import { useEffect, useRef } from 'react';

export const useHorizontalScroll = () => {
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = scrollableRef.current;

    if (element) {
      const handleScroll = (event: WheelEvent) => {
        event.preventDefault();
        element.scrollLeft += event.deltaY;
      };

      element.addEventListener('wheel', handleScroll, { passive: false });

      return () => {
        element.removeEventListener('wheel', handleScroll);
      };
    }
  }, []);

  return { scrollableRef };
};
