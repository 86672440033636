import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import c from 'classnames';
import { DeviceConnectionState } from '../../data-access/gql-types/graphql';
import { useChannelsState, useDevicesAndChannels } from '../../hooks';
import { useConfiguration } from '../../modules/channel-configuration/hooks/use-configuration';
import { ROUTES } from '../../routes';
import { IconArrowLink, IconConnectionError, IconRecovery } from '../icons';
import { IconDevice } from '../icons/device';
import './index.scss';

type DeviceProps = {
  id: string;
};

export const Device: React.FC<DeviceProps> = ({ id }) => {
  const history = useHistory();
  const { device, deviceChannels } = useDevicesAndChannels({ deviceId: id });
  const { t } = useTranslation('common');
  const { t: td } = useTranslation('device-list');
  const { t: ti } = useTranslation('device-info');
  const { channelState } = useChannelsState();
  const { updateDevice, updateDeviceLoading, updateRecoveryDevice } = useConfiguration({ device });

  const isDeviceDisconnected = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Disconnected,
      ),
    [deviceChannels, channelState],
  );

  const isDeviceInRecoveryState = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
      ),
    [deviceChannels, channelState],
  );

  if (!device) return null;

  return (
    <div className={`driver ${device.type}`}>
      <div className="driver__link" onClick={() => history.push(`${ROUTES.DeviceSettings(id)}?from=list`)}>
        <div className="driver__header-icon">
          <IconDevice />
          {isDeviceDisconnected ? (
            <IconConnectionError big details size={12} />
          ) : isDeviceInRecoveryState ? (
            <IconRecovery big details />
          ) : null}
        </div>
        <div className="driver__header">
          <div className="driver__header-title">
            <div className="driver__header-title-name">{device.payload.name || 'N/A'}</div>
            <div className="driver__header-title-type">
              {t(`types.${device.type}`) || t('types.unknown')}{' '}
              {device.payload.model && (
                <>
                  | {td('model')}: {device.payload.model}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {device.payload.isUpdateAvailable ? (
        <div className="driver__update">
          {!updateDeviceLoading ? (
            <p className="driver__update-version">
              <span>{device.payload.currentFirmwareVersion}</span>
              <IconArrowLink size={10} className="icon-arrow-link--accent" />{' '}
              <span>{device.payload.newestFirmwareVersion}</span>
            </p>
          ) : null}

          {isDeviceInRecoveryState ? (
            <button
              className="button button--accent update"
              disabled={isDeviceDisconnected}
              onClick={updateRecoveryDevice}
              type="button"
            >
              <span className="update">{ti('updateSoftware.update')}</span>
            </button>
          ) : (
            <button
              className="button button--accent update"
              disabled={updateDeviceLoading || isDeviceDisconnected}
              onClick={updateDevice}
              type="button"
            >
              <span className={c({ update: !updateDeviceLoading })}>
                {!updateDeviceLoading ? ti('updateSoftware.update') : t('buttons.loading')}
              </span>
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};
