import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { IconInitial, IconPhoto, EditIconControl, EditIconPage, SubmitButton } from '../../../components';
import { RemoveProfileImageMutation } from '../../../data-access/gql-types/graphql';
import { REMOVE_PROFILE_IMAGE } from '../../../data-access/mutations/user';
import { USER_ME } from '../../../data-access/queries/user';
import { useBackdropContext, useProfile } from '../../../hooks';
import { useSetProfileImage } from '../../../hooks/user/user-profile-image';
import { toastError, toastSuccess } from '../../../utils/toast';
import Avatar from './avatar';
import './index.scss';
import UserInitial from './user-initial';

type PropsInterface = {
  setOpen: (open: boolean) => void;
  setUseUserInitial: Dispatch<SetStateAction<boolean>>;
  useUserInitial: boolean;
  profileImageUrlTemp: string | undefined;
  setProfileImageUrlTemp: (value: string | undefined) => void;
  setProfileImageUrl: (value: string | undefined) => void;
};

const EditAvatar: React.FC<PropsInterface> = ({
  setOpen,
  useUserInitial,
  setUseUserInitial,
  profileImageUrlTemp,
  setProfileImageUrlTemp,
  setProfileImageUrl,
}) => {
  const { t } = useTranslation('profile');
  const { userInitial, refetchUserMe } = useProfile();
  const { setProfileImage } = useSetProfileImage();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [useFile, setUseFile] = useState<File>();
  const [userInitialIsChecked, setUserInitialIsChecked] = useState<boolean>(useUserInitial);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const [removeProfileImage, { loading }] = useMutation<RemoveProfileImageMutation>(REMOVE_PROFILE_IMAGE, {
    refetchQueries: [USER_ME],
  });

  useEffect(() => {
    if (useFile) {
      const objectUrl = URL.createObjectURL(useFile);
      setProfileImageUrlTemp(objectUrl);
    }
  }, [useFile, setProfileImageUrlTemp]);

  const finishSubmit = useCallback(
    (content: string, imageUrl: boolean) => {
      refetchUserMe();
      if (imageUrl) {
        setProfileImageUrl(profileImageUrlTemp);
      } else {
        setProfileImageUrlTemp(undefined);
        setProfileImageUrl(undefined);
      }
      toastSuccess({ content });
      setOpen(false);
      setIsValid(false);
    },
    [profileImageUrlTemp],
  );

  const onSubmit = useCallback(() => {
    if (!useFile) {
      turnOnBackdrop();
      removeProfileImage({
        onCompleted: (data) => {
          if (data.removeProfileImage.ok) {
            turnOffBackdrop();
          }
        },
        onError: () => turnOffBackdrop(),
      });
      setUseUserInitial(userInitialIsChecked);
      finishSubmit(
        t(userInitialIsChecked ? 'avatar.toastUserInitialSuccess' : 'avatar.toastRemoveUserInitialSuccess'),
        false,
      );
    }

    if (useFile) {
      setProfileImage(useFile, () => finishSubmit(t('avatar.toastFileSuccess'), true));
    }
  }, [setOpen, useFile, userInitialIsChecked]);

  const currentIconControl = useMemo(() => {
    if (userInitialIsChecked) {
      return userInitial ? <IconInitial initial={userInitial} /> : <IconPhoto />;
    }

    return profileImageUrlTemp ? (
      <img
        src={
          profileImageUrlTemp.includes('blob:')
            ? profileImageUrlTemp
            : `${profileImageUrlTemp}?t=${new Date().getTime()}`
        }
        alt="avatar"
        className="edit-avatar__avatar"
      />
    ) : (
      <IconPhoto />
    );
  }, [userInitialIsChecked, profileImageUrlTemp, userInitial]);

  const handleAvatar = useCallback((value: File) => {
    if (value.type.includes('image')) {
      setUseFile(value);
      setUserInitialIsChecked(false);
      setIsValid(!!value);
    } else toastError({ content: t('avatar.toastFileError') });
  }, []);

  const handleUserInitial = useCallback(() => {
    const newValue = !userInitialIsChecked;
    setUserInitialIsChecked(newValue);
    setIsValid(newValue !== useUserInitial);
  }, [userInitialIsChecked]);

  return (
    <EditIconPage setOpen={setOpen} title={t('avatar.change')}>
      <EditIconControl caption={t('avatar.current')}>{currentIconControl}</EditIconControl>
      <hr className="m-t-24 m-b-24" />
      <h2>{t('avatar.option')}</h2>
      <div className="grid-list-24">
        <Avatar profileImageUrl={profileImageUrlTemp} setUseFile={handleAvatar} />
        <UserInitial
          setUseUserInitial={handleUserInitial}
          useUserInitial={userInitialIsChecked}
          disabled={!profileImageUrlTemp}
        />
      </div>
      <SubmitButton disabled={!isValid} onClick={onSubmit} isLoading={loading} />
    </EditIconPage>
  );
};
export default EditAvatar;
