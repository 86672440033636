import React, { useMemo } from 'react';
import { ACCENT_COLOR_DEFAULT_INDEX_MAP, INSTALLATION_COLOR_LIST } from '../../const';
import { environment } from '../../environment';
import { IconInstallation0 } from '../icons/installation';
import { availableInstallationIcons } from './available-icons';
import './index.scss';

type InstallationIconProps = {
  iconName: string;
  activeColor?: string;
  size?: number;
};

export const InstallationIcon: React.FC<InstallationIconProps> = ({
  iconName,
  activeColor = INSTALLATION_COLOR_LIST[ACCENT_COLOR_DEFAULT_INDEX_MAP[environment.INTEGRATION_DEFAULT]],
  size = 42,
}) => {
  const Component = useMemo(() => {
    const icon = availableInstallationIcons.find((icon) => icon.iconName === iconName);
    if (icon) return icon.component;

    return <IconInstallation0 />;
  }, [iconName]);

  const style = useMemo(
    () =>
      ({
        '--icon-color-accent': activeColor,
        '--icon-size': `${size}px`,
        '--icon-border-size': `${size + 2}px`,
      } as React.CSSProperties),
    [activeColor, size],
  );

  return (
    <div className="installation-icon" style={style}>
      {Component}
    </div>
  );
};
