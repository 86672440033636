import React, { useMemo } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { BlindDetailsControls } from '../../../components/channel/controls/blind/details';
import { CameraDetailsControls } from '../../../components/channel/controls/camera/details';
import { EntryPhoneDetailsControls } from '../../../components/channel/controls/entry-phone/details';
import { FacadeDetailsControls } from '../../../components/channel/controls/facade/details';
import { FloodDetailsControls } from '../../../components/channel/controls/flood/details';
import { GateDetailsControls } from '../../../components/channel/controls/gate/details';
import { HumidityDetailsControls } from '../../../components/channel/controls/humidity/details';
import { MeasuredBrightnessDetailsControls } from '../../../components/channel/controls/measured-brightness/details';
import { MeterDetailsControls } from '../../../components/channel/controls/meter/details';
import { MovementDetailsControls } from '../../../components/channel/controls/movement/details';
import { PressureDetailsControls } from '../../../components/channel/controls/pressure/details';
import { ReedDetailsControls } from '../../../components/channel/controls/reed/details';
import { RemoteDetailsControls } from '../../../components/channel/controls/remote';
import RgbDetailsControls from '../../../components/channel/controls/rgb';
import { SwitchDetailsControls } from '../../../components/channel/controls/switch/details';
import { TemperatureDetailsControls } from '../../../components/channel/controls/temperature/details';
import { TransmitterDetailsControls } from '../../../components/channel/controls/transmitter';
import { WindDetailsControls } from '../../../components/channel/controls/wind/details';
import { ChannelType, LightType, SensorType } from '../../../enums';
import { getChannelType, getLightType, getSensorType } from '../../../utils';
import { useTasks } from '../../channel-list/hooks/use-tasks';

type ComponentProps = {
  channel: IDeviceChannel;
};

export const ChannelDetailsControls: React.FC<ComponentProps> = ({ channel }) => {
  const { active } = useTasks(channel);

  return useMemo(() => {
    switch (getChannelType(channel)) {
      case ChannelType.Switch:
        return <SwitchDetailsControls channel={channel} active={active} />;
      case ChannelType.Light: {
        switch (getLightType(channel)) {
          case LightType.Rgb:
            return <RgbDetailsControls channel={channel} active={active} />;
          default:
            return <SwitchDetailsControls channel={channel} active={active} />;
        }
      }
      case ChannelType.Blind:
        return <BlindDetailsControls channel={channel} active={active} />;
      case ChannelType.Facade:
        return <FacadeDetailsControls channel={channel} active={active} />;
      case ChannelType.Camera:
        return <CameraDetailsControls channel={channel} />;
      case ChannelType.EntryPhone:
        return <EntryPhoneDetailsControls channel={channel} />;
      case ChannelType.Meter:
        return <MeterDetailsControls channel={channel} />;
      case ChannelType.Gate:
        return <GateDetailsControls channel={channel} active={active} />;
      case ChannelType.Transmitter:
        return <TransmitterDetailsControls channel={channel} />;
      case ChannelType.Remote:
        return <RemoteDetailsControls channel={channel} />;
      case ChannelType.Sensor:
        switch (getSensorType(channel)) {
          case SensorType.Temperature:
            return <TemperatureDetailsControls channel={channel} active={active} />;
          case SensorType.Wind:
            return <WindDetailsControls channel={channel} />;
          case SensorType.Pressure:
            return <PressureDetailsControls channel={channel} />;
          case SensorType.Humidity:
            return <HumidityDetailsControls channel={channel} />;
          case SensorType.Movement:
            return <MovementDetailsControls channel={channel} active={active} />;
          case SensorType.Flood:
            return <FloodDetailsControls channel={channel} />;
          case SensorType.MeasuredBrightness:
            return <MeasuredBrightnessDetailsControls channel={channel} />;
          case SensorType.Reed:
            return <ReedDetailsControls channel={channel} />;
          default:
            return null;
        }
      default:
        return null;
    }
  }, [channel.States, active]);
};
