import React from 'react';
import classNames from 'classnames';
import './index.scss';

type PropsInterface = {
  className?: string;
};

export const IconLogo: React.FC<PropsInterface> = ({ className }) => {
  return (
    <div className={classNames('icon-logo', { [className as string]: className })}>
      <svg width="84" height="16" viewBox="0 0 84 16" xmlns="http://www.w3.org/2000/svg" fill="white">
        <path d="M82.311 1.54062C82.3994 1.46485 82.4499 1.36382 82.4499 1.22491C82.4499 1.086 82.3994 0.984973 82.311 0.909203C82.2226 0.833433 82.0837 0.795561 81.9195 0.795561H81.427V1.65427H81.9195C82.0837 1.65427 82.21 1.61639 82.311 1.54062ZM82.5004 1.55326C82.4247 1.64165 82.3363 1.71742 82.1974 1.75531L82.6141 2.39934H82.4373L82.0458 1.79318C82.0206 1.79318 81.9827 1.79318 81.9322 1.79318H81.4397V2.39934H81.2755V0.644013H81.9448C82.1468 0.644013 82.311 0.694535 82.4373 0.795561C82.5636 0.896587 82.6141 1.03549 82.6141 1.21228C82.6015 1.3512 82.5762 1.46486 82.5004 1.55326ZM82.5509 2.72768C82.7656 2.60139 82.9298 2.43723 83.0561 2.22255C83.1824 2.00786 83.2455 1.76793 83.2455 1.51537C83.2455 1.2628 83.1824 1.02287 83.0687 0.808186C82.9424 0.593506 82.7782 0.429335 82.5762 0.315681C82.3615 0.189398 82.1342 0.138882 81.869 0.138882C81.6038 0.138882 81.3765 0.202024 81.1618 0.328306C80.9472 0.454589 80.783 0.618756 80.6567 0.833436C80.5304 1.04812 80.4673 1.27542 80.4673 1.54062C80.4673 1.79318 80.5304 2.03313 80.6567 2.24781C80.783 2.46249 80.9472 2.62666 81.1618 2.75294C81.3765 2.87922 81.6038 2.94237 81.869 2.94237C82.109 2.91711 82.3363 2.85396 82.5509 2.72768ZM82.6267 0.202024C82.854 0.328306 83.0434 0.517736 83.1697 0.745045C83.296 0.972354 83.3718 1.22492 83.3718 1.51537C83.3718 1.79319 83.3086 2.05838 83.1697 2.28569C83.0308 2.513 82.854 2.70242 82.6141 2.84133C82.3868 2.98024 82.1216 3.04338 81.8438 3.04338C81.5659 3.04338 81.3007 2.98024 81.0734 2.84133C80.8461 2.70242 80.6567 2.52562 80.5304 2.29831C80.3915 2.071 80.3284 1.80581 80.3284 1.54062C80.3284 1.26279 80.3915 1.01023 80.5304 0.78292C80.6693 0.555612 80.8461 0.3662 81.0861 0.227289C81.3134 0.0883784 81.5786 0.0252248 81.8564 0.0252248C82.1342 -3.17264e-05 82.3994 0.0757409 82.6267 0.202024Z" />
        <path d="M0 0H3.72534V12.9945H11.7822V16H0V0Z" />
        <path d="M34.1595 0H30.1437L37.0766 16H40.7388L34.1595 0Z" />
        <path d="M44.0096 0L40.2716 8.58722L42.0774 12.944L47.697 0H44.0096Z" />
        <path d="M53.5944 0H49.5786L56.4989 16H60.1737L53.5944 0Z" />
        <path d="M63.4319 0L59.7065 8.58722L61.5124 12.944L67.132 0H63.4319Z" />
        <path d="M17.5911 16H13.79L19.599 3.01813L21.3417 7.0718L17.5911 16Z" />
        <path d="M24.6127 0H20.9505L20.9379 0.0126252L27.896 16H31.7982L24.6127 0Z" />
        <path d="M76.1864 0H72.5116V0.0126252L79.4698 16H83.3719L76.1864 0Z" />
        <path d="M69.0892 16H65.2881L71.0971 3.01813L72.8398 7.0718L69.0892 16Z" />
      </svg>
    </div>
  );
};
