import React from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, CustomBackdrop, EmptyStateBox, IconWarning, InputSelect } from '../../../../components';
import { ChannelInterface } from '../../../../types';
import { CurrentAbsoluteValues } from './components/absolute-values';
import { EcoIndicator } from './components/eco-indicator';
import { EcoState } from './components/eco-state';
import { EnergyConsumption } from './components/energy-consumption';
import { EnergyConsumptionSummary } from './components/energy-consumption/summary';
import { useMeterCurrent } from './hooks/use-meter-current';

type PropsInterface = {
  channel: ChannelInterface;
};

export const MeterCurrent: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const {
    data,
    isLoading,
    percentageRatioActualToMaximum,
    selectedParameter,
    selectedSinglePhaseMeasurements,
    totalForwardActiveEnergy,
    selectedAverageValue,
    selectedMinValue,
    selectedMaxValue,
    isActivePowerSelected,
    isStandalone,
    parameters,
    supportedScalarAverage,
    supportedScalarMax,
    supportedScalarMin,
    changeSelectedParameter,
  } = useMeterCurrent({ channel });
  const ecoVisible = supportedScalarMin && supportedScalarMax;
  const summaryVisible = supportedScalarMin || supportedScalarMax || supportedScalarAverage;

  if (isLoading && !data) {
    return <CustomBackdrop loading />;
  }

  if (data === null) {
    return (
      <div className="p-t-16 p-l-24 p-r-24">
        <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
      </div>
    );
  }

  return (
    <>
      <ControlWrapper className="control-wrapper--column">
        <div className="max-width-desktop">
          <InputSelect
            options={parameters}
            value={selectedParameter}
            onChange={changeSelectedParameter}
            className="input--reverse m-b-32"
            label={''}
          />
        </div>
        {/* <CurrentHeader header={t(`energyConsumption.${selectedParameter}`)} /> */}
        <EnergyConsumption
          maxValue={selectedMaxValue}
          averageValue={selectedAverageValue}
          selectedParameter={selectedParameter}
          changeSelectedParameter={changeSelectedParameter}
          selectedSinglePhaseMeasurements={selectedSinglePhaseMeasurements}
          isActivePowerSelected={isActivePowerSelected}
          percentageRatioActualToMaximum={percentageRatioActualToMaximum}
          totalForwardActiveEnergy={totalForwardActiveEnergy}
          isStandalone={isStandalone}
          ecoVisible={ecoVisible}
          {...(!isStandalone ? { itemPrefix: t('outputShort') } : {})}
        />
        {selectedSinglePhaseMeasurements?.length ? (
          <>
            <hr />
            <EnergyConsumptionSummary
              measurements={selectedSinglePhaseMeasurements}
              {...(!isStandalone ? { itemPrefix: t('outputShort') } : {})}
            />
          </>
        ) : null}
      </ControlWrapper>
      {ecoVisible && (
        <>
          <EcoIndicator value={percentageRatioActualToMaximum} />
          <EcoState value={percentageRatioActualToMaximum} />
        </>
      )}
      {summaryVisible && (
        <ControlWrapper className="control-wrapper control-wrapper--column  control-wrapper--height-auto control-wrapper--stretch">
          <CurrentAbsoluteValues
            selectedParameter={selectedParameter}
            averageValue={selectedAverageValue}
            minValue={selectedMinValue}
            maxValue={selectedMaxValue}
            isActivePowerSelected={isActivePowerSelected}
            supportedScalarMin={supportedScalarMin}
            supportedScalarMax={supportedScalarMax}
            supportedScalarAverage={supportedScalarAverage}
          />
        </ControlWrapper>
      )}
    </>
  );
};
