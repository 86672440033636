import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { UpdateStatus } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';

type UpdateContent = {
  status: UpdateStatus;
  progress?: number;
  progressTotal?: number;
  updateNumber: number;
  updatedResourceName: string;
  availableUpdates: string[];
  description?: string;
  warning?: string;
  handleClose?: () => void;
};

type DownloadContent = {
  progress?: number;
  updatedResourceName: string;
  speedMbps: number;
};

type ExalusStateInfo = {
  message: string | ReactNode;
  loader?: boolean;
  changeInstallationButton?: boolean;
  updateContent?: UpdateContent;
  downloadContent?: DownloadContent;
  header?: string;
  buttonTitle?: string;
  handleClick?: () => void;
};

type BackdropProviderInterface = {
  backdropOpen: boolean;
  backdropPopupOpen: boolean;
  carouselDisabled: boolean;
  exalusPopupContent: ExalusStateInfo | null;
  turnOnBackdrop: () => void;
  turnOffBackdrop: () => void;
  turnOnBackdropPopup: (info: ExalusStateInfo) => void;
  turnOffBackdropPopup: () => void;
  preventCarouselSwipe: () => void;
};

const initialState: BackdropProviderInterface = {
  backdropOpen: false,
  backdropPopupOpen: false,
  exalusPopupContent: null,
  carouselDisabled: false,
  turnOnBackdrop: () => null,
  turnOffBackdrop: () => null,
  turnOnBackdropPopup: () => null,
  turnOffBackdropPopup: () => null,
  preventCarouselSwipe: () => null,
};

export const BackdropContext = createContext<BackdropProviderInterface>(initialState);

export const useBackdropContext = (): BackdropProviderInterface => useContext(BackdropContext);

export const BackdropContextProvider: React.FC = ({ children }) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [backdropOpen, setBackdropOpen] = useState<boolean>(() => initialState.backdropOpen);
  const [backdropPopupOpen, setBackdropPopupOpen] = useState<boolean>(() => initialState.backdropPopupOpen);
  const [exalusPopupContent, setExalusPopupContent] = useState<ExalusStateInfo | null>(null);
  const [carouselDisabled, setCarouselDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (carouselDisabled) {
      timeout.current = setTimeout(() => setCarouselDisabled(false), 800);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [carouselDisabled]);

  const turnOnBackdrop = () => {
    setBackdropOpen(true);
  };

  const turnOffBackdrop = () => {
    setBackdropOpen(false);
  };

  const turnOnBackdropPopup = (info: ExalusStateInfo) => {
    setExalusPopupContent(info);
    setBackdropPopupOpen(true);
  };

  const turnOffBackdropPopup = () => {
    setExalusPopupContent(null);
    setBackdropPopupOpen(false);
  };

  const preventCarouselSwipe = () => {
    setCarouselDisabled(true);
  };

  const values: BackdropProviderInterface = {
    backdropOpen,
    backdropPopupOpen,
    exalusPopupContent,
    carouselDisabled,
    turnOnBackdrop,
    turnOffBackdrop,
    turnOnBackdropPopup,
    turnOffBackdropPopup,
    preventCarouselSwipe,
  };

  return <BackdropContext.Provider value={values}>{children}</BackdropContext.Provider>;
};
