import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconHeartProps = {
  isChecked: boolean;
  isDisabled: boolean;
};

export const IconCheckbox: React.FC<IconHeartProps> = ({ isChecked, isDisabled }) => {
  return (
    <svg
      className={classNames('icon-checkbox', {
        'icon-checkbox--disabled': isDisabled,
      })}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!isChecked ? (
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="5.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          vectorEffect="non-scaling-stroke"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0H6ZM19.5671 6.49081C19.8382 6.1776 19.804 5.70396 19.4908 5.43289C19.1776 5.16183 18.704 5.19599 18.4329 5.50919L9.41459 15.9295L5.50314 12.3912C5.19596 12.1133 4.72168 12.1371 4.4438 12.4442C4.16593 12.7514 4.18968 13.2257 4.49686 13.5036L8.97686 17.5562L9.54541 18.0705L10.0471 17.4908L19.5671 6.49081Z"
          fill="#15141D"
        />
      )}
    </svg>
  );
};
