import React from 'react';
import { Slider } from '@mui/material';
import './index.scss';

type PropsInterface = {
  defaultValue?: number;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  onChange: (event: Event, value: number | number[]) => void;
  onChangeCommitted?: (event: React.SyntheticEvent | Event, value: number | number[]) => void;
};

export const SliderHorizontal: React.FC<PropsInterface> = ({
  defaultValue,
  value,
  min,
  max,
  step = 1,
  onChange,
  onChangeCommitted,
}) => {
  return (
    <Slider
      classes={{
        root: 'horizontal-slider',
        rail: 'horizontal-slider__rail',
        thumb: 'horizontal-slider__thumb',
        track: 'horizontal-slider__track',
        active: 'horizontal-slider--active',
      }}
      value={value || 0}
      defaultValue={defaultValue || 0}
      step={step}
      {...(min !== undefined ? { min } : {})}
      {...(max !== undefined ? { max } : {})}
      onChange={onChange}
      {...(onChangeCommitted ? { onChangeCommitted: onChangeCommitted } : {})}
    />
  );
};
