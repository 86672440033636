import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useGetTariffs } from '../../../../../api/modules/optimizer/optimizer.hooks';
import {
  ChannelTypeInternal,
  DynamicMarketPricesQueryVariables,
  Query,
  TariffKindInternal,
} from '../../../../../data-access/gql-types/graphql';
import { DYNAMIC_MARKET_PRICES } from '../../../../../data-access/queries/channels';
import { useInstallation } from '../../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../../hooks/devices-and-channels/provider';
import { ChannelMeterInterface } from '../../../../../types';
import { SelectedPhase } from '../../../measurements/types';
import { tariffKind } from '../../../optimizer/configuration/utils/tariff-kind';
import { TariffPricesData } from '../types';

export const useMeterPrices = (selectedPhases?: SelectedPhase[]) => {
  const { channel } = useDevicesAndChannelsContext();
  const kind = (channel?.data as ChannelMeterInterface)?.tariff?.kind;
  const [emptyPrices, setEmptyPrices] = useState<boolean>(false);
  const { skipLavvaFetch } = useInstallation();
  const { data: dynamicPrices } = useQuery<Query, DynamicMarketPricesQueryVariables>(DYNAMIC_MARKET_PRICES, {
    skip: skipLavvaFetch || !kind || !kind.includes('_DYNAMIC'),
  });
  const { data: tariffs } = useGetTariffs(ChannelTypeInternal.Meter);

  const tariffPricesConfiguration = useMemo(() => {
    const tariffConfigurations =
      (channel?.data as ChannelMeterInterface)?.sharedChannelSettings?.tariffConfigurations || [];
    const priceData = tariffConfigurations.find((x) => x.tariff === kind);

    if (!kind || kind === TariffKindInternal.None) return null;

    if (!kind.includes('_DYNAMIC')) {
      if (!priceData) {
        setEmptyPrices(true);
        return null;
      }

      const myTariff = tariffKind[kind];

      const operatorTariff = tariffs?.find((x) => x.operator.toLowerCase() === myTariff.operator);

      if (operatorTariff) {
        if (kind === priceData.tariff) {
          setEmptyPrices(false);

          return {
            prices: priceData.prices.reduce((result, tier) => {
              result[tier.pricingTier] = tier.price;
              return result;
            }, {}),
            hours: operatorTariff.tariffs.find((x) => x.name.toLowerCase() === myTariff.tariff)?.hoursRanges || [],
          } as TariffPricesData;
        } else {
          setEmptyPrices(true);
        }
      }
    }

    return null;
  }, [channel?.data, kind, tariffs, selectedPhases?.length]);

  return {
    kind,
    tariffPricesConfiguration,
    dynamicPrices: dynamicPrices?.dynamicMarketPrices || [],
    emptyPrices,
  };
};
