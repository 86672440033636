import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { TableSortLabel } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MeasurementDetailItem } from '../../index';
import { Order } from '../index';

type CustomTableHeadProps = {
  onSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof MeasurementDetailItem) => void;
  order: Order;
  orderBy: string;
};

export const CustomTableHead: React.FC<CustomTableHeadProps> = ({ order, orderBy, onSort }) => {
  const { t } = useTranslation('channel-details');

  const headCells = useMemo(() => {
    return [
      {
        id: 'timestamp',
        label: t('time'),
        align: 'left',
      },
      {
        id: 'value',
        label: t('value'),
        align: 'right',
      },
    ];
  }, []);

  const createSortHandler = (newOrderBy: keyof MeasurementDetailItem) => (event: React.MouseEvent<unknown>) => {
    onSort(event, newOrderBy);
  };

  return (
    <TableHead
      classes={{
        root: 'live-table-container__head',
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align as 'left' | 'right'}
            sortDirection={orderBy === headCell.id ? order : false}
            classes={{
              root: c('live-table-container__cell', {
                'live-table-container__cell--value': headCell.id === 'value',
              }),
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id as keyof MeasurementDetailItem)}
              hideSortIcon
              classes={{
                icon: 'live-table-container__icon',
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
