import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useOptimizerSetConfig, useOptimizerSetMode } from '../../../../../api/modules/optimizer/optimizer.hooks';
import { OptimizerModeInternal } from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../hooks';
import { ChannelInterface } from '../../../../../types';
import { getEnumKeyByValue } from '../../../../../utils/helpers';
import { toastSuccess } from '../../../../../utils/toast';
import { useOptimizerConfigurationContext } from '../../context/configuration-context';
import { RelaysConsumptionFormBody } from '../types';
import { prepareRelaysToSend, prepareWorkingHoursToSend } from '../utils';
import { useOptimizerErrors } from './use-optimizer-errors';

export const useOptimizerRelaysConsumption = (channel: ChannelInterface) => {
  const { t } = useTranslation('channel-details');
  const { mutate: setMode } = useOptimizerSetMode();
  const { mutate: setConfig } = useOptimizerSetConfig();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleOptimizerErrors } = useOptimizerErrors();
  const { clearErrors } = useOptimizerConfigurationContext();

  const submitConfiguration = (mode: OptimizerModeInternal, formValues: RelaysConsumptionFormBody) => {
    const { relayConfig1, relayConfig2, relayConfig3, relayConfig4, from, to } = formValues;
    clearErrors();

    turnOnBackdrop();

    setMode(
      {
        deviceId: channel.deviceId,
        channelId: channel.id,
        mode: getEnumKeyByValue(OptimizerModeInternal, mode),
      },
      {
        onSuccess: ({ data }) => {
          if (data) {
            turnOnBackdrop();

            setConfig(
              {
                deviceId: channel.deviceId,
                channelId: channel.id,
                selfConsumptionWithRelays: {
                  relaysConfig: prepareRelaysToSend({ relayConfig1, relayConfig2, relayConfig3, relayConfig4 }),
                  workingHours: prepareWorkingHoursToSend(from, to),
                },
              },
              {
                onSuccess: () => {
                  turnOffBackdrop();
                  toastSuccess({ content: t('optimizer.configuration.configurationSaved') });
                },
                onError: (error: AxiosError) => {
                  handleOptimizerErrors(error);
                },
              },
            );
          } else {
            turnOffBackdrop();
          }
        },
      },
    );
  };

  return {
    submitConfiguration,
  };
};
