import React from 'react';
import './index.scss';

type ComponentProps = {
  tilt: number;
  fullRange: boolean;
};

export const Slats: React.FC<ComponentProps> = ({ tilt, fullRange = false }) => {
  return (
    <div className="slats">
      <div className="slat-diagonal"></div>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
        <div
          key={index}
          className="slat"
          style={{ transform: `rotate(${0.8 * (tilt * (fullRange ? 1.8 : 0.9) - (fullRange ? 90 : 0))}deg)` }}
        ></div>
      ))}
    </div>
  );
};
