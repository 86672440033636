import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trigger } from '../../../data-access/gql-types/graphql';
import { TriggerIcon } from '../icon';

type PropsInterface = {
  trigger: Trigger;
};

export const TriggerDetailsHeader: React.FC<PropsInterface> = ({ trigger }) => {
  const { t } = useTranslation('action');

  return (
    <div className="details-header p-t-0">
      <div className="details-header--left">
        <TriggerIcon isValid={trigger.isValid} />
        <div className="details-header__name">
          <h2 className="text-ellipsis">{trigger.name}</h2>
          <div className="details-header__type">{t('automation')}</div>
        </div>
      </div>
    </div>
  );
};
