import React from 'react';
import { BatteryStateEnum } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';

type ComponentProps = {
  state: BatteryStateEnum;
};

export const Battery: React.FC<ComponentProps> = ({ state }) => (
  <div className="battery-icon">
    {state === BatteryStateEnum.Low && (
      <svg width="24" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.35485 2.50793C1.92698 2.50793 1.58066 2.83456 1.58066 3.23809V22.2222C1.58066 22.6258 1.92698 22.9524 2.35485 22.9524H11.6452C12.073 22.9524 12.4194 22.6258 12.4194 22.2222V3.23809C12.4194 2.83456 12.073 2.50793 11.6452 2.50793"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.32259 2.50792V1.77777C9.32259 1.37423 8.97627 1.04761 8.5484 1.04761H5.45162C5.02375 1.04761 4.67743 1.37423 4.67743 1.77777V2.50792"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.35483 2.50793H4.67741"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6452 2.50793H9.32257"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2 18H12V23H2V18Z" fill="#009EE3" />
      </svg>
    )}
    {(state === BatteryStateEnum.Half || state === BatteryStateEnum.Percentage) && (
      <svg width="24" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.35485 2.50793C1.92698 2.50793 1.58066 2.83456 1.58066 3.23809V22.2222C1.58066 22.6258 1.92698 22.9524 2.35485 22.9524H11.6452C12.073 22.9524 12.4194 22.6258 12.4194 22.2222V3.23809C12.4194 2.83456 12.073 2.50793 11.6452 2.50793"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.32259 2.50792V1.77777C9.32259 1.37423 8.97627 1.04761 8.5484 1.04761H5.45162C5.02375 1.04761 4.67743 1.37423 4.67743 1.77777V2.50792"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.35483 2.50793H4.67741"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6452 2.50793H9.32257"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="2" y="13" width="10" height="12" />
      </svg>
    )}
    {state === BatteryStateEnum.Full && (
      <svg width="24" height="24" viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.35485 2.50793C1.92698 2.50793 1.58066 2.83456 1.58066 3.23809V22.2222C1.58066 22.6258 1.92698 22.9524 2.35485 22.9524H11.6452C12.073 22.9524 12.4194 22.6258 12.4194 22.2222V3.23809C12.4194 2.83456 12.073 2.50793 11.6452 2.50793"
          fill="#009EE3"
        />
        <path
          d="M9.32259 2.50792V1.77777C9.32259 1.37423 8.97627 1.04761 8.5484 1.04761H5.45162C5.02375 1.04761 4.67743 1.37423 4.67743 1.77777V2.50792"
          fill="#009EE3"
        />
        <path
          d="M2.35485 2.50793C1.92698 2.50793 1.58066 2.83456 1.58066 3.23809V22.2222C1.58066 22.6258 1.92698 22.9524 2.35485 22.9524H11.6452C12.073 22.9524 12.4194 22.6258 12.4194 22.2222V3.23809C12.4194 2.83456 12.073 2.50793 11.6452 2.50793L9.32259 2.50792V1.77777C9.32259 1.37423 8.97627 1.04761 8.5484 1.04761H5.45162C5.02375 1.04761 4.67743 1.37423 4.67743 1.77777V2.50792L2.35485 2.50793Z"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {state === BatteryStateEnum.Empty && (
      <svg width="24" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.32259 2.50792V1.77777C9.32259 1.37423 8.97627 1.04761 8.5484 1.04761H5.45162C5.02375 1.04761 4.67743 1.37423 4.67743 1.77777V2.50792"
          fill="#009EE3"
        />
        <path
          d="M2.35485 2.50793C1.92698 2.50793 1.58066 2.83456 1.58066 3.23809V22.2222C1.58066 22.6258 1.92698 22.9524 2.35485 22.9524H11.6452C12.073 22.9524 12.4194 22.6258 12.4194 22.2222V3.23809C12.4194 2.83456 12.073 2.50793 11.6452 2.50793L9.32259 2.50792V1.77777C9.32259 1.37423 8.97627 1.04761 8.5484 1.04761H5.45162C5.02375 1.04761 4.67743 1.37423 4.67743 1.77777V2.50792L2.35485 2.50793Z"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {state === BatteryStateEnum.Unknown && (
      <svg width="24" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.35485 2.50793C1.92698 2.50793 1.58066 2.83456 1.58066 3.23809V22.2222C1.58066 22.6258 1.92698 22.9524 2.35485 22.9524H11.6452C12.073 22.9524 12.4194 22.6258 12.4194 22.2222V3.23809C12.4194 2.83456 12.073 2.50793 11.6452 2.50793"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.32259 2.50792V1.77777C9.32259 1.37423 8.97627 1.04761 8.5484 1.04761H5.45162C5.02375 1.04761 4.67743 1.37423 4.67743 1.77777V2.50792"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.35483 2.50793H4.67741"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6452 2.50793H9.32257"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.14083 10.5032C5.03224 10.2529 4.98205 9.9751 5.00578 9.68396C5.08152 8.77215 5.92198 8.03553 6.92213 8.00133C8.06191 7.96212 9 8.79467 9 9.82828C9 10.3255 8.39042 10.9144 7.70327 11.3891C7.25521 11.6986 6.9997 12.1883 6.9997 12.7022V13"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.25 15.25C6.25 14.8354 6.58543 14.5 7 14.5C7.41457 14.5 7.75 14.8354 7.75 15.25C7.75 15.6646 7.41457 16 7 16C6.58543 16 6.25 15.6646 6.25 15.25Z"
          fill="#009EE3"
        />
      </svg>
    )}
    {state === BatteryStateEnum.ExternalPower && (
      <svg width="24" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.087 6.73913V1M3.3913 6.73913V1M6.73913 15.3478V21.087C6.73913 22.1442 7.5949 23 8.65218 23H11.7609M8.65218 15.3478H4.82609C2.71154 15.3478 1 13.6363 1 11.5217V6.73913H12.4783V11.5217C12.4783 13.6363 10.7667 15.3478 8.65218 15.3478Z"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </div>
);
