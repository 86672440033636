import React from 'react';

type IconDeskLampProps = {
  isOn: boolean;
};

export const IconDeskLamp: React.FC<IconDeskLampProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-switch icon-desk-lamp"
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.14258 26.3127C3.72836 26.3127 3.39258 26.6485 3.39258 27.0627C3.39258 27.4769 3.72836 27.8127 4.14258 27.8127V26.3127ZM17.6477 27.8127C18.0619 27.8127 18.3977 27.4769 18.3977 27.0627C18.3977 26.6485 18.0619 26.3127 17.6477 26.3127V27.8127ZM9.77715 5.95543L10.3702 6.41455L10.3762 6.40683L10.382 6.39895L9.77715 5.95543ZM8.47912 2.28094C8.12919 2.05931 7.66584 2.16333 7.44422 2.51326C7.22259 2.8632 7.32661 3.32654 7.67654 3.54817L8.47912 2.28094ZM4.14258 27.8127H11.6553V26.3127H4.14258V27.8127ZM11.6553 27.8127H17.6477V26.3127H11.6553V27.8127ZM12.2581 26.6165L5.665 17.7101L4.45939 18.6026L11.0525 27.5089L12.2581 26.6165ZM10.382 6.39895L11.3658 5.05739L10.1562 4.17034L9.17234 5.51191L10.382 6.39895ZM11.1623 3.98025L8.47912 2.28094L7.67654 3.54817L10.3597 5.24748L11.1623 3.98025ZM10.3679 5.25261L11.5306 5.96811L12.3167 4.69062L11.154 3.97512L10.3679 5.25261ZM5.06149 13.2717L10.3702 6.41455L9.1841 5.4963L3.8754 12.3535L5.06149 13.2717Z"
        fill="#15141D"
      />
      <path
        d="M6.56935 15.3042C6.56935 16.7931 5.3624 18 3.87354 18C2.38469 18 1.17773 16.7931 1.17773 15.3042C1.17773 13.8154 2.38469 12.6084 3.87354 12.6084C5.3624 12.6084 6.56935 13.8154 6.56935 15.3042Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M11.8349 5.52487C10.3193 7.9981 10.9999 11.1936 14.0614 13.0878C14.1644 13.1515 14.2996 13.1199 14.3656 13.0183L19.9239 4.46714C19.9973 4.35421 19.9553 4.20163 19.8344 4.1423C15.9365 2.22983 13.3515 3.04994 11.8349 5.52487Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      {isOn ? (
        <path d="M18.125 12.8125L21.0938 8.0625" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      ) : null}
    </svg>
  );
};
