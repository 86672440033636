import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconRemoteProps = {
  className?: string;
};

export const IconRemote: React.FC<IconRemoteProps> = ({ className }) => (
  <div
    className={classNames('icon-remote', {
      [className as string]: className,
    })}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4138 22H6.37931C5.61754 22 5 21.3825 5 20.6207V3.37931C5 2.61754 5.61754 2 6.37931 2H17.4138C18.1756 2 18.7931 2.61754 18.7931 3.37931V20.6207C18.7931 21.3825 18.1756 22 17.4138 22Z"
        stroke="#009EE3"
        strokeWidth="1.5"
      />
      <path
        d="M11.0077 6.56702L8.92331 7.75811C7.85489 8.36863 8.28812 10 9.51868 10H14.4813C15.7119 10 16.1451 8.36863 15.0767 7.75811L12.9923 6.56702C12.3774 6.21566 11.6226 6.21566 11.0077 6.56702Z"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9923 16.433L15.0767 15.2419C16.1451 14.6314 15.7119 13 14.4813 13H9.51868C8.28812 13 7.85489 14.6314 8.92331 15.2419L11.0077 16.433C11.6226 16.7843 12.3774 16.7843 12.9923 16.433Z"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
