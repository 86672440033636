import React from 'react';
import { useTranslation } from 'react-i18next';
import '../index.scss';

type EnergyConsumptionSummaryElementProps = {
  value: string;
  index: number;
  divider: boolean;
  unit: string;
  itemPrefix?: string;
};

export const EnergyConsumptionSummaryElement: React.FC<EnergyConsumptionSummaryElementProps> = ({
  value,
  index,
  divider,
  unit,
  itemPrefix,
}) => {
  const { t } = useTranslation('channel-details');

  return (
    <>
      <li key={index} className="energy-consumption-summary__element">
        <span className="energy-consumption-summary__text">
          {`${!itemPrefix ? t('phase') : itemPrefix} ${index + 1}`}
        </span>
        <p>
          {value}
          <span className="energy-consumption-summary__text--small m-l-2">{unit}</span>
        </p>
      </li>
      {divider && <li className="energy-consumption-summary__divider"></li>}
    </>
  );
};
