import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconTime } from '../../../components';
import { DayOfWeekInternal, TriggerPriceAndTimeConditionResponse } from '../../../data-access/gql-types/graphql';
import { sortedDays } from '../../../utils/helpers';
import ActionConditionItemDetails from '../condition-item-details';

type PropsInterface = {
  priceCondition: TriggerPriceAndTimeConditionResponse;
  removePriceCondition?: () => void;
  handlePriceConditionClick?: () => void;
};

export const PriceConditionDetails: React.FC<PropsInterface> = ({
  priceCondition,
  removePriceCondition,
  handlePriceConditionClick,
}) => {
  const { t } = useTranslation('action');

  const shortDayOfWeek = (day: DayOfWeekInternal) => t(`trigger.shortenedDaysOfWeek.${day.toLowerCase()}`);

  const condition = useMemo(() => {
    return {
      icon: <IconTime size={30} />,
      title: t('trigger.type.price.header'),
    };
  }, [priceCondition]);

  const repeatOption = useMemo(() => {
    let text = '';

    if (priceCondition.daysOfWeek?.length) {
      text = text += `${[...priceCondition.daysOfWeek]
        ?.sort((a, b) => sortedDays[a] - sortedDays[b])
        .map((day) => shortDayOfWeek(day))
        .join(', ')}\r\n`;
    } else if (priceCondition.targetExecutionCount === 1) {
      text = text += `${t('trigger.conditions.time.repeat.once')}\r\n`;
    } else {
      text = text += `${t('trigger.conditions.time.repeat.daily')}\r\n`;
    }

    if (priceCondition.betweenHoursFromInclusive && priceCondition.betweenHoursToInclusive)
      text = text += `${t('trigger.priceConditionOptions.hours')}: ${priceCondition.betweenHoursFromInclusive}:00-${
        priceCondition.betweenHoursToInclusive
      }:00\r\n`;
    if (priceCondition.numberOfCheapestPeriodsInDay)
      text = text += `${t('trigger.priceConditionOptions.cheapestPeriods')}: ${
        priceCondition.numberOfCheapestPeriodsInDay
      }\r\n`;
    if (priceCondition.priceGreaterThanOrEqual)
      text = text += `${t('trigger.priceConditionOptions.priceGreater')}: ${
        priceCondition.priceGreaterThanOrEqual
      } zł/kWh\r\n`;
    if (priceCondition.priceLesserThanOrEqual)
      text = text += `${t('trigger.priceConditionOptions.priceLesser')}: ${
        priceCondition.priceLesserThanOrEqual
      } zł/kWh\r\n`;

    return text;
  }, [priceCondition]);

  const handleClick = () => handlePriceConditionClick?.();

  return (
    <ActionConditionItemDetails
      removeCondition={removePriceCondition}
      handleConditionClick={handleClick}
      title={condition.title}
      icon={condition.icon}
      repeatOption={repeatOption}
    />
  );
};
