import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LinearProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useBluetoothContext } from '../../../context';
import './index.scss';

type ComponentProps = {
  show: boolean;
  onUpdateSuccess: () => void;
};

export const UpdateDialog: React.FC<ComponentProps> = ({ show, onUpdateSuccess }) => {
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const { updatePayload } = useBluetoothContext();

  return (
    <Backdrop classes={{ root: 'backdrop-popup update-lavva-device' }} open={show}>
      <div className="backdrop-popup--content">
        <div className="backdrop-popup--info bold">{t('addDeviceUpdate.updateInProgress')}</div>
        <div className="linear-progress">
          <div className="linear-progress--container">
            <p
              className={classNames('percentage', {
                success: updatePayload.status,
                error: updatePayload.errorCode,
              })}
            >{`${updatePayload.progress.toFixed(2)}%`}</p>
          </div>
          <LinearProgress
            className={classNames('linear-progress--line', {
              success: updatePayload.status,
              error: updatePayload.errorCode,
            })}
            variant="determinate"
            value={Math.round(updatePayload.progress * 100) / 100}
          />

          {updatePayload.errorCode ? (
            <p className="error-code">{t(`addDeviceUpdate.error.${updatePayload.errorCode}`)}</p>
          ) : (
            <p className="update-version">
              {updatePayload.version
                ? `${t('addDeviceUpdate.newVersion')} ${updatePayload.version}v`
                : t('addDeviceUpdate.newVersionLooking')}
            </p>
          )}
        </div>
        {updatePayload.status !== null && (
          <button className="button button--secondary close-btn" onClick={onUpdateSuccess}>
            {tc('buttons.close')}
          </button>
        )}
      </div>
    </Backdrop>
  );
};
