import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { format, fromUnixTime } from 'date-fns';
import { MeasurementDetailItem, MeasurementGroup } from '../index';

type PropsInterface = {
  unit: string;
  data: MeasurementDetailItem[];
  phase: string;
  measurementGroup: MeasurementGroup;
};

export const DownloadMeasurementsData: React.FC<PropsInterface> = ({ unit, data, phase, measurementGroup }) => {
  const { t } = useTranslation('channel-details');

  const headers = useMemo(() => {
    return [
      {
        key: 'timestamp',
        label: t('time'),
      },
      {
        key: 'date',
        label: t('date'),
      },
      ...(phase ? [{ key: 'value', label: `${t('phase')} ${phase}` }] : []),
      ...(!phase
        ? measurementGroup === MeasurementGroup.SinglePhase
          ? [
              { key: 'value1', label: `${t('phase')} 1` },
              { key: 'value2', label: `${t('phase')} 2` },
              { key: 'value3', label: `${t('phase')} 3` },
            ]
          : [
              {
                key: 'value',
                label: t('value'),
              },
            ]
        : []),

      { key: 'unit', label: t('unit') },
    ];
  }, []);

  const dataWithUnits = useMemo(() => {
    return data.map((item) => ({
      ...item,
      date: format(fromUnixTime(item.timestamp), 'dd.MM.yyyy HH:mm:ss'),
      ...(phase || (!phase && measurementGroup === MeasurementGroup.Absolute)
        ? { value: item.value.toString().replace(/\./g, ',') }
        : {}),
      ...(!phase && item?.phasesValues?.length && measurementGroup === MeasurementGroup.SinglePhase
        ? {
            value1: item?.phasesValues[0]?.toString().replace(/\./g, ','),
            value2: item?.phasesValues[1]?.toString().replace(/\./g, ','),
            value3: item?.phasesValues[2]?.toString().replace(/\./g, ','),
          }
        : {}),
      unit,
    }));
  }, [data]);

  return (
    <div className="center-button-container ">
      <CSVLink
        className="button m-b-24"
        filename={`measurements-${new Date().getTime()}.csv`}
        target="_blank"
        data={dataWithUnits}
        headers={headers}
      >
        {t('downloadMeasurementsData')}
      </CSVLink>
    </div>
  );
};
