import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import { useApi, useBackdropContext } from '../../hooks';
import { ROUTES } from '../../routes';
import { NotificationInterface, NotificationInvitationType, NotificationTypeEnum } from '../../types';
import { toastError } from '../../utils/toast';
import { IconDelete, IconNotification } from '../icons';
import './index.scss';

type NotificationItemProps = {
  notification: NotificationInterface;
  title: string;
  children: ReactNode;
};

const ONE_MIN_IN_MS = 1000 * 60;
const ONE_HOUR_IN_MS = 1000 * 60 * 60;
const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export const NotificationItem: React.FC<NotificationItemProps> = ({ notification, title, children }) => {
  const history = useHistory();
  const { t } = useTranslation('notifications');
  const { t: tc } = useTranslation('common');
  const { notificationList, markAsHidden, markAsDisplayed } = useApi();
  const [timeElepse, setTimeElapse] = useState<string>('');
  const [timeElapseTimeout, setTimeElapseTimeout] = useState<NodeJS.Timeout>();
  const [isSwiped, setSwiped] = useState<boolean>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const onDeleteNotification = useCallback(() => {
    turnOnBackdrop();
    markAsHidden([notification.id], {
      onSuccess: () => turnOffBackdrop(),
      onError: () => {
        turnOffBackdrop();
        toastError({ content: tc('errors.somethingWentWrong') });
      },
    });
  }, [notificationList, notification]);

  const handlers = useSwipeable({
    onSwipedLeft: () => setSwiped(false),
    onSwipedRight: () => setSwiped(true),
    trackMouse: true,
    preventDefaultTouchmoveEvent: true,
  });

  const calculateElapsedTime = useCallback(() => {
    if (!notification || !notification.createdTimestampUTC) return;

    clearTimeout(timeElapseTimeout as number | undefined);

    const timeNow = new Date().getTime();
    const timeDiff = timeNow - notification.createdTimestampUTC;
    let delay = 0;

    if (timeDiff < ONE_MIN_IN_MS) {
      setTimeElapse(t('now'));
      delay = ONE_MIN_IN_MS - timeDiff;
    } else if (timeDiff < ONE_HOUR_IN_MS) {
      setTimeElapse(Math.floor(timeDiff / ONE_MIN_IN_MS) + 'm ' + t('ago'));
      delay = ONE_MIN_IN_MS - (timeDiff % ONE_MIN_IN_MS);
    } else if (timeDiff < ONE_DAY_IN_MS) {
      setTimeElapse(Math.floor(timeDiff / ONE_HOUR_IN_MS) + 'h ' + t('ago'));
      delay = ONE_HOUR_IN_MS - (timeDiff % ONE_HOUR_IN_MS);
    } else {
      setTimeElapse(Math.floor(timeDiff / ONE_DAY_IN_MS) + 'd ' + t('ago'));
      delay = ONE_DAY_IN_MS - (timeDiff % ONE_DAY_IN_MS);
    }

    setTimeElapseTimeout(setTimeout(() => calculateElapsedTime(), delay));
  }, [timeElapseTimeout, setTimeElapseTimeout, notification]);

  const onClick = useCallback(() => {
    markAsDisplayed([notification.id], {
      onSuccess: () => {
        turnOffBackdrop();

        if (notification.data.type === NotificationTypeEnum.INVITATION) {
          switch (notification.data.type) {
            case NotificationTypeEnum.INVITATION:
              switch (notification.data.invitationType) {
                case NotificationInvitationType.InvitationReceived:
                  history.push(ROUTES.InvitationReceived(notification.id));
                  break;
                case NotificationInvitationType.InvitationAcceptedByInvitee:
                  history.push(ROUTES.InvitationAccepted(notification.id));
                  break;
                default:
                  break;
              }
              break;
          }
        }
      },
      onError: () => {
        turnOffBackdrop();
        toastError({ content: tc('errors.somethingWentWrong') });
      },
    });
  }, [notification.id, notificationList]);

  useEffect(calculateElapsedTime, [notification?.createdTimestampUTC]);

  if (notification === undefined) return null;

  return (
    <div className={classNames('notification-item-wrapper')}>
      <div className="notification-item-wrapper__delete-section" onClick={onDeleteNotification}>
        <IconDelete className="icon-delete--element" />
      </div>
      <div
        className={classNames('notification-item box box--rounder', {
          'notification-item--was-displayed': notification.wasDisplayed,
          'notification-item--is-swiped': isSwiped,
        })}
        onClick={onClick}
        {...handlers}
      >
        <header className="notification-item__header">
          <h4 className="notification-item__header-title">{title}</h4>
          <div className="notification-item__header-icon">
            <IconNotification isOn={!notification.wasDisplayed} />
          </div>
        </header>
        {children}
        {notification.createdTimestampUTC && <p className="notification-item__time-elapse">{timeElepse}</p>}
      </div>
    </div>
  );
};
