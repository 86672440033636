import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../components';
import IpInput from '../../../../../components/ip-input';
import { useStaticSettings } from '../hooks/use-static-settings';

const ExalusNetworkStaticSettings: React.FC = () => {
  const { t } = useTranslation('installation');
  const { handleSubmit, handleChange } = useStaticSettings();

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('exalusNetwork.staticSettings')} isUnderline />
        </>
      }
    >
      <form onSubmit={handleSubmit}>
        <IpInput
          label={t('exalusNetwork.ipAddress')}
          handleChange={(val) => handleChange(val, 'ip')}
          className="m-b-24"
        />
        <IpInput
          label={t('exalusNetwork.subnetMask')}
          handleChange={(val) => handleChange(val, 'mask')}
          className="m-b-24"
        />
        <IpInput
          label={t('exalusNetwork.defaultGateway')}
          handleChange={(val) => handleChange(val, 'gateway')}
          className="m-b-24"
        />
        <IpInput
          label={t('exalusNetwork.broadcast')}
          handleChange={(val) => handleChange(val, 'broadcast')}
          className="m-b-24"
        />
        <SubmitButton />
      </form>
    </Page>
  );
};

export default ExalusNetworkStaticSettings;
