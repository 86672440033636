import React from 'react';
import { ControlSourceType } from '../../../../../../data-access/gql-types/graphql';
import './index.scss';

type ComponentProps = {
  source: ControlSourceType;
};

export const IconGateOpen: React.FC<ComponentProps> = ({ source }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-gate icon-gate-open"
  >
    <g clipPath="url(#clip0_23434_14655)" className="gate-position">
      <path
        d="M6 33 L6 8 L 33 8 33 33"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3677 13.9192L6.09685 13.9192"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect width="20" height="20" transform="translate(20 20)" fill="white" />
    {source === ControlSourceType.User || source === ControlSourceType.Trigger ? (
      <g clipPath="url(#clip1_23434_14655)">
        <path
          d="M32.9699 22.8101H27.3899C26.7051 22.8101 26.1499 23.3652 26.1499 24.0501V36.4501C26.1499 37.1349 26.7051 37.6901 27.3899 37.6901H32.9699C33.6547 37.6901 34.2099 37.1349 34.2099 36.4501V24.0501C34.2099 23.3652 33.6547 22.8101 32.9699 22.8101Z"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M32.3499 22.8101L31.7299 24.0501H29.2499L28.6299 22.8101"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M36.0266 28.4458C36.4389 28.9294 36.69 29.5618 36.69 30.25C36.69 30.9382 36.4358 31.5799 36.0142 32.0666"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M24.3333 28.4458C23.921 28.9294 23.6699 29.5618 23.6699 30.25C23.6699 30.9382 23.9241 31.5799 24.3457 32.0666"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M37.3439 27.1284C38.0941 27.953 38.5498 29.0473 38.5498 30.2501C38.5498 31.4529 38.0879 32.5596 37.3315 33.3842"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M23.0155 27.1284C22.2653 27.953 21.8096 29.0473 21.8096 30.2501C21.8096 31.4529 22.2715 32.5596 23.0279 33.3842"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
      </g>
    ) : (
      <g clipPath="url(#clip1_23434_14670)">
        <path
          d="M33.2942 22.8801H27.5747C26.8728 22.8801 26.3037 23.4492 26.3037 24.1511V36.8611C26.3037 37.5631 26.8728 38.1321 27.5747 38.1321H33.2942C33.9962 38.1321 34.5652 37.5631 34.5652 36.8611V24.1511C34.5652 23.4492 33.9962 22.8801 33.2942 22.8801Z"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M29.9661 26.7963L28.5758 27.53C27.929 27.8714 28.1698 28.8575 28.8999 28.8575H32.1129C32.8431 28.8575 33.0839 27.8714 32.4371 27.53L31.0467 26.7963C30.7084 26.6177 30.3045 26.6177 29.9661 26.7963Z"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M31.0466 33.5631L32.4369 32.8293C33.0837 32.488 32.8429 31.5019 32.1128 31.5019H28.8997C28.1696 31.5019 27.9288 32.488 28.5756 32.8293L29.9659 33.5631C30.3043 33.7417 30.7082 33.7417 31.0466 33.5631Z"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M36.4273 28.657C36.8499 29.1527 37.1072 29.8009 37.1072 30.5063C37.1072 31.2117 36.8467 31.8694 36.4146 32.3683"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M24.4417 28.657C24.0191 29.1527 23.7617 29.8009 23.7617 30.5063C23.7617 31.2117 24.0223 31.8694 24.4544 32.3683"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M37.7774 27.3066C38.5463 28.1519 39.0134 29.2735 39.0134 30.5064C39.0134 31.7393 38.54 32.8736 37.7646 33.7188"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
        <path
          d="M23.091 27.3066C22.3221 28.1519 21.855 29.2735 21.855 30.5064C21.855 31.7393 22.3284 32.8736 23.1037 33.7188"
          stroke="#15141D"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sub-icon"
        />
      </g>
    )}
    <defs>
      <clipPath id="clip0_23434_14655">
        <rect width="28.3203" height="28.3203" fill="white" transform="matrix(0 1 -1 0 33.6719 7.32812)" />
      </clipPath>
      <clipPath id="clip1_23434_14655">
        <rect width="17.36" height="15.5" fill="white" transform="translate(21.5 22.5)" />
      </clipPath>
    </defs>
  </svg>
);
