import { ReactNode } from 'react';
import { StateInterval } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';

export const stateInterval = {
  DAY_IN_HOURS: StateInterval.Day,
  WEEK_IN_DAYS: StateInterval.Week,
  MONTH_IN_DAYS: StateInterval.Month,
  YEAR_IN_MONTHS: StateInterval.Year,
};

export type Section = {
  date: string;
  items: ListItem[];
};

export type ListItem = {
  icon: ReactNode;
  state: string;
  period: string;
};
