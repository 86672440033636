import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconToastWarningProps = {
  colorError?: boolean;
};

export const IconToastWarning: React.FC<IconToastWarningProps> = ({ colorError = false }) => (
  <div
    className={classNames('icon-toast-warning', {
      'color-error': colorError,
    })}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11" transform="rotate(-180 12 12)" fill="#FF4016" />
      <path d="M12 11V17" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="12" cy="8" r="0.5" transform="rotate(-180 12 8)" stroke="white" strokeLinecap="round" />
    </svg>
  </div>
);
