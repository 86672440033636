import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ChannelBoxIcon } from '../../../components/channel/box-icon';
import { ChannelType } from '../../../enums';
import { getChannelType, getSensorType } from '../../../utils';
import { ChannelDetailsErrorIcons } from '../error-icons';
import './index.scss';

type ComponentProps = {
  channel?: IDeviceChannel | null;
};

export const ChannelDetailsHeader: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('common');

  const type = useMemo(() => {
    if (channel) {
      if (getChannelType(channel) === ChannelType.Sensor) {
        return t(`types.exalus.channel.sensors.${getSensorType(channel)}`);
      }
      return t(`types.exalus.channel.${getChannelType(channel)}`);
    }
  }, [channel]);

  return (
    <div className="details-header">
      <div className="details-header--left">
        <div className="details-header__icon">
          {channel && (
            <>
              <ChannelDetailsErrorIcons channel={channel} />
              <ChannelBoxIcon channel={channel} className="device-box__head-icon--no-margin" />
            </>
          )}
        </div>
        <div className="details-header__name">
          <h2 className="text-ellipsis">{channel?.Name}</h2>
          {channel && <div className="details-header__type">{type}</div>}
        </div>
      </div>
    </div>
  );
};
