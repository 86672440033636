import { TariffKindInternal, TierPriceInput } from '../../../data-access/gql-types/graphql';
import { ChannelInterface, DeviceInterface } from '../../../types';

export enum InfoPageType {
  UNDEFINED = 'UNDEFINED',
  CHANNEL = 'CHANNEL',
  INSERVICE = 'INSERVICE',
  VISIBILITY = 'VISIBILITY',
  INPUT_TYPE = 'INPUT_TYPE',
  CALIBRATION = 'CALIBRATION',
}

export type ConfigurationHook = {
  autoCalibrate: () => void;
  editChannel: (values: ChannelGeneralConfiguration) => void;
  swapInDevice: () => void;
  swapOut: () => void;
  updateRecoveryDevice: () => void;
  updateDevice: () => void;
  updateDevices: () => void;
  setDeviceName: (name: string) => void;
  calibrateLoading: boolean;
  updateDeviceLoading: boolean;
};

export type ConfigurationHookParams = {
  channel?: ChannelInterface;
  device?: DeviceInterface;
  devices?: DeviceInterface[];
};

export type ConfigurationFormHookParams = {
  channel?: ChannelInterface;
};

export type ChannelGeneralConfiguration = {
  name: string;
  icon_name: string;
  hidden: boolean;
  isPartialControlModeVisible?: boolean;
  isDailyModeVisible?: boolean;
};

export type TariffPricesForm = {
  tariff: TariffKindInternal;
  prices: TierPriceInput[];
};

export type PropertyNameError = {
  PropertyName: string;
  ErrorCode: string;
  ErrorMessage: string;
  FormattedMessagePlaceholderValues: {
    ComparisonValue?: number;
    CollectionIndex?: number;
    PropertyValue?: {
      From?: number;
      To?: number;
    };
  };
};
