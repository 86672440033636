import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../dialog';

type TriggerNumberExceededDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const TriggerNumberExceededDialog: React.FC<TriggerNumberExceededDialogProps> = ({ open, setOpen }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');

  const onClose = () => setOpen(false);

  return (
    <DialogConfirmation
      show={open}
      setShow={setOpen}
      content={t('trigger.exceeded.content')}
      header={t('trigger.exceeded.header')}
      primaryBtnText={`${tc('buttons.understand')}`}
      primaryBtnAction={onClose}
    />
  );
};
