import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { NavHeader, Page } from '../../../../components';
import { LocationStateType } from '../../../../types';
import { CentralActionDetailsChannels } from '../../components/central-action/details-channels';
import { CentralActionDetailsControl } from '../../components/central-action/details-control';
import { CentralActionDetailsHeader } from '../../components/central-action/details-header';
import { ChannelType } from '../../enums';
import { useExalusChannels } from '../../hooks';
import { getChannelType } from '../../utils';
import { CentralActionType } from '../dashboard/types';

const CentralActionByTypeDetails: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const { type } = useParams<{ type?: ChannelType }>();
  const { data: channels } = useExalusChannels();

  const onBack = () => {
    if (location.state?.back) history.go(-location.state.back);
    else history.goBack();
  };

  const filteredChannels = useMemo(() => {
    return channels?.filter((channel) => getChannelType(channel) === type);
  }, [channels]);

  const centralAction: CentralActionType = useMemo(() => {
    return {
      id: uuid.v4(),
      items: channels?.filter((channel) => getChannelType(channel) === type) ?? [],
      type: type ?? ChannelType.Unknown,
      name: tc(`typesPlural.exalus.channel.${type}`),
    };
  }, [type, channels]);

  return (
    <Page
      containerClassName="p-t-0 p-r-0 p-b-0 p-l-0"
      headerClassName="p-t-24 p-r-24 p-b-0 p-l-24"
      contentClassName={classNames('p-r-24 p-b-24 p-l-24')}
      header={<NavHeader onClick={onBack} />}
    >
      <CentralActionDetailsHeader centralAction={centralAction} />
      <CentralActionDetailsControl centralAction={centralAction} />
      {filteredChannels && <CentralActionDetailsChannels channels={filteredChannels} />}
    </Page>
  );
};

export default CentralActionByTypeDetails;
