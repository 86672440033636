import {
  GlobalTimeParameters,
  IInputOutputSwitchParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { LedControllersLightTransitionTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/SlrConfigParameters';

export enum SlrParamsEnum {
  TURN_ON_BEHAVIOUR = 'TurnOnBehaviourParams',
  DIMMING_AND_BRIGHTNESS = 'DimmingAndBrighteningParams',
  TURN_ON_OFF_TIME_ACTIVATION = 'TurnOnOffTimeActivationParams',
  ENABLE_TIME = 'EnableTimeParams',
  DISABLE_TIME = 'DisableTimeParams',
  INPUT_MODE = 'InputModeParams',
  FLOATING_TIME = 'FloatingTimeParams',
}

export type SlrConfigParams = {
  [SlrParamsEnum.TURN_ON_BEHAVIOUR]: StateOfTurnOnSwitchDeviceParameters | null;
  [SlrParamsEnum.DIMMING_AND_BRIGHTNESS]: LedControllersLightTransitionTime | null;
  [SlrParamsEnum.TURN_ON_OFF_TIME_ACTIVATION]: boolean | null;
  [SlrParamsEnum.ENABLE_TIME]: GlobalTimeParameters | null;
  [SlrParamsEnum.DISABLE_TIME]: GlobalTimeParameters | null;
  [SlrParamsEnum.INPUT_MODE]: IInputOutputSwitchParameters[] | null;
  [SlrParamsEnum.FLOATING_TIME]: GlobalTimeParameters | null;
};

export const initialConfig: SlrConfigParams = {
  [SlrParamsEnum.TURN_ON_BEHAVIOUR]: null,
  [SlrParamsEnum.DIMMING_AND_BRIGHTNESS]: null,
  [SlrParamsEnum.TURN_ON_OFF_TIME_ACTIVATION]: null,
  [SlrParamsEnum.ENABLE_TIME]: null,
  [SlrParamsEnum.DISABLE_TIME]: null,
  [SlrParamsEnum.INPUT_MODE]: null,
  [SlrParamsEnum.FLOATING_TIME]: null,
};
