import React from 'react';

type IconLeakProps = {
  isOn: boolean;
};

export const IconLeak: React.FC<IconLeakProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-leak"
      width="30"
      height="37"
      viewBox="0 0 30 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3426 28.3072C22.3426 32.4522 18.9102 35.8123 14.6762 35.8123C10.4421 35.8123 7.00977 32.4522 7.00977 28.3072C7.00977 24.1623 9.30969 21.348 14.6762 15.7533C19.9033 21.4162 22.3426 24.1623 22.3426 28.3072Z"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      <path
        d="M19.2932 27.4167C19.2932 28.7309 18.8191 29.9368 18.0292 30.8793M13.7129 32.8795C14.7552 32.8795 15.7308 32.5998 16.5656 32.1127"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      {isOn ? (
        <>
          <path
            d="M27.6057 30.29C28.3758 29.5656 28.8551 28.5471 28.8551 27.419C28.8551 26.2661 28.3546 25.2278 27.5547 24.5007"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M2.39627 30.29C1.62616 29.5656 1.14688 28.5471 1.14688 27.419C1.14688 26.2661 1.64738 25.2278 2.44727 24.5007"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </>
      ) : null}
      <path
        d="M8.78675 5.34856H3.41886C3.05485 5.34856 2.75977 5.05348 2.75977 4.68947V2.03409C2.75977 1.67009 3.05485 1.375 3.41886 1.375H8.4485M8.78675 5.34856V9.44888C8.78675 10.1769 9.37692 10.7671 10.1049 10.7671H18.723C19.4511 10.7671 20.0412 10.1769 20.0412 9.44888V5.34856M8.78675 5.34856H20.0412M20.0412 5.34856H24.8556C25.2196 5.34856 25.5147 5.05348 25.5147 4.68947V2.03409C25.5147 1.67009 25.2196 1.375 24.8556 1.375H19.826"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
