import { NativeContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { TutorialStep } from '../types';

export const tutorialStepsWeb: TutorialStep[] = [
  {
    title: 'notificationsTutorial.sendPushOn.title',
    content: 'notificationsTutorial.sendPushOn.content',
  },
  {
    title: 'notificationsTutorial.sourcesOn.title',
    content: 'notificationsTutorial.sourcesOn.content',
  },
  {
    title: 'notificationsTutorial.browserSettings.title',
    content: 'notificationsTutorial.browserSettings.content',
  },
  {
    title: 'notificationsTutorial.internetConnection.title',
    content: 'notificationsTutorial.internetConnection.content',
  },
  {
    title: 'notificationsTutorial.battery.title',
    content: 'notificationsTutorial.battery.content',
  },
  {
    title: 'notificationsTutorial.cacheBrowser.title',
    content: 'notificationsTutorial.cacheBrowser.content',
  },
  {
    title: 'notificationsTutorial.applicationPermissions.title',
    content: 'notificationsTutorial.applicationPermissions.content',
  },
  {
    title: 'notificationsTutorial.support.title',
    content: 'notificationsTutorial.support.content',
  },
];
export const tutorialStepsNative: TutorialStep[] = [
  {
    title: 'notificationsTutorial.sendPushOn.title',
    content: 'notificationsTutorial.sendPushOn.content',
  },
  {
    title: 'notificationsTutorial.sourcesOn.title',
    content: 'notificationsTutorial.sourcesOn.content',
  },
  {
    title: 'notificationsTutorial.applicationSettings.title',
    content: 'notificationsTutorial.applicationSettings.content',
  },
  {
    title: 'notificationsTutorial.internetConnection.title',
    content: 'notificationsTutorial.internetConnection.content',
  },
  {
    title: 'notificationsTutorial.battery.title',
    content: 'notificationsTutorial.battery.content',
  },
  {
    title: 'notificationsTutorial.mobileCache.title',
    content: 'notificationsTutorial.mobileCache.content',
    enabled: NativeContext.AndroidGecko,
  },
  {
    title: 'notificationsTutorial.applicationPermissions.title',
    content: 'notificationsTutorial.applicationPermissions.content',
  },
  {
    title: 'notificationsTutorial.support.title',
    content: 'notificationsTutorial.support.content',
  },
];
