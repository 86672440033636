import { useEffect } from 'react';
import { useExalusContext } from '../../../context';
import { useExalusDevicesContext } from '../../../context/devices';

export const useDevicesUpdatesAvailability = () => {
  const { synchronized, connected } = useExalusContext();
  const { updatesAvailability, checkDevicesUpdates } = useExalusDevicesContext();

  useEffect(() => {
    if (synchronized && connected) {
      checkDevicesUpdates();
    }
  }, [synchronized, connected]);

  return { updatesAvailability };
};
