import React from 'react';
import './index.scss';

type PropsInterface = {
  style: React.CSSProperties;
};

export const IconTriangle: React.FC<PropsInterface> = ({ style }) => (
  <div className="icon-triangle" style={style}>
    <svg width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.8 3.30718C7.33333 3.6151 7.33333 4.3849 6.8 4.69282L1.7 7.63731C1.16667 7.94523 0.5 7.56033 0.5 6.94449L0.5 1.05551C0.5 0.439674 1.16667 0.0547729 1.7 0.362693L6.8 3.30718Z"
        fill="#FF4016"
      />
    </svg>
  </div>
);
