import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TriggerActionType } from '../../../data-access/gql-types/graphql';
import { convertMSToMinutesSeconds } from '../../../utils/helpers';

type TriggerChannelParametersProps = {
  action: any;
};

export const TriggerChannelParameters: React.FC<TriggerChannelParametersProps> = ({ action }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');

  if (!action?.id) return null;

  const valueConverted = useMemo(() => {
    const { minutes, seconds } = convertMSToMinutesSeconds(action.integerValue);
    if (minutes) {
      if (!seconds) {
        return `${minutes} ${t('minutesUnitShort')}`;
      }
      return `${minutes} ${t('minutesUnitShort')} ${seconds} ${t('secondsUnitShort')}`;
    }
    return `${seconds} ${t('secondsUnitShort')}`;
  }, [action]);

  switch (action.actionType) {
    case TriggerActionType.SetBlindPosition:
    case TriggerActionType.GateSetPosition:
      return <span>{`${t('triggerParameters.blindPosition')} ${action.integerValue}%`}</span>;
    case TriggerActionType.SetBlindDirection:
    case TriggerActionType.GateSetDirection:
      switch (action.integerValue) {
        case 0:
          return <span>{t('triggerParameters.blindDirection.unknown')}</span>;
        case 1:
          return <span>{t('triggerParameters.blindDirection.stop')}</span>;
        case 2:
          return <span>{t('triggerParameters.blindDirection.up')}</span>;
        case 3:
        default:
          return <span>{t('triggerParameters.blindDirection.down')}</span>;
      }
    case TriggerActionType.GateTilt:
      return <span>{t('triggerParameters.tilt')}</span>;
    case TriggerActionType.SetSwitchSetOnTime:
      return valueConverted ? (
        <span>{`${t('triggerParameters.switchOn')} ${tc('forTime')} ${valueConverted}`}</span>
      ) : null;
    case TriggerActionType.SetLightSetOnTime:
      return valueConverted ? (
        <span>{`${t('triggerParameters.switchOn')} ${tc('forTime')} ${valueConverted}`}</span>
      ) : null;
    case TriggerActionType.SetSwitchState:
      return <span>{action.booleanValue ? t('triggerParameters.switchOn') : t('triggerParameters.switchOff')}</span>;
    case TriggerActionType.SetLightState:
      return <span>{action.booleanValue ? t('triggerParameters.lightOn') : t('triggerParameters.lightOff')}</span>;
  }

  return <></>;
};
