import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { useSetActionBindings } from '../../../../api/modules/device/device.hooks';
import { DeviceBindingsBody } from '../../../../api/modules/device/device.types';
import {
  InputTriggerInternal,
  OnDeviceErrorSubscription,
  OnDeviceErrorSubscriptionVariables,
} from '../../../../data-access/gql-types/graphql';
import { ON_DEVICE_ERROR } from '../../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext, useInstallation } from '../../../../hooks';
import { useErrors } from '../../../../hooks/use-errors';
import { getEnumKeyByValue, timeToMilliseconds } from '../../../../utils/helpers';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { ActionBindingType, DeviceBindingsForm } from '../types';

export const useSubmitBindings = () => {
  const { t } = useTranslation('device-settings');
  const { t: tb } = useTranslation('backend-validation');
  const { deviceId } = useParams<{ deviceId: string }>();
  const { skipLavvaFetch, selectedInstallationId } = useInstallation();
  const { mutate } = useSetActionBindings();
  const { handleLavvaResolve } = useErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { data: deviceErrorData } = useSubscription<OnDeviceErrorSubscription, OnDeviceErrorSubscriptionVariables>(
    ON_DEVICE_ERROR,
    {
      variables: { installationId: selectedInstallationId },
      skip: skipLavvaFetch,
    },
  );

  useEffect(() => {
    if (deviceErrorData?.onDeviceError) {
      toastError({ content: tb(deviceErrorData.onDeviceError.code) });
    }
  }, [deviceErrorData]);

  const handleSubmitBindings = (values: DeviceBindingsForm) => {
    turnOnBackdrop();

    const request: DeviceBindingsBody = {
      deviceId,
      bindings: values.bindings.map((x) => ({
        inputId: x.inputId,
        bindings: [...x.single, ...x.double]
          .filter((b) => b.slot !== null)
          .map((y) => ({
            $: ActionBindingType[y.channelType.charAt(0).toUpperCase() + y.channelType.slice(1).toLowerCase()],
            inputTrigger:
              y.inputTrigger !== null
                ? getEnumKeyByValue(InputTriggerInternal, y.inputTrigger)
                : getEnumKeyByValue(InputTriggerInternal, InputTriggerInternal.SingleClick),
            slot: y.slot || 0,
            isDefault: y.actionType
              ? ![
                  ActionBindingType.Switch,
                  ActionBindingType.Light,
                  ActionBindingType.Blind,
                  ActionBindingType.Gate,
                  ActionBindingType.Cloud,
                ].includes(y.actionType)
              : true,
            ...(y.channelId !== 'cloud'
              ? {
                  destination: {
                    channelId: y.channelId,
                    deviceId,
                  },
                }
              : {}),
            ...(y.setOnTimeValue !== undefined ? { setOnTimeValue: timeToMilliseconds(y.setOnTimeValue) } : {}),
            ...(y.position !== undefined ? { position: y.position } : {}),
            ...(y.dir !== undefined ? { dir: y.dir } : {}),
            ...(y.temperature !== undefined ? { temperature: y.temperature } : {}),
            ...(y.brightness !== undefined ? { brightness: y.brightness } : {}),
            ...(y.r !== undefined ? { r: y.r } : {}),
            ...(y.g !== undefined ? { g: y.g } : {}),
            ...(y.b !== undefined ? { b: y.b } : {}),
            ...(y.onSignalActionId !== undefined && y.onSignalActionId !== null
              ? { onSignalActionId: y.onSignalActionId }
              : {}),
            ...(y.offSignalActionId !== undefined && y.offSignalActionId !== null
              ? { offSignalActionId: y.offSignalActionId }
              : {}),
          })),
      })),
    };

    mutate(request, {
      onSuccess: ({ data }) => {
        handleLavvaResolve({
          status: data.publishStatus,
          deviceId,
          onSuccess: () => {
            turnOffBackdrop();
            toastSuccess({ content: t('bindings.submitResult.success') });
          },
        });
      },
      onError: () => {
        turnOffBackdrop();
        toastError({ content: t('bindings.submitResult.error') });
      },
    });
  };

  return {
    handleSubmitBindings,
  };
};
