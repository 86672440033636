import React from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { AggregateVectorParameterTypeInternal } from '../../../../../../../data-access/gql-types/graphql';
import { aggregatedParameters } from '../../../../../measurements/utils';
import { ChartDataValues, CostEnum } from '../../../types';
import { prepareTooltipPayload } from '../../../utils';
import './index.scss';

export const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');

  const getValue = (el: ChartDataValues, parameters: ChartDataValues[], showPrice: boolean) => {
    if (el.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice) {
      const activeEnergy = parameters.find((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy);

      if (activeEnergy !== undefined) {
        if (!activeEnergy.empty) {
          return `${(el.value * activeEnergy.value).toFixed(aggregatedParameters[el.type].fixed)} ${
            aggregatedParameters[el.type].unit
          } ${
            showPrice
              ? `(${el.value.toFixed(aggregatedParameters[el.type].fixed)} ${aggregatedParameters[el.type].unit}/kWh)`
              : ''
          }`;
        } else {
          const predictionEnergy = parameters.find(
            (x) => x.type === AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
          );

          if (predictionEnergy !== undefined) {
            return `${(el.value * predictionEnergy.value).toFixed(aggregatedParameters[el.type].fixed)} ${
              aggregatedParameters[el.type].unit
            } ${
              showPrice
                ? `(${el.value.toFixed(aggregatedParameters[el.type].fixed)} ${aggregatedParameters[el.type].unit}/kWh)`
                : ''
            } `;
          }
        }
      }

      return `${el.value.toFixed(aggregatedParameters[el.type].fixed)} ${aggregatedParameters[el.type].unit}/kWh`;
    }

    return `${el.value.toFixed(aggregatedParameters[el.type].fixed)} ${aggregatedParameters[el.type].unit}`;
  };

  const getLabel = (el: ChartDataValues, parameters: ChartDataValues[]) => {
    if (el.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice) {
      const activeEnergy = parameters.find((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy);

      if (!activeEnergy) {
        return <span style={{ color: el.color }}>{`${t('price')}: `}</span>;
      }

      if (activeEnergy.empty) {
        return (
          <span style={{ color: el.color }}>{`${t(
            `energyMeasurements.types.summary.${CostEnum.PredictedCost}`,
          )}: `}</span>
        );
      }
    }

    return <span style={{ color: el.color }}>{`${t(`energyMeasurements.types.summary.${el.type}`)}: `}</span>;
  };

  if (active && payload?.length) {
    const values = prepareTooltipPayload(payload[0].payload.values);

    return (
      <div className="custom-tooltip">
        {payload[0].payload && (
          <div style={{ textAlign: 'center', width: '100%' }}>{payload[0].payload.tooltipTime}</div>
        )}
        {Object.entries(groupBy(values, 'index')).map(([key, value]) => {
          return (
            <div key={key} className="m-b-8">
              {key !== '0' ? <span>{`${t('phase')} ${key}`}</span> : null}
              {value.map((el: ChartDataValues) => (
                <div key={el.type} className="tooltip__element">
                  <span style={{ color: el.color }}>{getLabel(el, value)}</span>
                  <span>{!el.empty ? getValue(el, value, key === '0') : tc('noData')}</span>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};
