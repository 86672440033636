import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { ISlr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/ISlr22ConfigService';
import { Slr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr22ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { TurnOnOffActivationParams } from '../../../device-details/types';

type ComponentProps = {
  device: IDevice;
  activation: boolean | null;
};

const OnOffActivationControllerForm: React.FC<ComponentProps> = ({ device, activation }) => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<TurnOnOffActivationParams>();
  const { Activation } = form.watch();

  useEffect(() => {
    form.setValue('Activation', activation ? 1 : 0);
  }, [activation]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISlr22ConfigService>(
      Slr22ConfigService.ServiceName,
    );

    const result = await configService.SetTurnOnOffTimeActivationAsync(device, !!values.Activation);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  const handleChange = (value: number) => form.setValue('Activation', value);

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={handleChange}
          value={Activation}
          options={[
            { label: tc('status.on'), value: 1 },
            { label: tc('status.off'), value: 0 },
          ]}
          label={t('exalus.params.OnOffParams.TurnOnOffTimeActivationParams')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default OnOffActivationControllerForm;
