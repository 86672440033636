import React from 'react';
import classNames from 'classnames';
import { CircularProgress } from '@mui/material';
import { ONLY_LABEL_OPTION_VALUE } from '../../../const';
import { IconRadio } from '../../icons';
import { Dialog } from '../base';
import { SelectOptionInterface } from '../helper';
import './select.scss';

type Props<T> = {
  show: boolean;
  value?: T;
  setShow: (show: boolean) => void;
  title: string;
  options: SelectOptionInterface<T>[];
  onChange: (value: T) => void;
  titleClassName?: string;
  loading?: boolean;
};

export const DialogSelect = <T,>({
  show,
  setShow,
  title,
  options,
  onChange,
  loading = false,
  value,
  titleClassName,
}: Props<T>) => {
  return (
    <Dialog show={show} setShow={setShow}>
      <div className="dialog-select">
        <h2
          className={classNames('dialog-select__header', {
            [titleClassName as string]: titleClassName,
          })}
        >
          {title}
        </h2>
        {loading ? (
          <CircularProgress color="inherit" size={50} />
        ) : (
          <ul>
            {options
              .filter((x) => x.label)
              .map((option) => {
                const isSelected = option.value === value;

                return (
                  <li
                    key={option.label}
                    className={classNames('dialog-select__option-item', {
                      'dialog-select__option-item--selected': isSelected,
                      'dialog-select__option-item--disabled': option.disabled,
                      'dialog-select__option-item--only-label': option.value === ONLY_LABEL_OPTION_VALUE,
                    })}
                    onClick={() => {
                      if (!option.disabled) {
                        if (option.onClick) option.onClick();
                        onChange(option.value);
                      }
                    }}
                  >
                    <IconRadio className="dialog-select__option-item-icon-radio" selected={isSelected} />
                    <span className="dialog-select__option-item-label">
                      <span>{`${option.label}${option.intValue ? ` (${option.intValue})` : ''}`}</span>
                      {option.subLabel && <span className="sub-label">{option.subLabel}</span>}
                    </span>
                    {option.icon ? <div className="dialog-select__option-item-icon-option">{option.icon}</div> : null}
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </Dialog>
  );
};
