import React from 'react';
import c from 'classnames';

type IconDoorProps = {
  position?: number;
  big?: boolean;
  noState?: boolean;
  className?: string;
};

export const IconDoor: React.FC<IconDoorProps> = ({ position = 100, big, noState, className }) => {
  return (
    <>
      {position < 100 ? (
        <svg
          className={c('icon-cover icon-door', { big: big, 'no-state': noState, [className as string]: className })}
          width="23"
          height="42"
          viewBox="0 0 23 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1H20.0395C21.144 1 22.0395 1.89543 22.0395 3V33.5263C22.0395 34.6309 21.144 35.5263 20.0395 35.5263H15.5658M1 1V34.2266C1 35.0169 1.46547 35.7332 2.18772 36.0542L12.7535 40.7501C14.0761 41.3379 15.5658 40.3698 15.5658 38.9225V35.5263M1 1L13.6077 5.66953C14.7846 6.10541 15.5658 7.22778 15.5658 8.48278V35.5263"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M11.25 21.2632V26.6579"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          className={c('icon-cover icon-door', { big: big, 'no-state': noState, [className as string]: className })}
          width="23"
          height="37"
          viewBox="0 0 23 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0395 1H3C1.89543 1 1 1.89543 1 3V33.5263C1 34.6309 1.89543 35.5263 3 35.5263H20.0395C21.144 35.5263 22.0395 34.6309 22.0395 33.5263V3C22.0395 1.89543 21.144 1 20.0395 1Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M17.25 17.2632V22.6579"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
