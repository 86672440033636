import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { InputSelect } from '../../../../../../components';
import { useActionParameterOptions } from '../../utils/use-action-parameter-options';

type ComponentProps = {
  conditionType: ConditionsTypes;
  setConditionType: Dispatch<SetStateAction<ConditionsTypes>>;
  allow: ConditionsTypes[];
  astronomical?: boolean;
};

export const ConditionTypeSelect: React.FC<ComponentProps> = ({
  conditionType,
  setConditionType,
  allow,
  astronomical = false,
}) => {
  const { t } = useTranslation('action');
  const { conditionsTypes } = useActionParameterOptions(astronomical);

  const onChangeConditionType = (type: ConditionsTypes) => {
    setConditionType(type);
  };

  const options = useMemo(() => conditionsTypes.filter((x) => allow.includes(x.value)), [allow]);

  return (
    <InputSelect
      options={options}
      value={conditionType}
      onChange={onChangeConditionType}
      label={t('action.create.conditions.condition')}
    />
  );
};
