import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, InputSelect, NavHeader, Page } from '../../../components';
import FixedTwoButtons from '../../../components/fixed-two-buttons';
import { IconFluorescentLamp } from '../../../components/icons';
import { IconDevice } from '../../../components/icons/device';
import { ROUTES } from '../../../routes';
import { useBluetoothContext } from '../context';
import ConfigurationInput from './components/input-controller';
import { StatusItem } from './components/status-item';
import './index.scss';
import { IdentifyFormValues } from './types';

const DeviceAddBluetoothIdentify: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const {
    bluetoothData,
    identifing,
    typeOptions,
    isAlreadyAdded,
    advancedSettings,
    identifyDevice,
    checkIfConnected,
    updateBluetoothData,
  } = useBluetoothContext();
  const form = useForm<IdentifyFormValues>({
    mode: 'all',
    defaultValues: {
      name: '',
      type: '',
      subTypes: [],
    },
  });
  const { type, subTypes } = form.watch();

  useEffect(() => {
    checkIfConnected();

    form.setValue('name', bluetoothData.deviceName);
    form.setValue('type', bluetoothData.type);
    form.setValue('subTypes', bluetoothData.subTypes);
  }, []);

  useEffect(() => {
    if (advancedSettings?.subTypesInfo[type]) {
      form.setValue('subTypes', Array(advancedSettings.subTypesInfo[type].maxSubTypes).fill(''));
    }
  }, [type, advancedSettings]);

  const subTypeOptions = useMemo(() => {
    if (advancedSettings?.subTypesInfo[type]) {
      const { availableSubTypes } = advancedSettings.subTypesInfo[type];

      return availableSubTypes.map((subType) => ({
        label: tc(`gateSubTypes.${subType}`),
        value: subType,
      }));
    }

    return [];
  }, [type, advancedSettings]);

  const onSubmit = (formData: IdentifyFormValues) => {
    updateBluetoothData({
      name: formData.name,
      type: formData.type,
      subTypes: formData.subTypes,
    });

    history.push(ROUTES.DeviceAddBluetoothNetwork());
  };

  return (
    <Page
      isStickyHeader
      className="identify-page"
      header={
        <>
          <NavHeader />
          <Header title={t('bluetooth.deviceIdentifing')} isUnderline column>
            {identifing && (
              <div className="identify-page--header">
                <IconFluorescentLamp isOn />
                <span>{t('bluetooth.identifyActive')}</span>
              </div>
            )}
          </Header>
        </>
      }
    >
      <>
        <div className="device-bluetooth">
          <IconDevice />
          <div className="device-bluetooth--info">
            <p className="device-name">{bluetoothData.deviceName || 'N/A'}</p>
            <p className="mac-address">{`MAC: ${bluetoothData.mac}`}</p>
          </div>
        </div>
        {isAlreadyAdded && <StatusItem label={t('bluetooth.isAlreadyAddedToCloud')} value={isAlreadyAdded} />}
        {bluetoothData.diagnostic && isAlreadyAdded && (
          <div>
            <StatusItem
              label={t('bluetooth.deviceCloudConnected')}
              value={bluetoothData.diagnostic.lavvaCloudConnected}
            />
            <StatusItem
              label={t('bluetooth.deviceInternetConnected')}
              value={bluetoothData.diagnostic.internetConnected}
            />
            <StatusItem label={t('bluetooth.deviceWifiConnected')} value={bluetoothData.diagnostic.wifiIsConnected} />
          </div>
        )}
        <FormProvider {...form}>
          <form className="grid-list-24 m-t-32">
            <ConfigurationInput name="name" label={t('bluetooth.name')} placeholder={t('bluetooth.namePlaceholder')} />
            <Controller
              name="type"
              control={form.control}
              rules={{
                required: tc('isRequired') as string,
              }}
              render={({ field, fieldState: { error } }) => (
                <InputSelect
                  onChange={field.onChange}
                  value={field.value}
                  options={typeOptions}
                  label={t('bluetooth.deviceType')}
                  name={field.name}
                  disabled={typeOptions.length <= 1 || isAlreadyAdded}
                  {...(error ? { errorMessage: error.message } : {})}
                />
              )}
            />
            {type &&
              subTypes.map((_, index) => (
                <Controller
                  key={index}
                  name={`subTypes.${index}`}
                  control={form.control}
                  rules={{
                    required: tc('isRequired') as string,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputSelect
                      onChange={field.onChange}
                      value={field.value}
                      options={subTypeOptions}
                      label={`${t('bluetooth.gateType')} ${index + 1}`}
                      name={field.name}
                      {...(error ? { errorMessage: error.message } : {})}
                    />
                  )}
                />
              ))}
          </form>
          <FixedTwoButtons
            titlePrimary={tc('buttons.next')}
            titleSecondary={t('bluetooth.identify')}
            handleClickPrimary={form.handleSubmit(onSubmit)}
            {...(bluetoothData.identifyIsAvailable ? { handleClickSecondary: identifyDevice } : {})}
            reverse
          />
        </FormProvider>
      </>
    </Page>
  );
};

export default DeviceAddBluetoothIdentify;
