import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { InstallationPermissions, Query, UserProfileQueryVariables } from '../../data-access/gql-types/graphql';
import { USER_ME, USER_PROFILE } from '../../data-access/queries/user';
import { User } from '../../types';
import { isLocalApp } from '../../utils/helpers/local-app';
import { mapPermissions } from '../../utils/helpers/permissions';
import { useSentry } from '../common/use-sentry';
import { useInstallation } from '../installation';

type UseProfileInterface = {
  user: User;
  userInitial: string;
  userId: string | undefined;
  userProfileData: Query | undefined;
  refetchUserMe: () => void;
  refetchUserProfile: () => void;
  loadingUser: boolean;
  tips: string[] | undefined;
  permissions: Record<string, boolean>;
};

export const useProfile = (): UseProfileInterface => {
  const { selectedInstallationId } = useInstallation();
  const {
    data,
    refetch: refetchUser,
    loading: loadingMe,
  } = useQuery<Query>(USER_ME, {
    fetchPolicy: 'cache-first',
    skip: isLocalApp,
    onCompleted: (data) => {
      const profile = data.me?.profile;

      if (profile) {
        setSentryUser(profile.id, profile.email);
      }
    },
  });
  const { setSentryUser } = useSentry();

  const {
    data: userProfileData,
    refetch: refetchProfile,
    loading: loadingProfile,
  } = useQuery<Query, UserProfileQueryVariables>(USER_PROFILE, {
    variables: { installationId: selectedInstallationId },
    fetchPolicy: 'cache-first',
    skip: isLocalApp || !selectedInstallationId,
  });

  const refetchUserMe = () => refetchUser();
  const refetchUserProfile = () => refetchProfile();

  const userInitial = useMemo(() => data?.me?.profile?.firstName.charAt(0) || '', [data]);
  const userId = useMemo(() => data?.me?.profile?.id, [data]);

  const permissions = useMemo(() => {
    if (data?.me?.permissions) {
      return mapPermissions((data.me.permissions || []) as InstallationPermissions[]);
    }

    return {};
  }, [data?.me?.permissions]);

  const tips = useMemo(() => {
    return (userProfileData?.userProfile?.displayedLearningScenarios as string[]) || undefined;
  }, [userProfileData]);

  return {
    user: {
      profile: {
        firstName: data?.me?.profile?.firstName || '',
        id: data?.me?.profile?.id || '',
        email: data?.me?.profile?.email || '',
      },
      profileImage: {
        imageUrl: data?.me?.profileImage?.imageUrl || '',
      },
    },
    userInitial,
    userId,
    userProfileData,
    tips,
    refetchUserMe,
    refetchUserProfile,
    loadingUser: loadingMe || loadingProfile,
    permissions,
  };
};
