import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/IRob21ConfigService';
import {
  LimitSwitchConfiguration,
  LimitSwitchFunction,
  LimitSwitchType,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigParams';
import { Rob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { Accordion, InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type ComponentProps = {
  device: IDevice;
  switches: LimitSwitchConfiguration[] | null;
};

const LimitingSwitchesForm: React.FC<ComponentProps> = ({ device, switches }) => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [expanded, setExpanded] = useState<string | false>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService(device);
  const form = useForm<LimitSwitchConfiguration>();

  const { Function, Type, Channel } = form.watch();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const data = (switches || []).find((x) => x.Input.toString() === panel);

    if (data) {
      form.setValue('Type', data.Type);
      form.setValue('Function', data.Function);
      form.setValue('Channel', data.Channel);
      form.setValue('Input', data.Input);

      setExpanded(isExpanded ? panel : false);
    }
  };

  const onSubmit = form.handleSubmit(async (values) => {
    const data = (switches || []).find((x) => x.Input === values.Input);

    if (data) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRob21ConfigService>(
        Rob21ConfigService.ServiceName,
      );

      const result = await configService.SetLimitingSwitchConfigurationAsync(device, {
        Channel: values.Channel,
        Input: data.Input,
        Function: values.Function,
        Type: values.Type,
      });
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const onChangeChannel = (value: string) => form.setValue('Channel', parseInt(value));

  const onChangeFunction = (value: LimitSwitchFunction) => form.setValue('Function', value);

  const onChangeType = (value: LimitSwitchType) => form.setValue('Type', value);

  const channelOptions = useMemo(() => {
    return device.Channels.map((x) => ({
      label: `${t('exalus.params.Rob21Params.Channel')} ${x.Number}`,
      value: `${x.Number}`,
    }));
  }, [device]);

  return (
    <>
      {(switches || []).map((x) => (
        <Accordion
          key={x.Input}
          kind="controlled-base"
          title={`${t('exalus.params.Rob21Params.Switch')} ${x.Input}`}
          expanded={expanded === x.Input.toString()}
          handleExpand={handleChange(x.Input.toString())}
          titleNotbold
        >
          <FormProvider {...form}>
            <form onSubmit={onSubmit} className="grid-list-24">
              <InputSelect
                onChange={onChangeChannel}
                value={Channel?.toString()}
                options={channelOptions}
                label={t('exalus.params.Rob21Params.OutputAssigned')}
              />
              <InputSelect
                onChange={onChangeFunction}
                value={Function}
                options={[
                  {
                    label: t('exalus.params.Rob21Params.SwitchFunctionSteps.LimitSwitchDisabled'),
                    value: LimitSwitchFunction.LimitSwitchDisabled,
                  },
                  {
                    label: t('exalus.params.Rob21Params.SwitchFunctionSteps.LimitSwitchClosed'),
                    value: LimitSwitchFunction.LimitSwitchClosed,
                  },
                  {
                    label: t('exalus.params.Rob21Params.SwitchFunctionSteps.LimitSwitchOpened'),
                    value: LimitSwitchFunction.LimitSwitchOpened,
                  },
                ]}
                label={t('exalus.params.Rob21Params.SwitchFunction')}
              />
              <InputSelect
                onChange={onChangeType}
                value={Type}
                options={[
                  {
                    label: t('exalus.params.Rob21Params.SwitchTypeSteps.NormallyOpened'),
                    value: LimitSwitchType.NormallyOpened,
                  },
                  {
                    label: t('exalus.params.Rob21Params.SwitchTypeSteps.NormallyClosed'),
                    value: LimitSwitchType.NormallyClosed,
                  },
                ]}
                label={t('exalus.params.Rob21Params.SwitchType')}
              />
            </form>
          </FormProvider>
          <SubmitButton onClick={onSubmit}>{`${tc('buttons.save')} ${t('exalus.params.Rob21Params.Switch')} ${
            x.Input
          }`}</SubmitButton>
        </Accordion>
      ))}
    </>
  );
};

export default LimitingSwitchesForm;
