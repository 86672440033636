import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconSortHandleProps = {
  onClick?: () => void;
  className?: string;
  size?: number;
};

export const IconSortHandle: React.FC<IconSortHandleProps> = ({ onClick, className, size = 36 }) => (
  <div
    className={classNames('icon-sort-handle', {
      clickable: onClick,
      [className as string]: className,
    })}
    onClick={onClick}
  >
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="18" cy="18" r="17" stroke="white" vectorEffect="non-scaling-stroke" />
      <path d="M11 22H25" stroke="white" vectorEffect="non-scaling-stroke" />
      <path d="M11 18H25" stroke="white" vectorEffect="non-scaling-stroke" />
      <path d="M11 14H25" stroke="white" vectorEffect="non-scaling-stroke" />
    </svg>
  </div>
);
