import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import {
  IUpdatesService,
  UpdateBranch,
  UpdateErrorCode,
} from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { UpdatesService } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';
import { SelectOptionInterface } from '../../../../../components';
import { useBackdropContext } from '../../../../../hooks';
import { isDev } from '../../../../../utils/helpers/environment';
import { AutomaticEnum } from '../types';
import { useUpdateErrors } from './use-update-errors';

export const useUpdatesSettings = () => {
  const { t } = useTranslation('installation');
  const [branch, setBranch] = useState<UpdateBranch>(UpdateBranch.Beta);
  const [showBranch, setShowBranch] = useState<boolean>(false);
  const [automatic, setAutomatic] = useState<AutomaticEnum>(AutomaticEnum.Enabled);
  const [showAutomatic, setShowAutomatic] = useState<boolean>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { showUpdateError } = useUpdateErrors();

  const getControllerUpdateBranch = async () => {
    turnOnBackdrop();
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result = await updateService.GetControllerUpdateBranchAsync();

    if (!(result as ResponseResult<UpdateErrorCode>).Type) {
      setBranch(result as UpdateBranch);
      setShowBranch(true);
    } else {
      showUpdateError(result as ResponseResult<UpdateErrorCode>);
    }

    turnOffBackdrop();
  };

  const getControllerAutomaticOption = async () => {
    turnOnBackdrop();
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result = await updateService.GetAutomaticControllerUpdateDownloadStatusAsync();

    if (!(result as ResponseResult<UpdateErrorCode>).Type) {
      setAutomatic((result as boolean) === true ? AutomaticEnum.Enabled : AutomaticEnum.Disabled);
      setShowAutomatic(true);
    } else {
      showUpdateError(result as ResponseResult<UpdateErrorCode>);
    }

    turnOffBackdrop();
  };

  const setControllerUpdateBranch = async (branch: UpdateBranch) => {
    turnOnBackdrop();
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result = await updateService.SetControllerUpdateBranchAsync(branch);

    if ((result as ResponseResult<UpdateErrorCode>).Type) {
      showUpdateError(result as ResponseResult<UpdateErrorCode>);
    }

    turnOffBackdrop();
  };

  const handleChangeBranch = (branch: UpdateBranch) => {
    setBranch(branch);
    setControllerUpdateBranch(branch);
  };

  const handleChangeAutomatic = (option: AutomaticEnum) => {
    setAutomatic(option);
    handleUpdateAutomaticOption(option);
  };

  const handleUpdateAutomaticOption = async (option: AutomaticEnum) => {
    turnOnBackdrop();
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    let result: Status | ResponseResult<UpdateErrorCode>;

    if (option === AutomaticEnum.Enabled) {
      result = await updateService.EnableAutomaticControllerUpdateDonwloadAsync();
    } else {
      result = await updateService.DisableAutomaticControllerUpdateDonwloadAsync();
    }

    if ((result as ResponseResult<UpdateErrorCode>).Type) {
      showUpdateError(result as ResponseResult<UpdateErrorCode>);
    }

    turnOffBackdrop();
  };

  const branchOptions: SelectOptionInterface<UpdateBranch>[] = [
    { label: t('exalus.environmentOption.dev'), value: UpdateBranch.Development, disabled: !isDev },
    { label: t('exalus.environmentOption.beta'), value: UpdateBranch.Beta },
    { label: t('exalus.environmentOption.public'), value: UpdateBranch.Public },
  ];

  const automaticOptions: SelectOptionInterface<AutomaticEnum>[] = [
    { label: t('exalus.enabled'), value: AutomaticEnum.Enabled },
    { label: t('exalus.disabled'), value: AutomaticEnum.Disabled },
  ];

  return {
    getControllerUpdateBranch,
    setControllerUpdateBranch,
    getControllerAutomaticOption,
    branch,
    handleChangeBranch,
    handleChangeAutomatic,
    branchOptions,
    automaticOptions,
    showBranch,
    automatic,
    showAutomatic,
  };
};
