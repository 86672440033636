import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputSelect } from '../../../../../components';
import { GateActionTypeEnum, GateDirection } from '../../types';
import { PositionAction } from '../position-action';

type ComponentProps = {
  bindingIndex: number;
  bindingListIndex: number;
  listName: string;
};

export const GateAction: React.FC<ComponentProps> = ({ bindingIndex, bindingListIndex, listName }) => {
  const { t } = useTranslation('device-settings');
  const form = useFormContext();
  const { update } = useFieldArray({
    control: form.control,
    name: 'bindings',
  });
  const [gateActionType, setGateActionType] = useState<GateActionTypeEnum | undefined>(undefined);
  const { bindings } = form.watch();

  useEffect(() => {
    const binding = bindings[bindingIndex][listName][bindingListIndex];

    if (binding.dir) setGateActionType(GateActionTypeEnum.Direction);
    else if (binding.position) setGateActionType(GateActionTypeEnum.Position);
  }, [bindings[bindingIndex][listName][bindingListIndex]]);

  const handleClearRest = () => {
    const { bindings } = form.getValues();

    delete bindings[bindingIndex][listName][bindingListIndex].dir;
    delete bindings[bindingIndex][listName][bindingListIndex].position;
  };

  const handleChangeDir = (value: GateDirection) => {
    const { bindings } = form.getValues();
    handleClearRest();

    bindings[bindingIndex][listName][bindingListIndex].dir = value;
    update(bindingIndex, { ...bindings[bindingIndex] });
  };

  const handleChangeGateActionType = (action: GateActionTypeEnum) => {
    const { bindings } = form.getValues();
    setGateActionType(action);

    handleClearRest();

    switch (action) {
      case GateActionTypeEnum.Direction: {
        bindings[bindingIndex][listName][bindingListIndex].dir = '';
        break;
      }
      case GateActionTypeEnum.Position: {
        bindings[bindingIndex][listName][bindingListIndex].position = 0;
        break;
      }
    }
  };

  return (
    <div>
      <InputSelect
        value={gateActionType}
        onChange={handleChangeGateActionType}
        options={[
          { label: t('bindings.direction'), value: GateActionTypeEnum.Direction },
          { label: t('bindings.position'), value: GateActionTypeEnum.Position },
        ]}
        label={t('bindings.actionType')}
        className="input--reverse"
      />
      {gateActionType === GateActionTypeEnum.Direction && (
        <InputSelect
          value={bindings[bindingIndex][listName][bindingListIndex].dir}
          onChange={handleChangeDir}
          options={[
            { label: t('bindings.stop'), value: GateDirection.Stop },
            { label: t('bindings.open'), value: GateDirection.Open },
            { label: t('bindings.close'), value: GateDirection.Close },
          ]}
          label={t('bindings.direction')}
          className="input--reverse"
        />
      )}
      {gateActionType === GateActionTypeEnum.Position && (
        <PositionAction
          value={bindings[bindingIndex][listName][bindingListIndex].position}
          bindingIndex={bindingIndex}
          bindingListIndex={bindingListIndex}
          listName={listName}
        />
      )}
    </div>
  );
};
