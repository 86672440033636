import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DialogConfirmation } from '../../../../../../components';
import { UserPermissionType } from '../../../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../../../hooks';
import { usePermissionsContext } from '../../../../../../hooks/user-permissions';
import { ROUTES } from '../../../../../../routes';

type PropsInterface = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const InstallationLocationDialog: React.FC<PropsInterface> = ({ open, setOpen }) => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId } = useInstallation();
  const { permissions } = usePermissionsContext();

  const onClose = useCallback(() => setOpen(false), []);

  const handleConfirm = useCallback(() => history.push(ROUTES.InstallationSettings(selectedInstallationId)), []);

  const canChangeGeolocation = useMemo(() => permissions[UserPermissionType.ChangeGeolocation], [permissions]);

  return (
    <DialogConfirmation
      show={open}
      setShow={onClose}
      header={t('trigger.installationLocation.header')}
      content={t(`trigger.installationLocation.content${!canChangeGeolocation ? 'Contact' : ''}`)}
      primaryBtnText={canChangeGeolocation ? t('trigger.installationLocation.button') : tc('buttons.understand')}
      primaryBtnAction={canChangeGeolocation ? handleConfirm : onClose}
      {...(canChangeGeolocation ? { secondaryBtnText: tc('buttons.cancel'), secondaryBtnAction: onClose } : {})}
      direction="column"
    />
  );
};
