import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectInputController } from '../../../../../components';
import { ActionType } from '../../../../../data-access/gql-types/graphql';
import { useApi } from '../../../../../hooks';
import { ChannelActions } from '../../types';

type ComponentProps = {
  bindingIndex: number;
  bindingListIndex: number;
  listName: string;
};

export const CloudAction: React.FC<ComponentProps> = ({ bindingIndex, bindingListIndex, listName }) => {
  const { t } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const form = useFormContext();
  const { update } = useFieldArray({
    control: form.control,
    name: 'bindings',
  });
  const { userActions } = useApi();
  const { bindings } = form.watch();

  const actions = useMemo(() => {
    const actionList = userActions
      .filter((x) => x.action.actionType === ActionType.Advanced)
      .map((action) => ({
        label: action.action.name,
        value: action.id,
      }));

    return [{ label: tc('select'), value: null }, ...actionList];
  }, [userActions]);

  const handleActionChange = (
    actionId: string,
    property: ChannelActions.OnSignalActionId | ChannelActions.OffSignalActionId,
  ) => {
    const { bindings } = form.getValues();

    bindings[bindingIndex][listName][bindingListIndex][property] = actionId;
    update(bindingIndex, { ...bindings[bindingIndex] });
  };

  const handleCloudValidation = () => {
    const onAction = bindings[bindingIndex][listName][bindingListIndex].onSignalActionId;
    const offAction = bindings[bindingIndex][listName][bindingListIndex].offSignalActionId;
    const atLeastOneSelected = onAction || offAction;

    return !!atLeastOneSelected || t('bindings.cloudRequiredError');
  };

  return (
    <>
      <SelectInputController
        name={`bindings[${bindingIndex}][${listName}][${bindingListIndex}].onSignalActionId`}
        value={bindings[bindingIndex][listName][bindingListIndex].onSignalActionId}
        handleChange={(actionId) => handleActionChange(actionId, ChannelActions.OnSignalActionId)}
        options={actions}
        label={t('bindings.onSignalActionId')}
        validation={handleCloudValidation}
        className="input--reverse"
      />
      <SelectInputController
        name={`bindings[${bindingIndex}][${listName}][${bindingListIndex}].offSignalActionId`}
        value={bindings[bindingIndex][listName][bindingListIndex].offSignalActionId}
        handleChange={(actionId) => handleActionChange(actionId, ChannelActions.OffSignalActionId)}
        options={actions}
        label={t('bindings.offSignalActionId')}
        className="input--reverse"
      />
    </>
  );
};
