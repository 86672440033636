import * as Sentry from '@sentry/react';
import { environment } from './environment';
import { EnvironmentName } from './types';
import { isDev, isLocalhost, isProd, isStag } from './utils/helpers/environment';
import { isLocalApp } from './utils/helpers/local-app';

const getEnvironment = () => {
  if (isDev) return EnvironmentName.Dev;
  if (isStag) return EnvironmentName.Stage;
  if (isProd) return EnvironmentName.Prod;

  return EnvironmentName.Localhost;
};

export const initSentry = () => {
  if (isLocalApp || isLocalhost) return;

  Sentry.init({
    dsn: environment.SENTRY_DSN,
    tracesSampleRate: 1.0,
    integrations: [Sentry.browserTracingIntegration()],
    environment: getEnvironment(),
    release: environment.BUILD_ID,
    denyUrls: [/node_modules/],
    ignoreErrors: [
      /Failed to fetch/,
      /NetworkError/,
      /The connection was stopped during negotiation/,
      /Non-Error promise rejection captured with value/,
      /Connection is not established/,
      /Response timeout/,
      /The current user is not authorized to access this resource/,
      /A timeout occurred/,
      /Invariant Violation/,
      /ApolloError/,
      /Failed to send request, resource/,
      /InvalidAccessError/,
    ],
    beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
      console.log('SENTRY EVENT: ', { EVENT: JSON.stringify(event), HINT: JSON.stringify(hint) });
      return event;
    },
  });
};
