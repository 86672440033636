import React, { useMemo, useState } from 'react';
import { DeviceTaskType as ChannelTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper, LightSlider, OnOffControl } from '../../../../../../../components';
import { canChannelDo } from '../../../../../utils';
import { useCentralActionLightControl } from '../../../hooks/use-central-action-light-control';
import { useCentralActionSwitchControl } from '../../../hooks/use-central-action-switch-control';

type ComponentProps = {
  channels: IDeviceChannel[];
};

export const CentralActionLightDetailsControlBox: React.FC<ComponentProps> = ({ channels }) => {
  const { turnOn, turnOff } = useCentralActionSwitchControl(channels);
  const { handleSliderEvent } = useCentralActionLightControl(channels);
  const [sliderValue, setSliderValue] = useState<number>(50);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSliderValue(Number(event.target.value));

  const showLightSlider = useMemo(
    () => channels.every((channel) => canChannelDo(channel, [ChannelTaskType.SetLightBrightness])),
    [channels],
  );

  return (
    <>
      <ControlWrapper>
        <OnOffControl actionOn={turnOn} actionOff={turnOff} />
      </ControlWrapper>

      {showLightSlider && (
        <div className="details-page__controls-slider p-l-24 p-r-24">
          <LightSlider value={sliderValue} onPointerUp={handleSliderEvent} onChange={handleChange} />
        </div>
      )}
    </>
  );
};
