import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GateEventTypeInternal, GateHistoryEntry } from '../../../../../../data-access/gql-types/graphql';
import { IconGateClose } from '../icons/close';
import { IconGateOpen } from '../icons/open';
import './index.scss';

type ComponentProps = {
  item: GateHistoryEntry;
  hasVentilation: boolean;
};

export const HistoryItem: React.FC<ComponentProps> = ({ item, hasVentilation }) => {
  const { t } = useTranslation('channel-details');

  const icon = useMemo(() => {
    switch (item.eventType) {
      case GateEventTypeInternal.SetClose:
        return <IconGateClose source={item.controlledBy.controlSource} />;
      case GateEventTypeInternal.SetTilt:
        return <IconGateClose source={item.controlledBy.controlSource} />;
      default:
        return <IconGateOpen source={item.controlledBy.controlSource} />;
    }
  }, [item.eventType]);

  return (
    <>
      <div className="gate-history-item">
        <div className="history-icon">{icon}</div>
        <div className="history-details">
          <p className="title">
            {`${t(
              `gateHistory.event.${
                item.eventType === GateEventTypeInternal.SetTilt
                  ? `${item.eventType}${hasVentilation ? '_VENTILATION' : ''}`
                  : item.eventType
              }`,
              {
                position: item.value !== undefined ? item.value : '?',
                time: item.value ? `(${item.value / 1000}s)` : '',
              },
            )} ${t(`gateHistory.controlledBy.${item.controlledBy.controlSource}`, {
              name: item.controlledBy.name || '-',
            })}`}
          </p>
          {item.occuredAt !== '1970-01-01T00:00:00.000Z' && (
            <p className="date">{item.occuredAt ? format(new Date(item.occuredAt), 'dd.MM.yy, HH:mm') : ''}</p>
          )}
        </div>
      </div>
      <hr />
    </>
  );
};
