import { Dictionary } from 'lodash';
import {
  AggregateVectorParameterTypeInternal,
  PeriodWeeklyPeriod,
  VectorMeasurementResponseItem,
} from '../../../../data-access/gql-types/graphql';

export const measurementNameParse = (measurement: string) => {
  return measurement.replace('_DAILY', '').replace('_WEEKLY', '').replace('_MONTHLY', '');
};

export const period1MeasurementByPeriod = {
  [PeriodWeeklyPeriod.Total]: 15,
  [PeriodWeeklyPeriod.Weekly]: 18,
  [PeriodWeeklyPeriod.Monthly]: 21,
  [PeriodWeeklyPeriod.Daily]: 24,
};

export const period2MeasurementByPeriod = {
  [PeriodWeeklyPeriod.Total]: 16,
  [PeriodWeeklyPeriod.Weekly]: 19,
  [PeriodWeeklyPeriod.Monthly]: 22,
  [PeriodWeeklyPeriod.Daily]: 25,
};

export const period3MeasurementByPeriod = {
  [PeriodWeeklyPeriod.Total]: 17,
  [PeriodWeeklyPeriod.Weekly]: 20,
  [PeriodWeeklyPeriod.Monthly]: 23,
  [PeriodWeeklyPeriod.Daily]: 26,
};

export const analizeAvailableMeasurementParameters = [
  AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
  AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
];

export const extractNumberFromMeasurement = (measurement: string) => {
  const match = measurement.match(/\d+$/);
  return match ? parseInt(match[0], 10) : null;
};

const orderParameterList = [10, 27, 28, 29, 11, 12, 13, 14, 15, 16, 17];

export const sortedParameters = (list: VectorMeasurementResponseItem[]) => {
  return [...list].sort((a, b) => {
    const aIncluded = orderParameterList.includes(a.type);
    const bIncluded = orderParameterList.includes(b.type);

    if (aIncluded && !bIncluded) return -1;
    if (!aIncluded && bIncluded) return 1;
    return 0;
  });
};

export const sortedSummary = (phaseMeasurementsGroupedByType: Dictionary<VectorMeasurementResponseItem[]>) => {
  return Object.entries({ ...phaseMeasurementsGroupedByType }).sort((a, b) => {
    const aIsPriority = orderParameterList.includes(parseInt(a[0]));
    const bIsPriority = orderParameterList.includes(parseInt(b[0]));

    if (aIsPriority && !bIsPriority) return -1;
    if (!aIsPriority && bIsPriority) return 1;
    return 0;
  });
};
