import React, { useMemo } from 'react';
import classNames from 'classnames';
import './index.scss';

type EcoBarProps = {
  value: number;
  maxValue: number;
  className?: string;
  color?: string;
  disabled?: boolean;
};

export const EcoBar: React.FC<EcoBarProps> = ({ value, maxValue, className, color, disabled }) => {
  const style = useMemo(() => {
    const width = maxValue ? (value / maxValue) * 100 : 0;
    const background = width > 0 && width <= 33 ? '#489B58' : width > 33 && width < 67 ? '#DEB342' : '#9F271F';
    return {
      '--width': `${width}%`,
      '--background': color || background,
    } as React.CSSProperties;
  }, [value, maxValue, color]);

  return (
    <div className={classNames('eco-bar', className, { disabled: disabled })}>
      <div className="eco-bar__element" style={style}></div>
    </div>
  );
};
