import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import {
  AddNewGroupErrorCode,
  IChannelsGroup,
  SetObjectsInGroupAndOrderResponseCode,
} from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { useBackdropContext } from '../../../hooks';
import { useExalusContext } from '../context';
import { useExalusServicesContext } from '../context/services';
import { ChannelsToGroupBody, CreateGroupNameBody, EditGroupNameBody, ExalusGroupsParams } from '../types/groups.types';

export const useExalusGroups = ({
  selectedExalusCredentials,
  connected,
  synchronized,
}: ExalusGroupsParams): UseQueryResult<IChannelsGroup[]> => {
  const { usersApi, groupsApi } = useExalusServicesContext();

  return useQuery<IChannelsGroup[]>(
    ['exalus-groups', selectedExalusCredentials?.id, synchronized],
    async () => {
      const loggedUser = await usersApi.GetUserAsync();
      if (!loggedUser || loggedUser.AccessLevel === AccessLevel.Guest) return [];

      return await groupsApi.GetGroupsAsync();
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusGroup = (id: string): UseQueryResult<IChannelsGroup | null> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { groupsApi } = useExalusServicesContext();

  return useQuery<IChannelsGroup | null>(
    ['exalus-group', id, selectedExalusCredentials?.id, synchronized],
    async () => {
      return groupsApi.GetGroupByGuid(id);
    },
    {
      enabled: !!id && connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusGroupChannels = (): UseQueryResult<IDeviceChannel[] | undefined> => {
  const { connected, selectedExalusCredentials, activeGroup, synchronized } = useExalusContext();
  const { groupsApi } = useExalusServicesContext();

  return useQuery<IDeviceChannel[] | undefined>(
    ['exalus-group-channels', activeGroup?.Guid, selectedExalusCredentials?.id, synchronized],
    async () => {
      const group = await groupsApi.GetGroupByGuid(activeGroup?.Guid || '');
      const channels = await group?.GetDevicesChannelsInGroupAsync(false);
      return channels;
    },
    {
      enabled: !!activeGroup?.Guid && connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusRemoveGroup = (): UseMutationResult<Status, unknown, string> => {
  const { groupsApi, configurationApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useMutation<Status, unknown, string>(
    ['exalus-remove-group'],
    async (groupId: string) => {
      turnOnBackdrop();
      await configurationApi.EnterConfigurationModeAsync();
      return await groupsApi.RemoveGroupByGuidAsync(groupId);
    },
    { onError: () => turnOffBackdrop() },
  );
};

export const useExalusAddGroup = (): UseMutationResult<
  IChannelsGroup | ResponseResult<AddNewGroupErrorCode>,
  unknown,
  CreateGroupNameBody
> => {
  const { groupsApi, configurationApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useMutation<IChannelsGroup | ResponseResult<AddNewGroupErrorCode>, unknown, CreateGroupNameBody>(
    ['exalus-add-group'],
    async ({ name, iconName }) => {
      turnOnBackdrop();
      await configurationApi.EnterConfigurationModeAsync();
      return await groupsApi.AddNewGroupWithIconAsync(name, iconName);
    },
    { onError: () => turnOffBackdrop() },
  );
};

export const useExalusEditGroup = (): UseMutationResult<
  ResponseResult<AddNewGroupErrorCode>,
  unknown,
  EditGroupNameBody
> => {
  const { groupsApi, configurationApi } = useExalusServicesContext();

  return useMutation<ResponseResult<AddNewGroupErrorCode>, unknown, EditGroupNameBody>(
    ['exalus-edit-group-name'],
    async ({ groupGuid, name, iconName }) => {
      await configurationApi.EnterConfigurationModeAsync();
      return await groupsApi.ChangeGroupNameAndIconByGuidAsync(groupGuid, name, iconName);
    },
  );
};

export const useExalusUpdateGroupWithChannels = (): UseMutationResult<
  ResponseResult<SetObjectsInGroupAndOrderResponseCode>,
  unknown,
  ChannelsToGroupBody
> => {
  const { groupsApi, configurationApi } = useExalusServicesContext();

  return useMutation<ResponseResult<SetObjectsInGroupAndOrderResponseCode>, unknown, ChannelsToGroupBody>(
    ['exalus-update-group-with-channels'],
    async (body) => {
      await configurationApi.EnterConfigurationModeAsync();
      const result = await groupsApi.SetObjectsInGroupAndOrderAsync(body.group, body.items);
      return result;
    },
  );
};
