import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateBox, Header, IconWarning, NavHeader, Page } from '../../components';
import { ChannelTypeInternal } from '../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../hooks';

type PropsInterface = {
  onChooseType: (type: ChannelTypeInternal) => void;
};

const ActionChannelsType: React.FC<PropsInterface> = ({ onChooseType }) => {
  const { channelTypesList } = useDevicesAndChannels({
    channelTypes: [
      ChannelTypeInternal.Switch,
      ChannelTypeInternal.Blind,
      ChannelTypeInternal.Light,
      ChannelTypeInternal.Gate,
    ],
  });
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');

  return (
    <Page
      header={
        <>
          <NavHeader />
          <Header title={t('basic.creatorHeaderStep1')} />
        </>
      }
    >
      {channelTypesList.length ? (
        channelTypesList.map((type, index) => (
          <div
            onClick={() => onChooseType(type as ChannelTypeInternal)}
            key={index}
            className="action-create-basic__type"
          >
            {tc(`typesPlural.${type}`)}
          </div>
        ))
      ) : (
        <EmptyStateBox content={t('basic.typeOfDevicesEmptyList')} icon={<IconWarning />} />
      )}
    </Page>
  );
};

export default ActionChannelsType;
