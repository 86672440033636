import React from 'react';
import { useTranslation } from 'react-i18next';
import { NativeContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { Integration, NavHeader, Page } from '../../../../components';
import { useNativeFunctionsContext } from '../../../../hooks/native/native-functions';
import { TutorialStep } from '../types';
import { TutorialStepItem } from './components/tutorial-step-item';
import './index.scss';
import { tutorialStepsWeb, tutorialStepsNative } from './steps';

type NotificationTutorialProps = {
  setOpen: (open: boolean) => void;
};

export const NotificationTutorial: React.FC<NotificationTutorialProps> = ({ setOpen }) => {
  const { t } = useTranslation('profile');
  const { nativeCtx } = useNativeFunctionsContext();

  const renderEnvironmentSteps = (steps: TutorialStep[]) =>
    steps
      .filter((x) => {
        return x.enabled !== undefined ? x.enabled === nativeCtx : true;
      })
      .map((step, index) => (
        <li key={index}>
          <TutorialStepItem index={index + 1} step={step} />
        </li>
      ));

  const renderInstructions = () => {
    switch (nativeCtx) {
      case NativeContext.None: {
        return renderEnvironmentSteps(tutorialStepsWeb);
      }
      case NativeContext.AndroidGecko:
      case NativeContext.IosWebkit: {
        return renderEnvironmentSteps(tutorialStepsNative);
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Page
      kind="above"
      contentClassName="notification__tutorial"
      whiteContent
      header={<NavHeader onClick={() => setOpen(false)} title={t('notificationIssues.instructionHeader')} />}
    >
      <div className="notification__tutorial--icon">
        <Integration />
      </div>
      <p className="notification__tutorial--header">{`${t('notificationIssues.instructionContent.commonMessage')}:`}</p>
      <ul>{renderInstructions()}</ul>
    </Page>
  );
};
