import { useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { ChannelsGetAllQueryVariables, Query } from '../../data-access/gql-types/graphql';
import { CHANNELS_GET_ALL } from '../../data-access/queries/channels';
import { ON_USES_DEPLETED } from '../../data-access/subscriptions/on-uses-depleted';
import { ChannelInterface, ChannelStateType } from '../../types';
import { parseChannel, parseChannelState } from '../../utils/channels/helpers';
import { useUpdateCommonLavvaState } from '../../utils/channels/subscriptions/common-lavva-state';
import { useChannelsState } from '../channels-state';
import { useDevicesAndChannelsContext } from '../devices-and-channels/provider';
import { useInstallation } from '../installation';

export const useChannelsGetAll = () => {
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { setChannelList } = useDevicesAndChannelsContext();
  const { setChannelState } = useChannelsState();
  const { usesDepleted } = useUpdateCommonLavvaState();
  const { data: channelsData, loading: channelsLoading } = useQuery<Query, ChannelsGetAllQueryVariables>(
    CHANNELS_GET_ALL,
    {
      variables: { installationId: selectedInstallationId },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      skip: skipLavvaFetch,
    },
  );
  const { data } = useSubscription(ON_USES_DEPLETED, {
    variables: { installationId: selectedInstallationId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (data) usesDepleted(data.onUsesDepleted);
  }, [data]);

  useEffect(() => {
    if (channelsData?.allUserChannels) {
      if (selectedInstallationId) {
        const newChannelList: ChannelInterface[] = [];
        let channelStates: Record<string, ChannelStateType> = {};

        channelsData.allUserChannels.forEach((userChannel) => {
          if (userChannel.installationId !== selectedInstallationId) return;
          const channel = parseChannel(userChannel);
          const state = parseChannelState(userChannel);

          if (channel) {
            newChannelList.push(channel);

            if (state) {
              channelStates = { ...channelStates, [channel.id]: state };
            }
          }
        });

        setChannelList(newChannelList);
        setChannelState(channelStates);
      }
    }
  }, [channelsData]);

  return {
    channelsLoading,
  };
};
