import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, IconAdd, Page, PopUpNav, Tabs } from '../../../../components';
import { IconDeviceList, IconEye, IconGridView, IconListView } from '../../../../components/popup-nav/icons';
import { Tip } from '../../../../components/tip';
import { LearningScenarios } from '../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../routes';
import { isLocalApp } from '../../../../utils/helpers/local-app';
import ExalusChannels from '../../components/channels';
import StateSyncButton from '../../components/state-sync-button';
import { useExalusStorageContext } from '../../context/storage';
import { useExalusChannelStateListChanged } from '../../hooks';
import { useLoggedUser } from '../../hooks/common/logged-user';
import { useChannels } from './hooks/use-channels';

const ChannelList: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('channel-list');
  const { t: td } = useTranslation('device-list');
  const { t: tdash } = useTranslation('dashboard');
  const { groupedChannels, isFetched, isLoading, tabs } = useChannels();
  const { activeChannelTabIndex, setActiveChannelTab, isChannelsListView, toggleChannelsListView } =
    useExalusStorageContext();
  const { canAddRemoveConfigureDevice, canDeviceEdit } = useLoggedUser();
  useExalusChannelStateListChanged();

  return (
    <Page
      isStickyHeader
      containerClassName="page__container--flex-height"
      carouselContent
      header={
        <>
          <Header title={t('channels')} installationLogoTitleHeight>
            <StateSyncButton />
            {canAddRemoveConfigureDevice && (
              <button onClick={() => history.push(ROUTES.AddExalusDevices())}>
                <IconAdd />
              </button>
            )}

            <PopUpNav
              links={[
                ...(canDeviceEdit && !isLocalApp
                  ? [
                      {
                        onClick: () => history.push(ROUTES.Device()),
                        label: t('devicesList'),
                        icon: <IconDeviceList />,
                      },
                      {
                        onClick: () => history.push(ROUTES.ChannelsVisibility()),
                        label: t('editVisibility'),
                        hasLineBelow: true,
                        icon: <IconEye />,
                      },
                    ]
                  : []),
                {
                  onClick: toggleChannelsListView,
                  label: isChannelsListView ? tdash('gridView') : tdash('listView'),
                  icon: isChannelsListView ? <IconGridView /> : <IconListView />,
                },
              ]}
            />
          </Header>
          <Tabs
            tabList={tabs}
            activeTabIndex={activeChannelTabIndex}
            setActiveTabIndex={setActiveChannelTab}
            tabsType="pills"
            isDivider={false}
            moreButton="normal"
          />
        </>
      }
    >
      <ExalusChannels itemGroupsList={groupedChannels} isFetched={isFetched} isLoading={isLoading} />
      <Tip tip={td('DeviceAddTip')} learningKey={LearningScenarios.AddDevice} point={<IconAdd />} top={40} right={68} />
    </Page>
  );
};

export default ChannelList;
