import React from 'react';
import c from 'classnames';
import './index.scss';

type IconPowerProps = {
  colorAccent?: boolean;
};

export const IconPower: React.FC<IconPowerProps> = ({ colorAccent }) => (
  <svg
    className={c('icon-power', {
      'icon-power--accent': colorAccent,
    })}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 3H11.5V13H13.5V3ZM18.335 5.165L16.92 6.58C18.49 7.865 19.5 9.81 19.5 12C19.5 15.865 16.365 19 12.5 19C8.635 19 5.5 15.865 5.5 12C5.5 9.81 6.51 7.865 8.08 6.58L6.665 5.165C4.735 6.815 3.5 9.26 3.5 12C3.5 16.97 7.53 21 12.5 21C17.47 21 21.5 16.97 21.5 12C21.5 9.26 20.265 6.815 18.335 5.165Z"
      fill="#15141D"
    />
  </svg>
);
