import { useTranslation } from 'react-i18next';
import { ErrorTypeMapping } from '../../types';
import { toastError } from '../../utils/toast';

export const useMutationErrors = <T extends keyof ErrorTypeMapping>(errorType: T) => {
  const { t: tr } = useTranslation('errors');

  const handleErrors = (errors: ErrorTypeMapping[T][]) => {
    if (errors.length > 0) {
      const errorItem = errors[0];

      toastError({
        content: errorItem.error
          ? tr(`${errorType}ErrorDictionary.${errorItem.error}`)
          : (errorItem as ErrorTypeMapping[T]).message,
      });
    }
  };

  return {
    handleErrors,
  };
};
