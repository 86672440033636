import { useMemo } from 'react';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { useExalusServicesContext } from '../../context/services';
import { useExalusUser } from '../users.hooks';

export const useLoggedUser = () => {
  const { devicesApi } = useExalusServicesContext();
  const { data: user, isLoading } = useExalusUser();

  const canAddRemoveConfigureDevice = useMemo(() => {
    if (user?.Guid) return devicesApi.CanAddAndRemoveAndConfigureDevices();
    return false;
  }, [user?.Guid]);

  const canDeviceEdit = useMemo(() => {
    if (user?.Guid) return devicesApi.CanEditDevicesInfo();
    return false;
  }, [user?.Guid]);

  const canAddEditGroup = useMemo(() => {
    if (user?.AccessLevel)
      return [AccessLevel.Support, AccessLevel.Admin, AccessLevel.Installator].includes(user.AccessLevel);
    return false;
  }, [user?.AccessLevel]);

  const hasAccessToScenarios = useMemo(() => {
    if (user?.AccessLevel)
      return [AccessLevel.Support, AccessLevel.Admin, AccessLevel.Installator].includes(user.AccessLevel);
    return false;
  }, [user?.AccessLevel]);

  const hasAccessToControllerSettings = useMemo(() => {
    if (user?.AccessLevel)
      return [AccessLevel.Support, AccessLevel.Admin, AccessLevel.Installator].includes(user.AccessLevel);
    return false;
  }, [user?.AccessLevel]);

  const canCreateEditRemoveAndSeeUserByAccessLevel = (accessLevel: AccessLevel) => {
    if (user?.AccessLevel) return accessLevel <= user.AccessLevel;
    return false;
  };

  return {
    user,
    isLoading,
    canAddRemoveConfigureDevice,
    canDeviceEdit,
    canAddEditGroup,
    hasAccessToScenarios,
    hasAccessToControllerSettings,
    canCreateEditRemoveAndSeeUserByAccessLevel,
  };
};
