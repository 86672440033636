import React, { ChangeEvent } from 'react';
import { ControlWrapper, LightSlider, SubmitButton } from '../../../../components';

type ComponentProps = {
  brightness: number;
  handleChangeBrightness: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
};

export const DimChannelSettings: React.FC<ComponentProps> = ({ brightness, handleChangeBrightness, handleSubmit }) => (
  <>
    <ControlWrapper>
      <LightSlider value={brightness} onChange={handleChangeBrightness} />
    </ControlWrapper>
    <SubmitButton onClick={handleSubmit} />
  </>
);
