import React from 'react';
import { ChannelIcon } from '../../../../components';
import { TriggerActionRequestInput } from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../hooks';

type ChannelItemProps = {
  action: TriggerActionRequestInput;
  isOn: boolean;
  details: React.ReactNode;
};

export const ActionItem: React.FC<ChannelItemProps> = ({ action, isOn, details }) => {
  const { channel } = useDevicesAndChannels({ channelId: action.id });

  if (!channel) return null;

  return (
    <div className="action-condition">
      <div className="icon-wrapper">
        <ChannelIcon channel={channel} statusOn={isOn} />
      </div>
      <div className="action-condition__content">
        <h4 className="action-condition__title">{channel.alias || ''}</h4>
        <p className="action-condition__text">{details}</p>
      </div>
    </div>
  );
};
