import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ProductType,
  SupportedDevice,
} from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { uniq } from 'lodash';
import { useSupportedDevices } from '../../../../hooks/supported-devices/supported-devices';
import { pathnameWithParameters } from '../../../../utils/location';

export const useStore = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { t } = useTranslation('store');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { data, isLoading } = useSupportedDevices();

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, [search]);

  const sortedChannelTypes = [
    ProductType.BlindController,
    ProductType.FacadeBlindController,
    ProductType.SwitchController,
    ProductType.LightController,
    ProductType.Cameras,
    ProductType.HeatingController,
    ProductType.Meters,
    ProductType.Intercoms,
    ProductType.GateController,
    ProductType.GatewayController,
    ProductType.DoorController,
    ProductType.Sensor,
    ProductType.Remote,
    ProductType.Unknown,
  ];

  const allTypes = useMemo(() => {
    if (!data) return [];
    return uniq(data.flatMap((device: SupportedDevice) => device.ProductTypes)).sort((a, b) => a - b);
  }, [data]);

  const tabs = useMemo(() => {
    return allTypes
      .sort(
        (a: number, b: number) =>
          sortedChannelTypes.findIndex((f) => f === a) - sortedChannelTypes.findIndex((f) => f === b),
      )
      .map((type) => ({
        label: t(`types.${ProductType[type]}`),
        data: type,
      }));
  }, [allTypes, t]);

  const selectedTabType = useMemo(() => {
    return tabs.length ? (tabs[activeTabIndex].data as ProductType) : null;
  }, [tabs, activeTabIndex]);

  const filterDevicesByType = (includeSelected: boolean) => {
    if (selectedTabType === null || !data) return [];
    return data.filter((device) =>
      includeSelected ? device.ProductTypes.includes(selectedTabType) : !device.ProductTypes.includes(selectedTabType),
    );
  };

  const channelsPerSelectedType = useMemo(() => filterDevicesByType(true), [data, selectedTabType]);
  const channelsSeeMore = useMemo(() => filterDevicesByType(false), [data, selectedTabType]);

  const handleChangeTab = (activeTabIndex: number) => {
    history.replace(
      pathnameWithParameters(pathname, search, {
        key: 'tab',
        value: activeTabIndex,
      }),
    );
  };

  return {
    data,
    isLoading,
    activeTabIndex,
    tabs,
    channelsPerSelectedType,
    channelsSeeMore,
    handleChangeTab,
  };
};
