import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconDelay } from '../../../../../../components';
import { ColorTimeDialog } from '../../../../components/color-time-dialog';
import './index.scss';

type RgbSceneFormSetColorTimeProps = {
  colorTimeValue: number;
  setColorTimeValue: (value: number) => void;
};

export const RgbSceneFormSetColorTime: React.FC<RgbSceneFormSetColorTimeProps> = ({
  colorTimeValue,
  setColorTimeValue,
}) => {
  const { t } = useTranslation('channel-details');
  const [showColorTimeDialog, setShowColorTimeDialog] = useState<boolean>(false);

  return (
    <>
      <div className="set-color-time-wrapper" onClick={() => setShowColorTimeDialog(true)}>
        <div className="set-color-time-wrapper__icon">
          <IconDelay width={32} height={32} />
        </div>
        <div className="set-color-time-wrapper__value">{`${colorTimeValue} ${t('rgbScenes.secondsUnitShort')}`}</div>
      </div>

      <ColorTimeDialog
        open={showColorTimeDialog}
        setOpen={setShowColorTimeDialog}
        title={t('rgbScenes.setColorTimeHeading')}
        onSave={setColorTimeValue}
        time={colorTimeValue}
      />
    </>
  );
};
