import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  ChangeUserRoleMutation,
  ChangeUserRoleMutationVariables,
  Query,
  RemoveUserFromInstallationMutation,
  RemoveUserFromInstallationMutationVariables,
  UserSharingInfoQueryVariables,
} from '../../../data-access/gql-types/graphql';
import { REMOVE_USER_FROM_INSTALLATION } from '../../../data-access/mutations/installations';
import { CHANGE_USER_ROLE } from '../../../data-access/mutations/share';
import { USER_SHARING_INFO } from '../../../data-access/queries/share';
import { useBackdropContext, useProfile } from '../../../hooks';
import { useMutationErrors } from '../../../hooks/backend-errors/use-mutation-errors';
import { MutationErrorType } from '../../../types';
import { toastError, toastSuccess } from '../../../utils/toast';
import { useShareInstallationContext } from '../context';

export const useMember = (fetchUserSharing?: boolean) => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { state } = useShareInstallationContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Invitation);
  const { refetchUserMe } = useProfile();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    data: userSharing,
    loading: userSharingLoading,
    refetch: refetchSharingInfo,
  } = useQuery<Query, UserSharingInfoQueryVariables>(USER_SHARING_INFO, {
    variables: { userId: state.userId, installationId: state.installationId },
    fetchPolicy: 'no-cache',
    skip: !state.installationId || !state.userId || !fetchUserSharing,
  });
  const [changeUserRole, { loading: changeRoleLoading }] = useMutation<
    ChangeUserRoleMutation,
    ChangeUserRoleMutationVariables
  >(CHANGE_USER_ROLE);
  const [removeUserFromInstallation, { loading: removeUserFromInstallationLoading }] = useMutation<
    RemoveUserFromInstallationMutation,
    RemoveUserFromInstallationMutationVariables
  >(REMOVE_USER_FROM_INSTALLATION);

  const changeRole = () => {
    turnOnBackdrop();
    changeUserRole({
      variables: {
        input: {
          userId: state.userId,
          installationId: state.installationId,
          accessType: state.permission,
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (!data || !data.changeUserRole) {
          toastError({ content: tc('errors.somethingWentWrong') });
          return;
        }

        if (data.changeUserRole?.result?.ok) {
          toastSuccess({ content: t('shared_user.change_role_success') });
          refetchUserMe();
          history.goBack();
        } else {
          if (data.changeUserRole?.errors) {
            handleErrors(data.changeUserRole.errors || []);
          }
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  const deleteUserFromInstallation = (userId: string, installationId: string, callback: () => void) => {
    turnOnBackdrop();
    removeUserFromInstallation({
      variables: {
        input: {
          userId,
          installationId,
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        callback();
        if (!data || !data.removeUserFromInstallation) {
          toastError({ content: tc('errors.somethingWentWrong') });
          return;
        }

        if (data.removeUserFromInstallation.result?.ok) {
          toastSuccess({ content: t('shared_user.remove_user_success') });
        } else {
          handleErrors(data.removeUserFromInstallation.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  return {
    changeRole,
    changeRoleLoading,
    deleteUserFromInstallation,
    removeUserFromInstallationLoading,
    userSharing,
    userSharingLoading,
    refetchSharingInfo,
  };
};
