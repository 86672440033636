import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelBox } from '../../../components';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { Calibration } from '../../device-settings/device-settings-form/sections';
import Info from '../components/info';
import { InputsType } from '../components/inputs-type';
import SwapIn from '../components/swap-in';
import { useConfiguration } from '../hooks/use-configuration';
import { InfoPageType } from '../types';

type ComponentProps = {
  setInfoPage: Dispatch<SetStateAction<InfoPageType>>;
};

const CoverConfiguration: React.FC<ComponentProps> = ({ setInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channel } = useDevicesAndChannelsContext();
  const { swapOut } = useConfiguration({ channel });

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => setInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      <div className="row_container max-width-desktop">
        <button className="button button--secondary" onClick={swapOut}>
          {t('reverseOutputs')}
        </button>
      </div>
      <div className="row_container margin_top">
        <span>{t('reverseOutputsContent')}</span>
      </div>
      <hr />
      <Info title={t('calibration')} onClick={() => setInfoPage(InfoPageType.CALIBRATION)} />
      <Calibration channel={channel} />
      <hr />
      <Info title={t('inService')} onClick={() => setInfoPage(InfoPageType.INSERVICE)} />
      <SwapIn channel={channel} />
      <InputsType setInfoPage={setInfoPage} isUnderlineUp />
    </>
  );
};

export default CoverConfiguration;
