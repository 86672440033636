import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/IRob21ConfigService';
import { TypeOfGateChannel } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigParams';
import { Rob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { GateModeParams } from '../../../device-details/types';

type ComponentProps = {
  device: IDevice;
  workingMode: TypeOfGateChannel | null;
  channel: IDeviceChannel;
};

const WorkingModeForm: React.FC<ComponentProps> = ({ device, workingMode, channel }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<GateModeParams>();

  const { mode } = form.watch();

  useEffect(() => {
    if (workingMode !== null) form.setValue('mode', workingMode);
  }, [workingMode]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRob21ConfigService>(
      Rob21ConfigService.ServiceName,
    );

    const result = await configService.SetGateModeAsync(device, channel.Number, values.mode);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  const onChangeMode = (value: TypeOfGateChannel) => form.setValue('mode', value);

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeMode}
          value={mode}
          options={[
            { label: t('exalus.params.Rob21Params.WorkingModeSteps.Gate'), value: TypeOfGateChannel.Gate },
            { label: t('exalus.params.Rob21Params.WorkingModeSteps.Gateway'), value: TypeOfGateChannel.Gateway },
            { label: t('exalus.params.Rob21Params.WorkingModeSteps.Monostable'), value: TypeOfGateChannel.Monostable },
          ]}
          label={t('exalus.params.Rob21Params.WorkingMode')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default WorkingModeForm;
