import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { IconChevron } from '../icons';
import './index.scss';

type CommonProps = {
  title: string;
  children: React.ReactNode;
  className?: string;
  hideExpandedBorder?: boolean;
  expanded?: boolean;
  selectedItems?: number;
  totalItems?: number;
  titleNotbold?: boolean;
  titleIcon?: React.ReactNode;
};

type CustomAccordionProps = CommonProps & {
  kind: 'custom';
};

type ControlledAccordionProps = CommonProps & {
  kind: 'controlled';
  handleExpand: (value: number | null) => void;
  position: number;
};

type ControlledBaseAccordionProps = CommonProps & {
  kind: 'controlled-base';
  handleExpand: (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void;
};

type AccordionProps = CustomAccordionProps | ControlledAccordionProps | ControlledBaseAccordionProps;

export const Accordion: React.FC<AccordionProps> = (props) => {
  const {
    kind,
    title,
    children,
    className,
    selectedItems,
    totalItems,
    hideExpandedBorder = false,
    expanded = false,
    titleNotbold = false,
    titleIcon = null,
  } = props;
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(expanded);

  const summaryTotal = useMemo(
    () =>
      selectedItems && totalItems
        ? `${selectedItems ? '(' : ''}${selectedItems}/${totalItems}${selectedItems ? ')' : ''}`
        : '',
    [selectedItems, totalItems],
  );

  useEffect(() => {
    setAccordionExpanded(expanded);
  }, [expanded]);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => {
    setAccordionExpanded(!accordionExpanded);

    if (kind === 'controlled') {
      props.handleExpand(!accordionExpanded ? props.position : null);
    }

    if (kind === 'controlled-base') {
      props.handleExpand(event, expanded);
    }
  };

  return (
    <MuiAccordion
      onChange={handleChange}
      disableGutters
      elevation={0}
      square
      expanded={accordionExpanded}
      defaultExpanded={expanded}
      classes={{
        root: classNames('accordion', {
          'accordion--hide-border': accordionExpanded && hideExpandedBorder,
          [className as string]: className,
        }),
      }}
    >
      <MuiAccordionSummary
        classes={{
          root: 'accordion__header',
          content: classNames('accordion__header-content', { 'not-bold': titleNotbold }),
          expandIconWrapper: 'accordion__expand-icon',
        }}
        expandIcon={<IconChevron direction={'down'} />}
      >
        <h3>
          <span {...(titleIcon ? { className: 'with-icon' } : {})}>
            {title} {summaryTotal}
          </span>
          {titleIcon}
        </h3>
      </MuiAccordionSummary>
      <MuiAccordionDetails
        classes={{
          root: 'accordion__content-wrapper',
        }}
      >
        <div className="accordion__content">{children}</div>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};
