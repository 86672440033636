import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dialog } from '../../../../components/dialog/base';
import { convertMSToMinutesSeconds, convertMinutesToSeconds } from '../../../../utils/helpers';

type SetActivationTimeDialogProps = {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  onSave: (data: number) => void;
  miliseconds: number;
};

export const SetActivationTimeDialog: React.FC<SetActivationTimeDialogProps> = ({
  title,
  open,
  setOpen,
  onSave,
  miliseconds,
}) => {
  const { t: tc } = useTranslation('common');
  const { minutes, seconds } = convertMSToMinutesSeconds(miliseconds);
  const [selectedMinutes, setSelectedMinutes] = useState<number>(minutes);
  const [selectedSeconds, setSelectedSeconds] = useState<number>(seconds);
  const minutesValues = Array.from(Array(60).keys());
  const secondsValues = Array.from(Array(60).keys());

  const onClose = useCallback(() => setOpen(false), []);

  const onSubmit = useCallback(() => {
    const seconds = convertMinutesToSeconds({ minutes: selectedMinutes, seconds: selectedSeconds });
    onSave(seconds * 1000);
    onClose();
  }, [selectedMinutes, selectedSeconds]);

  return (
    <Dialog setShow={setOpen} show={open} className="dialog--full-width">
      <div className="dialog-time-picker dialog-time-picker--font-small">
        <div className="dialog-time-picker__header">
          <h3 className="dialog-time-picker__heading">{title}</h3>
        </div>
        <div className="dialog-time-picker__time-wrapper">
          <div className="time-picker-wrapper">
            <div className="picker">
              <Swiper
                initialSlide={Number(selectedMinutes)}
                slidesPerView={3}
                direction="vertical"
                grabCursor
                centeredSlides
                loop
                slideToClickedSlide
                onSlideChange={(e) => {
                  setSelectedMinutes(e.realIndex);
                }}
              >
                {minutesValues.map((minute) => {
                  return (
                    <SwiperSlide key={minute}>
                      <div className="time">{('' + minute).padStart(2, '0')}</div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="picker-divider">:</div>
            <div className="picker">
              <Swiper
                initialSlide={Number(selectedSeconds)}
                slidesPerView={3}
                direction="vertical"
                grabCursor
                centeredSlides
                loop
                slideToClickedSlide
                onSlideChange={(e) => {
                  setSelectedSeconds(e.realIndex);
                }}
              >
                {secondsValues.map((second) => {
                  return (
                    <SwiperSlide key={second}>
                      <div className="time">{('' + second).padStart(2, '0')}</div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="dialog-time-picker__buttons">
          <button className="dialog-time-picker__button" onClick={onClose}>
            {tc('buttons.cancel')}
          </button>
          <button onClick={onSubmit} className="dialog-time-picker__button dialog-time-picker__button--active">
            {tc('buttons.save')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
