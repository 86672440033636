import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconMicrophoneProps = {
  active?: boolean;
};

export const IconMicrophone: React.FC<IconMicrophoneProps> = ({ active }) => {
  return (
    <svg
      className={classNames('icon-microphone', { active: active })}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.75 21.8115C11.75 22.2257 12.0858 22.5615 12.5 22.5615C12.9142 22.5615 13.25 22.2257 13.25 21.8115H11.75ZM11.75 18.0107V21.8115H13.25V18.0107H11.75Z"
        fill="#0A0203"
      />
      <path
        d="M16.75 7.01074V11.0107C16.75 13.358 14.8472 15.2607 12.5 15.2607C10.1528 15.2607 8.25 13.358 8.25 11.0107V7.01074C8.25 4.66353 10.1528 2.76074 12.5 2.76074C14.8472 2.76074 16.75 4.66353 16.75 7.01074Z"
        stroke="#0A0203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 10.0107V10.7407C20.5 15.159 16.9183 18.7407 12.5 18.7407V18.7407C8.08172 18.7407 4.5 15.159 4.5 10.7407V10.0107"
        stroke="#0A0203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
