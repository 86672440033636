import { TariffHoursRange } from '../../../../../api/modules/optimizer/optimizer.types';
import { AggregateVectorParameterTypeInternal } from '../../../../../data-access/gql-types/graphql';
import { getSelectedTheme } from '../../../../../utils/theme';

const isDark = getSelectedTheme() === 'dark';

export const parameterColor = {
  [AggregateVectorParameterTypeInternal.ForwardActiveEnergy]: '#FF4016',
  [AggregateVectorParameterTypeInternal.ReverseActiveEnergy]: '#00B191',
  [AggregateVectorParameterTypeInternal.ForwardReactiveEnergy]: '#9c27b0',
  [AggregateVectorParameterTypeInternal.ReverseReactiveEnergy]: '#FCAF00',
  [AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy]: '#2196f3',
  [AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice]: isDark ? 'F7F3E4' : '#452929',
  [AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy]: isDark ? '#EDECE8' : '#242423',
  [AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy]: isDark ? 'F7F3E4' : '#452929',
};

export const sortedChartsParameters = [
  AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
  AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
  AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
];

export const predictionParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
];

export const clearMergeParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
  AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
];

export const lineChartParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
  AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice,
];

export const summaryExludedParameters = [
  AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.PredictionVectorBalancedActiveEnergy,
];

export const noPredictionValues = [
  AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
  AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
  AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
  AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
];

export const getWeekDate = (startDate: Date, index: number) => {
  const startOfWeek = new Date(startDate);

  const currentDay = new Date(startOfWeek);
  currentDay.setDate(startOfWeek.getDate() + index);

  return currentDay;
};

export const getMonday = (date = new Date()) => {
  const dayOfWeek = date.getDay();
  const diffToMonday = (dayOfWeek + 6) % 7;
  const lastMonday = new Date(date);
  lastMonday.setDate(date.getDate() - diffToMonday);
  return lastMonday;
};

export const getPricingTier = (currentHour: number, timeRanges: TariffHoursRange[]) => {
  for (const range of timeRanges) {
    if (range.from <= range.to) {
      // Normal range (e.g., 13 to 16)
      if (currentHour >= range.from && currentHour < range.to) {
        return range.pricingTier;
      }
    } else {
      // Overnight range (e.g., 22 to 7)
      if (currentHour >= range.from || currentHour < range.to) {
        return range.pricingTier;
      }
    }
  }
  return 2; // Default pricingTier when no match is found
};
