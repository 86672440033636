import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceTaskType as ChannelTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import LavvaDetailsWrapper from '../../../../../../components/details-wrapper';
import { PICKER_COLOR_LIST } from '../../../../../../const';
import { hsbToRgb, rgbToHsb } from '../../../../../../utils/helpers';
import ColorComponent from '../../../../../channel-details/light/components/rgb/color';
import RgbPicker from '../../../../../channel-details/light/components/rgb/picker';
import TemperatureComponent from '../../../../../channel-details/light/components/rgb/temperature';
import { canChannelDo } from '../../../../utils';
import { useRgbControl } from '../../hooks/use-rgb-control';
import { OnOffControls } from '../switch/components/on-off-controls';

type RgbDetailsControlsProps = {
  channel: IDeviceChannel;
  active: boolean;
};

const RgbDetailsControls: React.FC<RgbDetailsControlsProps> = ({ channel, active }) => {
  const { t } = useTranslation('channel-details');
  const [sliderColor, setSliderColor] = useState('');
  const [activeColor, setActiveColor] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [rgbValue, setRgbValue] = useState('');
  const [brightness, setBrightness] = useState(100);
  const [temperature, setTemperature] = useState(0);
  const [hue, setHue] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [preventSwipeViews, setPreventSwipeViews] = useState(false);
  const { state, isOn, handleControlRgb, handleControlBrightness } = useRgbControl(channel);
  const tabs = [{ label: t('rgb') }, { label: t('color') }, { label: t('white') }];

  useEffect(() => {
    if (state && !initialized) {
      setInitialized(true);
      setBrightness(state.Brightness);
      const hsb = rgbToHsb([state.R.toString(), state.G.toString(), state.B.toString()]);
      setRgbValue(`${state.R},${state.G},${state.B}`);
      setSliderColor(hsbToRgb([hsb[0], hsb[1] / 100, 1]).join(','));
      setActiveColor(hsbToRgb([hsb[0], hsb[1] / 100, state.Brightness / 100]).join(','));

      if (state.R === 255 && state.G === 255 && state.B === 255) setTemperature(45);
      if (state.R === 255 && state.G === 255 && state.B !== 255) {
        setTemperature(Math.round((38 * state.B) / 255));
      }
      if (state.R !== 255 && state.G === 255 && state.B === 255) {
        setTemperature(Math.round((54 * 255) / state.R));
      }
    }
  }, [state?.Brightness, state?.R, state?.G, state?.B, initialized]);

  useEffect(() => {
    const timeout = setTimeout(() => setPreventSwipeViews(false), 950);
    return () => clearTimeout(timeout);
  }, [activeColor]);

  const handleBrightness = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => {
      if (state) {
        const brightness = Number((event.target as HTMLInputElement).value);

        setBrightness(brightness);
        handleControlBrightness(brightness);

        const hsb = rgbToHsb([state.R.toString(), state.G.toString(), state.B.toString()]);
        const h = hue ? hue : hsb[0];
        const s = saturation ? saturation : hsb[1] / 100;
        setActiveColor(hsbToRgb([h, s, brightness / 100]).join(','));
      }
    },
    [hue, saturation, state],
  );

  const handleTemperature = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => {
      const value = Number((event.target as HTMLInputElement).value);
      setTemperature(value);
      let rgb: string;

      if (value <= 38) rgb = `255,255,${Math.round((value * 255) / 38)}`;
      else if (value > 38 && value < 54) rgb = '255,255,255';
      else rgb = `${Math.round((54 * 255) / value)},255,255`;

      setRgbValue(rgb);
      setActiveColor(rgb);
      setSliderColor(rgb);
      handleControlRgb(rgb, brightness);
    },
    [brightness],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPreventSwipeViews(true);
      setBrightness(Number(event.target.value));
    },
    [setBrightness],
  );

  const onChangeTemperature = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      setTemperature(value);
      let rgb: string;

      if (value <= 38) {
        rgb = `255,255,${Math.round((value * 255) / 38)}`;
      } else if (value > 38 && value < 54) {
        rgb = '255,255,255';
      } else {
        rgb = `${Math.round((54 * 255) / value)},255,255`;
      }

      setRgbValue(rgb);
      setActiveColor(rgb);
      setSliderColor(rgb);
    },
    [brightness],
  );

  const handleColorPicked = useCallback(
    (hue, saturation) => {
      const brightnessVal = brightness || 100;
      const rgb = hsbToRgb([hue, saturation, 1]).join(',');

      handleControlRgb(rgb, brightnessVal);
      setBrightness(brightnessVal);
      setActiveColor(rgb);
      setSliderColor(hsbToRgb([hue, saturation, 1]).join(','));
      setHue(hue);
      setSaturation(saturation);
    },
    [brightness],
  );

  const showLightSlider = useMemo(() => canChannelDo(channel, [ChannelTaskType.SetLightBrightness]), [channel.States]);

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={preventSwipeViews}
      tabs={tabs}
      additionalTabs={[
        <ColorComponent
          key={2}
          rgbValue={rgbValue}
          handleChange={handleChange}
          brightness={brightness}
          handleBrightness={handleBrightness}
          handleControlRgb={handleControlRgb}
          colors={PICKER_COLOR_LIST}
          darkBorder
        />,
        <TemperatureComponent
          key={3}
          temperature={temperature}
          onChangeTemperature={onChangeTemperature}
          handleChange={handleChange}
          brightness={brightness}
          handleBrightness={handleBrightness}
          handleTemperature={handleTemperature}
        />,
      ]}
    >
      <RgbPicker
        rgbValue={rgbValue}
        handleChange={handleChange}
        handleColorPicked={handleColorPicked}
        {...(showLightSlider ? { brightness } : {})}
        handleBrightness={handleBrightness}
        sliderColor={sliderColor}
        isOn={isOn}
        activeColor={activeColor}
        pickerCircleRadius={24}
        innerRadius={70}
        setPreventSwipeViews={setPreventSwipeViews}
      >
        <OnOffControls channel={channel} active={active} />
      </RgbPicker>
    </LavvaDetailsWrapper>
  );
};

export default RgbDetailsControls;
