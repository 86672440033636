import React from 'react';
import './index.scss';

type IconEcoProps = {
  size?: number;
  style?: React.CSSProperties;
};

export const IconEco: React.FC<IconEcoProps> = ({ size = 24, style }) => (
  <svg
    className="icon-eco"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M17.639 16.726C14.7405 19.6479 10.0672 19.6588 7.19777 16.7663C4.32494 13.8703 4.336 9.14885 7.23775 6.2237C7.7049 5.75278 8.42457 5.34297 9.35301 5.0018C10.2736 4.66352 11.3498 4.41058 12.4819 4.22448C14.7463 3.85223 17.159 3.75923 18.8401 3.75002C19.6362 3.74566 20.2683 4.38477 20.2498 5.20294C20.212 6.87486 20.0798 9.26197 19.6787 11.5048C19.4783 12.626 19.2137 13.6933 18.8685 14.6088C18.5205 15.532 18.1083 16.2529 17.639 16.726Z"
      stroke="#FF4016"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M16.1751 7.72766L4.08594 19.9143M9.46861 14.4882L9.16202 8.99833M12.2622 11.6722L16.9635 11.8978"
      stroke="#FF4016"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7.25714 16.7429L2.5 21.5"
      stroke="#FF4016"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
