import { CorrectionSteps } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { BlindWorkStats } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/VersionedOptions/^4.0.0.0/ISsrVersionedService';

export enum SSRParamsEnum {
  CORRECTION_STEPS = 'CorrectionSteps',
  HEAD_SENSOR = 'HeadSensor',
  WORK_STATISTICS = 'WorkStatistics',
  DIRECTIONS = 'Directions',
}

export type SSRConfigParams = {
  [SSRParamsEnum.CORRECTION_STEPS]: CorrectionSteps | null;
  [SSRParamsEnum.HEAD_SENSOR]: boolean | null;
  [SSRParamsEnum.WORK_STATISTICS]: BlindWorkStats | null;
  [SSRParamsEnum.DIRECTIONS]: boolean | null;
};

export const initialConfig: SSRConfigParams = {
  [SSRParamsEnum.CORRECTION_STEPS]: null,
  [SSRParamsEnum.HEAD_SENSOR]: null,
  [SSRParamsEnum.WORK_STATISTICS]: null,
  [SSRParamsEnum.DIRECTIONS]: null,
};
