import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { BatteryDeviceState, BatteryStateEnum } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { Battery } from '../../../../../components/icons/device/exalus/battery';
import { IconToastWarning2 } from '../../../../../components/icons/icon-toast-warning-2';
import { canChannelReturnAny } from '../../../utils';
import './index.scss';

type ComponentProps = {
  channel: IDeviceChannel;
};

export const PowerState: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');

  const battery = useMemo(() => {
    const batteryState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.BatteryState,
    ) as BatteryDeviceState;

    if (batteryState) return batteryState.Data;
    if (canChannelReturnAny(channel, [ChannelResponseType.BatteryState])) {
      return { State: BatteryStateEnum.Unknown, Percentage: 0 };
    }
    return null;
  }, [channel?.States]);

  const isLowBatteryState = useMemo(() => {
    return battery?.State === BatteryStateEnum.Low || battery?.State === BatteryStateEnum.Empty;
  }, [battery]);

  return (
    <>
      {battery && (
        <div className={classNames('power-state', { error: isLowBatteryState })}>
          <div className="power-state--left">
            <Battery state={battery.State} />
            <p>{t(`exalus.batteryState.${battery.State}`, { percentage: battery.Percentage })}</p>
          </div>
          {isLowBatteryState && <IconToastWarning2 colorError size={16} />}
        </div>
      )}
    </>
  );
};
