import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { RemoteButtonStateEnum } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { InputSelect } from '../../../../../../components';
import { useActionParameterOptions } from '../../utils/use-action-parameter-options';

type ComponentProps = {
  triggerState: RemoteButtonStateEnum;
  setTriggerState: Dispatch<SetStateAction<RemoteButtonStateEnum>>;
};

export const RemoteTriggerState: React.FC<ComponentProps> = ({ triggerState, setTriggerState }) => {
  const { t } = useTranslation('action');
  const { stateDeviceTriggerOptions } = useActionParameterOptions();

  const onChangeDeviceTriggerState = (state: RemoteButtonStateEnum) => setTriggerState(state);

  return (
    <InputSelect
      options={stateDeviceTriggerOptions}
      value={triggerState}
      onChange={onChangeDeviceTriggerState}
      label={t('action.create.conditions.sources.schedule.triggerMethod')}
    />
  );
};
