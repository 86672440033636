import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ControlWrapper,
  IconInitiate,
  InitiateButton,
  SubmitButton,
  TimeActivationButton,
} from '../../../../../components';
import { defaultFavouriteSetOnTimeList } from '../../../../../const';
import {
  ChannelActionParameterInput,
  ChannelTypeInternal,
  TriggerActionRequestInput,
  TriggerActionType,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface, ChannelLightInterface } from '../../../../../types';
import { parseFavouritePositions } from '../../../../../utils/channels/helpers';
import { useTriggerFormContext } from '../../../context';
import { useOnOffControl } from '../helpers/use-control';

type LightSetupProps = {
  setAction: (value: TriggerActionRequestInput) => void;
  channel: ChannelInterface;
  defaultParameters: TriggerActionRequestInput | undefined;
};

const LightSetup: React.FC<LightSetupProps> = ({ setAction, channel, defaultParameters }) => {
  const { t } = useTranslation('channel-details');
  const [ChannelParameters, setChannelParameters] = useState<{ [key: string]: ChannelActionParameterInput }>({});
  const [statusOn, setStatusOn] = useState<boolean>(false);
  const [activeOnTime, setActiveOnTime] = useState<number | null>(null);
  const { setOff, setOn } = useOnOffControl(ChannelParameters);
  const { updateAction } = useTriggerFormContext();

  useEffect(() => {
    if (defaultParameters) {
      switch (defaultParameters.actionType) {
        case TriggerActionType.SetLightState: {
          setStatusOn(defaultParameters?.booleanValue ?? false);
          setChannelParameters(defaultParameters?.booleanValue ? setOn() : setOff());
          break;
        }
        case TriggerActionType.SetLightSetOnTime: {
          setStatusOn(true);
          setChannelParameters(setOn());
          setActiveOnTime(defaultParameters.integerValue || 0);
          break;
        }
      }
    }
  }, [setStatusOn]);

  const handleControlOn = useCallback(
    (value: boolean) => {
      setChannelParameters(value ? setOn() : setOff());
      setStatusOn(value);
    },
    [setOn, setStatusOn],
  );

  const favouriteSetOnTimeList = useMemo(() => {
    if (channel.data.type === ChannelTypeInternal.Light) {
      return parseFavouritePositions(channel.data.favouritePositionList, defaultFavouriteSetOnTimeList, false);
    }

    return defaultFavouriteSetOnTimeList;
  }, [(channel.data as ChannelLightInterface).favouritePositionList]);

  const onSubmit = useCallback(() => {
    const data = {
      id: channel.id,
      actionType: activeOnTime ? TriggerActionType.SetLightSetOnTime : TriggerActionType.SetLightState,
      ...(activeOnTime ? { integerValue: activeOnTime } : { booleanValue: statusOn }),
    };

    updateAction(channel.id, data);
    setAction(data);
  }, [ChannelParameters, activeOnTime, statusOn]);

  const onFavouriteClick = useCallback(
    (value) => {
      if (activeOnTime === value) {
        setActiveOnTime(null);
      } else {
        setActiveOnTime(value);
        setChannelParameters(setOn());
        setStatusOn(true);
      }
    },
    [statusOn, setStatusOn, favouriteSetOnTimeList, activeOnTime],
  );

  return (
    <>
      <ControlWrapper>
        <InitiateButton isOn={statusOn} onClickControl={handleControlOn}>
          <IconInitiate />
        </InitiateButton>
      </ControlWrapper>
      <div className="p-l-24 p-r-24">
        <h3 className="m-t-16 m-b-16">{t('timeActivation')}</h3>
        <div className="favourite">
          <div className="favourite__buttons favourite__buttons--switch">
            {favouriteSetOnTimeList.map((value, index) => {
              return (
                <TimeActivationButton
                  key={index}
                  value={value}
                  onClick={() => onFavouriteClick(value)}
                  active={activeOnTime === value}
                />
              );
            })}
          </div>
        </div>
      </div>
      <SubmitButton onClick={onSubmit} />
    </>
  );
};

export default LightSetup;
