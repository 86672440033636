import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconEdit, IconPhoto } from '../../../components';
import { useProfile } from '../../../hooks';
import { toastError } from '../../../utils/toast';

type AvatarProps = {
  profileImageUrl: string | undefined;
  setUseFile: (value: File) => void;
};

const Avatar: React.FC<AvatarProps> = ({ profileImageUrl, setUseFile }) => {
  const { t } = useTranslation('profile');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useProfile();

  const inputPhotoLabel = useMemo(
    () => (
      <span className="m-l-24">
        {t(`${!profileImageUrl ? 'avatar.optionPhoto.labelUpload' : 'avatar.optionPhoto.labelChange'}`)}
      </span>
    ),
    [user, t],
  );

  const inputPhotoIcon = useMemo(
    () => (!profileImageUrl ? <IconAdd className="icon-add--reverse" /> : <IconEdit />),
    [profileImageUrl],
  );

  const onChangePhoto = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      if (files[0].size > 15 * 1042 * 1024) {
        toastError({ content: t('avatar.fileTooBig') });
      } else {
        setUseFile(files[0]);
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, []);

  return (
    <div className="edit-avatar__option">
      <span className="edit-avatar__option-header">{t('avatar.optionPhoto.header')}</span>
      <label className="edit-avatar__input">
        <div className="edit-avatar__input--left">
          <IconPhoto />
          {inputPhotoLabel}
        </div>
        <div className="edit-avatar__input-file">
          <input
            type="file"
            ref={fileInputRef}
            onChange={onChangePhoto}
            onClick={(event) => (event.currentTarget.value = '')}
            hidden
            accept="image/*"
          />
          {inputPhotoIcon}
        </div>
      </label>
    </div>
  );
};

export default Avatar;
