import React from 'react';
import { ChannelTypeInternal } from '../../../../../data-access/gql-types/graphql';
import { useCoverAction } from '../../../../../hooks';
import { ChannelInterface } from '../../../../../types';
import { RollerControls } from '../../../../roller-controls';

type CoverActionControlBoxProps = {
  channels: ChannelInterface[];
  isListItem?: boolean;
};

export const CoverActionControlBox: React.FC<CoverActionControlBoxProps> = ({ channels, isListItem }) => {
  const { handleAction } = useCoverAction(channels);

  const handleCoverAction = (type: string) => handleAction(type);

  return (
    <RollerControls handleAction={handleCoverAction} isListItem={isListItem} type={ChannelTypeInternal.Blind} action />
  );
};
