import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { convertErrorsToOneString } from '../../../utils/helpers';
import { toastError } from '../../../utils/toast';
import { PropertyNameError } from '../types';

export const useMeterTariffErrors = () => {
  const { t } = useTranslation('backend-validation');

  const handleMeterTariffErrors = (error: AxiosError) => {
    const response = error.response;

    if (response) {
      if ('errors' in (response as AxiosResponse).data) {
        const errors: string[] = [];
        const errorObject = (response as AxiosResponse).data.errors as Record<string, string>;

        Object.keys(errorObject).map((x) => {
          const errorParsed = JSON.parse(errorObject[x]) as PropertyNameError;
          errors.push(t(errorParsed.ErrorCode));

          Sentry.captureMessage('METER TARIFF: NO HANDLE ERROR', {
            level: 'info',
            extra: {
              error: errorParsed,
            },
          });
        });

        toastError({ content: convertErrorsToOneString(errors) });
      }
    }
  };

  return {
    handleMeterTariffErrors,
  };
};
