import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ManuallyPairedDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IconButton } from '@mui/material';
import { IconAdd } from '../../../../../../components';
import { DeviceIcon } from '../../../../components/device/icon';
import { useManuallyPaired } from '../../hooks/use-manually-paired';
import './index.scss';

type ComponentProps = {
  device: ManuallyPairedDevice;
};

const ManuallyDevice: React.FC<ComponentProps> = ({ device }) => {
  const { addManuallyDevice } = useManuallyPaired();

  const registerManually = useCallback(() => addManuallyDevice(device), [device]);

  return (
    <div className="driver m-b-24 found-driver">
      <div className={classNames('driver__header-icon')}>
        <DeviceIcon iconType={device.IconType} />
      </div>
      <h4 className="driver__header">
        <div className="driver__header-title">
          <div className="driver__header-title-name text-ellipsis">{device.Model || ''}</div>
        </div>
      </h4>
      <IconButton className="register-btn" onClick={registerManually}>
        <IconAdd />
      </IconButton>
    </div>
  );
};

export default ManuallyDevice;
