import React from 'react';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceUpdateInfo } from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import { Header } from '../../../../../../components';
import { GroupedDevice } from '../../types';
import DeviceItem from '../device-item';

type ComponentProps = {
  groupDevice: GroupedDevice;
  updatesAvailability?: IDeviceUpdateInfo[];
  hiddenHeader?: boolean;
};

const DeviceGroup: React.FC<ComponentProps> = ({ groupDevice, hiddenHeader = false, updatesAvailability = [] }) => (
  <>
    {!hiddenHeader ? <Header title={groupDevice.label} subheader /> : null}
    <div className="page-list__item">
      <ul className="page-list__items-list items-list">
        {groupDevice.devices.map((device: IDevice) => {
          const update = updatesAvailability.find((x) => x.UpdateResourceGuid === device.Guid);

          return (
            <li key={device.Guid} className="items-list__item">
              <DeviceItem device={device} {...(update ? { update } : {})} />
            </li>
          );
        })}
      </ul>
    </div>
  </>
);

export default DeviceGroup;
