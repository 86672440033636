import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { NetworkConfigurationService } from 'lavva.exalushome.network';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { INetworkService } from 'lavva.exalushome/build/js/INetworkService';
import { useBackdropContext } from '../../../../../hooks';
import { toastSuccess } from '../../../../../utils/toast';
import { useHandleDataFrameErrors } from '../../../hooks';

export const useStaticSettings = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const [ipAddress, setIpAddress] = useState<string>('');
  const [subnetMask, setSubnetMask] = useState<string>('');
  const [gateway, setGateway] = useState<string>('');
  const [broadcast, setBroadcast] = useState<string>('');
  const { handleError } = useHandleDataFrameErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    turnOnBackdrop();

    const networkService = Api.Get<INetworkService>(NetworkConfigurationService.ServiceName);
    const result = await networkService.SetStaticIpConfigurationAsync({
      IpAddress: ipAddress,
      SubnetMask: subnetMask,
      DefaultGateway: gateway,
      Broadcast: broadcast,
    });

    if (result === Status.OK) {
      toastSuccess({ content: t('exalusNetwork.staticSettingsSaved') });
      history.goBack();
    } else {
      handleError(result);
    }

    turnOffBackdrop();
  };

  const handleChange = (val: string, property: string) => {
    switch (property) {
      case 'ip': {
        setIpAddress(val);
        break;
      }
      case 'mask': {
        setSubnetMask(val);
        break;
      }
      case 'gateway': {
        setGateway(val);
        break;
      }
      case 'broadcast': {
        setBroadcast(val);
        break;
      }
    }
  };

  return {
    handleSubmit,
    handleChange,
  };
};
