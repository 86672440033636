import { useEffect, useState } from 'react';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService, GetParamsErrorCode } from 'lavva.exalushome.extalife';
import { FirmwareVersion } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/FirmwareService';
import { IFirmwareService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/IFirmwareService';
import { ISsrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/ISsrConfigService';
import {
  SsrConfigService,
  VersionedOptions,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/SsrConfigService';
import { ISsr_4_0_0_0 } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/VersionedOptions/^4.0.0.0/ISsrVersionedService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { useBackdropContext } from '../../../../../hooks';
import { useExalusContext } from '../../../context';
import { getVersionService, parseFirmware } from '../../device-details/utils';

export const useSsr = (device: IDevice, firmwareService: IFirmwareService | undefined) => {
  const { synchronized, connected } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [currentVersion, setCurrentVersion] = useState<FirmwareVersion | null>(null);
  const [configService, setConfigService] = useState<ISsrConfigService | ISsr_4_0_0_0 | null>(null);

  useEffect(() => {
    const getSsrVersion = async () => {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const defaultService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISsrConfigService>(
        SsrConfigService.ServiceName,
      );
      setConfigService(defaultService);

      if (!firmwareService) {
        return;
      }

      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISsrConfigService>(
        SsrConfigService.ServiceName,
      );
      const versionedOptions: VersionedOptions = await configService.VersionedConfigurationOptions();
      const data = await firmwareService.GetFirmwareVersionAsync(device);

      if (!(data as ResponseResult<GetParamsErrorCode>).Type) {
        const currentFirmware = parseFirmware(data as FirmwareVersion);

        if (currentFirmware) {
          const serviceVersion = await getVersionService(currentFirmware, versionedOptions);
          if (versionedOptions[serviceVersion]) setConfigService(versionedOptions[serviceVersion]);
          setCurrentVersion(data as FirmwareVersion);
          turnOffBackdrop();
        }
      } else {
        turnOffBackdrop();
      }
    };

    if (synchronized && connected && firmwareService) getSsrVersion();
  }, [synchronized, connected, firmwareService]);

  return {
    configService,
    isGraterThan4: currentVersion ? currentVersion && currentVersion.Major >= 4 : null,
  };
};
