import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EmptyStateBox, IconWarning } from '../../../components';
import { DeviceConnectionState } from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { ChannelInterface } from '../../../types';

type PropsInterface = {
  channel: ChannelInterface;
};

export const RecoveryInfoDetails: React.FC<PropsInterface> = ({ channel }) => {
  const history = useHistory();
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { t: tco } = useTranslation('configuration');
  const { channelState } = useChannelsState();

  const handleRecovery = useCallback(() => {
    if (channel) {
      history.push(ROUTES.DeviceSettings(channel?.deviceId));
    }
  }, [channel]);

  if (channelState[channel.id]?.deviceConnectionState !== DeviceConnectionState.Recovery) {
    return null;
  }

  return (
    <EmptyStateBox
      header={tc('status.recoveryMode')}
      btnText={tco('goToDevice')}
      content={t('recoveryNeeded')}
      icon={<IconWarning />}
      onClick={handleRecovery}
      className="m-b-16"
    />
  );
};
