import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FloodSensorStates } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ChannelBox } from '../../../box';
import { ChannelBoxIcon } from '../../../box-icon';
import { useFloodControl } from '../../../hooks/use-flood-control';
import { PowerState } from '../../../power';
import SensorDetailsWrapper from '../../../sensor-details-wrapper';
import FloodControl from '../controls';

type ComponentProps = {
  channel: IDeviceChannel;
};

export const FloodDetailsControls: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { flood, time } = useFloodControl(channel);

  return (
    <SensorDetailsWrapper channel={channel}>
      <div className="details-controls grid-list-16">
        <div>
          <ChannelBox channel={channel} isListItem className="device-box--details">
            <div className="device-box__head space-between">
              <div className="left">
                <ChannelBoxIcon channel={channel} />
                {flood === FloodSensorStates.FloodDetected && (
                  <span className="details-controls__state">{t('exalus.floodDetected.heading')}</span>
                )}
              </div>
              <FloodControl channel={channel} isListItem details />
            </div>
          </ChannelBox>
          {flood === FloodSensorStates.FloodDetected && (
            <div className="details-controls__additional-text">{t('exalus.floodDetected.text')}</div>
          )}
          {time && <div className="details-controls__detection-time">{`${t('exalus.checked')} ${time}`}</div>}
        </div>
        <PowerState channel={channel} />
      </div>
    </SensorDetailsWrapper>
  );
};
