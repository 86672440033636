import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTimePicker, IconEdit, Input } from '../../../../../../components';

type ComponentProps = {
  time: string;
  setTime: Dispatch<SetStateAction<string>>;
  label?: string;
  minSeconds?: number;
};

export const TimeInput: React.FC<ComponentProps> = ({ time, setTime, label, minSeconds }) => {
  const { t } = useTranslation('action');
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);

  return (
    <>
      <Input
        defaultValue={time}
        value={time}
        required
        readOnly
        label={label || t('action.create.conditions.sources.schedule.triggerTime')}
        onClickInput={() => setShowTimePicker(true)}
        endIcon={<IconEdit />}
      />
      <DialogTimePicker
        header={t('action.create.conditions.sources.schedule.triggerTime')}
        open={showTimePicker}
        setOpen={setShowTimePicker}
        onSave={(value) => setTime(value)}
        time={time}
        showSeconds
        minSeconds={minSeconds}
      />
    </>
  );
};
