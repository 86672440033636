import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChannelInterface } from '../../../../../types';
import { useOptimizerConfigurationContext } from '../../context/configuration-context';
import { OptimizerConfigurationPhaseLoad } from '../components/phase-load';
import { useOptimizerTest } from '../hooks/use-optimizer-test';
import { TestFormBody } from '../types';

type ComponentProps = {
  channel: ChannelInterface;
};

export const OptimizerConfigurationWorkModeTest: React.FC<ComponentProps> = ({ channel }) => {
  const { t: tc } = useTranslation('common');
  const { submitConfiguration } = useOptimizerTest(channel);
  const { selectedWorkMode } = useOptimizerConfigurationContext();
  const form = useForm<TestFormBody>({
    defaultValues: {
      phase1: 0,
      phase2: 0,
      phase3: 0,
    },
  });

  const onSubmit = form.handleSubmit((values) => {
    submitConfiguration(selectedWorkMode, values);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationPhaseLoad />
        </div>

        <div className="button-container">
          <button className="button m-t-48 max-width-desktop" type="submit">
            {tc('buttons.save')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
