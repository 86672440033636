import { gql } from '@apollo/client';

export const NOTIFICATION_MARK_AS_DISPLAYED = gql`
  mutation NotificationMarkAsDisplayed($correlationIds: [UUID!]!) {
    markAsDisplayed(correlationIds: $correlationIds) {
      ok
    }
  }
`;

export const NOTIFICATION_MARK_AS_HIDDEN = gql`
  mutation NotificationMarkAsHidden($correlationIds: [UUID!]!) {
    markAsHidden(correlationIds: $correlationIds) {
      ok
    }
  }
`;

export const REGISTER_PUSH_NOTIFICATION_RECEIVER = gql`
  mutation RegisterPushNotificationReceiver($input: RegisterPushReceiverRequestInput!) {
    registerPushNotificationReceiver(input: $input) {
      ok
    }
  }
`;

export const DEMO_PUSH = gql`
  mutation DemoPush {
    demoPush
  }
`;
