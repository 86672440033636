import React from 'react';
import { useTranslation } from 'react-i18next';
import { defaultFavouriteSetOnTimeList } from '../../../../const';
import { useSwitchAction } from '../../../../hooks';
import { ChannelInterface } from '../../../../types';
import { TimeActivation } from '../../../channel-details/components/time-activation';

type PropsInterface = {
  channels: ChannelInterface[];
};

export const ActionTimesActivations: React.FC<PropsInterface> = ({ channels }) => {
  const { t } = useTranslation('channel-details');
  const { handleSetOnTime } = useSwitchAction(channels);

  const onTimeChange = (value: number) => handleSetOnTime(value);

  return (
    <div className="p-l-24 p-r-24">
      <h3 className="m-t-16 m-b-16">{t('timeActivation')}</h3>
      <div className="favourite">
        <div className="favourite__buttons favourite__buttons--switch">
          {defaultFavouriteSetOnTimeList.map((value, index) => {
            return (
              <TimeActivation
                key={index}
                value={value}
                index={index}
                onTimeChange={onTimeChange}
                onBtnClick={handleSetOnTime}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
