import React from 'react';
import { SubmitButton } from '../../../../components';

type ComponentProps = {
  handleSubmit: () => void;
};

export const VentilationChannelSettings: React.FC<ComponentProps> = ({ handleSubmit }) => (
  <>
    <SubmitButton onClick={handleSubmit} />
  </>
);
