import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstallation } from '../../hooks';
import { getIcon } from '../../modules/exalus/utils/icons';
import { AvailableIcon } from '../../types';
import { EditIconControl } from '../edit-icon-control';
import { EditIconPage } from '../edit-icon-page';

type ComponentProps = {
  currentIcon: string;
  iconList: AvailableIcon[];
  handleChangeIcon: (value: string) => void;
  installationIcon?: React.ReactNode;
  iconBackgroundColor?: string;
  header?: string;
  disabled?: boolean;
};

export const EditIcon: React.FC<ComponentProps> = ({
  currentIcon,
  iconList,
  handleChangeIcon,
  installationIcon,
  iconBackgroundColor,
  header,
  disabled,
}) => {
  const { t: tc } = useTranslation('common');
  const [open, setOpen] = useState<boolean>(false);
  const { selectedInstallation } = useInstallation();

  const onChangeIcon = (value: string) => {
    handleChangeIcon(value);
    setOpen(false);
  };

  const IconComponent = useMemo(() => {
    if (installationIcon) return { component: installationIcon };
    return getIcon(iconList, currentIcon);
  }, [currentIcon, installationIcon, iconList]);

  return (
    <>
      {open ? (
        <EditIconPage setOpen={setOpen} title={header || tc('editIcon.change')}>
          <EditIconControl caption={tc('editIcon.current')} noBackground={!!installationIcon}>
            {IconComponent && IconComponent.component}
          </EditIconControl>
          <span className="edit-icon__caption">{tc('editIcon.select')}</span>
          <div className="edit-icon__list">
            {iconList.map((icon) => (
              <div onClick={() => onChangeIcon(icon.iconName)} key={icon.iconName} className="edit-icon__icon-wrapper">
                {icon.component}
              </div>
            ))}
          </div>
        </EditIconPage>
      ) : (
        <EditIconControl
          subCaption={tc('editIcon.change')}
          setOpen={setOpen}
          noBackground={!!installationIcon}
          disabled={disabled}
          {...(iconBackgroundColor !== undefined
            ? {
                activeColor: iconBackgroundColor || selectedInstallation?.hexColor,
              }
            : {})}
        >
          {IconComponent && IconComponent.component}
        </EditIconControl>
      )}
    </>
  );
};
