import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../../../components';

type ComponentProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  handleConfirmation: () => void;
};

export const TempWarningDialog: React.FC<ComponentProps> = ({ show, setShow, handleConfirmation }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');

  return (
    <DialogConfirmation
      show={show}
      setShow={setShow}
      header={t('optimizer.configuration.tempMinWarning.header')}
      primaryBtnText={tc('buttons.yes')}
      secondaryBtnText={tc('buttons.cancel')}
      primaryBtnAction={handleConfirmation}
      secondaryBtnAction={() => setShow(false)}
      timer
    >
      <div>
        <p>{t('optimizer.configuration.tempMinWarning.content')}</p>
        <p>
          <span style={{ fontWeight: 600 }}>{t('optimizer.configuration.tempMinWarning.warning1.title')}</span>
          {`: ${t('optimizer.configuration.tempMinWarning.warning1.message')}`}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>{t('optimizer.configuration.tempMinWarning.warning2.title')}</span>
          {`: ${t('optimizer.configuration.tempMinWarning.warning2.message')}`}
        </p>
        <p>{t('optimizer.configuration.tempMinWarning.confirmation')}</p>
      </div>
    </DialogConfirmation>
  );
};
