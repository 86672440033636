import { GateKindInternal } from '../../../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../../../types';
import { DefaultKindControl } from './default';

type ComponentProps = {
  gateKind: GateKindInternal;
  channel: ChannelInterface;
  handleAction: () => void;
};

export const GateKindsControl: React.FC<ComponentProps> = ({ gateKind, channel, handleAction }) => {
  switch (gateKind) {
    default: {
      return <DefaultKindControl channel={channel} handleAction={handleAction} />;
    }
  }
};
