import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { DeviceConnectionState } from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { ChannelInterface } from '../../../types';
import { Checkbox } from '../../checkbox';
import { IconSortHandle } from '../../icons';
import { ChannelBoxControls } from '../controls';

type BodyProps = {
  channel: ChannelInterface;
  isDraggable: boolean;
  isCheckbox: boolean;
  checked?: boolean;
  onChannelToggle?: (id: string, value: boolean) => void;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
  isListItem: boolean;
};

export const Body: React.FC<BodyProps> = ({
  channel,
  isDraggable,
  isCheckbox,
  attributes,
  onChannelToggle,
  listeners,
  checked,
  isListItem,
}) => {
  const history = useHistory();
  const { t } = useTranslation('common');
  const { channelState } = useChannelsState();

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    onChannelToggle?.(channel.id, e.target.checked);
  };

  if (isDraggable) {
    return (
      <div className="device-box__draggable">
        <div className="device-box__draggable-handle" {...attributes} {...listeners}>
          <IconSortHandle />
        </div>
      </div>
    );
  }

  if (isCheckbox) return <Checkbox id={channel.id} checked={checked} onChange={handleToggle} />;

  if (channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery) {
    return (
      <div className="device-box__update" onClick={() => history.push(ROUTES.DeviceSettings(channel?.deviceId))}>
        {t('update')}
      </div>
    );
  }

  return (
    <div className="device-box__controls">
      <ChannelBoxControls isListItem={isListItem} channel={channel} />
    </div>
  );
};
