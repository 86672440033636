import React, { ReactNode, useCallback, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import './index.scss';

type CarouselProps = {
  children: ReactNode[];
  setActiveIndex: (activeTabIndex: number) => void;
  activeIndex: number;
  preventSwipeEvent?: boolean;
  triggerDistancePx?: number;
};

export const Carousel: React.FC<CarouselProps> = ({
  children,
  setActiveIndex,
  activeIndex,
  preventSwipeEvent,
  triggerDistancePx,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const refInner = useRef<HTMLDivElement | null>(null);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (preventSwipeEvent) return;
      refInner?.current?.animate([{ transform: `translate3d(100%, 0, 0)` }, { transform: `translate3d(0%, 0, 0)` }], {
        duration: 200,
        easing: 'ease-out',
      });

      return updateIndex(activeIndex + 1);
    },
    onSwipedRight: () => {
      if (preventSwipeEvent) return;
      refInner?.current?.animate([{ transform: `translate3d(-100%, 0, 0)` }, { transform: `translate3d(0%, 0, 0)` }], {
        duration: 200,
        easing: 'ease-out',
      });

      return updateIndex(activeIndex - 1);
    },
    delta: triggerDistancePx || 20,
    preventDefaultTouchmoveEvent: false,
    rotationAngle: 0,
  });

  const refHandler = useCallback(
    (el) => {
      if (el && !preventSwipeEvent) {
        handlers.ref(el);
        el.scrollTo({ top: 0 });
      }
      ref.current = el;
    },
    [ref, handlers, activeIndex, preventSwipeEvent],
  );

  return (
    <div ref={refHandler} className="carouselCustom">
      <div ref={refInner} className={classNames('carouselCustom__inner')}>
        {React.Children.map(children, (child: any, index: number) =>
          React.cloneElement(child, { width: '100%', isActive: activeIndex === index }),
        )}
      </div>
    </div>
  );
};
