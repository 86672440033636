import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChannelItem, AdvancedActionChannelParameters } from '../../../components';
import { ROUTES } from '../../../routes';
import { ChannelActionDetailsInterface } from '../../../types';

type ActionChannelListProps = {
  channels: ChannelActionDetailsInterface[];
  heading: string;
};

const ActionChannelList: React.FC<ActionChannelListProps> = ({ channels, heading }) => {
  const history = useHistory();
  const { t } = useTranslation('action');

  return (
    <div className="action-device-list p-l-24 p-r-24">
      <div className="action-device-list__header">
        <h3>{heading}</h3>
      </div>
      {channels.length > 0 ? (
        <div className="action-device-list__list">
          {channels.map((item: ChannelActionDetailsInterface, index: number) => (
            <div onClick={() => history.push(ROUTES.ChannelDetails(item.id))} key={index}>
              <ChannelItem
                id={item.id}
                isOn={false}
                details={
                  'activityType' in item ? (
                    <AdvancedActionChannelParameters
                      parameter={{ activityType: item.activityType, value: item.value }}
                    />
                  ) : null
                }
              />
            </div>
          ))}
        </div>
      ) : (
        <p>{t('noChannels')}</p>
      )}
    </div>
  );
};

export default ActionChannelList;
