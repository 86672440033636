import React from 'react';
import { useTranslation } from 'react-i18next';
import { TriggerConditionType, TriggerStateConditionRequest } from '../../../data-access/gql-types/graphql';

type TriggerConditionParametersProps = {
  condition: TriggerStateConditionRequest;
};

export const TriggerConditionParameters: React.FC<TriggerConditionParametersProps> = ({ condition }) => {
  const { t } = useTranslation('action');

  if (!condition?.id) return null;

  switch (condition.conditionType) {
    case TriggerConditionType.SwitchStateIs:
      return <span>{condition.booleanValue ? t('triggerParameters.switchOn') : t('triggerParameters.switchOff')}</span>;

    case TriggerConditionType.LightStateIs:
      return <span>{condition.booleanValue ? t('triggerParameters.lightOn') : t('triggerParameters.lightOff')}</span>;
    case TriggerConditionType.BlindDirectionIs:
    case TriggerConditionType.GateDirectionIs:
      return (
        <span>
          {condition.integerValue === 3
            ? t('triggerParameters.blindDirection.down')
            : t('triggerParameters.blindDirection.up')}
        </span>
      );

    case TriggerConditionType.BlindPositionIsGreaterThanOrEqualTo:
    case TriggerConditionType.GatePositionIsGreaterThanOrEqualTo:
      return (
        <span>
          {condition.integerValue === 100
            ? t('triggerParameters.blindDirection.down')
            : `${t('trigger.conditions.state.blind.greaterThanOrEqual')} ${condition.integerValue}%`}
        </span>
      );
    case TriggerConditionType.BlindPositionIsLessThanOrEqualTo:
    case TriggerConditionType.GatePositionIsLessThanOrEqualTo:
      return (
        <span>
          {condition.integerValue === 0
            ? t('triggerParameters.blindDirection.up')
            : `${t('trigger.conditions.state.blind.lessThanOrEqual')} ${condition.integerValue}%`}
        </span>
      );
  }

  return <></>;
};
