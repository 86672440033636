import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation, Header, IconEdit, Input } from '../../../../../../components';
import { SliderHorizontal } from '../../../../../../components/slider-horizontal';

type ComponentProps = {
  propertyName: string;
  propertyValue: number;
  unit: string;
  dialogTitle: string;
  headerTitle?: string;
  inputLabel?: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  noBottomMargin?: boolean;
  reverse?: boolean;
  maxWidth?: boolean;
  errorMessage?: string;
};

export const InputSliderDialog: React.FC<ComponentProps> = ({
  propertyName,
  propertyValue,
  unit,
  dialogTitle,
  min,
  max,
  step,
  headerTitle,
  inputLabel,
  disabled = false,
  noBottomMargin = false,
  reverse,
  maxWidth,
  errorMessage,
}) => {
  const { t: tc } = useTranslation('common');
  const { control, setValue } = useFormContext();
  const [tempValue, setTempValue] = useState<number>(0);
  const [showPicker, setShowPicker] = useState<boolean>(false);

  useEffect(() => {
    if (propertyValue) setTempValue(propertyValue);
  }, [propertyValue]);

  const handleCloseDialog = () => {
    setShowPicker(false);
    setTempValue(propertyValue);
  };

  return (
    <>
      {headerTitle && <Header title={headerTitle} className="m-b-16" />}
      <Controller
        name={propertyName}
        control={control}
        rules={{ required: tc('isRequired') as string }}
        render={({ field, fieldState: { error } }) => (
          <div style={{ width: '100%' }} {...(maxWidth ? { className: 'grid-list-24' } : {})}>
            <Input
              defaultValue={`${field.value}${unit}`}
              value={`${field.value}${unit}`}
              required
              readOnly
              endIcon={<IconEdit />}
              reverse={reverse}
              disabled={disabled}
              noBottomMargin={noBottomMargin}
              onClickInput={() => setShowPicker(true)}
              {...(inputLabel && { label: inputLabel })}
              {...(error ? { errorMessage: error.message } : { ...(errorMessage ? { errorMessage } : {}) })}
            />
          </div>
        )}
      />
      <DialogConfirmation
        show={showPicker}
        setShow={handleCloseDialog}
        header={dialogTitle}
        headerValue={`${tempValue}${unit}`}
        primaryBtnText={tc('buttons.save')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={() => {
          setValue(propertyName, tempValue);
          setShowPicker(false);
        }}
        secondaryBtnAction={handleCloseDialog}
        contentClassName="p-r-0 p-l-0"
      >
        <SliderHorizontal
          defaultValue={tempValue}
          value={tempValue}
          {...(min !== undefined ? { min } : {})}
          {...(max !== undefined ? { max } : {})}
          {...(step !== undefined ? { step } : {})}
          onChange={(_, value) => {
            setTempValue(value as number);
          }}
        />
      </DialogConfirmation>
    </>
  );
};
