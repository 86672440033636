import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ControlWrapper, CoverButton, IconChevron, IconVent, Slider, SubmitButton } from '../../../../../components';
import { IconWoodenBlind } from '../../../../../components/icons';
import { defaultFavouritePositionList } from '../../../../../const';
import {
  ChannelTypeInternal,
  TriggerActionRequestInput,
  TriggerActionType,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../../types';
import { useTriggerFormContext } from '../../../context';

type CoverSetupProps = {
  setAction: (value: TriggerActionRequestInput) => void;
  channel: ChannelInterface;
  defaultParameters: TriggerActionRequestInput | undefined;
};

enum BlindDirection {
  Unknown = -1,
  Stop = -2,
  Up = 0,
  Down = 100,
}

const ValueMapper = {
  '-1': 0,
  '-2': 1,
  '0': 2,
  '100': 3,
};

const CoverSetup: React.FC<CoverSetupProps> = ({ setAction, channel, defaultParameters }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [activeControl, setActiveControl] = useState<BlindDirection>(BlindDirection.Unknown);
  const [channelParameters, setChannelParameters] = useState<TriggerActionRequestInput | null>(null);
  const { updateAction } = useTriggerFormContext();

  const setPosition = useCallback(
    (value: number) => {
      setSliderValue(value);
      setChannelParameters(() => ({
        id: channel.id,
        actionType: TriggerActionType.SetBlindPosition,
        integerValue: value,
      }));
    },
    [setChannelParameters],
  );

  useEffect(() => {
    if (defaultParameters) {
      if (defaultParameters.actionType === TriggerActionType.SetBlindPosition) {
        setSliderValue(defaultParameters.integerValue || 0);
        setPosition(defaultParameters.integerValue || 0);
      } else {
        const value = Object.keys(ValueMapper).find((key) => ValueMapper[key] === defaultParameters.integerValue);
        if (value !== undefined) {
          const sliderVal = parseInt(value);
          if (sliderVal >= 0) setSliderValue(sliderVal);

          setChannelParameters(() => ({
            id: channel.id,
            actionType: TriggerActionType.SetBlindDirection,
            integerValue: defaultParameters.integerValue,
          }));
          setActiveControl(sliderVal || 0);
        }
      }
    }
  }, [setSliderValue, defaultParameters]);

  const favouritePositionList = useMemo(() => {
    if (
      channel.data.type === ChannelTypeInternal.Blind &&
      channel.data.favouritePositionList?.length === 3 &&
      channel.data.favouritePositionList.every((value: number) => Number(value))
    ) {
      return channel.data.favouritePositionList;
    } else if (channel.data.type === ChannelTypeInternal.Blind && channel.data.favouritePositionList?.length) {
      return [
        channel.data.favouritePositionList[0] || defaultFavouritePositionList[0],
        channel.data.favouritePositionList[1] || defaultFavouritePositionList[1],
        channel.data.favouritePositionList[2] || defaultFavouritePositionList[2],
      ];
    } else {
      return defaultFavouritePositionList;
    }
  }, [channel, defaultFavouritePositionList]);

  const onFavouriteClick = useCallback(
    (favoritePositionIndex) => {
      setActiveControl(BlindDirection.Unknown);
      setPosition(favouritePositionList[favoritePositionIndex]);
    },
    [favouritePositionList, setChannelParameters],
  );

  const handleSliderEvent = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => {
      setActiveControl(BlindDirection.Unknown);
      setPosition(Number((event.target as HTMLInputElement).value));
    },
    [setChannelParameters],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSliderValue(Number(event.target.value)),
    [setSliderValue],
  );

  const onSubmit = useCallback(() => {
    updateAction(channel.id, {
      id: channel.id,
      actionType: channelParameters?.actionType || TriggerActionType.SetBlindPosition,
      integerValue: channelParameters?.integerValue,
    });
    setAction({
      id: channel.id,
      actionType: channelParameters?.actionType || TriggerActionType.SetBlindPosition,
      integerValue: channelParameters?.integerValue,
    });
  }, [channelParameters]);

  const handleControl = useCallback(
    (value: BlindDirection) => {
      if (value >= 0) setSliderValue(value);

      setChannelParameters(() => ({
        id: channel.id,
        actionType: TriggerActionType.SetBlindDirection,
        integerValue: ValueMapper[value.toString()],
      }));
      setActiveControl(value);
    },
    [setActiveControl],
  );

  return (
    <>
      <ControlWrapper>
        <div className="cover-detail-view__slider">
          <Slider value={sliderValue} onPointerUp={handleSliderEvent} onChange={onChange} />
        </div>
      </ControlWrapper>
      <div className="cover-detail-view__controls p-l-24 p-r-24">
        <div
          className={classNames('cover-detail-view__controls-down', {
            'cover-detail-view__controls--active': activeControl === BlindDirection.Down,
          })}
          onClick={() => handleControl(BlindDirection.Down)}
        >
          <IconChevron withCircle direction="down" />
        </div>
        <button
          className={classNames('cover-detail-view__controls-stop', {
            'cover-detail-view__controls--active': activeControl === BlindDirection.Stop,
          })}
          onClick={() => handleControl(BlindDirection.Stop)}
        >
          {tc('status.stop')}
        </button>
        <div
          className={classNames('cover-detail-view__controls-up', {
            'cover-detail-view__controls--active': activeControl === BlindDirection.Up,
          })}
          onClick={() => handleControl(BlindDirection.Up)}
        >
          <IconChevron withCircle direction="up" />
        </div>
      </div>
      <div className="favourite p-l-24 p-r-24">
        <div className="favourite__buttons favourite__buttons--cover">
          {[0, 1, 2].map((index) => (
            <CoverButton
              key={index}
              lastPosition={sliderValue}
              position={favouritePositionList[index]}
              onClick={() => onFavouriteClick(index)}
            >
              <IconWoodenBlind position={favouritePositionList[index]} />
              <span>{favouritePositionList[index]}%</span>
            </CoverButton>
          ))}
          <CoverButton lastPosition={sliderValue} position={99} onClick={() => setPosition(99)}>
            <IconVent />
            <span>{t('microventilation')}</span>
          </CoverButton>
        </div>
      </div>
      <SubmitButton onClick={onSubmit} />
    </>
  );
};

export default CoverSetup;
