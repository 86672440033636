import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useSubscription } from '@apollo/client';
import {
  ChannelTypeInternal,
  MeasurementChannelKind,
  MeterTypeInternal,
  OnMeasurementSubscriptionVariables,
  StandaloneMeasurementPayload,
  SubPriority,
  Subscription,
  VectorParameterTypeInternal,
} from '../../../../data-access/gql-types/graphql';
import { ON_MEASUREMENT } from '../../../../data-access/subscriptions/on-measurement';
import { useApi, useInstallation } from '../../../../hooks';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { ChannelInterface, ChannelMeterInterface } from '../../../../types';
import { IconArrowLink, IconEco } from '../../../icons';
import './index.scss';

type MeterControlProps = {
  channel: ChannelInterface;
};

export const MeterControl: React.FC<MeterControlProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { setChannelList } = useDevicesAndChannelsContext();
  const { measurementMap, convertMeasurementToNumber } = useApi();
  const { skipLavvaFetch } = useInstallation();
  const { data } = useSubscription<Subscription, OnMeasurementSubscriptionVariables>(ON_MEASUREMENT, {
    variables: {
      input: {
        channelId: channel.id,
        kind: MeasurementChannelKind.Meter,
        priority: SubPriority.Medium,
        scalarParameterTypes: [],
        vectorParameterTypes: [
          convertMeasurementToNumber(MeasurementChannelKind.Meter)('vectors', VectorParameterTypeInternal.ActivePower),
        ],
        indices: [],
      },
    },
    skip: !measurementMap || skipLavvaFetch,
  });

  const isStandalone = useMemo(() => {
    return channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  useEffect(() => {
    if (data && isStandalone) {
      if ('vectorMeasurements' in data.onMeasurement.data) {
        const sum = data.onMeasurement.data.vectorMeasurements.sum((x) => x.value);

        if (sum !== undefined) {
          setChannelList((prev) => {
            const tempList = cloneDeep(prev);
            const index = tempList.findIndex((x) => x.id === channel.id);

            if (index !== -1) {
              tempList[index] = {
                ...tempList[index],
                data: {
                  ...tempList[index].data,
                  payload: {
                    ...((tempList[index].data as ChannelMeterInterface).payload as StandaloneMeasurementPayload),
                    sumOfActivePower: sum,
                  },
                } as ChannelMeterInterface,
              };

              (
                (tempList[index].data as ChannelMeterInterface).payload as StandaloneMeasurementPayload
              ).sumOfActivePower = sum;
            }

            return tempList;
          });
        }
      }
    }
  }, [data]);

  if (!isStandalone) {
    return null;
  }

  const value = useMemo(() => {
    if (isStandalone && ((channel.data as ChannelMeterInterface)?.payload as StandaloneMeasurementPayload)) {
      const payload = (channel.data as ChannelMeterInterface)?.payload as StandaloneMeasurementPayload;
      return payload?.sumOfActivePower;
    }
  }, [channel, isStandalone]);

  if (value === null || !(channel.data as ChannelMeterInterface)?.payload) {
    return null;
  }

  return (
    <div className="meter-control">
      {value !== undefined ? (
        <div className="meter-control__value-wrapper">
          {value < 0 ? <IconEco /> : null}
          <span className="meter-control__value">
            {Math.abs(value / 1000).toFixed(1) !== '0.0' ? (value / 1000).toFixed(1) : '0.0'}
          </span>
          <span className="meter-control__unit">{t('kW')}</span>
          {value ? <IconArrowLink size={22} direction={value < 0 ? 'up' : 'down'} /> : null}
        </div>
      ) : null}
    </div>
  );
};
