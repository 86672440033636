import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconVentProps = {
  onClick?: () => void;
  className?: string;
};

export const IconVent: React.FC<IconVentProps> = ({ onClick, className }) => (
  <div
    className={classNames('icon-vent', {
      clickable: onClick,
      [className as string]: className,
    })}
    onClick={onClick}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M12 12C12 8.95 14.45 6.5 17.5 6.5C20.55 6.5 23 8.95 23 12H12ZM12 12C12 15.05 9.55 17.5 6.5 17.5C3.45 17.5 1 15.05 1 12H12ZM12 12C8.95 12 6.5 9.55 6.5 6.5C6.5 3.45 8.95 1 12 1V12ZM12 12C15.05 12 17.5 14.45 17.5 17.5C17.5 20.55 15.05 23 12 23V12Z"
        stroke="#15141D"
      />
    </svg>
  </div>
);
