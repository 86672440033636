import { useMemo } from 'react';
import { InitiateButton } from '../../../../../../../components';
import { useChannelsState } from '../../../../../../../hooks';
import { useGate } from '../../../../../../../hooks/use-gate';
import { ChannelGateStateInterface, ChannelInterface } from '../../../../../../../types';
import { GateStepIcon } from '../../../gate-step-icon';

type ComponentProps = {
  channel: ChannelInterface;
  handleAction: () => void;
};

export const DefaultKindControl: React.FC<ComponentProps> = ({ channel, handleAction }) => {
  const { channelState } = useChannelsState();
  const { gateActive } = useGate(channel);

  const gateStep = useMemo(() => {
    const position = (channelState[channel.id] as ChannelGateStateInterface)?.position;
    if (!position) return 3;
    return position === 100 ? 1 : 2;
  }, [channelState[channel.id]]);

  return (
    <InitiateButton isOn={false} onClickControl={handleAction} active={gateActive}>
      <GateStepIcon step={gateStep} />
    </InitiateButton>
  );
};
