import React from 'react';
import classNames from 'classnames';
import './index.scss';

type PropsInterface = {
  className?: string;
};

export const IconDayTime: React.FC<PropsInterface> = ({ className }) => {
  return (
    <div
      className={classNames('icon-daytime', {
        [className as string]: className,
      })}
    >
      <svg width="34" height="18" viewBox="0 0 34 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.251 16.6818C10.251 12.7451 13.4423 9.55371 17.3791 9.55371C21.3158 9.55371 24.5071 12.7451 24.5071 16.6818M32.348 16.6818L1.69727 16.6818H5.97401M17.3791 5.27685V1M28.7839 16.6818L33.0608 16.6818M6.29037 5.5931L9.31456 8.61729M28.4679 5.5931L25.4437 8.61729"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
