import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionType, DeviceConnectionState, UserAction } from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { ChannelActionDetailsInterface } from '../../../types';
import { IconConnectionError, IconErrorSmall, IconRecovery } from '../../icons';
import ActionHeader from '../action-header';
import { ActionAdvancedIcon } from '../advanced-icon';
import { ActionIcon } from '../icon';

type ActionDetailsHeaderProps = {
  userAction: UserAction;
  channels: ChannelActionDetailsInterface[];
};

export const ActionDetailsHeader: React.FC<ActionDetailsHeaderProps> = ({ userAction, channels }) => {
  const { t } = useTranslation('action');
  const { channelState } = useChannelsState();
  const isAdvanced = userAction?.action.actionType === ActionType.Advanced;

  const type = useMemo(() => {
    switch (userAction.action.actionType) {
      case ActionType.Central:
        return t('defaultAction');
      case ActionType.Basic:
        return t('basicAction');
      case ActionType.Advanced:
        return t('advancedAction');
      default:
        return '';
    }
  }, [userAction, t]);

  const isRecoveryStatus = useMemo(() => {
    return (
      channels?.length &&
      channels.every(
        (actionChannel) => channelState[actionChannel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
      )
    );
  }, [channels, channelState]);

  const isDisconnected = useMemo(() => {
    return (
      channels?.length &&
      channels.every(
        (actionChannel) => channelState[actionChannel.id]?.deviceConnectionState === DeviceConnectionState.Disconnected,
      )
    );
  }, [channels, channelState]);

  const statusIcon = useMemo(() => {
    if (!channels.length) {
      return <IconErrorSmall size={18} />;
    } else if (isDisconnected) {
      return <IconConnectionError big details size={14} />;
    } else if (isRecoveryStatus) {
      return <IconRecovery big details />;
    } else {
      return null;
    }
  }, [channels, isDisconnected, isRecoveryStatus]);

  return (
    <ActionHeader
      actionName={userAction.action.name}
      actionType={type}
      statusIcon={statusIcon}
      isAdvanced={isAdvanced}
      icon={<ActionIcon action={userAction.action} className={'action-icon--header'} />}
      iconAdvanced={<ActionAdvancedIcon iconName={userAction.iconName} />}
    />
  );
};
