import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputSelect, LightSlider } from '../../../../../components';
import {
  LightChannelStateResponse,
  LightModeTypeInternal,
  UserChannel,
} from '../../../../../data-access/gql-types/graphql';
import { hsbToRgb } from '../../../../../utils/helpers';
import RgbPicker from '../../../../channel-details/light/components/rgb/picker';
import TemperatureComponent from '../../../../channel-details/light/components/rgb/temperature';
import { LightActionTypeEnum } from '../../types';
import { SetOnTimeAction } from '../set-on-time-action';

type ComponentProps = {
  bindingIndex: number;
  bindingListIndex: number;
  channels: UserChannel[];
  listName: string;
};

export const LightAction: React.FC<ComponentProps> = ({ bindingIndex, bindingListIndex, channels, listName }) => {
  const { t } = useTranslation('device-settings');
  const form = useFormContext();
  const { update } = useFieldArray({
    control: form.control,
    name: 'bindings',
  });
  const [lightActionType, setLightActionType] = useState<LightActionTypeEnum | undefined>(undefined);
  const [rgbValue, setRgbValue] = useState<string>('255,255,255');
  const [sliderColor, setSliderColor] = useState<string>('');
  const [brightness, setBrightness] = useState<number>(100);
  const [temperature, setTemperature] = useState(0);
  const { bindings } = form.watch();

  useEffect(() => {
    if (bindings.length) {
      if (bindings[bindingIndex][listName].length) {
        const binding = bindings[bindingIndex][listName][bindingListIndex];
        if ('setOnTimeValue' in binding) setLightActionType(LightActionTypeEnum.SetOnTimeValue);
        if ('brightness' in binding) setLightActionType(LightActionTypeEnum.Brightness);
        if ('temperature' in binding) setLightActionType(LightActionTypeEnum.Temperature);
        if ('r' in binding) setLightActionType(LightActionTypeEnum.RGB);
      }
    }
  }, []);

  const availableModes = useMemo(() => {
    const channel = channels.find((x) => x.id === bindings[bindingIndex].channelId);

    if (channel) {
      const supported = (channel.payload as LightChannelStateResponse).supportedModes || [];

      return [
        { label: t('bindings.setOnTime'), value: LightActionTypeEnum.SetOnTimeValue },
        ...(supported.includes(LightModeTypeInternal.Colors)
          ? [{ label: t('bindings.rgb'), value: LightActionTypeEnum.RGB }]
          : []),
        ...(supported.includes(LightModeTypeInternal.Brightness)
          ? [{ label: t('bindings.brightness'), value: LightActionTypeEnum.Brightness }]
          : []),
        ...(supported.includes(LightModeTypeInternal.Temperature)
          ? [{ label: t('bindings.temperature'), value: LightActionTypeEnum.Temperature }]
          : []),
      ];
    }

    return [{ label: t('bindings.setOnTime'), value: LightActionTypeEnum.SetOnTimeValue }];
  }, [channels, bindings[bindingIndex]]);

  const handleClearRest = () => {
    const { bindings } = form.getValues();

    delete bindings[bindingIndex][listName][bindingListIndex].setOnTimeValue;
    delete bindings[bindingIndex][listName][bindingListIndex].brightness;
    delete bindings[bindingIndex][listName][bindingListIndex].temperature;
    delete bindings[bindingIndex][listName][bindingListIndex].r;
    delete bindings[bindingIndex][listName][bindingListIndex].g;
    delete bindings[bindingIndex][listName][bindingListIndex].b;
  };

  const handleColorPicked = (hue, saturation) => {
    const { bindings } = form.getValues();
    handleClearRest();

    const rgb = hsbToRgb([hue, saturation, 1]).join(',');
    setRgbValue(rgb);
    setSliderColor(hsbToRgb([hue, saturation, 1]).join(','));

    const [r, g, b] = rgb.split(',');
    bindings[bindingIndex][listName][bindingListIndex].r = Number(r);
    bindings[bindingIndex][listName][bindingListIndex].g = Number(g);
    bindings[bindingIndex][listName][bindingListIndex].b = Number(b);
    update(bindingIndex, { ...bindings[bindingIndex] });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { bindings } = form.getValues();
    handleClearRest();

    const brightnessVal = Number(event.target.value);
    setBrightness(brightnessVal);
    bindings[bindingIndex][listName][bindingListIndex].temperature = brightnessVal;
    update(bindingIndex, { ...bindings[bindingIndex] });
  };

  const handleBrightness = (event: React.PointerEvent<HTMLInputElement>) => {
    const { bindings } = form.getValues();
    handleClearRest();

    const brightness = Number((event.target as HTMLInputElement).value);
    const brightnessVal = brightness || 100;
    setBrightness(brightnessVal);
    bindings[bindingIndex][listName][bindingListIndex].temperature = brightnessVal;
    update(bindingIndex, { ...bindings[bindingIndex] });
  };

  const handleTemperature = (event: React.PointerEvent<HTMLInputElement>) => {
    const { bindings } = form.getValues();
    handleClearRest();

    const temp = Number((event.target as HTMLInputElement).value);
    setTemperature(temp);
    bindings[bindingIndex][listName][bindingListIndex].temperature = temp;
    update(bindingIndex, { ...bindings[bindingIndex] });
  };

  const onChangeTemperature = (event: ChangeEvent<HTMLInputElement>) => {
    const { bindings } = form.getValues();
    handleClearRest();

    const temp = Number(event.target.value);
    setTemperature(temp);
    bindings[bindingIndex][listName][bindingListIndex].temperature = temp;
    update(bindingIndex, { ...bindings[bindingIndex] });
  };

  const handleChangeLightActionType = (action: LightActionTypeEnum) => {
    const { bindings } = form.getValues();
    setLightActionType(action);

    handleClearRest();

    switch (action) {
      case LightActionTypeEnum.SetOnTimeValue: {
        bindings[bindingIndex][listName][bindingListIndex].setOnTimeValue = '00:00:00';
        break;
      }
      case LightActionTypeEnum.Brightness: {
        bindings[bindingIndex][listName][bindingListIndex].brightness = 100;
        break;
      }
      case LightActionTypeEnum.Temperature: {
        bindings[bindingIndex][listName][bindingListIndex].brightness = 0;
        break;
      }
      case LightActionTypeEnum.RGB: {
        bindings[bindingIndex][listName][bindingListIndex].r = 255;
        bindings[bindingIndex][listName][bindingListIndex].g = 255;
        bindings[bindingIndex][listName][bindingListIndex].b = 255;
        break;
      }
    }
  };

  return (
    <div style={{ paddingBottom: lightActionType === LightActionTypeEnum.Brightness ? 72 : 0 }}>
      <InputSelect
        value={lightActionType}
        onChange={handleChangeLightActionType}
        options={availableModes}
        label={t('bindings.actionType')}
        className="input--reverse"
      />
      {lightActionType === LightActionTypeEnum.SetOnTimeValue &&
        bindings[bindingIndex][listName][bindingListIndex].setOnTimeValue && (
          <SetOnTimeAction
            value={bindings[bindingIndex][listName][bindingListIndex].setOnTimeValue}
            bindingIndex={bindingIndex}
            bindingListIndex={bindingListIndex}
            listName={listName}
          />
        )}
      {lightActionType === LightActionTypeEnum.RGB && (
        <RgbPicker rgbValue={rgbValue} handleColorPicked={handleColorPicked} sliderColor={sliderColor} />
      )}
      {lightActionType === LightActionTypeEnum.Brightness && (
        <LightSlider value={brightness} onChange={handleChange} onPointerUp={handleBrightness} />
      )}
      {lightActionType === LightActionTypeEnum.Temperature && (
        <TemperatureComponent
          temperature={temperature}
          onChangeTemperature={onChangeTemperature}
          handleTemperature={handleTemperature}
        />
      )}
    </div>
  );
};
