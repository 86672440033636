import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { EnergyMeasurementParameter } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { StateHistoryErrorCode } from 'lavva.exalushome/build/js/Services/StatesHistory/IStatesHistoryService';
import { EnergyHistory, StateDataResponse } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ControlWrapper, DatePicker, InputSelect, TimeRanges } from '../../../../../../components';
import { useBackdropContext, useInstallation } from '../../../../../../hooks';
import { Phases } from '../../../../../channel-details/measurements/components/phases-button-group/phases';
import { useExalusServicesContext } from '../../../../context/services';
import { energyParams } from '../../../../pages/action-create/utils/energy-parameters';
import { useMeterPhases } from '../../controls/meter/hooks/use-meter-phases';
import { useHistoryState } from '../../hooks/use-history-state';
import ChartPagination from '../components/chart-pagination';
import ChartSummary from '../components/chart-summary';
import CustomizedXTick from '../components/customized-x-tick';
import { useHistoryCharts } from '../hooks/use-history-charts';
import { stateInterval } from '../types';

type ComponentProps = {
  channel: IDeviceChannel;
  active?: boolean;
};

const limit = 744;

const EnergyChart: React.FC<ComponentProps> = ({ channel, active }) => {
  const { t } = useTranslation('channel-details');
  const [state, setState] = useState<StateDataResponse<EnergyHistory> | null>(null);
  const { turnOnBackdrop, turnOffBackdrop, backdropOpen } = useBackdropContext();
  const { historyStatesApi } = useExalusServicesContext();
  const { handleHistoryError } = useHistoryState(channel);
  const { selectedInstallation } = useInstallation();
  const { mappedPhases, handlePhaseChange } = useMeterPhases(channel);
  const { offSet, setOffSet, activeRange, onClickActiveMeasurementRange, formatDate } = useHistoryCharts();
  const [activeAggregatedParameter, setActiveAggregatedParameter] = useState<EnergyMeasurementParameter>(
    EnergyMeasurementParameter.ActivePower,
  );

  useEffect(() => {
    const getStates = async () => {
      turnOnBackdrop();
      const result = await historyStatesApi.GetStatesByIntervalAsync<EnergyHistory>(
        channel.GetDevice(),
        channel.Number,
        DeviceResponseType.MeasuredEnergy,
        stateInterval[activeRange],
        limit,
        offSet,
        true,
      );

      if ((result as ResponseResult<StateHistoryErrorCode>).Type) {
        handleHistoryError(result as ResponseResult<StateHistoryErrorCode>);
      } else {
        setState(result as StateDataResponse<EnergyHistory>);
        turnOffBackdrop();
      }
    };

    getStates();
  }, [activeRange, offSet, channel.Number]);

  const currentDate = useMemo(() => {
    if (state?.Data.reverse().length) {
      return new Date(state?.Data[0].Time);
    }

    return null;
  }, [state]);

  const options = useMemo(
    () => [
      {
        label: t('exalus.energyMeasurements.types.ActivePower'),
        value: EnergyMeasurementParameter.ActivePower,
      },
      {
        label: t('exalus.energyMeasurements.types.ReactivePower'),
        value: EnergyMeasurementParameter.ReactivePower,
      },
      {
        label: t('exalus.energyMeasurements.types.Voltage'),
        value: EnergyMeasurementParameter.Voltage,
      },
      {
        label: t('exalus.energyMeasurements.types.Current'),
        value: EnergyMeasurementParameter.Current,
      },
    ],
    [],
  );

  const data = useMemo(() => {
    return state?.Data.map((x) => {
      const measurement = x.Values?.MeasurementAveragingParameters.get(activeAggregatedParameter);

      return {
        date: formatDate(x.Time),
        [t(`exalus.energyMeasurements.types.${activeAggregatedParameter}`)]: measurement?.Average,
      };
    });
  }, [state, activeAggregatedParameter]);

  const handleOnChange = (value: EnergyMeasurementParameter) => setActiveAggregatedParameter(value);

  const aggregatedData = useMemo(() => {
    return state?.AggregateDataList.find((x) => x.DataEntryIdentity === activeAggregatedParameter)?.AggregateData;
  }, [state?.AggregateDataList, activeAggregatedParameter]);

  return (
    <>
      <ControlWrapper className={classNames('control-wrapper--full-space no-center', { active: active })}>
        <div className="exalus-chart-container">
          <InputSelect
            options={options}
            value={activeAggregatedParameter}
            onChange={handleOnChange}
            className="input--reverse m-b-32"
            label={''}
          />
          <TimeRanges
            activeMeasurementRange={activeRange}
            setActiveMeasurementRange={onClickActiveMeasurementRange}
            disableMonth
            disableYear
          />
          {currentDate && (
            <DatePicker
              showDateButtons={false}
              activeDay={currentDate}
              activeMeasurementRange={activeRange}
              setActiveDay={() => null}
              showHours
            />
          )}
          {data && data.length > 0 ? (
            <div className="binary-chart">
              <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                  width={500}
                  height={150}
                  data={data}
                  margin={{
                    top: 15,
                    right: 20,
                    left: 5,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="date" tick={<CustomizedXTick />} height={44} />
                  <YAxis unit={energyParams[activeAggregatedParameter].unit} />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey={t(`exalus.energyMeasurements.types.${activeAggregatedParameter}`)}
                    stroke={selectedInstallation?.hexColor}
                    fill={selectedInstallation?.hexColor}
                  />
                </AreaChart>
              </ResponsiveContainer>
              {channel.GetDevice().Channels.length > 1 && (
                <div className="energy-consumption__button-group">
                  <Phases
                    mappedPhases={mappedPhases}
                    selectedPhases={[channel.Number]}
                    onClickPhase={handlePhaseChange}
                  />
                </div>
              )}
              <ChartPagination offSet={offSet} setOffSet={setOffSet} isNextAvailable={!!state?.IsNextPageAvailable} />
            </div>
          ) : (
            <>{!backdropOpen && <p className="empty-states">{t('chartEmptyState.header')}</p>}</>
          )}
        </div>
      </ControlWrapper>
      {aggregatedData && (
        <ChartSummary
          activeRange={activeRange}
          aggregateData={aggregatedData}
          unit={energyParams[activeAggregatedParameter].unit}
        />
      )}
    </>
  );
};

export default EnergyChart;
