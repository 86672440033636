import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { RgbColorListItem } from '../../../../components/color-list-item';
import { RgbSceneColor } from '../../../../context';

type DeviceBoxSortableProps = {
  color: RgbSceneColor;
};

export const RgbColorListItemSortable: React.FC<DeviceBoxSortableProps> = ({ color }) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({ id: color.id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    opacity: isDragging ? '0' : '1',
  };

  return (
    <RgbColorListItem
      id={color.id}
      colorItem={color}
      isDraggable
      setNodeRef={setNodeRef}
      style={style}
      attributes={attributes}
      listeners={listeners}
    />
  );
};
