import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupTabs, Header, NavHeader } from '../../../components';

type GroupSortChannelsHeaderProps = {
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
};

export const GroupSortChannelsHeader: React.FC<GroupSortChannelsHeaderProps> = ({
  activeTabIndex,
  setActiveTabIndex,
}) => {
  const { t } = useTranslation('groups');

  return (
    <>
      <NavHeader isIconClose />
      <Header title={t('channelsOrder')} />
      <GroupTabs activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} />
    </>
  );
};
