import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { ChannelTypeInternal } from '../../../data-access/gql-types/graphql';
import { InfoPageType } from '../types';
import CoverConfiguration from './cover';
import GateConfiguration from './gate';
import LightConfiguration from './light';
import MeterConfiguration from './meter';
import OptimizerConfiguration from './optimizer';
import SwitchConfiguration from './switch';

type ComponentProps = {
  setInfoPage: Dispatch<SetStateAction<InfoPageType>>;
};

const ChannelConfigurationForm: React.FC<ComponentProps> = ({ setInfoPage }) => {
  const { channelType } = useParams<{ channelType: ChannelTypeInternal }>();

  switch (channelType) {
    case ChannelTypeInternal.Blind:
      return <CoverConfiguration setInfoPage={setInfoPage} />;
    case ChannelTypeInternal.Gate:
      return <GateConfiguration setInfoPage={setInfoPage} />;
    case ChannelTypeInternal.Switch:
      return <SwitchConfiguration setInfoPage={setInfoPage} />;
    case ChannelTypeInternal.Light:
      return <LightConfiguration setInfoPage={setInfoPage} />;
    case ChannelTypeInternal.Meter:
      return <MeterConfiguration />;
    case ChannelTypeInternal.Optimizer:
      return <OptimizerConfiguration />;
    default:
      return null;
  }
};

export default ChannelConfigurationForm;
