import React from 'react';
import { useTranslation } from 'react-i18next';
import { TutorialStep } from '../../../types';
import './index.scss';

type ComponentProps = {
  index: number;
  step: TutorialStep;
};

export const TutorialStepItem: React.FC<ComponentProps> = ({ index, step }) => {
  const { t } = useTranslation('notifications');

  return (
    <div className="tutorial-step-item">
      <p className="tutorial-step-item--title">{`Krok ${index}: ${t(step.title)}`}</p>
      <p className="tutorial-step-item--content">{t(step.content)}</p>
    </div>
  );
};
