import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { EmptyStateBox, IconWarning } from '../../../../../components';
import { ChannelType } from '../../../enums';
import { getChannelType } from '../../../utils';
import { useHistoryState } from '../hooks/use-history-state';
import BrightnessChart from './charts/brightness';
import CameraChart from './charts/camera';
import EnergyChart from './charts/energy';
import EntryPhoneChart from './charts/entry-phone';
import FloodChart from './charts/flood';
import HumidityChart from './charts/humidity';
import MovementChart from './charts/movement';
import PressureChart from './charts/pressure';
import ReedChart from './charts/reed';
import TemperatureChart from './charts/temperature';
import WindChart from './charts/wind';
import './index.scss';

type ComponentProps = {
  channel: IDeviceChannel;
  active?: boolean;
};

const SensorHistory: React.FC<ComponentProps> = ({ channel, active }) => {
  const { t } = useTranslation('channel-details');
  const { getAvailableStates, stateType } = useHistoryState(channel);

  useEffect(() => {
    getAvailableStates();
  }, [channel]);

  const chart = useMemo(() => {
    switch (stateType) {
      case DeviceResponseType.MovementSensorState: {
        return <MovementChart channel={channel} active={active} />;
      }
      case DeviceResponseType.FloodSensorState: {
        return <FloodChart channel={channel} active={active} />;
      }
      case DeviceResponseType.ReedState: {
        return <ReedChart channel={channel} active={active} />;
      }
      case DeviceResponseType.MeasuredTemperature: {
        return <TemperatureChart channel={channel} active={active} />;
      }
      case DeviceResponseType.PressureSensorState: {
        return <PressureChart channel={channel} active={active} />;
      }
      case DeviceResponseType.MeasuredBrightness: {
        return <BrightnessChart channel={channel} active={active} />;
      }
      case DeviceResponseType.HumiditySensorState: {
        return <HumidityChart channel={channel} active={active} />;
      }
      case DeviceResponseType.MeasuredWindSpeed: {
        return <WindChart channel={channel} active={active} />;
      }
      case DeviceResponseType.MeasuredEnergy: {
        return <EnergyChart channel={channel} active={active} />;
      }
      case DeviceResponseType.OnlineCamera: {
        return <CameraChart channel={channel} />;
      }
      default:
        if (getChannelType(channel) === ChannelType.EntryPhone) return <EntryPhoneChart channel={channel} />;
        return (
          <div className="p-t-16 p-l-24 p-r-24">
            <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
          </div>
        );
    }
  }, [stateType, channel, active]);

  return <div className="charts-wrapper">{chart}</div>;
};

export default SensorHistory;
