import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { IconBullet } from '../../../../components';
import { Trigger } from '../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../hooks';
import { formatExecution, getCurrentDate, getPlannedExecutionDate } from '../../utils';

type TriggerNextExecutionProps = {
  trigger: Trigger;
};

export const TriggerNextExecution: React.FC<TriggerNextExecutionProps> = ({ trigger }) => {
  const { t } = useTranslation('action');
  const { selectedInstallation } = useInstallation();

  const nextExecutions = useMemo(() => {
    const current = getCurrentDate();

    return trigger.executionInfo
      .filter((item) => {
        const plannedExecutionDate = getPlannedExecutionDate(item);
        return plannedExecutionDate.getTime() >= current.getTime();
      })
      .map((x) => formatExecution(x, selectedInstallation?.payload?.timeZone || ''));
  }, [trigger.executionInfo]);

  const nextCalculateLabel = useMemo(() => {
    const { priceAndTimeCondition } = trigger;

    return (
      priceAndTimeCondition?.priceGreaterThanOrEqual !== null ||
      priceAndTimeCondition?.priceLesserThanOrEqual !== null ||
      priceAndTimeCondition?.numberOfCheapestPeriodsInDay !== null
    );
  }, [trigger.priceAndTimeCondition]);

  return (
    <>
      <div className="trigger-details__execution--next">
        <IconBullet />
        <p>{`${t('trigger.nextFireTime')}: ${
          !nextExecutions.length
            ? nextCalculateLabel
              ? t('trigger.nextFireTimeRecalculate')
              : t('trigger.nextFireTimeNull')
            : ''
        }`}</p>
      </div>
      {nextExecutions.length
        ? orderBy(nextExecutions, 'date', 'asc').map((x, i) => (
            <div className="trigger-details__execution--next p-l-24" key={i}>
              <p>{x.execution}</p>
            </div>
          ))
        : null}
    </>
  );
};
