import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type DeviceListInterface = {
  updateMode: boolean;
  setUpdateMode: Dispatch<SetStateAction<boolean>>;
  selectedDeviceIds: string[];
  toggleDevice: (deviceId: string) => void;
  showUpdateAllDialog: boolean;
  setShowUpdateAllDialog: Dispatch<SetStateAction<boolean>>;
};

const initialState: DeviceListInterface = {
  updateMode: false,
  setUpdateMode: () => null,
  selectedDeviceIds: [],
  toggleDevice: () => null,
  showUpdateAllDialog: false,
  setShowUpdateAllDialog: () => null,
};

export const DeviceListContext = createContext(initialState);

export const useDeviceListContext = (): DeviceListInterface => useContext(DeviceListContext);

const DeviceListProvider: React.FC = ({ children }) => {
  const [updateMode, setUpdateMode] = useState<boolean>(initialState.updateMode);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>(initialState.selectedDeviceIds);
  const [showUpdateAllDialog, setShowUpdateAllDialog] = useState<boolean>(false);

  const toggleDevice = (deviceId: string) => {
    setSelectedDeviceIds((prev) => {
      const tempList = [...prev];
      const index = tempList.findIndex((x) => x === deviceId);

      if (index !== -1) tempList.splice(index, 1);
      else tempList.push(deviceId);

      return tempList;
    });
  };

  const values: DeviceListInterface = {
    updateMode,
    setUpdateMode,
    selectedDeviceIds,
    toggleDevice,
    showUpdateAllDialog,
    setShowUpdateAllDialog,
  };

  return <DeviceListContext.Provider value={values}>{children}</DeviceListContext.Provider>;
};

export default DeviceListProvider;
