import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ChannelTypeInternal } from '../../../data-access/gql-types/graphql';
import { ChannelGateInterface } from '../../../types';
import { ChannelGeneralConfiguration, ConfigurationFormHookParams } from '../types';
import { useConfiguration } from './use-configuration';

export const useConfigurationForm = ({ channel }: ConfigurationFormHookParams) => {
  const { channelType } = useParams<{ channelType: ChannelTypeInternal }>();
  const { editChannel } = useConfiguration({
    channel,
  });

  const formMethods = useForm<ChannelGeneralConfiguration>({
    defaultValues: {
      name: '',
      icon_name: '',
      hidden: false,
    },
  });

  useEffect(() => {
    if (channel) {
      formMethods.setValue('name', channel.alias);
      formMethods.setValue('hidden', !channel.isVisible);
      if (channel.iconName) formMethods.setValue('icon_name', channel.iconName);

      if (channelType) {
        formMethods.setValue(
          'isPartialControlModeVisible',
          (channel.data as ChannelGateInterface).isPartialControlModeVisible,
        );
        formMethods.setValue('isDailyModeVisible', (channel.data as ChannelGateInterface).isDailyModeVisible);
      }
    }
  }, [channel]);

  const onSubmit = formMethods.handleSubmit((values) => {
    editChannel(values);
  });

  const { hidden, name, icon_name, isPartialControlModeVisible, isDailyModeVisible } = formMethods.watch();

  const handleChangeVisibility = () => formMethods.setValue('hidden', !hidden);

  const submitActive = useMemo(() => {
    if (channel) {
      const active = name !== channel.alias || icon_name !== channel.iconName || !hidden !== channel.isVisible;

      if (channelType === ChannelTypeInternal.Gate) {
        return (
          active ||
          isPartialControlModeVisible !== (channel.data as ChannelGateInterface).isPartialControlModeVisible ||
          isDailyModeVisible !== (channel.data as ChannelGateInterface).isDailyModeVisible
        );
      }

      return active;
    }

    return false;
  }, [channel, name, icon_name, hidden, isPartialControlModeVisible, isDailyModeVisible, channelType]);

  return {
    formMethods,
    submitActive,
    onSubmit,
    hiddenValue: hidden,
    handleChangeVisibility,
  };
};
