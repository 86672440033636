import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AvailableLanguage } from '../../types';

const supportedLanguages = [AvailableLanguage.EN, AvailableLanguage.PL];

export const useFetchDocuments = (type: string) => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const [content, setContent] = useState<string>('');

  const checkAndGetSupportedLang = (lang: string) => {
    return supportedLanguages.includes(lang as AvailableLanguage) ? lang : AvailableLanguage.EN;
  };

  useEffect(() => {
    const lang = new URLSearchParams(search).get('lang');
    const requestLang = checkAndGetSupportedLang(lang || i18n.language);

    fetch(`/documents/${type}/${requestLang}.md`)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, [search]);

  return { content };
};
