import React from 'react';

type IconBinaryStateProps = {
  isOn: boolean;
};

export const IconBinaryState: React.FC<IconBinaryStateProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-sensor icon-binary-state"
      width="38"
      height="30"
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M36.416 14.7441L26.6191 14.7441" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
      {!isOn ? (
        <path
          d="M1.58398 14.7442L12.8303 14.7442L23.4072 8.38934"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      ) : (
        <path
          d="M1.58398 14.7442L12.8303 14.7442L28.5006 14.7442"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      )}
      <circle cx="19" cy="15" r="13.5" stroke="#15141D" strokeWidth="1.5" />
    </svg>
  );
};
