import { UseQueryResult, useQuery } from 'react-query';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { ScenesServiceErrorCode } from 'lavva.exalushome/build/js/Services/Scenes/IScenesService';
import { IRawSequence, ISequence, ISequenceInfo, ScenesUtils } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { useBackdropContext } from '../../../hooks';
import { useExalusContext } from '../context';
import { useExalusServicesContext } from '../context/services';
import { useScenariosErrors } from '../pages/action-list/hooks/use-scenarios-errors';
import { ScenarioSunTimes, ScenarioUtils } from '../types/scenarios.types';

export const useExalusScenarios = (): UseQueryResult<ISequenceInfo[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { scenesApi } = useExalusServicesContext();
  const { handleScenariosErrors } = useScenariosErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<ISequenceInfo[]>(
    ['exalus-scenarios', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const data = await scenesApi.GetSequencesListAsync();
      turnOffBackdrop();

      if ((data as ResponseResult<ScenesServiceErrorCode>).Type) {
        handleScenariosErrors(data as ResponseResult<ScenesServiceErrorCode>);
      } else {
        return data as ISequenceInfo[];
      }

      return [];
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
      onError: () => turnOffBackdrop(),
    },
  );
};

export const useExalusScenario = (id: string): UseQueryResult<(ISequence & IRawSequence) | null> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { scenesApi } = useExalusServicesContext();
  const { handleScenariosErrors } = useScenariosErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<(ISequence & IRawSequence) | null>(
    ['exalus-scenario', selectedExalusCredentials?.id, synchronized, id],
    async () => {
      turnOnBackdrop();
      const data = await scenesApi.GetSequenceAsync(id);
      turnOffBackdrop();

      if ((data as ResponseResult<ScenesServiceErrorCode>).Type) {
        handleScenariosErrors(data as ResponseResult<ScenesServiceErrorCode>);
      } else {
        return data as ISequence & IRawSequence;
      }

      return null;
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized && !!id,
      retry: false,
      onError: () => turnOffBackdrop(),
    },
  );
};

export const useExalusSunTimes = (): UseQueryResult<ScenarioSunTimes> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { scenesApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<ScenarioSunTimes>(
    ['exalus-sunTimes', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const sceneUtils: ScenesUtils = await scenesApi.GetUtilsAsync();
      const sunRiseTime = sceneUtils.GetSunRiseTime();
      const sunSetTime = sceneUtils.GetSunSetTime();
      turnOffBackdrop();

      return {
        sunRiseTime,
        sunSetTime,
      };
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
      onError: () => turnOffBackdrop(),
    },
  );
};

export const useExalusUtils = (): UseQueryResult<ScenarioUtils> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { scenesApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useQuery<ScenarioUtils>(
    ['exalus-utils', selectedExalusCredentials?.id, synchronized],
    async () => {
      turnOnBackdrop();
      const sceneUtils: ScenesUtils = await scenesApi.GetUtilsAsync();
      turnOffBackdrop();

      return {
        parseLuxToLinearScale: sceneUtils.ParseLuxToLinearScale,
        parseLinearScaleToLux: sceneUtils.ParseLinearScaleToLux,
      };
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
      onError: () => turnOffBackdrop(),
    },
  );
};
