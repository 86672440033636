import React, { ChangeEvent, useCallback } from 'react';
import c from 'classnames';
import { SelectOptionInterface } from '../dialog';
import './index.scss';

type ComponentProps<T> = {
  options: SelectOptionInterface<T>[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: T;
  withImage?: boolean;
  textEllipsis?: boolean;
  subLabelFontSize?: number;
  transparent?: boolean;
  accent?: boolean;
};

const RadiosGroup = <T extends string>({
  options,
  onChange,
  value,
  withImage = false,
  textEllipsis = false,
  subLabelFontSize = 14,
  transparent = false,
  accent = false,
}: ComponentProps<T>) => {
  const renderOptions = useCallback(
    () =>
      options.map((opt: SelectOptionInterface<T>) => (
        <label key={opt.value} className={c('', { checked: value === opt.value, transparent: transparent })}>
          <input type="radio" value={opt.value} checked={value === opt.value} onChange={onChange} />
          <span></span>
          {opt.label}
        </label>
      )),
    [options, value],
  );

  const renderImageOptions = useCallback(
    () =>
      options.map((opt: SelectOptionInterface<T>) => (
        <label key={opt.value} className={c('', { checked: value === opt.value, transparent: transparent })}>
          <div className={c('left_section', { 'text-ellipsis': textEllipsis })}>
            {!opt.image ? (
              <div className={c('icon', { accent: accent })}>{opt.icon}</div>
            ) : (
              <img height={40} width={40} src={opt.image} alt={''} />
            )}
            <div className={c('text_container', { 'text-ellipsis': textEllipsis })}>
              {opt.label}
              <span
                className={c('sub_label', { 'text-ellipsis': textEllipsis })}
                style={{ fontSize: subLabelFontSize }}
              >
                {opt.subLabel && opt.subLabel}
              </span>
            </div>
          </div>
          <input type="radio" value={opt.value} checked={value === opt.value} onChange={onChange} />
          <span></span>
        </label>
      )),
    [options, value],
  );

  return (
    <div className={c('radios_group', { with_image: withImage })}>
      {!withImage ? renderOptions() : renderImageOptions()}
    </div>
  );
};

export default RadiosGroup;
