import React, { useMemo } from 'react';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  GateFeatureTypeInternal,
} from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { ChannelCoverStateInterface, ChannelGateStateInterface, ChannelInterface } from '../../../types';
import { IconConnectionError, IconRecovery } from '../../icons';
import { IconCalibrateInfo } from '../../icons/calibrate-info';

type ChannelParams = {
  channel?: ChannelInterface;
  bigIcons?: boolean;
  details?: boolean;
};

export const useChannel = ({ channel, bigIcons, details }: ChannelParams) => {
  const { channelState } = useChannelsState();
  const channelId = channel?.id || '';

  const calibrationNeeded = useMemo(() => {
    if (channel?.id) {
      if (channel.data.type === ChannelTypeInternal.Blind) {
        const calibrateState = (channelState[channelId] as ChannelCoverStateInterface)?.calibrateState;
        return (
          !(calibrateState?.openingMilliseconds && calibrateState?.closingMilliseconds) || calibrateState?.progress
        );
      } else if (
        channel.data.type === ChannelTypeInternal.Gate &&
        channel.data.supportedGateFeatures.includes(GateFeatureTypeInternal.Calibrate)
      ) {
        const calibrateState = (channelState[channelId] as ChannelGateStateInterface)?.calibrateState;
        return (
          !(calibrateState?.openingMilliseconds && calibrateState?.closingMilliseconds) || calibrateState?.progress
        );
      }
    }
  }, [
    (channelState[channelId] as ChannelCoverStateInterface)?.calibrateState,
    (channelState[channelId] as ChannelGateStateInterface)?.calibrateState,
  ]);

  const additionalInfo = useMemo(() => {
    if (channelState[channelId]?.deviceConnectionState === DeviceConnectionState.Disconnected)
      return <IconConnectionError big={bigIcons} details={details} size={14} />;
    if (channelState[channelId]?.deviceConnectionState === DeviceConnectionState.Recovery)
      return <IconRecovery big={bigIcons} details={details} />;
    if (calibrationNeeded) return <IconCalibrateInfo big={bigIcons} details={details} />;
    return null;
  }, [channelState[channelId]?.deviceConnectionState, calibrationNeeded, bigIcons, details]);

  return {
    calibrationNeeded,
    additionalInfo,
  };
};
