import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { useMovementControl } from '../../../hooks/use-movement-control';
import { SensorControlBox } from '../../../sensor-control-box';

type ComponentProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
  details?: boolean;
};

const MovementControl: React.FC<ComponentProps> = ({ channel, isListItem, details }) => {
  const { t } = useTranslation('channel-details');
  const { movement } = useMovementControl(channel);

  return (
    <SensorControlBox isListItem={isListItem} details={details}>
      <span className="sensor-control-box__status">
        {movement === null
          ? t('exalus.sensor.noData')
          : movement
          ? t('exalus.movement.detected')
          : t('exalus.movement.notDetected')}
      </span>
    </SensorControlBox>
  );
};

export default MovementControl;
