import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ExalusInstallationPayload } from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext, useInstallation } from '../../../../../hooks';
import { toastSuccess } from '../../../../../utils/toast';
import { useExalusServicesContext } from '../../../context/services';
import { PopupType } from '../../../enums/controller';
import { useHandleDataFrameErrors } from '../../../hooks';

export const useControllerSettings = () => {
  const { t } = useTranslation('installation');
  const { selectedInstallation } = useInstallation();
  const { configurationApi, devicesApi } = useExalusServicesContext();
  const { turnOnBackdropPopup, turnOffBackdrop, turnOnBackdrop } = useBackdropContext();
  const [popup, setPopup] = useState<boolean>(false);
  const { handleError } = useHandleDataFrameErrors();
  const [popupType, setPopupType] = useState<PopupType | null>(null);
  const [fastSyncEnabled, setFastSyncEnabled] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const controllerInfo = useMemo(
    () => selectedInstallation?.externalPayload as ExalusInstallationPayload,
    [selectedInstallation],
  );

  const showDialog = async (popupType: PopupType) => {
    setPopupType(popupType);

    if (popupType === PopupType.StateSync) {
      turnOnBackdrop();
      const result = await devicesApi.IsFastStatesSyncEnabledAsync();
      turnOffBackdrop();

      if (typeof result === 'boolean') {
        setFastSyncEnabled(result);
        setPopup(true);
      } else handleError(result as Status);
    } else {
      setPopup(true);
    }
  };

  const handleConfirm = useCallback(async () => {
    setLoading(true);

    switch (popupType) {
      case PopupType.Restart: {
        const result = await configurationApi.RestartControllerAsync((progress: number) => {
          turnOnBackdropPopup({
            message: t('exalus.restartInProgress', { progress: progress.toFixed(1) }),
            loader: true,
          });
        });

        setPopup(false);
        setLoading(false);

        if (result === Status.OK) {
          turnOnBackdropPopup({
            message: t('exalus.restartSuccess'),
            buttonTitle: 'OK',
            handleClick: () => window.location.reload(),
          });
        } else handleError(result);

        break;
      }
      case PopupType.Reset: {
        const result = await configurationApi.FactoryResetAsync((progress: number) => {
          turnOnBackdropPopup({
            message: t('exalus.resetInProgress', { progress: progress.toFixed(1) }),
            loader: true,
          });
        });

        setPopup(false);
        setLoading(false);

        if (result === Status.OK) {
          turnOnBackdropPopup({
            message: t('exalus.resetSuccess'),
            buttonTitle: 'OK',
            handleClick: () => window.location.reload(),
          });
        } else handleError(result);

        break;
      }
      case PopupType.Rollback: {
        const result = await configurationApi.RollbackLastUpdateAsync((progress: number) => {
          turnOnBackdropPopup({
            message: t('exalus.rollbackInProgress', { progress: progress.toFixed(1) }),
            loader: true,
          });
        });

        setPopup(false);
        setLoading(false);

        if (result === Status.OK) {
          turnOnBackdropPopup({
            message: t('exalus.rollbackSuccess'),
            buttonTitle: 'OK',
            handleClick: () => window.location.reload(),
          });
        } else handleError(result);

        break;
      }
      case PopupType.StateSync: {
        turnOnBackdrop();
        let result: Status;

        if (fastSyncEnabled !== null) {
          if (fastSyncEnabled) {
            result = await devicesApi.DisableFastStatesSyncAsync();
          } else {
            result = await devicesApi.EnableFastStatesSyncAsync();
          }

          setLoading(false);

          if (result === Status.OK) {
            toastSuccess({
              content: t(`exalus.${popupType?.toLowerCase()}${fastSyncEnabled ? 'Off' : 'On'}Success`),
            });
          } else {
            handleError(result);
          }

          setPopup(false);
          turnOffBackdrop();
        }
        break;
      }
    }
  }, [popupType, fastSyncEnabled]);

  return {
    popup,
    popupType,
    controllerInfo,
    fastSyncEnabled,
    loading,
    setPopup,
    showDialog,
    handleConfirm,
  };
};
