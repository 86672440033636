import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { groupBy } from 'lodash';
import {
  CustomBackdrop,
  ChannelIcon,
  DialogConfirmation,
  Header,
  NavHeader,
  Page,
  PopUpNav,
  SubmitButton,
  Tabs,
  EmptyStateBox,
} from '../../../components';
import { IconDelete, IconUserAccess } from '../../../components/popup-nav/icons';
import { ModifyShareInfoItemBaseInput, ShareInfoModificationType } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { ROUTES } from '../../../routes';
import { ChannelSharingInfo, SharedItemType, SharingType } from '../../../types';
import { leadingZero } from '../../../utils/helpers';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import { useModify } from '../hooks/use-modify';
import './index.scss';

type RemoveChannel = {
  channelId: string;
  channelName: string;
};

type ChannelGroup = {
  label: string;
  list: (ChannelSharingInfo | null)[];
};

const ChannelsShareEdit: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('installation');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { methods } = useInstallationShare();
  const { modyfyChannelAccess, modifyUserAccessesLoading } = useModify();
  const { userSharing, userSharingLoading, refetchSharingInfo } = useMember(true);
  const [removeAccessPopup, setRemoveAccessPopup] = useState<boolean>(false);
  const { channelList } = useDevicesAndChannelsContext();
  const [channelRemove, setChannelRemove] = useState<RemoveChannel>({ channelId: '', channelName: '' });

  const editAccess = (channel: ChannelSharingInfo) => {
    if (userSharing?.sharingInfo) {
      methods.handleResetShareInstallation();
      methods.handleSetModificationType(ShareInfoModificationType.Modify);
      methods.handleSetShareSubject(SharedItemType.Channel);
      methods.handleSetUserId(userSharing.sharingInfo.sharedToUserId || '');
      methods.handleSetEditedId(channel.channelId);
      if (channel.sharedFrom && channel.sharedUntil) {
        methods.handleSetShareAccess(SharingType.Timed);
        const dateStart = new Date(channel.sharedFrom);
        methods.handleSetShareDateStart(dateStart);
        methods.handleSetShareTimeStart([dateStart.getHours(), dateStart.getMinutes()].map(leadingZero).join(':'));
        const dateEnd = new Date(channel.sharedUntil);
        methods.handleSetShareDateEnd(dateEnd);
        methods.handleSetShareTimeEnd([dateEnd.getHours(), dateEnd.getMinutes()].map(leadingZero).join(':'));
      } else if (channel.grantedUses) {
        methods.handleSetShareAccess(SharingType.Quantitative);
        methods.handleSetShareQuantity(channel.grantedUses);
      } else methods.handleSetShareAccess(SharingType.Permanent);
      history.push(ROUTES.InstallationShareRestriction());
    }
  };

  const deletePopup = (channel: ChannelSharingInfo) => {
    setChannelRemove({ channelId: channel.channelId, channelName: channel.channelName });
    setRemoveAccessPopup(true);
  };

  const removeAccess = () => {
    const channels: ModifyShareInfoItemBaseInput[] = [
      { id: channelRemove.channelId, shareInfoModificationType: ShareInfoModificationType.Remove },
    ];

    modyfyChannelAccess(userSharing?.sharingInfo?.sharedToUserId || '', channels, () => {
      setChannelRemove({ channelId: '', channelName: '' });
      setRemoveAccessPopup(false);
      refetchSharingInfo();
    });
  };

  const channelGroups = useMemo(() => {
    const channels: ChannelGroup[] = [];

    if (userSharing?.sharingInfo) {
      const list = userSharing?.sharingInfo.channels.map((ch) => {
        const channel = channelList.find((y) => y.id === ch.id);

        if (channel) {
          return {
            channelId: channel.id,
            channelName: channel.alias,
            iconName: channel.iconName,
            channelType: channel.data.type,
            sharedFrom: ch.sharedFrom,
            sharedUntil: ch.sharedFrom,
            grantedUses: ch.grantedUses,
          } as ChannelSharingInfo;
        }

        return null;
      });

      const channelGroups = groupBy(list, 'channelType');

      if (channelGroups) {
        if (list.length) channels.push({ label: tc('all'), list: list });

        Object.keys(channelGroups).forEach((type) => {
          channels.push({ label: tc(`typesPlural.${type}`), list: channelGroups[type] });
        });
      }
    }

    return channels;
  }, [userSharing, channelList, tc]);

  const channelsList = useMemo(() => {
    if (!channelGroups.length) return <EmptyStateBox content={t('shared_user.channels_empty')} />;

    const type = Object.values(channelGroups)[activeTabIndex];

    if (type && userSharing?.sharingInfo?.channels) {
      return type.list.map((channel) => {
        if (channel) {
          const sharingType =
            channel.sharedFrom && channel.sharedUntil
              ? SharingType.Timed
              : channel.grantedUses
              ? SharingType.Quantitative
              : SharingType.Permanent;

          return (
            <div key={channel.channelId} className="share-channelsEdit-item">
              <div className="left-section">
                <div className="icon">
                  <ChannelIcon
                    statusOn
                    channel={
                      {
                        id: channel.channelId,
                        iconName: channel.iconName,
                        data: { type: channel.channelType, status: '' },
                      } as any
                    }
                  />
                </div>
                <div>
                  <p>{channel.channelName}</p>
                  <p className="access">
                    {sharingType === SharingType.Timed
                      ? `${t('share_path.access')} ${t('share_path.from')} ${format(
                          new Date(channel.sharedFrom),
                          'dd.MM.yyyy, HH:mm',
                        )} ${t('share_path.to')} ${format(new Date(channel.sharedUntil), 'dd.MM.yyyy, HH:mm')}`
                      : `${t('share_path.access')} ${t(`share_path.access_${sharingType.toLowerCase()}`)}`}
                  </p>
                </div>
              </div>
              <PopUpNav
                dark
                links={[
                  {
                    label: t('shared_user.edit_access'),
                    onClick: () => editAccess(channel),
                    hasLineBelow: true,
                    icon: <IconUserAccess />,
                  },
                  {
                    label: t('shared_user.remove_access'),
                    onClick: () => deletePopup(channel),
                    hasLineBelow: false,
                    icon: <IconDelete />,
                  },
                ]}
              />
            </div>
          );
        }
      });
    }

    return [];
  }, [channelGroups, activeTabIndex, userSharing]);

  const handleNext = () => {
    if (userSharing?.sharingInfo) {
      methods.handleResetShareInstallation();
      methods.handleSetModificationType(ShareInfoModificationType.Add);
      methods.handleSetShareSubject(SharedItemType.Channel);
      methods.handleSetUserId(userSharing.sharingInfo.sharedToUserId || '');
      methods.handleSetShareEmail(userSharing.sharingInfo.sharedToUser?.email || '');
      methods.handleSetSharePermission(userSharing.sharingInfo.installation.accessType);
      history.push(ROUTES.InstallationChannelsShare());
    }
  };

  return (
    <Page
      className="share-channelsEdit"
      header={
        <>
          <NavHeader />
          <Header title={t('shared_user.channels')} isUnderline />
          {channelGroups.length > 0 && (
            <Tabs
              tabList={[...channelGroups]}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setActiveTabIndex}
              tabsType="pills"
              isDivider={false}
            />
          )}
        </>
      }
    >
      {!userSharingLoading ? channelsList : <CustomBackdrop loading={userSharingLoading} />}
      <SubmitButton type="button" onClick={handleNext}>
        {t('shared_user.share_add_access')}
      </SubmitButton>
      <DialogConfirmation
        show={removeAccessPopup}
        setShow={setRemoveAccessPopup}
        header={t('shared_user.remove_device_access')}
        content={t('shared_user.remove_device_access_confirm', {
          user: userSharing?.sharingInfo?.sharedToUser?.firstName,
          item: channelRemove.channelName,
        })}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={!modifyUserAccessesLoading ? t('shared_user.remove_access') : tc('buttons.loading')}
        secondaryBtnAction={() => setRemoveAccessPopup(false)}
        primaryBtnAction={removeAccess}
        direction="column"
      />
    </Page>
  );
};

export default ChannelsShareEdit;
