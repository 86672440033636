import React, { createContext } from 'react';
import { ApiStateInterface } from '../../types';
import { useChannelsGetAll } from './channels-get-all';
import { useDevicesGetAll } from './devices-get-all';
import { useGroupAndAction } from './group-and-action';
import { useInvitations } from './invitation';
import { useMapVariables } from './map-variables';
import { useNotification } from './notification';
import { useUserActions } from './user-actions';

const initialState: ApiStateInterface = {
  groups: [],
  userActions: [],
  notificationList: [],
  allInvitations: [],
  dashboardLoading: false,
  notificationsLoading: false,
  channelsLoading: false,
  devicesLoading: false,
  allInvitationsLoading: false,
  favouriteGroup: undefined,
  measurementMap: null,
  convertNumberToMeasurement: () => null,
  convertMeasurementToNumber: () => null,
  setNotificationList: () => null,
  markAsDisplayed: () => null,
  markAsHidden: () => null,
  invitationAcceptById: () => null,
  invitationRejectById: () => null,
};

export const ApiContext = createContext(initialState);

const ApiContextProvider: React.FC = ({ children }) => {
  const devicesGetAll = useDevicesGetAll();
  const channelsGetAll = useChannelsGetAll();
  const groupAndAction = useGroupAndAction();
  const notifications = useNotification();
  const invitations = useInvitations();
  const userActions = useUserActions();
  const mapVariables = useMapVariables();

  const values: ApiStateInterface = {
    ...devicesGetAll,
    ...channelsGetAll,
    ...groupAndAction,
    ...notifications,
    ...invitations,
    ...userActions,
    ...mapVariables,
  };

  return <ApiContext.Provider value={values}>{children}</ApiContext.Provider>;
};

export default ApiContextProvider;
