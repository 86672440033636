import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNative } from '../../../hooks';
import * as storage from '../../../utils/storage/lavva';
import { toastSuccess } from '../../../utils/toast';

export const useVibrationSettings = () => {
  const { t } = useTranslation('profile');
  const { setVibration } = useNative();

  const optionsVibration = useMemo(
    () => [
      { value: 'true', label: t('vibration.on') },
      { value: 'false', label: t('vibration.off') },
    ],
    [t],
  );

  const optionsVibrationDefaultIndex = useMemo(() => {
    const currentVibration = storage.getItem('vibration');
    const optionIndex = optionsVibration.findIndex((option) => (option.value === 'true') === currentVibration);
    return optionIndex < 0 ? 0 : optionIndex;
  }, [optionsVibration, storage]);

  const changeVibration = (vibrationValue: string) => {
    const isVibrationOn = vibrationValue === 'true';

    storage.setItem('vibration', isVibrationOn);
    toastSuccess({ content: t(`app-vibration-${isVibrationOn ? 'on' : 'off'}`) });
    setVibration(isVibrationOn);
  };

  return {
    optionsVibration,
    optionsVibrationDefaultIndex,
    changeVibration,
  };
};
