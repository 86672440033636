import React, { createContext } from 'react';
import { useProfile } from '../user';

type UserPermissionsProviderStateInterface = {
  userPermissions: Record<string, boolean>;
  refetchUserMe: () => void;
};

const initialState: UserPermissionsProviderStateInterface = {
  userPermissions: {},
  refetchUserMe: () => null,
};

export const UserPermissionsContext = createContext(initialState);

const UserPermissionsProvider: React.FC = ({ children }) => {
  const { permissions, refetchUserMe } = useProfile();

  const values: UserPermissionsProviderStateInterface = {
    userPermissions: permissions,
    refetchUserMe,
  };

  return <UserPermissionsContext.Provider value={values}>{children}</UserPermissionsContext.Provider>;
};

export default UserPermissionsProvider;
