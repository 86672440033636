import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { IconDelay, IconDelete, IconEdit, IconSortHandle } from '../../../../components';
import { RgbSceneColor, useRgbSceneFormContext } from '../../context';
import { RgbSceneFormView } from '../../enums';
import './index.scss';

type PropsInterface = {
  id: string;
  colorItem?: RgbSceneColor;
  onClick?: (id: string) => void;
  isRemoveMode?: boolean;
  isDraggable?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
};

export const RgbColorListItem: React.FC<PropsInterface> = ({
  id,
  colorItem,
  onClick,
  isRemoveMode = false,
  isDraggable,
  setNodeRef,
  style,
  attributes,
  listeners,
}) => {
  const { t } = useTranslation('channel-details');
  const { colors, setSelectedView, setActiveColorId } = useRgbSceneFormContext();

  const color = colorItem ? colorItem : colors.find((color) => color.id === id);

  const styles = useMemo(() => {
    return {
      '--color': color?.hex,
    } as React.CSSProperties;
  }, [color]);

  const clickEdit = useCallback(() => {
    if (color) {
      setActiveColorId(color.id);
      setSelectedView(RgbSceneFormView.SET_COLOR);
    }
  }, [color]);

  if (!color) {
    return null;
  }

  return (
    <div className="rgb-color-list-item" style={style} ref={setNodeRef}>
      <div className="rgb-color-list-item__color-wrapper">
        <div className="rgb-color-list-item__color" style={styles}></div>

        <div className="rgb-color-list-item__time">
          <IconDelay width={32} height={32} />
          <span className="rgb-color-list-item__time-value">
            {color.swap} {t('rgbScenes.secondsUnitShort')}
          </span>
        </div>

        {isDraggable ? (
          <div className="rgb-color-list-item__draggable-handle" {...attributes} {...listeners}>
            <IconSortHandle />
          </div>
        ) : (
          <div className="rgb-color-list-item__icon" onClick={() => (onClick ? onClick(color.id) : clickEdit())}>
            {isRemoveMode ? <IconDelete /> : <IconEdit />}
          </div>
        )}
      </div>
    </div>
  );
};
