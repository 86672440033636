import React from 'react';
import c from 'classnames';
import { v4 } from 'uuid';

type IconSignalStateProps = {
  signal?: number;
  big?: boolean;
  noState?: boolean;
  channelNumber?: number;
};

export const IconSignalState: React.FC<IconSignalStateProps> = ({ signal = 0, big, noState, channelNumber }) => {
  const id = v4();
  return (
    <>
      <svg
        className={c('icon-sensor icon-signal-state', { big: big, 'no-state': noState })}
        width="36"
        height="35"
        viewBox="0 0 36 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath={`url(#${id})`}>
          <path
            d="M17.6903 1.08853L9.125 1.08853V5.03386L9.125 8.9792L17.6903 8.9792M17.6903 1.08853L26.2557 1.08853V8.9792L17.6903 8.9792M17.6903 1.08853V5.03386V8.9792"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {signal > 70 ? (
            <>
              <path
                d="M4.13477 27.8866C7.5401 31.4975 12.3278 33.7448 17.6307 33.7448C23.0497 33.7448 27.9307 31.398 31.3483 27.6475"
                stroke="#15141D"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M7.59766 23.5199C10.1083 26.1821 13.6383 27.839 17.548 27.839C21.5433 27.839 25.142 26.1088 27.6617 23.3436"
                stroke="#15141D"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </>
          ) : null}
          {signal > 10 ? (
            <path
              d="M10.7676 19.3989C12.4854 21.2205 14.9006 22.3541 17.5757 22.3541C20.3093 22.3541 22.7716 21.1703 24.4956 19.2783"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          ) : null}
          {signal > 5 ? (
            <path
              d="M12.8789 14.5795C14.0682 15.8406 15.7402 16.6254 17.5922 16.6254C19.4847 16.6254 21.1894 15.8058 22.3829 14.496"
              stroke="#15141D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          ) : null}
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="34.8333" height="34.8333" fill="white" transform="translate(0.583984)" />
          </clipPath>
        </defs>
      </svg>
      {channelNumber && <div className="channel-number">{channelNumber}</div>}
    </>
  );
};
