import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ReedStateHistory, StateData } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';
import { uniqBy } from 'lodash';
import { dataPickerFormat } from '../../../../../../components';
import { IconPadlock } from '../../../../../../components/icons';
import { MeasurementRange } from '../../../../../../types';
import { convertDateToFormat } from '../../../../../../utils/helpers';
import { Section } from '../types';

export const useDetailsList = (data?: StateData<ReedStateHistory>[]) => {
  const { t } = useTranslation('common');
  const [sections, setSections] = useState<Section[]>([]);

  const formatDate = (date: Date) =>
    convertDateToFormat(
      date,
      dataPickerFormat[MeasurementRange.DayInHours].format[0],
    );

  useEffect(() => {
    if (data) {
      const uniqedData = uniqBy(data, (x) => x.Time);
      const sectionsTemp: Section[] = [];

      for (let i = 0; i < uniqedData.length; i++) {
        const formatedDate = formatDate(new Date(uniqedData[i].Time));
        const index = sectionsTemp.findIndex((x) => x.date);

        if (index !== -1) {
          if (uniqedData[i].Values?.State !== uniqedData[i - 1].Values?.State) {
            sectionsTemp[index].items.push({
              icon: <IconPadlock isOn={!!uniqedData[i].Values?.State} />,
              state: t(uniqedData[i].Values?.State ? 'opened' : 'closed'),
              period: `${format(new Date(uniqedData[i].Time), 'HH:mm:ss')} - ${
                sectionsTemp[index].items[sectionsTemp[index].items.length - 1].period.split(' - ')[0]
              }`,
            });
          } else {
            if (sectionsTemp[index].items.length <= 1) {
              sectionsTemp[index].items[sectionsTemp[index].items.length - 1].period = `${format(
                new Date(uniqedData[i].Time),
                'HH:mm:ss',
              )} - ${
                sectionsTemp[index].items[sectionsTemp[index].items.length - 1].period.split(' - ')[1] !== t('now')
                  ? sectionsTemp[index].items[sectionsTemp[index].items.length - 1].period.split(' - ')[0]
                  : t('now')
              }`;
            }
          }
        } else {
          sectionsTemp.push({
            date: formatedDate,
            items: [
              {
                icon: <IconPadlock isOn={!!uniqedData[i].Values?.State} />,
                state: t(uniqedData[i].Values?.State ? 'opened' : 'closed'),
                period: `${format(new Date(uniqedData[i].Time), 'HH:mm:ss')} - ${t('now')}`,
              },
            ],
          });
        }
      }

      setSections(sectionsTemp);
    }
  }, [data]);

  return {
    sections,
  };
};
