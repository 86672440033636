import React, { Dispatch, SetStateAction } from 'react';
import { Header, IconChevron } from '../../../../../../../components';
import './index.scss';

type PropsInterface = {
  prevAvailable: boolean;
  nextAvailable: boolean;
  range: string;
  setPage: Dispatch<SetStateAction<number>>;
};

export const RangeHeader: React.FC<PropsInterface> = ({ prevAvailable, nextAvailable, range, setPage }) => (
  <div className="analyse-range-header" style={{ display: 'flex', alignItems: 'center' }}>
    <button disabled={!prevAvailable} onClick={() => setPage((prev) => prev - 1)}>
      <IconChevron />
    </button>
    <Header title={range} center />
    <button disabled={!nextAvailable} onClick={() => setPage((prev) => prev + 1)}>
      <IconChevron direction="right" />
    </button>
  </div>
);
