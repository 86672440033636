import React from 'react';
import c from 'classnames';
import classNames from 'classnames';
import './index.scss';

type ComponentProps = {
  isListItem?: boolean;
  value?: number | string | null;
  unit?: string;
  unitAsDegree?: boolean;
  details?: boolean;
};

export const SensorControlBox: React.FC<ComponentProps> = ({
  isListItem,
  value,
  unit,
  unitAsDegree = false,
  details = false,
  children,
}) => {
  return (
    <div
      className={c('sensor-control-box', {
        'sensor-control-box--list-view': isListItem,
        'sensor-control-box--details': details,
      })}
    >
      {children ? (
        <>{children}</>
      ) : (
        <div className="sensor-control-box__value-wrapper">
          <span className="sensor-control-box__value">{value !== null ? value : '-'}</span>
          <span
            className={classNames('sensor-control-box__unit', {
              'sensor-control-box__unit--degree': unitAsDegree,
            })}
          >
            {unit}
          </span>
        </div>
      )}
    </div>
  );
};
