import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/IRcr21ConfigService';
import {
  LuxSettingThreshold,
  MoveSensorExternalOutputMode,
  MoveSensorMode,
  MovementDetectorParams,
  AccLevel as AccLevelType,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigParams';
import { Rcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { Slider } from '@mui/material';
import { Checkbox, InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type ComponentProps = {
  device: IDevice;
  channel: IDeviceChannel;
  params: MovementDetectorParams | null;
};

const DetectorForm: React.FC<ComponentProps> = ({ device, channel, params }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const [syncError, setSyncError] = useState<string>('');
  const form = useForm<MovementDetectorParams>();

  const { AccLevel, SensorMode, ExternalOutMode, AccEvent, MoveLEDSignalization, OpticalLEDSignalization, LuxSetting } =
    form.watch();

  useEffect(() => {
    if (params !== null) {
      form.setValue('OffTime', params.OffTime);
      form.setValue('SyncTime', params.SyncTime);
      form.setValue('AccLevel', params.AccLevel);
      form.setValue('SensorMode', params.SensorMode);
      form.setValue('ExternalOutMode', params.ExternalOutMode);
      form.setValue('AccEvent', params.AccEvent);
      form.setValue('MoveLEDSignalization', params.MoveLEDSignalization);
      form.setValue('OpticalLEDSignalization', params.OpticalLEDSignalization);
      form.setValue('LuxSetting', params.LuxSetting);
    }
  }, [params]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (channel) {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcr21ConfigService>(
        Rcr21ConfigService.ServiceName,
      );

      if (values.SyncTime % 10 !== 0) {
        setSyncError(t('exalus.params.Rcr21Params.SyncStepError'));
        turnOffBackdrop();
        return;
      }

      const result = await configService.SetMovementDetectorParamsAsync(
        {
          DeviceGuid: device.Guid,
          AccLevel: values.AccLevel,
          OffTime: values.OffTime,
          SyncTime: values.SyncTime,
          SensorMode: values.SensorMode,
          ExternalOutMode: values.ExternalOutMode,
          AccEvent: values.AccEvent,
          MoveLEDSignalization: values.MoveLEDSignalization,
          OpticalLEDSignalization: values.OpticalLEDSignalization,
          LuxSetting: values.LuxSetting,
          CurrentLuxValue: params?.CurrentLuxValue || 0,
        },
        channel.Number,
      );
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const onChangeAccLevel = (value: AccLevelType) => form.setValue('AccLevel', value);

  const onChangeSensorMode = (value: MoveSensorMode) => form.setValue('SensorMode', value);

  const onChangeExternalOutMode = (value: MoveSensorExternalOutputMode) => form.setValue('ExternalOutMode', value);

  const onChangeLuxSetting = (event: Event, value: number | number[]) => {
    form.setValue('LuxSetting', value as LuxSettingThreshold);
  };

  const marks = useMemo(
    () => Array.from(Array(66).keys()).map((x) => ({ label: params?.CurrentLuxValue === x ? '|' : '', value: x })),
    [params?.CurrentLuxValue, SensorMode, LuxSetting],
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <ConfigurationInput
          name="OffTime"
          label={t('exalus.params.Rcr21Params.DimmingTime')}
          placeholder={t('exalus.params.Rcr21Params.DimmingTime')}
          inputType="number"
          min={10}
          max={3600}
        />
        <ConfigurationInput
          name="SyncTime"
          label={t('exalus.params.Rcr21Params.SyncTime')}
          placeholder={t('exalus.params.Rcr21Params.SyncTime')}
          inputType="number"
          step="10"
          min={60}
          max={1440}
          errorMessage={syncError}
        />
        <InputSelect
          onChange={onChangeAccLevel}
          value={AccLevel}
          options={[
            { label: t('exalus.params.Rcr21Params.TamperSensorSteps.Low'), value: AccLevelType.Low },
            { label: t('exalus.params.Rcr21Params.TamperSensorSteps.Medium'), value: AccLevelType.Medium },
            { label: t('exalus.params.Rcr21Params.TamperSensorSteps.High'), value: AccLevelType.High },
          ]}
          label={t('exalus.params.Rcr21Params.TamperSensor')}
        />
        <InputSelect
          onChange={onChangeSensorMode}
          value={SensorMode}
          options={[
            {
              label: t('exalus.params.Rcr21Params.WorkModeSteps.NoBrightnessSensor'),
              value: MoveSensorMode.NoBrightnessSensor,
            },
            { label: t('exalus.params.Rcr21Params.WorkModeSteps.NightMode'), value: MoveSensorMode.NightMode },
            { label: t('exalus.params.Rcr21Params.WorkModeSteps.DayMode'), value: MoveSensorMode.DayMode },
          ]}
          label={t('exalus.params.Rcr21Params.WorkMode')}
        />
        <InputSelect
          onChange={onChangeExternalOutMode}
          value={ExternalOutMode}
          options={[
            { label: t('exalus.params.Rcr21Params.ExternalOutputSteps.NO'), value: MoveSensorExternalOutputMode.NO },
            { label: t('exalus.params.Rcr21Params.ExternalOutputSteps.NC'), value: MoveSensorExternalOutputMode.NC },
            {
              label: t('exalus.params.Rcr21Params.ExternalOutputSteps.Disabled'),
              value: MoveSensorExternalOutputMode.Disable,
            },
          ]}
          label={t('exalus.params.Rcr21Params.ExternalOutput')}
        />
        <Checkbox checked={AccEvent} onChange={(e) => form.setValue('AccEvent', e.target.checked)} reverse>
          {t('exalus.params.Rcr21Params.SabotageDetection')}
        </Checkbox>
        <Checkbox
          checked={OpticalLEDSignalization}
          onChange={(e) => form.setValue('OpticalLEDSignalization', e.target.checked)}
          reverse
        >
          {t('exalus.params.Rcr21Params.TransmisionSignalistion')}
        </Checkbox>
        <Checkbox
          checked={MoveLEDSignalization}
          onChange={(e) => form.setValue('MoveLEDSignalization', e.target.checked)}
          reverse
        >
          {t('exalus.params.Rcr21Params.MovementDetection')}
        </Checkbox>
        <div className="m-t-24 m-b-48">
          <label className="input__label">{t('exalus.params.Rcr21Params.ThresholdForBrightness')}</label>
          <Slider
            className="custom-slider"
            defaultValue={20}
            step={1}
            min={0}
            max={65}
            marks={marks}
            value={LuxSetting || 0}
            onChange={onChangeLuxSetting}
            valueLabelDisplay="off"
            disabled={SensorMode === MoveSensorMode.NoBrightnessSensor}
          />
        </div>
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default DetectorForm;
