import React from 'react';

type IconRGBCirclesProps = {
  isOn: boolean;
};

export const IconRGBCircles: React.FC<IconRGBCirclesProps> = ({ isOn = true }) => {
  return isOn ? (
    <svg
      className="icon-switch icon-rgb-circles"
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.2235"
        cy="23.9852"
        r="10.64"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="25.5233"
        cy="23.9852"
        r="10.64"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="18.8734"
        cy="12.015"
        r="10.64"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="11.5565"
        cy="11.5565"
        rx="10.64"
        ry="10.64"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
