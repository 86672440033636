import React from 'react';

type IconPlafonLampProps = {
  isOn: boolean;
};

export const IconPlafonLamp: React.FC<IconPlafonLampProps> = ({ isOn = true }) => {
  return (
    <svg
      className="icon-switch icon-plafon-lamp"
      width="39"
      height="26"
      viewBox="0 0 39 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.40909 5.56818H1C1.5 12.0227 5.90909 25 19.2727 25C32.691 25 37.0455 12.0682 37.5455 5.56818H33.5227M4.40909 5.56818H19.2727H33.5227M4.40909 5.56818V1H33.5227V5.56818"
        stroke="#15141D"
        strokeWidth="1.5"
      />
      {isOn ? (
        <path
          d="M21.4949 20.1447C23.3221 20.5582 25.2933 20.2531 26.9505 19.1353C28.644 17.993 29.6735 16.2289 29.9489 14.3334"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      ) : null}
    </svg>
  );
};
