import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CustomBackdrop, EmptyStateBox, IconWarning, NavHeader, Page, Tabs } from '../../../components';
import ProductList from './components/product-list';
import SeeMoreList from './components/see-more-list';
import { useStore } from './hooks/use-store';
import './index.scss';

const Store: React.FC = () => {
  const { t } = useTranslation('store');
  const { data, isLoading, activeTabIndex, tabs, channelsPerSelectedType, channelsSeeMore, handleChangeTab } =
    useStore();

  return (
    <Page
      className={classNames('store', { empty: !data?.length })}
      contentClassName="page__content--flex"
      header={<NavHeader title={t('ourProducts')} />}
    >
      <>
        {!isLoading && data ? (
          <>
            {data.length ? (
              <>
                <Tabs
                  tabList={tabs}
                  activeTabIndex={activeTabIndex}
                  setActiveTabIndex={handleChangeTab}
                  tabsType="pills"
                  isDivider={false}
                />
                <ProductList channelsPerSelectedType={channelsPerSelectedType} />
                <SeeMoreList channelsSeeMore={channelsSeeMore} />
              </>
            ) : (
              <EmptyStateBox content={t('notFoundDevices')} icon={<IconWarning />} />
            )}
          </>
        ) : null}
      </>
      <CustomBackdrop loading={isLoading} />
    </Page>
  );
};

export default Store;
