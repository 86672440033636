import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Input } from '../../../../../../components';
import { ROUTES } from '../../../../../../routes';
import { patterns } from '../../../../../../utils/validation';

type ComponentProps = {
  errorMessage?: string;
};

const GroupName: React.FC<ComponentProps> = ({ errorMessage = '' }) => {
  const { control } = useFormContext();
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const { pathname } = useLocation();

  return (
    <Controller
      name="name"
      control={control}
      rules={{
        required: tc('isRequired') as string,
        maxLength: {
          value: 40,
          message: tc('tooLongName'),
        },
        pattern: {
          value: patterns.name,
          message: tc('invalidName'),
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <Input
          label={t('name')}
          name={field.name}
          defaultValue={field.value}
          autoFocus={pathname === ROUTES.GroupCreate()}
          onChange={field.onChange}
          errorMessage={error?.message || errorMessage}
        />
      )}
    />
  );
};

export default GroupName;
