import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmptyStateBox, Header, IconPdf, IconWarning, NavHeader, Page } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import { useBackdropContext } from '../../../../hooks';
import { useSupportedDeviceById } from '../../../../hooks/supported-devices/supported-devices';
import './index.scss';

const UserManual: React.FC = () => {
  const { t } = useTranslation('store');
  const { modelId } = useParams<{ modelId: string }>();
  const { data } = useSupportedDeviceById(modelId);
  const { backdropOpen } = useBackdropContext();

  return (
    <Page
      className="user-manual"
      containerClassName="page__container--no-padding-top"
      whiteContent
      isStickyHeader
      header={<NavHeader title={t('userManual')} />}
    >
      {data ? (
        <>
          <p className="user-manual--title">{data.Name}</p>
          <p className="user-manual--description">{data.Description}</p>
          {data.PromoVideo && (
            <div className="user-manual--video">
              <iframe
                style={{ display: 'block', marginBottom: 12 }}
                width="100%"
                height="200"
                src={data.PromoVideo}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          )}
          {data.ManualsAndDocuments.length ? (
            <>
              <hr className="m-t-24 m-b-24" />
              <Header title={t('deviceManual')} />

              {data.ManualsAndDocuments.map((x) => (
                <div key={x.Id} className="m-b-24">
                  <div className="store-device-details--pdf-box">
                    <div className="upper">
                      <IconPdf />
                      <p>{x.Title}</p>
                    </div>

                    <div className="pdf-list">
                      <a
                        key={x.Id}
                        href={data.ManualsAndDocuments[0].Link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="m-b-12"
                      >
                        {t('downloadPDF')}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}

          {data.WhereToBuy.length ? (
            <div className="buy-section">
              <hr className="m-t-24 m-b-24" />
              <Header title={t('buy')} />
              <div className="grid-list-24">
                {data.WhereToBuy.map((x) => (
                  <ArrowButton
                    key={x.Id}
                    title={x.Name}
                    dark
                    className="m-b-16"
                    small
                    onClick={() => window.open(x.Link, '_blank')}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <>{!backdropOpen ? <EmptyStateBox content={t('notFoundUserManual')} icon={<IconWarning />} /> : null}</>
      )}
    </Page>
  );
};

export default UserManual;
