import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

type ComponentProps = {
  label: string;
  value: string;
};

const Detail: React.FC<ComponentProps> = ({ label, value }) => {
  const { t } = useTranslation('device-info');

  return (
    <div className="configuration_detail">
      <p className="label">{t(label)}</p>
      <p className="value">{value}</p>
    </div>
  );
};

export default Detail;
