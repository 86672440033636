import { Dispatch, SetStateAction } from 'react';
import { SelectOptionInterface } from '../../../components';

export type CreateInstallationControlForm = {
  installation_name: string;
  icon_name: string;
  time_zone: string;
  hex_color: string;
  location: string;
};

export type TimeZonesHook = {
  timeZonesOptions: SelectOptionInterface<string>[];
  search: string;
  isLoading: boolean;
  setSearch: Dispatch<SetStateAction<string>>;
};

export enum LocationViews {
  BUTTON = 'BUTTON',
  ADD_LOCATION = 'ADD_LOCATION',
  MAP = 'MAP',
  LEARN_NEED_LOCATION = 'LEARN_NEED_LOCATION',
  LEARN_REMOVE_LOCATION = 'LEARN_REMOVE_LOCATION',
}

export type LocationCoords = {
  latitude: number | null;
  longitude: number | null;
};

export type LocationCoordsHook = {
  getCoords: () => void;
};
