import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconErrorProps = {
  className?: string;
};

export const IconError: React.FC<IconErrorProps> = ({ className }) => (
  <div
    className={classNames('icon-error', {
      [className as string]: className,
    })}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10.25" stroke="#CC0909" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="12" cy="16" r="0.5" stroke="#CC0909" strokeLinecap="round" />
      <path d="M12 13V7" stroke="#CC0909" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  </div>
);
