import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation, Header, NavHeader, Page, PopUpNav, SubmitButton } from '../../../components';
import { availableGroupIcons } from '../../../components/action/edit-icon/available-icons';
import { EditIcon } from '../../../components/edit-icon';
import GroupFormChannels from '../components/form-channels';
import GroupFormName from '../components/form-name';
import { useEditGroup } from '../hooks/use-edit-group';
import '../index.scss';

export const Edit: React.FC = () => {
  const { t } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const {
    showDeleteDialog,
    showSaveDialog,
    userGroup,
    form,
    popupNavLinks,
    editGroupNameLoading,
    isPristine,
    defaultChannels,
    defaultActions,
    onSubmit,
    handleAttemptToSave,
    setShowDeleteDialog,
    setShowSaveDialog,
    handleRemoveGroup,
    onCancel,
    confirmSubmit,
    closeRemoveDialog,
    handleChangeIcon,
    isfavouriteForm,
  } = useEditGroup();

  const { name, iconName } = form.watch();

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader>
            <PopUpNav links={popupNavLinks} />
          </NavHeader>
          <Header title={t('editGroup')} />
        </>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          {!isfavouriteForm && (
            <>
              <GroupFormName />
              <EditIcon currentIcon={iconName} iconList={availableGroupIcons} handleChangeIcon={handleChangeIcon} />
              <hr className="m-t-24 m-b-28" />
            </>
          )}
          <GroupFormChannels defaultChannels={defaultChannels} defaultActions={defaultActions} />
          <SubmitButton
            onClick={handleAttemptToSave}
            disabled={
              editGroupNameLoading ||
              (isPristine && userGroup?.group.name === name && userGroup?.group.iconName === iconName)
            }
            isLoading={editGroupNameLoading}
          />
        </form>
      </FormProvider>
      <DialogConfirmation
        show={showSaveDialog}
        setShow={setShowSaveDialog}
        header={t('confirmDialog.header')}
        primaryBtnText={tc('buttons.save')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={confirmSubmit}
        secondaryBtnAction={onCancel}
      />
      <DialogConfirmation
        show={showDeleteDialog}
        setShow={setShowDeleteDialog}
        header={t('removeDialog.header')}
        content={t('removeDialog.content', { name: userGroup?.group.name || '' })}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={tc('buttons.remove')}
        secondaryBtnAction={closeRemoveDialog}
        primaryBtnAction={handleRemoveGroup}
      />
    </Page>
  );
};
