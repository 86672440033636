import React, { ReactNode } from 'react';
import { Header } from '../header';
import { NavHeader } from '../nav-header';
import { Page } from '../page';
import './index.scss';

type EditIconPageProps = {
  setOpen: (open: boolean) => void;
  title: string;
  children: ReactNode;
};

export const EditIconPage: React.FC<EditIconPageProps> = ({ setOpen, children, title }) => (
  <Page
    className="edit-icon-page"
    kind="above"
    header={
      <>
        <NavHeader onClick={() => setOpen(false)} />
        <Header title={title} isUnderline />
      </>
    }
  >
    {children}
  </Page>
);
