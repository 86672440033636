import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState, PointerEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { BlindActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { CoverButton, DialogConfirmation, IconVent, IconWoodenBlind, Slider } from '../../../../../../../components';
import { defaultFavouritePositionList } from '../../../../../../../const';
import { useBackdropContext } from '../../../../../../../hooks';
import { CustomChannelDataEnum } from '../../../../../enums/custom-channel-data';
import { useLoggedUser } from '../../../../../hooks/common/logged-user';
import { useDeviceConfiguration } from '../../../../../pages/device-details/hooks/use-device-configuration';
import { getValueFromChannelCustomData } from '../../../../../utils';
import { useBlindControl } from '../../../hooks/use-blind-control';
import './index.scss';

type ComponentProps = {
  channel: IDeviceChannel;
  position: number;
  setTargetValue: Dispatch<SetStateAction<number>>;
  active: boolean;
};

const Favourites: React.FC<ComponentProps> = ({ channel, position, setTargetValue, active }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const [favouritePositionList, setFavouritePositionList] = useState<number[]>(defaultFavouritePositionList);
  const [microventilationPosition, setMicroventilationPosition] = useState<number>(91);
  const [microventilationDialog, setMicroventilationDialog] = useState<boolean>(false);
  const [sliderMicroventialation, setSliderMicroventialation] = useState<number>(0);
  const { handleAction, handleMicroventilation } = useBlindControl(channel);
  const { handleFieldChangeErrors } = useDeviceConfiguration();
  const { canAddRemoveConfigureDevice } = useLoggedUser();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const isFovouritesMemoryAvailable = useMemo(() => channel.IsCustomDataAndRolesSupported(), [channel]);

  useEffect(() => {
    const positionList = getValueFromChannelCustomData(CustomChannelDataEnum.favouritePositions, channel);
    if (positionList) setFavouritePositionList(JSON.parse(positionList));

    const favouriteMicroventilationPosition = getValueFromChannelCustomData(
      CustomChannelDataEnum.favouriteMicroventilationPosition,
      channel,
    );

    if (favouriteMicroventilationPosition) {
      setMicroventilationPosition(parseInt(favouriteMicroventilationPosition));
      setSliderMicroventialation(parseInt(favouriteMicroventilationPosition));
    }
  }, []);

  const onFavouriteClick = useCallback(
    (favoritePositionIndex) => {
      setTargetValue(favouritePositionList[favoritePositionIndex]);
      handleAction(BlindActionEnum.Percentage, favouritePositionList[favoritePositionIndex]);
    },
    [favouritePositionList],
  );

  const onFavouritePositionHold = useCallback(
    async (fieldIndex: number) => {
      turnOnBackdrop();
      const newFavouritePositionList = favouritePositionList.map((el, index) =>
        index !== fieldIndex ? el : position !== null ? position : defaultFavouritePositionList[index],
      );

      const result = await channel.SetCustomDataAsync(
        CustomChannelDataEnum.favouritePositions,
        JSON.stringify(newFavouritePositionList),
      );
      turnOffBackdrop();

      handleFieldChangeErrors(result, () => {
        setFavouritePositionList(newFavouritePositionList);
      });
    },
    [channel, position, favouritePositionList],
  );

  const onFavouriteMicroventilationSave = useCallback(async () => {
    turnOnBackdrop();

    const result = await channel.SetCustomDataAsync(
      CustomChannelDataEnum.favouriteMicroventilationPosition,
      sliderMicroventialation.toString(),
    );
    turnOffBackdrop();

    handleFieldChangeErrors(result, () => {
      setMicroventilationPosition(sliderMicroventialation);
      handleCloseDialog();
    });
  }, [channel, sliderMicroventialation]);

  const onFavouriteMicroventilationHold = () => setMicroventilationDialog(true);
  const handleCloseDialog = () => setMicroventilationDialog(false);

  const onChangeMicroventialation = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSliderMicroventialation(Number(event.target.value));
  }, []);

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    const pos = Number((event.target as HTMLInputElement).value);
    setSliderMicroventialation(pos);
    handleMicroventilation(pos);
  };

  return (
    <div className="favourite p-l-24 p-r-24">
      <div className="favourite__buttons favourite__buttons--cover">
        {[0, 1, 2].map((index) => (
          <CoverButton
            key={index}
            lastPosition={position}
            position={favouritePositionList[index]}
            onClick={() => onFavouriteClick(index)}
            {...(isFovouritesMemoryAvailable && canAddRemoveConfigureDevice && !active
              ? { onHold: () => onFavouritePositionHold(index) }
              : {})}
          >
            <IconWoodenBlind position={favouritePositionList[index]} />
            <span>{favouritePositionList[index]}%</span>
          </CoverButton>
        ))}
        <CoverButton
          lastPosition={position}
          position={microventilationPosition}
          {...(isFovouritesMemoryAvailable && canAddRemoveConfigureDevice && !active
            ? { onHold: onFavouriteMicroventilationHold }
            : {})}
          onClick={() => handleMicroventilation(microventilationPosition)}
        >
          <IconVent />
          <span>{t('microventilation')}</span>
        </CoverButton>
      </div>
      <DialogConfirmation
        show={microventilationDialog}
        setShow={handleCloseDialog}
        header={t('microventilation')}
        primaryBtnText={tc(`buttons.${!active ? 'save' : 'loading'}`)}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onFavouriteMicroventilationSave}
        secondaryBtnAction={handleCloseDialog}
        primaryBtnDisabled={active}
      >
        <div className="microventilation__popup--content">
          <Slider
            onPointerUp={handleSliderEvent}
            value={sliderMicroventialation}
            onChange={onChangeMicroventialation}
            active={active}
            showValue={false}
          />
        </div>
      </DialogConfirmation>
    </div>
  );
};

export default Favourites;
