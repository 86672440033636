import React, { useMemo } from 'react';
import { DeviceTaskType as ChannelTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import LavvaDetailsWrapper from '../../../../../../../components/details-wrapper';
import { canChannelDo } from '../../../../../utils';
import { GateCommonDetailsControls } from '../gate-types/common/details';
import { GatePreciseDetailsControls } from '../gate-types/precise/details';

type ComponentProps = {
  channel: IDeviceChannel;
  active: boolean;
};

export const GateDetailsControls: React.FC<ComponentProps> = ({ channel, active }) => {
  const isPreciseGate = useMemo(() => canChannelDo(channel, [ChannelTaskType.PreciseGateControl]), [channel]);

  return (
    <LavvaDetailsWrapper>
      {!isPreciseGate ? (
        <GateCommonDetailsControls channel={channel} active={active} />
      ) : (
        <GatePreciseDetailsControls channel={channel} active={active} />
      )}
    </LavvaDetailsWrapper>
  );
};
