import React from 'react';
import { useTranslation } from 'react-i18next';
import { CentralActionType } from '../../../pages/dashboard/types';
import { CentralActionBoxIcon } from '../box-icon';

type CentralActionDetailsHeaderProps = {
  centralAction: CentralActionType;
};

export const CentralActionDetailsHeader: React.FC<CentralActionDetailsHeaderProps> = ({ centralAction }) => {
  const { t } = useTranslation('action');

  return (
    <div className="details-header p-t-0">
      <div className="details-header--left">
        <CentralActionBoxIcon type={centralAction.type} className={'action-icon--header'} />
        <div className="details-header__name">
          <h2 className="text-ellipsis">{centralAction.name}</h2>
          <div className="details-header__type">{t('defaultAction')}</div>
        </div>
      </div>
    </div>
  );
};
