import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ISequenceInfo } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { IconButton } from '@mui/material';
import { IconPlay } from '../../../../../../components';
import { ActionAdvancedIcon } from '../../../../../../components/action/advanced-icon';
import { ROUTES } from '../../../../../../routes';
import { ActionAdvancedIconTypeEnum } from '../../../../../../types';
import { useExalusServicesContext } from '../../../../context/services';
import { useHandleDataFrameErrors } from '../../../../hooks';
import './index.scss';

type ComponentProps = {
  action: ISequenceInfo;
};

const ActionItem: React.FC<ComponentProps> = ({ action }) => {
  const history = useHistory();
  const { scenesApi } = useExalusServicesContext();
  const { handleError } = useHandleDataFrameErrors();

  const runSequence = async () => {
    const result = await scenesApi.RunSequenceAsync(action.Guid);
    if (result !== Status.OK) handleError(result);
  };

  return (
    <div className={classNames('exalus-action-item', { disabled: action.IsDisabled })}>
      <div
        onClick={() => history.push(`${ROUTES.ExalusActionDetails(action.Guid)}?isDisabled=${action.IsDisabled}`)}
        className="exalus-action-item__content"
      >
        <div className="icon">
          <ActionAdvancedIcon iconName={action.Icon || ActionAdvancedIconTypeEnum.DEFAULT} />
        </div>
        <div className="name">{action.Name}</div>
      </div>
      <IconButton className="action-btn" onClick={runSequence}>
        <IconPlay size={28} />
      </IconButton>
    </div>
  );
};

export default ActionItem;
