import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChannelIcon } from '../../../../components';
import { ROUTES } from '../../../../routes';
import { ChannelInterface } from '../../../../types';

type ActionChannelListProps = {
  channels: ChannelInterface[];
};

export const ActionChannelList: React.FC<ActionChannelListProps> = ({ channels }) => {
  const history = useHistory();
  const { t } = useTranslation('action');

  return (
    <div className="action-device-list">
      <div className="action-device-list__header">
        <h3>{t('basic.control')}</h3>
      </div>
      {channels && channels.length > 0 && (
        <div className="action-device-list__list">
          {channels.map((channel) => (
            <div
              onClick={() => history.push(ROUTES.ChannelDetails(channel.id))}
              key={channel.id}
              className="device-item"
            >
              <h4 className="device-item__header">
                <div className="icon-box box">
                  <ChannelIcon channel={channel} />
                </div>
                <div className="device-item__content-wrapper">
                  <span className="device-item__name">{channel.alias}</span>
                </div>
              </h4>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
