import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PopUpNav } from '../../../../components';
import { IconDelete, IconEdit } from '../../../../components/popup-nav/icons';
import { ROUTES } from '../../../../routes';
import { useLavvaCreateActionContext } from '../../context';
import { useActionDetailsPresentation } from '../../hooks/use-action-details-presentation';
import { DeviceTaskParams, SceneTaskType, Task, TaskVariant } from '../../types';
import { actionTaskVariants, actionTasks } from '../../utils/action-tasks';
import './index.scss';

type ComponentProps = {
  task: Task;
};

const TaskItem: React.FC<ComponentProps> = ({ task }) => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { setTaskVariant, setChannels, setTaskType, removeTask } = useLavvaCreateActionContext();
  const { getTaskState } = useActionDetailsPresentation();

  const handleDelete = () => removeTask(task.id);

  const editParameters = () => {
    setTaskType(task.taskType);

    switch (task.taskType) {
      case SceneTaskType.ChannelState: {
        setChannels([(task.taskParams as DeviceTaskParams).channel]);
        setTaskVariant((task.taskParams as DeviceTaskParams).variant);
        history.push(`${ROUTES.ActionChannelSettings()}?id=${task.id}`);
        break;
      }
      default: {
        break;
      }
    }
  };

  const value = useMemo(() => getTaskState(task), [task]);

  const taskOption = useMemo(() => {
    if (task.taskType === SceneTaskType.ChannelState) {
      return actionTaskVariants.find((x) => x.variant === (task.taskParams as DeviceTaskParams).variant);
    }

    return actionTasks.find((x) => x.type === task.taskType);
  }, [task]);

  const taskTitle = useMemo(() => {
    switch (task.taskType) {
      case SceneTaskType.ChannelState:
        return (task.taskParams as DeviceTaskParams).channel.alias;
      default:
        return '';
    }
  }, [task]);

  return (
    <div className="exalus-task-item-wrapper">
      <div className="exalus-task-item">
        <div className="icon-wrapper">{taskOption?.icon}</div>
        <div className="exalus-task-item">
          <div className="exalus-task-item__content">
            <h4 className="exalus-task-item__title">{taskTitle}</h4>
            <div className="exalus-task-item__text">{value}</div>
          </div>
        </div>
      </div>
      <PopUpNav
        dark
        links={[
          ...(task.taskParams.variant !== TaskVariant.SetVentilation
            ? [
                {
                  onClick: editParameters,
                  label: t('action.create.conditions.editConditionParameters'),
                  icon: <IconEdit />,
                },
              ]
            : []),
          {
            onClick: handleDelete,
            label: t('remove'),
            icon: <IconDelete />,
          },
        ]}
      />
    </div>
  );
};

export default TaskItem;
