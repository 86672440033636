import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components';
import { ActionType, NewActionForm } from '../../types';

type ComponentProps = {
  actionType?: ActionType;
};

export const ActionName: React.FC<ComponentProps> = ({ actionType }) => {
  const { control } = useFormContext<NewActionForm>();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');

  return (
    <Controller
      name="name"
      control={control}
      rules={{ required: tc('isRequired') as string }}
      render={({ field, fieldState: { error } }) => (
        <div className="grid-list-24">
          <Input
            label={t(`action.create.${actionType}Name`)}
            placeholder={t(`action.create.${actionType}Name`)}
            name={field.name}
            onChange={field.onChange}
            defaultValue={field.value}
            value={field.value}
            {...(error ? { errorMessage: error.message } : {})}
          />
        </div>
      )}
    />
  );
};
