import classNames from 'classnames';

type ComponentProps = {
  power?: number;
  column?: boolean;
};

export const PowerItem: React.FC<ComponentProps> = ({ power, column }) => (
  <>
    {power !== undefined ? (
      <div className="sensor-control-box" style={{ padding: '0 6px' }}>
        <div className={classNames('sensor-control-box__value-wrapper', { column: column })}>
          <span className="sensor-control-box__value">{power.toFixed(1)}</span>
          <span className="sensor-control-box__unit">{'W'}</span>
        </div>
      </div>
    ) : null}
  </>
);
