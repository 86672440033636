import { IntegrationDefaultEnum } from './types';

export const COLOR_LIST = [
  '255,238,84',
  '243,197,1',
  '232,127,5',
  '233,75,53',
  '194,56,37',
  '234,99,164',
  '143,64,175',
  '103,48,193',
  '27,163,239',
  '45,202,175',
  '15,166,83',
];

export const PICKER_COLOR_LIST = [
  '255,255,255',
  '255,238,84',
  '243,199,1',
  '232,126,5',
  '233,74,53',
  '194,56,37',
  '234,99,164',
  '144,64,175',
  '104,48,193',
  '27,161,239',
  '45,202,176',
  '15,166,83',
];

export const INSTALLATION_COLOR_LIST = [
  '#FCAF00',
  '#FF4016', // Default for Lavva
  '#EA346F',
  '#C10152',
  '#691EFF',
  '#2370FF',
  '#00B191',
  '#29D058',
  '#59a4a6',
  '#009EE3', // Default for Exalus Home
  '#3DD3DD', // Default for Wisniowscy
];

export const ACCENT_COLOR_DEFAULT_INDEX_MAP = {
  [IntegrationDefaultEnum.Lavva as string]: 1,
  [IntegrationDefaultEnum.Exalus as string]: 9,
  [IntegrationDefaultEnum.Wisniowski as string]: 10,
};

export const defaultFavouritePositionList: [number, number, number] = [25, 50, 75];

export const defaultFavouriteSetOnTimeList: [number, number, number] = [5000, 30000, 300000];

export const PHASES_COLORS = ['#FF4016', '#00B191', '#2370FF', '#FCAF00', '#CC0909'];

export const WRONG_MEASUREMENT_VALUE = -999;

export const ONLY_LABEL_OPTION_VALUE = 'ONLY_LABEL_OPTION_VALUE';

export const DEFAULT_TIME_ZONE = 'Europe/Warsaw';
