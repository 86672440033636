import React from 'react';
import { IconPlay, ControlWrapper, InitiateButton } from '../../../components';
import { UserAction } from '../../../data-access/gql-types/graphql';
import { useAdvancedAction } from '../../../hooks';
import './index.scss';

type PropsInterface = {
  action: UserAction;
};

const AdvancedActionSwitch: React.FC<PropsInterface> = ({ action }) => {
  const { setActionOn } = useAdvancedAction(action);

  return (
    <ControlWrapper>
      <InitiateButton isOn={false} onClickControl={setActionOn}>
        <IconPlay isLarge />
      </InitiateButton>
    </ControlWrapper>
  );
};

export default AdvancedActionSwitch;
