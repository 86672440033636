import React from 'react';
import classNames from 'classnames';
import { IconSun, IconWoodenBlind, IconFacadeBlind } from '../../../../../components/icons';
import { ChannelType } from '../../../enums';

type PropsInterface = {
  type: ChannelType;
  className?: string;
  quantity?: number;
};

export const CentralActionBoxIcon: React.FC<PropsInterface> = ({ type, className, quantity = 2 }) => {
  const icons = new Array(quantity).fill(true);

  return (
    <div
      className={classNames('action-icon', `action-icon--quantity-${quantity}`, { [className as string]: className })}
    >
      {icons.map((el, index) => (
        <div key={index} className="action-icon__icon">
          {type === ChannelType.Switch || type === ChannelType.Light ? (
            <IconSun isOn />
          ) : type === ChannelType.Blind ? (
            <IconWoodenBlind position={100} />
          ) : type === ChannelType.Facade ? (
            <IconFacadeBlind position={100} tilt={50} />
          ) : null}
        </div>
      ))}
    </div>
  );
};
