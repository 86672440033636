import React from 'react';
import classNames from 'classnames';
import './index.scss';

type PropsInterface = {
  className?: string;
  size?: number;
};

export const IconTime: React.FC<PropsInterface> = ({ className, size = 24 }) => {
  return (
    <div
      className={classNames('icon-time', {
        [className as string]: className,
      })}
    >
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="9.25" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M11 7L11 13H17" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </div>
  );
};
