import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconRadioProps = {
  selected?: boolean;
  onClick?: () => void;
  className?: string;
};

export const IconRadio: React.FC<IconRadioProps> = ({ selected = false, onClick, className }) => (
  <div
    className={classNames('icon-radio', {
      'icon-radio--selected': selected,
      clickable: onClick,
      [className as string]: className,
    })}
    onClick={onClick}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {selected ? (
        <>
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="#FF4016"
            vectorEffect="non-scaling-stroke"
          />
          <path
            className="dot"
            d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
            fill="#FF4016"
          />
        </>
      ) : (
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke="#15141D"
          vectorEffect="non-scaling-stroke"
        />
      )}
    </svg>
  </div>
);
