import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconCyclicalProps = {
  className?: string;
};

export const IconCyclical: React.FC<IconCyclicalProps> = ({ className }) => (
  <div
    className={classNames('icon-cyclical', {
      [className as string]: className,
    })}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 4L2 10H8.157" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M22 8.99995C21.4796 7.56674 20.595 6.28536 19.429 5.27537C18.2629 4.26539 16.8533 3.55972 15.3317 3.22421C13.81 2.8887 12.226 2.93429 10.7274 3.35673C9.22871 3.77916 7.86429 4.56467 6.76142 5.63995L2 9.99995"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 20.0023V14.0023H15.843"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 15.0024C2.52044 16.4356 3.40496 17.717 4.57103 18.7269C5.7371 19.7369 7.14671 20.4426 8.66833 20.7781C10.19 21.1136 11.774 21.068 13.2726 20.6456C14.7713 20.2232 16.1357 19.4377 17.2386 18.3624L22 14.0024"
        stroke="#009EE3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
