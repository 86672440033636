import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTransitionValue from 'react-transition-value';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { EnergyMeasurementParameter } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import {
  ControlWrapper,
  EmptyStateBox,
  IconWarning,
  InputSelect,
  SelectOptionInterface,
} from '../../../../../../../components';
import { DonutChartWrapper } from '../../../../../../../components/donut-chart';
import { Phases } from '../../../../../../channel-details/measurements/components/phases-button-group/phases';
import { energyParams } from '../../../../../pages/action-create/utils/energy-parameters';
import { useMeterControl } from '../../../hooks/use-meter-control';
import { useMeterPhases } from '../hooks/use-meter-phases';

type ComponentProps = {
  channel: IDeviceChannel;
  children?: ReactNode;
};

export const MeterDetailsControls: React.FC<ComponentProps> = ({ channel, children }) => {
  const { t } = useTranslation('channel-details');
  const { state } = useMeterControl(channel);
  const [lazyFormattedSum, setLazyFormattedSum] = useTransitionValue(0, { duration: 800 });
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { mappedPhases, handlePhaseChange } = useMeterPhases(channel);

  const parameters: SelectOptionInterface<EnergyMeasurementParameter>[] = useMemo(
    () => [
      {
        label: t(`exalus.energyMeasurements.types.${EnergyMeasurementParameter.ActivePower}`),
        value: EnergyMeasurementParameter.ActivePower,
        weight: 0,
      },
      {
        label: t(`exalus.energyMeasurements.types.${EnergyMeasurementParameter.Current}`),
        value: EnergyMeasurementParameter.Current,
        weight: 1,
      },
      {
        label: t(`exalus.energyMeasurements.types.${EnergyMeasurementParameter.Voltage}`),
        value: EnergyMeasurementParameter.Voltage,
        weight: 2,
      },
    ],
    [t],
  );

  const selectedParameter = useMemo(() => parameters[selectedIndex].value, [selectedIndex, parameters]);

  const data = useMemo(() => {
    if (state) {
      return state.Data.MeasurementParameters.get(selectedParameter);
    }
  }, [state, selectedParameter]);

  const isActivePowerSelected = useMemo(() => {
    return selectedParameter === EnergyMeasurementParameter.ActivePower;
  }, [selectedParameter]);

  const changeSelectedParameter = useCallback(
    (value?: EnergyMeasurementParameter) => {
      if (value && Object.values(EnergyMeasurementParameter).includes(value)) {
        const found = parameters.find((x) => x.value === value);
        if (found?.weight !== undefined) setSelectedIndex(found.weight);

        return;
      }

      if (selectedIndex === parameters.length - 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    },
    [selectedParameter, parameters, selectedIndex],
  );

  useEffect(() => {
    if (data) {
      setTimeout(() => setLazyFormattedSum(data, { from: 0 }), 200);
    } else {
      setLazyFormattedSum(0.000000001, { from: 0 }, 200);
    }
  }, [selectedParameter, data]);

  const dataMax = useMemo(() => {
    if (data !== undefined) return (Math.round(data * 100) / 100) * 2 || 0.1;
    return 0.1;
  }, [data]);

  const percentageRatioActualToMaximum = useMemo(() => {
    if (data !== undefined) {
      if (dataMax !== 0) return (data * 100) / dataMax;
    }

    return 0;
  }, [data, dataMax]);

  if (data === undefined) {
    return (
      <div className="p-t-16">
        <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
      </div>
    );
  }

  return (
    <div className="details-page">
      <ControlWrapper
        className={classNames('control-wrapper--full-space control-wrapper--column', {
          'control-wrapper--not-full-space': children,
        })}
      >
        <>
          {/* <CurrentHeader header={t(`exalus.energyMeasurements.types.${selectedParameter}`)} /> */}
          <div className="max-width-desktop p-l-24 p-r-24">
            <InputSelect
              options={parameters}
              value={selectedParameter}
              onChange={changeSelectedParameter}
              className="input--reverse m-b-32"
              label={''}
            />
          </div>
          <div className="energy-consumption">
            <DonutChartWrapper
              value={Math.round((data + Number.EPSILON) * 1000) / 1000}
              lazyValue={lazyFormattedSum}
              averageValue={+(data / 1).toFixed(2)}
              maxScaleValue={dataMax}
              label={t('now')}
              clickIndicator
              percentageRatioActualToMaximum={percentageRatioActualToMaximum}
              unit={isActivePowerSelected ? t('w') : energyParams[selectedParameter].unit}
              changeSelectedParameter={changeSelectedParameter}
              showEco={false}
              noFixedScaleValues={data < 1}
              selectedPhases={[]}
              phaseMeasurements={[]}
            />
            {channel.GetDevice().Channels.length > 1 && (
              <div className="energy-consumption__button-group p-l-24 p-r-24">
                <Phases
                  mappedPhases={mappedPhases}
                  selectedPhases={[channel.Number]}
                  onClickPhase={handlePhaseChange}
                />
              </div>
            )}
          </div>
        </>
      </ControlWrapper>
      {children}
    </div>
  );
};
