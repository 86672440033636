import { UseQueryResult, useQuery } from 'react-query';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { IPicture } from 'lavva.exalushome/build/js/Services/Pictures/IPicture';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { useExalusContext } from '../context';
import { useExalusServicesContext } from '../context/services';
import { UserItem } from '../types/users.types';

export const useExalusUsers = (): UseQueryResult<UserItem[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { usersApi } = useExalusServicesContext();

  return useQuery<UserItem[]>(
    ['exalus-users', selectedExalusCredentials?.id, synchronized],
    async () => {
      const usersList: UserItem[] = [];
      const users: IUser[] = await usersApi.GetUsersAsync();

      for (const user of users) {
        const result = await user.GetUserProfilePictureAsync();

        if ((result as ResponseResult<Status>).Type) {
          usersList.push({ user, image: '' });
        } else {
          usersList.push({ user, image: (result as IPicture).Base64Image });
        }
      }

      return usersList;
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusUser = (id?: string): UseQueryResult<IUser | null> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { usersApi } = useExalusServicesContext();

  return useQuery<IUser | null>(
    ['exalus-user', selectedExalusCredentials?.id, synchronized, id],
    async () => {
      return await usersApi.GetUserAsync(id);
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: false,
    },
  );
};

export const useExalusUserImg = (user: IUser): UseQueryResult<IPicture | ResponseResult<Status>> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { usersApi } = useExalusServicesContext();

  return useQuery<IPicture | ResponseResult<Status>>(
    ['exalus-user-profile', selectedExalusCredentials?.id, synchronized, user?.Guid],
    async () => {
      return await usersApi.GetUserProfilePictureAsync(user);
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized && !!user,
      retry: false,
    },
  );
};
