import React, { useCallback, useMemo, useState, useRef, ReactNode } from 'react';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { DialogSwitcher, SwitcherOptionInterface } from '../dialog';
import { IconChevron } from '../icons';
import './index.scss';

type InputSwitcherProps = {
  options: SwitcherOptionInterface[];
  defaultOptionIndex?: number;
  id?: string;
  name?: string;
  label: string;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (target: HTMLInputElement) => void;
  children?: ReactNode;
};

export const InputSwitcher: React.FC<InputSwitcherProps> = ({
  options,
  defaultOptionIndex = 0,
  id = uuid.v4(),
  name,
  label,
  disabled = false,
  onChange,
  children,
}) => {
  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(defaultOptionIndex);
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => setShowSelectDialog(true), [setShowSelectDialog]);

  const dialogOnChange = useCallback(
    (optionIndex) => {
      setSelectedOptionIndex(optionIndex);
      setShowSelectDialog(false);

      if (onChange && inputRef && inputRef.current) {
        inputRef.current.value = options[optionIndex].value;
        onChange(inputRef.current);
      }
    },
    [setShowSelectDialog, setSelectedOptionIndex, onChange, options, inputRef],
  );

  const option = useMemo(() => options[selectedOptionIndex], [options, selectedOptionIndex]);

  return (
    <>
      <div className={classNames('input', { 'input--disabled': disabled })} onClick={disabled ? undefined : onClick}>
        <label className="input__label" htmlFor={id}>
          {label}
        </label>
        <div className="input__wrapper">
          <input
            ref={inputRef}
            id={id}
            name={name}
            type="text"
            value={option.value}
            disabled={disabled}
            hidden
            readOnly
          />
          <span className={classNames('input__input', { clickable: !disabled })}>
            {option.icon && <span className="input__input-icon">{option.icon}</span>}
            <span className="input_input-label">{option.label}</span>
          </span>
          <div className="input__icon">
            <IconChevron direction="right" noSpace />
          </div>
        </div>
      </div>
      <DialogSwitcher
        show={showSelectDialog && !disabled}
        setShow={setShowSelectDialog}
        title={label}
        options={options}
        selectedOptionIndex={selectedOptionIndex}
        onChange={dialogOnChange}
      >
        {children}
      </DialogSwitcher>
    </>
  );
};
