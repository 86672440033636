import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconErrorSmallProps = {
  className?: string;
  size?: number;
};

export const IconErrorSmall: React.FC<IconErrorSmallProps> = ({ className, size = 16 }) => (
  <div
    className={classNames('icon-error-small', {
      [className as string]: className,
    })}
  >
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 7.87451C15.75 12.2238 12.2242 15.7495 7.875 15.7495C3.52576 15.7495 0 12.2238 0 7.87451C0 3.52527 3.52576 -0.000488281 7.875 -0.000488281C12.2242 -0.000488281 15.75 3.52527 15.75 7.87451Z"
        fill="#CC0909"
      />
      <path
        d="M7.875 8.3667L7.875 3.93701"
        stroke="white"
        strokeWidth="1.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_d_13084_15922)">
        <ellipse cx="7.875" cy="11.812" rx="0.984375" ry="0.984375" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_13084_15922"
          x="3.22396"
          y="10.8276"
          width="9.30208"
          height="9.30208"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.66667" />
          <feGaussianBlur stdDeviation="1.83333" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13084_15922" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13084_15922" result="shape" />
        </filter>
      </defs>
    </svg>
  </div>
);
