import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/IRdp21ConfigService';
import { MaxLightLevel } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { Rdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { MaxLevelParams } from '../../../device-details/types';

type ComponentProps = {
  device: IDevice;
  maxLevel: MaxLightLevel | null;
  channel: IDeviceChannel;
};

const MaxLevelForm: React.FC<ComponentProps> = ({ device, maxLevel, channel }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<MaxLevelParams>();

  useEffect(() => {
    if (maxLevel !== null) form.setValue('maxLevel', maxLevel);
  }, [maxLevel]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRdp21ConfigService>(
      Rdp21ConfigService.ServiceName,
    );

    const result = await configService.SetMaxLevelAsync(device, channel.Number, values.maxLevel);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <ConfigurationInput
          name="maxLevel"
          label={t('exalus.params.OnOffParams.MaxLevel')}
          placeholder={t('exalus.params.OnOffParams.MaxLevel')}
          inputType="number"
          min={2}
          max={99}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default MaxLevelForm;
