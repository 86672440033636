import { useState } from 'react';
import { isAndroid, isIos } from '../../utils/helpers/operation-system';
import * as storage from '../../utils/storage/lavva';

export const useVibrationManager = () => {
  const [withVibration, setWithVibration] = useState<boolean>(storage.getItem('vibration'));
  const [vibrationSupported, setVibrationSupported] = useState<number | null>(null);

  const checkForVibrationSupported = async () => {
    if (vibrationSupported !== null) return;
    if ('vibrate' in navigator && (isAndroid || isIos)) setVibrationSupported(1);
    setVibrationSupported(0);
  };

  const vibrate = () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(500);
      setVibrationSupported(1);
    } else {
      setVibrationSupported(0);
    }
  };

  const fireVibration = () => {
    if (withVibration) vibrate();
  };

  const setVibration = (value: boolean) => {
    setWithVibration(value);
    if (value) vibrate();
  };

  return {
    fireVibration,
    setVibration,
    checkForVibrationSupported,
    vibrationSupported: !!vibrationSupported,
  };
};
