import { Dispatch, SetStateAction, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import c from 'classnames';
import { SubmitButton, Switch } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { EditIcon } from '../../../components/edit-icon';
import { ChannelTypeInternal, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { ROUTES } from '../../../routes';
import { ChannelGateInterface } from '../../../types';
import ConfigurationName from '../components/configuration-name';
import { availableIcons } from '../components/icon-edit/available-icons';
import Info from '../components/info';
import ConfigurationGeneralPersonalization from '../general-personalization';
import { useConfigurationForm } from '../hooks/use-configuration-form';
import { InfoPageType } from '../types';

type ComponentProps = {
  setInfoPage: Dispatch<SetStateAction<InfoPageType>>;
};

const ChannelGeneralConfiguration: React.FC<ComponentProps> = ({ setInfoPage }) => {
  const history = useHistory();
  const { t } = useTranslation('configuration');
  const { t: tc } = useTranslation('common');
  const { channelType } = useParams<{ channelType: ChannelTypeInternal }>();
  const { channel, deviceList } = useDevicesAndChannelsContext();
  const { permissions } = usePermissionsContext();
  const { formMethods, onSubmit, submitActive, hiddenValue, handleChangeVisibility } = useConfigurationForm({
    channel,
  });
  const { icon_name } = formMethods.watch();

  const isDeviceAvailable = useMemo(() => {
    if (!channel) return false;
    return (
      deviceList.map((device) => device.id).includes(channel?.deviceId) &&
      !new URLSearchParams(window.location.search).get('redirect') &&
      permissions[UserPermissionType.DeviceList]
    );
  }, [deviceList, channel, permissions]);

  const iconList = useMemo(() => {
    if (channelType === ChannelTypeInternal.Gate)
      return availableIcons(channelType, (channel?.data as ChannelGateInterface)?.gateKind);
    return availableIcons(channelType);
  }, [channelType, (channel?.data as ChannelGateInterface)?.gateKind]);

  const handleChangeIcon = (value: string) => formMethods.setValue('icon_name', value);

  const goToDevice = () => history.push(ROUTES.DeviceSettings(channel?.deviceId));

  return (
    <>
      {isDeviceAvailable ? <ArrowButton title={t('deviceSettings')} onClick={goToDevice} /> : null}
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <hr className={c({ 'm-t-0': !isDeviceAvailable })} />
          <ConfigurationName label={t('configurationNameLabel')} placeholder={t('configurationName')} />
          <EditIcon
            currentIcon={icon_name}
            iconList={iconList}
            handleChangeIcon={handleChangeIcon}
            header={t('channelIcon')}
          />
          <hr />
          <Info title={t('visibility')} onClick={() => setInfoPage(InfoPageType.VISIBILITY)} />
          <div className="row_container">
            <p>{t('hideNotUsed')}</p>
            <Switch checked={hiddenValue} onChange={handleChangeVisibility} />
          </div>
          <ConfigurationGeneralPersonalization />

          <SubmitButton disabled={!submitActive}>{tc('buttons.save')}</SubmitButton>
        </form>
      </FormProvider>
    </>
  );
};

export default ChannelGeneralConfiguration;
