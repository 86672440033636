import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputEdit } from '../../../../../../components';

type ComponentProps = {
  name: string;
  label: string;
  placeholder: string;
  edited?: boolean;
  disabled?: boolean;
  setDisabled?: Dispatch<SetStateAction<boolean>>;
  inputType?: string;
  step?: string;
  errorMessage?: string;
  min?: number;
  max?: number;
};

const ConfigurationInput: React.FC<ComponentProps> = ({
  name,
  label,
  placeholder,
  inputType,
  max,
  min,
  edited,
  disabled,
  errorMessage,
  setDisabled,
  step,
}) => {
  const { t } = useTranslation('common');
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: true,
          message: t('isRequired'),
        },
        ...(min && max
          ? {
              validate: {
                minThan: (value: string) => parseFloat(value) >= min || t('errors.minNumber', { min }),
                maxThan: (value: string) => parseFloat(value) <= max || t('errors.maxNumber', { max }),
              },
            }
          : {}),
      }}
      render={({ field, fieldState: { error } }) =>
        !edited ? (
          <Input
            defaultValue={field.value}
            inputType={inputType}
            onChange={(e) => {
              if (inputType === 'number') setValue(name, parseFloat(e.target.value));
              else field.onChange(e);
            }}
            label={label}
            placeholder={placeholder}
            step={step}
            {...(error ? { errorMessage: error.message } : {})}
            {...(errorMessage ? { errorMessage } : {})}
          />
        ) : (
          <InputEdit
            defaultValue={field.value}
            onChange={(e) => {
              if (inputType === 'number') setValue(name, parseFloat(e.target.value));
              else field.onChange(e);
            }}
            inputType={inputType}
            label={label}
            disabled={disabled}
            setDisabled={setDisabled}
            step={step}
            {...(error ? { errorMessage: error.message } : {})}
            {...(errorMessage ? { errorMessage } : {})}
          />
        )
      }
    />
  );
};

export default ConfigurationInput;
