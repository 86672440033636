import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { IUpdatesService } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { UpdatesService } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';
import { DialogConfirmation } from '../../../../../../components';
import { useInstallation } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';
import { diffHours } from '../../../../../../utils/helpers';
import * as exalusStorage from '../../../../../../utils/storage/exalus';
import { useExalusContext } from '../../../../context';

export const UpdateDevicesDialog: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('device-list');
  const { t: tc } = useTranslation('common');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { selectedInstallationId } = useInstallation();
  const { synchronized, connected } = useExalusContext();

  const handleSaveMemoryForInstallationId = () => {
    if (selectedInstallationId) {
      let updateDevicesMemory = exalusStorage.getItem('updateDevicesMemory');

      if (updateDevicesMemory) {
        if (selectedInstallationId in updateDevicesMemory) {
          updateDevicesMemory[selectedInstallationId] = new Date();
        } else updateDevicesMemory = { ...updateDevicesMemory, [selectedInstallationId]: new Date() };
      } else updateDevicesMemory = { [selectedInstallationId]: new Date() };

      exalusStorage.setItem('updateDevicesMemory', updateDevicesMemory);
    }
  };

  const checkDevicesUpdateAvailability = async () => {
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result = await updateService.CheckDevicesUpdatesAvailabilityAsync();

    handleSaveMemoryForInstallationId();
    if (result.length) setShowDialog(true);
  };

  useEffect(() => {
    if (!selectedInstallationId || !synchronized || !connected) return;
    const updateDevicesMemory = exalusStorage.getItem('updateDevicesMemory');

    if (updateDevicesMemory) {
      if (selectedInstallationId in updateDevicesMemory) {
        if (diffHours(new Date(updateDevicesMemory[selectedInstallationId]), 24)) checkDevicesUpdateAvailability();
      } else checkDevicesUpdateAvailability();
    } else checkDevicesUpdateAvailability();
  }, [selectedInstallationId, synchronized, connected]);

  const handleClose = () => {
    handleSaveMemoryForInstallationId();
    setShowDialog(false);
  };

  const handleAction = () => {
    handleClose();
    history.push(ROUTES.Device());
  };

  return (
    <DialogConfirmation
      show={showDialog}
      setShow={handleClose}
      header={t('updateDevicesInfo.header')}
      content={t('updateDevicesInfo.content')}
      primaryBtnText={tc('buttons.continue')}
      secondaryBtnText={tc('buttons.cancel')}
      primaryBtnAction={handleAction}
      secondaryBtnAction={handleClose}
      timerCancel
    />
  );
};
