import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { Accordion, EmptyStateBox, IconWarning } from '../../../components';
import {
  OnGenericInputStatusChangedSubscription,
  OnGenericInputStatusChangedSubscriptionVariables,
  VersionedFeatureType,
} from '../../../data-access/gql-types/graphql';
import { ON_GENERIC_INPUT_STATUS_CHANGED } from '../../../data-access/subscriptions/lavva-devices';
import { useChannelsState } from '../../../hooks';
import { useVersionMap } from '../../../hooks/api/version-map';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { ChannelInterface } from '../../../types';
import { ChannelGenericInputStateInterface } from '../../../types/channel/generic-input';
import InputType from './components/input-type';
import './index.scss';

type ComponentProps = {
  inputs: ChannelInterface[];
  bindingsEnabled: boolean;
};

const DeviceInputs: React.FC<ComponentProps> = ({ inputs, bindingsEnabled }) => {
  const { t } = useTranslation('channel-list');
  const { t: td } = useTranslation('device-settings');
  const { checkSupport, versionMapData } = useVersionMap();
  const { deviceId } = useParams<{ deviceId: string }>();
  const { device } = useDevicesAndChannelsContext();
  const { channelState, setChannelState } = useChannelsState();
  const { data } = useSubscription<
    OnGenericInputStatusChangedSubscription,
    OnGenericInputStatusChangedSubscriptionVariables
  >(ON_GENERIC_INPUT_STATUS_CHANGED, {
    variables: { deviceId },
    skip: !deviceId,
  });

  useEffect(() => {
    if (data?.onGenericInputStatusChanged) {
      const { channelId, value } = data.onGenericInputStatusChanged;

      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[channelId] = {
          ...(newState[channelId] as ChannelGenericInputStateInterface),
          status: value,
        };

        return newState;
      });
    }
  }, [data]);

  const monoBiConfigEnable = useMemo(() => {
    return checkSupport({
      feature: VersionedFeatureType.MonoBiModeConfig,
      supportKey: 'Core',
      currentVersion: device?.payload.currentFirmwareVersion,
    });
  }, [device?.payload.currentFirmwareVersion, versionMapData]);

  return (
    <div className="device-inputs">
      {bindingsEnabled ? (
        <>
          {inputs.map((input) => {
            const status = (channelState[input.id] as ChannelGenericInputStateInterface)?.status;

            return (
              <Accordion
                key={input.id}
                kind="custom"
                title={`${t('input')} ${input.alias}`}
                titleIcon={
                  <div className="input-state" style={{ backgroundColor: !status ? ' #9a9ba2' : '#00B191' }} />
                }
              >
                <InputType key={input.id} inputChannel={input} monoBiConfigEnable={monoBiConfigEnable} />
              </Accordion>
            );
          })}
        </>
      ) : (
        <EmptyStateBox content={td('updateToUseFeature')} icon={<IconWarning />} />
      )}
    </div>
  );
};

export default DeviceInputs;
