import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useWakeLock } from 'react-screen-wake-lock';
import { DeviceConnectionError, LavvaBluetooth } from 'lavva.webbluetooth/build/js/LavvaBluetooth';
import { uniqBy } from 'lodash';
import * as Sentry from '@sentry/react';
import { SelectOptionInterface } from '../../../components';
import { ONLY_LABEL_OPTION_VALUE } from '../../../const';
import { environment } from '../../../environment';
import { useBackdropContext, useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { getSignalStrength } from '../../../utils/helpers/network';
import * as storage from '../../../utils/storage/lavva';
import { toastError, toastSuccess } from '../../../utils/toast';
import { NetworkState } from '../bluetooth/components/network-state';
import { useNativeWebBluetooth } from '../bluetooth/hooks/use-native-web-bluetooth';
import {
  AddingDeviceEnablePayload,
  AdvancedSettings,
  BluetoothData,
  BluetoothMethodRequest,
  BluetoothMethodResponse,
  DiagnosticPayload,
  DriveFlowType,
  RegisterLavvaProgressPayload,
  RegisterLavvaRequestBody,
  RegisterLavvaResponsePayload,
  RemoveWifiPayload,
  SetWifiPayload,
  SettingsPayload,
  UpdateByWifiErrorPayload,
  UpdateByWifiOkPayload,
  UpdateByWifiSuccessPayload,
  UpdateData,
  WiFiKnowsNetworksPayload,
  WiFiNetworksPayload,
} from '../bluetooth/types';
import { initialBluetoothDataState, initialContextState } from '../bluetooth/utils';
import { useConnectErrors } from '../hooks/use-connect-errors';
import { BluetoothProviderInterface } from '../types';
import { deviceTypeIcon } from '../utils';

export const BluetoothContext = createContext<BluetoothProviderInterface>(initialContextState);

export const useBluetoothContext = (): BluetoothProviderInterface => useContext(BluetoothContext);

export const BluetoothContextProvider: React.FC = ({ children }) => {
  const bluetoothData = useRef<BluetoothData>(initialBluetoothDataState);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId } = useInstallation();
  const {
    percentage,
    isCustomBluetooth,
    foundDevices,
    dialogScanning,
    scanning,
    setDialogScanning,
    setFoundDevices,
    handleDeviceConnect,
    stoppedScanning,
    setScanning,
    WebBluetooth,
  } = useNativeWebBluetooth();
  const [deviceConnected, setDeviceConnected] = useState<boolean>(false);
  const [redoRegisteringDisabled, setRedoRegisteringDisabled] = useState<boolean>(false);
  const [checkList, updateCheckList] = useState<string[]>([]);
  const [registerErrorMessage, setRegisterErrorMessage] = useState<string>('');
  const [errorCode, setErrorCode] = useState<string>('');
  const [identifing, setIdentifing] = useState<boolean>(false);
  const [typeOptions, setTypeOptions] = useState<SelectOptionInterface<string>[]>([]);
  const [advancedSettings, setAdvancedSettings] = useState<AdvancedSettings | null>(null);
  const [isAlreadyAdded, setIsAlreadyAdded] = useState<boolean>(false);
  const [wifiList, setWifiList] = useState<SelectOptionInterface<string>[]>([]);
  const [wifiKnowsList, setWifiKnowsList] = useState<SelectOptionInterface<string>[]>([]);
  const [wifiLoading, setWifiLoading] = useState<boolean>(false);
  const [updatePayload, setUpdatePayload] = useState<UpdateData>(initialContextState.updatePayload);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleConnectErrors } = useConnectErrors();
  const { request, release } = useWakeLock();

  useEffect(() => {
    request();
  }, []);

  const updateBluetoothData = (obj: Record<string, string | string[] | boolean | DiagnosticPayload | null>) => {
    bluetoothData.current = { ...bluetoothData.current, ...obj };
  };

  const resetBluetoothData = useCallback(() => {
    bluetoothData.current = initialBluetoothDataState;
    setUpdatePayload(initialContextState.updatePayload);
    setRedoRegisteringDisabled(false);
    setDeviceConnected(false);
    setIdentifing(false);
    setWifiList([]);
    setWifiKnowsList([]);
    setTypeOptions([]);
    setAdvancedSettings(null);
    turnOffBackdrop();
  }, []);

  const returnToStartPage = () => {
    if (pathname !== ROUTES.DeviceAddBluetooth()) {
      history.goBack();
    }
  };

  const checkIfConnected = async () => {
    if (!deviceConnected) {
      resetBluetoothData();
      await disconnect();

      returnToStartPage();
    }
  };

  const onTimeoutError = () => {
    toastError({ content: tc('errors.somethingWentWrong') });
    turnOffBackdrop();
  };
  const clearTimeoutError = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  const setTimeoutError = () => {
    clearTimeoutError();
    timeout.current = setTimeout(onTimeoutError, 1000 * 15);
  };

  const onDisconnected = () => {
    setRedoRegisteringDisabled(false);
    setDeviceConnected(false);
    turnOffBackdrop();
    console.log('Rozłączono z urządzeniem');
  };

  const handleMessage = async (message: string) => {
    console.log('MESSAGE', message);

    if (message) {
      clearTimeoutError();
      const data = JSON.parse(message);

      switch (data.method) {
        case BluetoothMethodResponse.Settings: {
          stoppedScanning();
          setDialogScanning(false);
          setFoundDevices([]);
          const payload = data.payload as SettingsPayload;
          console.log('SETTINGS', data.payload);

          setTypeOptions(
            payload.types.map((type) => {
              return {
                value: type,
                label: tc(`types.${type.toUpperCase()}`),
                icon: deviceTypeIcon[type],
              };
            }),
          );

          if (payload.advancedSettings) {
            setAdvancedSettings(payload.advancedSettings);
          }

          setIsAlreadyAdded(!!payload.isAlreadyAdded);

          updateBluetoothData({
            deviceName: payload.name,
            type: payload.currentType,
            mac: payload.mac,
            identifyIsAvailable: !!payload.identifyIsAvailable,
            isAlreadyAdded: !!payload.isAlreadyAdded,
            diagnostic: payload.diagnostic || null,
          });

          console.log(`Odebrano: Settings (${JSON.stringify(data)})`);
          handleNext(payload.name);
          break;
        }
        case BluetoothMethodResponse.WiFiNetworks: {
          const payload = data.payload as WiFiNetworksPayload;

          const wifi = uniqBy(payload.wifiList, 'SSID').map((wifi) => ({
            label: wifi.SSID,
            value: wifi.SSID,
            intValue: wifi.RSSI ? getSignalStrength(parseInt(wifi.RSSI)) : 0,
          }));

          const list = wifi.map((x) => {
            const foundInKnows = wifiKnowsList.find((y) => y.value === x.value);

            if (foundInKnows) {
              return {
                label: x.label,
                value: x.value,
                weight: foundInKnows.weight,
                icon: <NetworkState signal={x.intValue} remembered />,
              };
            }

            return { label: x.label, value: x.value, icon: <NetworkState signal={x.intValue} /> };
          });

          const knows = list.filter((wifiItem) => wifiKnowsList.find((knows) => knows.value === wifiItem.value));
          const rest = list.filter((wifiItem) => !wifiKnowsList.find((knows) => knows.value === wifiItem.value));

          setWifiKnowsList(knows);
          setWifiList(rest);

          console.log(`Odebrano: WiFiNetworks (${JSON.stringify(data)})`);
          setWifiLoading(false);
          break;
        }
        case BluetoothMethodResponse.KnownWifis: {
          const payload = data.payload as WiFiKnowsNetworksPayload;

          const wifi = payload.wifiList.map((wifi) => ({
            label: wifi,
            value: wifi,
            weight: 1,
          }));
          console.log('KNOWNS WIFI', wifi);
          setWifiKnowsList(wifi);

          console.log(`Odebrano: WiFiKnowsNetworks (${JSON.stringify(data)})`);
          setWifiLoading(false);
          break;
        }
        case BluetoothMethodResponse.RegisterLavvaResponse: {
          turnOffBackdrop();
          setRedoRegisteringDisabled(false);
          const payload = data.payload as RegisterLavvaResponsePayload;

          if (payload.status) {
            console.log(`Sukces: RegisterLavvaResponse (${JSON.stringify(data)})`);

            if (bluetoothData.current.ssid && bluetoothData.current.password) {
              storage.setItem(
                'bluetoothNetwork',
                JSON.stringify({ ssid: bluetoothData.current.ssid, password: `!${bluetoothData.current.password}` }),
              );
            }

            await sendSuccessConfirmation();
            if (!bluetoothData.current.deviceName.includes('smartAWSC')) {
              history.push(ROUTES.DeviceAddBluetoothSuccess(payload.deviceId));
            } else {
              history.push(ROUTES.DeviceAddBluetoothWisniowskiSuccess());
            }
          } else {
            setRegisterErrorMessage(
              payload.errorCode ? t(`bluetooth.errors.${payload.errorCode}`) : tc('errors.somethingWentWrong'),
            );
            if (payload.errorCode) {
              setErrorCode(payload.errorCode);

              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { password, ...rest } = bluetoothData.current;

              Sentry.captureMessage('REGISTER DEVICE LAVVA', {
                level: 'info',
                extra: {
                  status: payload.status,
                  errorCode: payload.errorCode,
                  bluetoothData: {
                    ...rest,
                  },
                },
              });
            }

            console.log(`Error: RegisterLavvaResponse (${JSON.stringify(data)})`);
          }

          break;
        }
        case BluetoothMethodResponse.RegisterLavvaProgress: {
          const payload = data.payload as RegisterLavvaProgressPayload;
          console.log('REGISTER DEVICE RESPONSE', payload);

          if (payload.step) {
            turnOffBackdrop();
            updateCheckList((prevList) => [...prevList, t(`bluetooth.progress.${payload.step}`)]);
          }

          break;
        }
        case BluetoothMethodResponse.SetWifiResponse: {
          const payload = data.payload as SetWifiPayload;
          turnOffBackdrop();
          console.log('SET WIFI RESPONSE', payload);

          if (payload.status) {
            if (bluetoothData.current.ssid && bluetoothData.current.password) {
              storage.setItem(
                'bluetoothNetwork',
                JSON.stringify({ ssid: bluetoothData.current.ssid, password: `!${bluetoothData.current.password}` }),
              );
            }

            await sendSuccessConfirmation();
            history.push(ROUTES.DeviceAddBluetoothSetWifiSuccess());
          } else {
            toastError({ content: t('bluetooth.setWifiError') });
          }

          break;
        }
        case BluetoothMethodResponse.RemoveWifiResponse: {
          const payload = data.payload as RemoveWifiPayload;
          turnOffBackdrop();

          if (payload.status) {
            toastSuccess({ content: t('bluetooth.wifiRemoved') });
            getKnowsWifiNetworks();
          } else {
            toastError({ content: tc('errors.somethingWentWrong') });
          }

          break;
        }
        case BluetoothMethodResponse.UpdateByWifiResponse: {
          const payload = data.payload as UpdateByWifiOkPayload | UpdateByWifiErrorPayload | UpdateByWifiSuccessPayload;

          if ((payload as UpdateByWifiOkPayload).update) {
            setUpdatePayload({ ...(payload as UpdateByWifiOkPayload).update, status: null });
          } else if ((payload as UpdateByWifiErrorPayload).errorCode) {
            setUpdatePayload((prev) => ({
              ...prev,
              status: (payload as UpdateByWifiErrorPayload).status,
              errorCode: (payload as UpdateByWifiErrorPayload).errorCode,
            }));
          } else {
            if ((payload as UpdateByWifiSuccessPayload).status) {
              setUpdatePayload((prev) => ({ ...prev, status: (payload as UpdateByWifiSuccessPayload).status }));
              await sendSuccessConfirmation();
            }
          }

          break;
        }
        case BluetoothMethodResponse.AddingDeviceEnable: {
          const payload = data.payload as AddingDeviceEnablePayload;

          if (
            window.location.pathname === ROUTES.DeviceAddBluetoothWisniowskiTutorialStep2(DriveFlowType.Remote) ||
            window.location.pathname === ROUTES.DeviceAddBluetoothWisniowskiTutorialStep2(DriveFlowType.Drive)
          ) {
            if (payload.status) {
              history.push(ROUTES.DeviceAddBluetoothIdentify());
            } else {
              history.replace(ROUTES.DeviceAddBluetoothWisniowskiStart());
            }
          }

          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const terminal = useMemo(() => {
    const bt = new LavvaBluetooth();
    bt.OnDisconnectedEvent.Subscribe(onDisconnected);
    bt.OnCharacteristicValueChangedEvent.Subscribe(handleMessage);
    return bt;
  }, []);

  const disconnect = async () => {
    turnOffBackdrop();
    setDeviceConnected(false);

    const device = terminal.GetConnectedDevice();
    if (device) {
      turnOnBackdrop();
      const disconnectResult = await terminal.DisconnectDeviceAsync(device);
      turnOffBackdrop();
      return disconnectResult;
    }
  };

  const identifyDevice = async () => {
    try {
      turnOnBackdrop();

      await terminal.SendDataAsync(
        JSON.stringify({
          method: BluetoothMethodRequest.Identify,
          payload: {},
        }),
      );

      console.log('Identify: wysłano pomyślnie');
      setIdentifing(true);
      turnOffBackdrop();
    } catch (error) {
      toastError({ content: t('bluetooth.identifyError') });
      console.log(`Identify error: ${error}`);
      turnOffBackdrop();
    }
  };

  const updateWifiData = (ssid: string, password: string) => {
    bluetoothData.current = { ...bluetoothData.current, ssid, password };
  };

  const getDeviceSettings = async () => {
    try {
      setTimeoutError();
      turnOnBackdrop();

      await terminal.SendDataAsync(
        JSON.stringify({
          method: BluetoothMethodRequest.GetSettings,
          payload: {},
        }),
      );

      console.log('Get Settings: wysłano pomyślnie');
    } catch (error) {
      setDeviceConnected(false);
      toastError({ content: t('bluetooth.settingsError') });
      console.log(`Get Settings error: ${error}`);
      setDialogScanning(false);
      turnOffBackdrop();
    }
  };

  const updateByWifi = async () => {
    try {
      setUpdatePayload(initialContextState.updatePayload);

      const data = {
        method: BluetoothMethodRequest.UpdateByWifi,
        payload: {
          wifi: {
            ssid: bluetoothData.current.ssid,
            password: bluetoothData.current.password,
          },
        },
      };

      await terminal.SendDataAsync(JSON.stringify(data));
      console.log('Update Device: wysłano pomyślnie');
    } catch (error) {
      console.log(`Update Device error: ${error}`);
    }
  };

  const setWifi = async (ssid: string, password: string) => {
    try {
      turnOnBackdrop();

      const data = {
        method: BluetoothMethodRequest.SetWifi,
        payload: {
          wifi: {
            ssid,
            password,
          },
        },
      };

      console.log('SET WIFI BODY', data);

      await terminal.SendDataAsync(JSON.stringify(data));

      console.log('Set Wifi: wysłano pomyślnie');
    } catch (error) {
      console.log(`Set Wifi error: ${error}`);
      turnOffBackdrop();
    }
  };

  const removeWifi = async (ssid: string) => {
    try {
      turnOnBackdrop();

      const data = {
        method: BluetoothMethodRequest.RemoveWifi,
        payload: {
          ssid,
        },
      };

      await terminal.SendDataAsync(JSON.stringify(data));

      console.log('Remove Wifi: wysłano pomyślnie');
    } catch (error) {
      console.log(`Remove Wifi error: ${error}`);
      toastError({ content: t('bluetooth.forgettingWifiError') });
      turnOffBackdrop();
    }
  };

  const getWifiNetworks = async () => {
    try {
      setWifiLoading(true);

      await terminal.SendDataAsync(
        JSON.stringify({
          method: BluetoothMethodRequest.GetWiFiNetworks,
          payload: {},
        }),
      );

      console.log('Get WiFi Networks: wysłano pomyślnie');
    } catch (error) {
      toastError({ content: t('bluetooth.wifiError') });
      console.log(`Get WiFi Networks error: ${error}`);
      setWifiLoading(false);
    }
  };

  const getKnowsWifiNetworks = async () => {
    try {
      setWifiKnowsList([]);
      setWifiList([]);
      setWifiLoading(true);

      if (!bluetoothData.current.diagnostic) {
        getWifiNetworks();
        return;
      }

      await terminal.SendDataAsync(
        JSON.stringify({
          method: BluetoothMethodRequest.GetKnownWifis,
          payload: {},
        }),
      );

      console.log('Get WiFi Knows Networks: wysłano pomyślnie');
      getWifiNetworks();
    } catch (error) {
      toastError({ content: t('bluetooth.wifiKnowsError') });
      console.log(`Get WiFi Knows Networks error: ${error}`);
      setWifiLoading(false);
    }
  };

  const onSelectedDevice = () => {
    setDeviceConnected(true);
    getDeviceSettings();
  };

  const onConnectError = (error) => {
    setDeviceConnected(false);
    console.log('CONNECT ERROR', error);
    setDialogScanning(false);
    turnOffBackdrop();
  };

  const searchBluetooth = async (custom: boolean) => {
    console.log('SEARCH BLUETOOTH');
    resetBluetoothData();
    await disconnect();
    stoppedScanning();
    setFoundDevices([]);
    setScanning(true);

    if (custom) setDialogScanning(true);
    else {
      turnOnBackdrop();
    }

    console.log('BLUETOOTH CONNECT STARTED');

    try {
      const result = await terminal.RequestDeviceAndConnectAsync();

      if ((result as BluetoothDevice).id) {
        console.log('CONNECT SUCCESS');
        onSelectedDevice();
      } else {
        onConnectError(result);
        handleConnectErrors(result as DeviceConnectionError);
      }
    } catch (error) {
      onConnectError(error);
    }
  };

  const registerDevice = async (ssid: string, password: string) => {
    try {
      turnOnBackdrop();
      setRedoRegisteringDisabled(true);
      updateCheckList([]);
      setRegisterErrorMessage('');
      setErrorCode('');

      const data: RegisterLavvaRequestBody = {
        method: BluetoothMethodRequest.RegisterLavva,
        payload: {
          wifi: {
            ssid,
            password,
          },
          name: bluetoothData.current.name,
          type: bluetoothData.current.type,
          installationId: selectedInstallationId || '',
          discoveryUrl:
            window.location.hostname === 'localhost' ? 'https://discovery.dev2.lavva.cloud' : environment.DISCOVERY_URL,
          ...(bluetoothData.current.subTypes.length && {
            settings: {
              subTypes: bluetoothData.current.subTypes,
            },
          }),
        },
      };

      await terminal.SendDataAsync(JSON.stringify(data));

      console.log('Register Lavva: wysłano pomyślnie');
    } catch (error) {
      console.log(`Register Lavva error: ${error}`);
    }
  };

  const sendSuccessConfirmation = async () => {
    try {
      await terminal.SendDataAsync(
        JSON.stringify({
          method: BluetoothMethodRequest.SendSuccessConfirmation,
          payload: {},
        }),
      );

      console.log('SendSuccessConfirmation: wysłano pomyślnie');
    } catch (error) {
      console.log(`Get Settings error: ${error}`);
    }
  };

  const handleNext = async (deviceName: string) => {
    turnOffBackdrop();

    if (!deviceName.includes('smartAWSC')) {
      history.push(ROUTES.DeviceAddBluetoothIdentify());
    } else {
      history.push(ROUTES.DeviceAddBluetoothWisniowskiStart());
    }
  };

  const releaseLock = () => release();

  const values: BluetoothProviderInterface = {
    bluetoothData: { ...bluetoothData.current },
    deviceConnected,
    identifing,
    wifiList: [
      ...(wifiKnowsList.length
        ? [{ label: t('bluetooth.myNetworks'), value: ONLY_LABEL_OPTION_VALUE, disabled: true }]
        : []),
      ...wifiKnowsList,
      ...(wifiList.length
        ? [{ label: t('bluetooth.otherNetworks'), value: ONLY_LABEL_OPTION_VALUE, disabled: true }]
        : []),
      ...wifiList,
    ],
    typeOptions,
    wifiLoading,
    isAlreadyAdded,
    checkList,
    registerErrorMessage,
    updateBluetoothData,
    redoRegisteringDisabled,
    setRedoRegisteringDisabled,
    checkIfConnected,
    searchBluetooth,
    getWifiNetworks,
    getDeviceSettings,
    identifyDevice,
    registerDevice,
    resetBluetoothData,
    releaseLock,
    getKnowsWifiNetworks,
    updateByWifi,
    disconnect,
    updatePayload,
    setWifi,
    removeWifi,
    percentage,
    isCustomBluetooth,
    foundDevices,
    dialogScanning,
    scanning,
    setDialogScanning,
    handleDeviceConnect,
    WebBluetooth,
    updateWifiData,
    returnToStartPage,
    errorCode,
    advancedSettings,
  };

  return <BluetoothContext.Provider value={values}>{children}</BluetoothContext.Provider>;
};
