import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconOn, IconOff } from '../icons';
import './index.scss';

type PropsInterface = {
  actionOn: () => void;
  actionOff: () => void;
  isDetailView?: boolean;
  isOn?: boolean;
  onlyBtnOn?: boolean;
};

export const OnOffControl: React.FC<PropsInterface> = ({
  actionOn,
  actionOff,
  isDetailView = true,
  isOn,
  onlyBtnOn,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames('on-off-control', { 'on-off-control--detail': isDetailView })}>
      {isDetailView ? (
        <>
          <div className="on-off-control__bg-element" />
          <div className="on-off-control__btn-wrapper">
            <button
              onClick={actionOn}
              className={classNames('on-off-control__btn on-off-control__btn--on', {
                'on-off-control__btn--active': isOn,
                'on-off-control__btn--full-width': onlyBtnOn,
              })}
            >
              {t('buttons.on')}
            </button>
            {!onlyBtnOn && (
              <>
                <div className="on-off-control__divider" />
                <button onClick={actionOff} className="on-off-control__btn on-off-control__btn--off">
                  {t('buttons.off')}
                </button>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <button onClick={actionOn} className="on-off-control__icon on-off-control__icon--on">
            <IconOn />
          </button>
          <button onClick={actionOff} className="on-off-control__icon on-off-control__icon--off">
            <IconOff />
          </button>
        </>
      )}
    </div>
  );
};
