import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  ModifyShareInfoChannelInput,
  ModifyShareInfoItemBaseInput,
  ModifyUserAccessesMutation,
  ModifyUserAccessesMutationVariables,
} from '../../../data-access/gql-types/graphql';
import { MODIFY_USER_ACCESSES } from '../../../data-access/mutations/share';
import { useBackdropContext } from '../../../hooks';
import { useMutationErrors } from '../../../hooks/backend-errors/use-mutation-errors';
import { MutationErrorType } from '../../../types';
import { toastError } from '../../../utils/toast';
import { useShareInstallationContext } from '../context';

export const useModify = () => {
  const { t: tc } = useTranslation('common');
  const { state } = useShareInstallationContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Invitation);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [modifyUserAccesses, { loading: modifyUserAccessesLoading }] = useMutation<
    ModifyUserAccessesMutation,
    ModifyUserAccessesMutationVariables
  >(MODIFY_USER_ACCESSES);

  const modyfyGroupAccess = (userId: string, groups: ModifyShareInfoItemBaseInput[], onSuccess: () => void) => {
    turnOnBackdrop();

    modifyUserAccesses({
      variables: {
        input: {
          installationId: state.installationId,
          sharedToUserId: userId,
          groups,
          channels: [],
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (!data || !data.modifyUserAccesses) {
          toastError({ content: tc('errors.somethingWentWrong') });
          return;
        }

        if (data.modifyUserAccesses?.result?.ok) {
          onSuccess();
        } else {
          handleErrors(data.modifyUserAccesses?.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  const modyfyChannelAccess = (userId: string, channels: ModifyShareInfoChannelInput[], onSuccess: () => void) => {
    turnOnBackdrop();

    modifyUserAccesses({
      variables: {
        input: {
          installationId: state.installationId,
          sharedToUserId: userId,
          channels,
          groups: [],
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (!data || !data.modifyUserAccesses) {
          toastError({ content: tc('errors.somethingWentWrong') });
          return;
        }

        if (data.modifyUserAccesses?.result?.ok) {
          onSuccess();
        } else {
          handleErrors(data.modifyUserAccesses?.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  return {
    modyfyGroupAccess,
    modyfyChannelAccess,
    modifyUserAccessesLoading,
  };
};
