import { useTranslation } from 'react-i18next';
import { useSetDailyMode } from '../../../../../api/modules/gate/gate.hooks';
import { InitiateButtonRectangle } from '../../../../../components';
import { useChannelsState } from '../../../../../hooks';
import { ChannelGateInterface, ChannelGateStateInterface, ChannelInterface } from '../../../../../types';
import { useGateSupportedFeatures } from '../../hooks/use-gate-supported';

type ComponentProps = {
  channel: ChannelInterface;
};

export const DailyMode: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { mutate } = useSetDailyMode();
  const { dailyModeModeSupported } = useGateSupportedFeatures(channel);
  const { channelState } = useChannelsState();
  const state = channelState[channel.id] as ChannelGateStateInterface | undefined;

  const handleSetDailyMode = () => {
    mutate({
      channelId: channel.id,
      deviceId: channel.deviceId,
      value: !state?.dailyModeStatus,
    });
  };

  if (!(channel.data as ChannelGateInterface).isDailyModeVisible || !dailyModeModeSupported) return null;

  return (
    <InitiateButtonRectangle isOn={!!state?.dailyModeStatus} onClickControl={handleSetDailyMode} className="m-t-16">
      {t('dailyMode')}
    </InitiateButtonRectangle>
  );
};
