import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { CorrectionSteps } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { ISsrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/ISsrConfigService';
import { SsrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/SsrConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { orderBy } from 'lodash';
import { InputSelect, SelectOptionInterface, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type ComponentProps = {
  device: IDevice;
  params: CorrectionSteps | null;
  channel: IDeviceChannel;
};

const CorrectionStepsForm: React.FC<ComponentProps> = ({ device, channel, params }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService(device);
  const form = useForm<CorrectionSteps>();

  const { Correction } = form.watch();

  useEffect(() => {
    if (params !== null) form.setValue('Correction', params.Correction);
  }, [params]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (params) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISsrConfigService>(
        SsrConfigService.ServiceName,
      );

      const result = await configService.SetCorrectionStepAsync(
        device,
        channel.Number,
        values.Correction,
        params.CorrectionType,
      );
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const options = useMemo(() => {
    return orderBy(params?.AvaliableCorrections || [], [], 'desc').map((c: number) => ({
      label: c.toString(),
      value: c.toString(),
    }));
  }, [params?.AvaliableCorrections]) as SelectOptionInterface<string>[];

  const onChangeCorrection = (value: string) => form.setValue('Correction', parseInt(value));

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeCorrection}
          value={Correction?.toString()}
          options={options}
          label={t(`exalus.params.SsrParams.CorrectionSteps${params?.CorrectionType}`)}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default CorrectionStepsForm;
