import React, { ChangeEvent, useCallback } from 'react';
import { useSwitch } from '../../../../hooks';
import { ChannelInterface } from '../../../../types';
import { Switch } from '../../../switch';

type SwitchControlProps = {
  channel: ChannelInterface;
};

export const SwitchControl: React.FC<SwitchControlProps> = ({ channel }) => {
  const { handleControlOn: onSwitch, isOn: isOnSwitch } = useSwitch({
    channelId: channel.id,
    deviceId: channel.deviceId,
  });

  const handleControl = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onSwitch(e.target.checked),
    [onSwitch, channel],
  );

  return <Switch checked={isOnSwitch} onChange={handleControl} />;
};
