import {
  BlindChannelCalibrateStateResponse,
  ChannelTypeInternal,
  DeviceConnectionState,
  MeasuredBy,
} from '../../data-access/gql-types/graphql';

export type ChannelCoverInterface = {
  type: ChannelTypeInternal.Blind;
  favouritePositionList: [number, number, number];
  measuredBy?: MeasuredBy;
};

export type ChannelCoverStateInterface = {
  usesCounter: number;
  movingParams: {
    predictedTimeInMs: number | null;
    slatsPredictedTimeInMs: number | null;
    targetPosition: number | null;
  };
  lavvaStateDirection?: string;
  lavvaStatePosition?: number;
  calibrateState?: BlindChannelCalibrateStateResponse;
  deviceConnectionState?: DeviceConnectionState;
  grantedUses?: number;
};

export enum CoverDirection {
  unknown = 0,
  stop = 1,
  open = 2,
  close = 3,
}

export enum CoverKindParam {
  Channel,
  Action,
}
