import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../components';
import { RgbSceneIcon } from '../../../../components/rgb-scene';
import { availableRgbSceneIcons } from '../../../../components/rgb-scene/edit-icon/available-icons';
import { RgbSceneIconTypeEnum } from '../../../../types';
import { useRgbSceneFormContext } from '../../context';
import { RgbSceneFormView } from '../../enums';

type RgbSceneChangeIconProps = {
  onIconChange: (iconName: RgbSceneIconTypeEnum) => void;
};

export const RgbSceneChangeIcon: React.FC<RgbSceneChangeIconProps> = ({ onIconChange }) => {
  const { t } = useTranslation('channel-details');
  const { icon, setSelectedView } = useRgbSceneFormContext();

  return (
    <Page
      header={
        <>
          <NavHeader onClick={() => setSelectedView(RgbSceneFormView.SETTINGS)} />
          <Header title={t('rgbScenes.sceneIcon.heading')} isUnderline />
        </>
      }
    >
      <div className="edit-icon">
        <div className="m-b-32">
          <span className="edit-icon__caption m-b-16">{t('rgbScenes.sceneIcon.current')}</span>
          <div className="edit-icon__icon-wrapper edit-icon__icon-wrapper--unset-size">
            <RgbSceneIcon iconName={icon} />
          </div>
        </div>
        <span className="edit-icon__caption m-b-16">{t('rgbScenes.sceneIcon.choose')}</span>
        <div className="edit-icon__list">
          {availableRgbSceneIcons.map((icon) => {
            return (
              <div
                key={icon.iconName}
                className="edit-icon__icon-wrapper edit-icon__icon-wrapper--unset-size"
                onClick={() => onIconChange(icon.iconName as RgbSceneIconTypeEnum)}
              >
                {icon.component}
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};
