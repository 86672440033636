import React, { useCallback } from 'react';
import c from 'classnames';
import './index.scss';
import { isHex } from './utils';

type PropsInterface = {
  colors: string[];
  onClick: (value: string) => void;
  activeColor: string;
  className?: string;
  darkBorder?: boolean;
};

export const RgbColor: React.FC<PropsInterface> = ({ colors, onClick, activeColor, className = '', darkBorder }) => {
  const renderColors = useCallback(
    () =>
      colors.map((color) => (
        <div
          onClick={() => onClick(color)}
          key={color}
          className={c('rgb-color__element', {
            'rgb-color__element--active': color === activeColor,
            'rgb-color__element--border': color === '255,255,255',
            'rgb-color__element--border-dark': darkBorder,
          })}
          style={{ background: isHex(colors) ? color : `rgb(${color})` }}
        />
      )),
    [colors, activeColor],
  );

  return <div className={c('rgb-color grid grid--3', className)}>{renderColors()}</div>;
};
