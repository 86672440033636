import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../components';
import { useLogoutExalus } from './hooks/use-logout-exalus';
import { useLogoutLavva } from './hooks/use-logout-lavva';

type ComponentProps = {
  handleSubmit: () => void;
};

export const Logout: React.FC<ComponentProps> = ({ handleSubmit }) => {
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const [showDialogConfirmation, setShowDialogConfirmation] = useState<boolean>(false);

  return (
    <div className="logout-container m-t-24 m-b-24">
      <button className="button button--secondary" onClick={() => setShowDialogConfirmation(true)}>
        {t('logout')}
      </button>
      <DialogConfirmation
        show={showDialogConfirmation}
        setShow={setShowDialogConfirmation}
        header={t('dialog.header')}
        content={t('dialog.content')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={t('dialog.logoutBtn')}
        secondaryBtnAction={() => setShowDialogConfirmation(false)}
        primaryBtnAction={handleSubmit}
        direction="column"
      />
    </div>
  );
};

export const LogoutLavva: React.FC = () => {
  const { handleLogoutLavva } = useLogoutLavva();

  return <Logout handleSubmit={handleLogoutLavva} />;
};

export const LogoutExalus: React.FC = () => {
  const { handleLogoutExalus } = useLogoutExalus();

  return <Logout handleSubmit={handleLogoutExalus} />;
};
