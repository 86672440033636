import React from 'react';
import classNames from 'classnames';
import './index.scss';

type PropsInterface = {
  className?: string;
  size?: number;
  direction?: 'up' | 'down';
};

export const IconArrowLink: React.FC<PropsInterface> = ({ className, size, direction = '' }) => (
  <svg
    className={classNames('icon-arrow-link', direction, { [className as string]: className })}
    width={size || '15'}
    height={size || '14'}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.62744 12.4248L13.0529 6.99933L7.62744 1.57385"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M12.5 7.20117L1 7.20117" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
