import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Accordion, ChannelItem } from '../../../../components';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { ChannelInterface } from '../../../../types';

type Item = { channels: ChannelInterface[]; label: string };

type GroupChannelsProps = {
  items: Item[];
  defaultChannels: string[] | undefined;
  handleSelectedChannel: (items: string[]) => void;
};

const GroupChannels: React.FC<GroupChannelsProps> = ({ items, handleSelectedChannel, defaultChannels }) => {
  const { channelList } = useDevicesAndChannelsContext();
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);

  useEffect(() => {
    setSelectedChannels(
      (channelList.filter((channel) => channel?.id && defaultChannels?.includes(channel.id)) as ChannelInterface[]).map(
        (channel) => channel.id,
      ),
    );
  }, [channelList, defaultChannels]);

  const handleChannelOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;

      if (checked && !selectedChannels.includes(value)) {
        const values = [...selectedChannels, value];
        handleSelectedChannel(values);
        setSelectedChannels(values);
      }

      if (!checked && selectedChannels.includes(value)) {
        const values = selectedChannels.filter((v) => v !== value);
        handleSelectedChannel(values);
        setSelectedChannels(values);
      }
    },
    [selectedChannels],
  );

  const itemsWithElements = useMemo(() => items.filter((item) => item.channels.length > 0), [items]);

  return (
    <ul className="form-devices">
      {itemsWithElements.map((item: Item) => {
        const selectedItems = item.channels.filter((element) => selectedChannels.includes(element.id)).length;

        return (
          <li key={item.label} className="page-list__item m-t-0">
            <Accordion
              kind="custom"
              title={item.label}
              selectedItems={selectedItems}
              totalItems={item.channels.length}
              expanded={itemsWithElements.length === 1}
            >
              <ul>
                {item.channels?.map((channel: ChannelInterface) => (
                  <Checkbox
                    id={channel.id}
                    key={channel.id}
                    checked={selectedChannels.includes(channel.id)}
                    onChange={handleChannelOnChange}
                  >
                    <ChannelItem id={channel.id} isOn showIsHidden={!channel.isVisible} />
                  </Checkbox>
                ))}
              </ul>
            </Accordion>
          </li>
        );
      })}
    </ul>
  );
};

export default GroupChannels;
