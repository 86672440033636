import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { DragAndDrop, SubmitButton, dragOverlayStyle } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { toastSuccess } from '../../../../../../utils/toast';
import { useExalusServicesContext } from '../../../../context/services';
import { useHandleDataFrameErrors } from '../../../../hooks';
import { GroupItem } from '../../components/group-item';
import { GroupSortable } from '../types';
import { GroupItemSortable } from './item-sortable';

type GroupSortBodyProps = {
  sortableGroups: GroupSortable[];
  setSortableGroups: (value: GroupSortable[]) => void;
  refetchAllGroups: () => any;
};

export const GroupSortBody: React.FC<GroupSortBodyProps> = ({
  sortableGroups,
  setSortableGroups,
  refetchAllGroups,
}) => {
  const { t } = useTranslation('groups');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { groupsApi } = useExalusServicesContext();
  const [dndActiveId, setDndActiveId] = useState<string | null>(null);
  const { handleError } = useHandleDataFrameErrors();

  const onDragStart = useCallback((event: dndCore.DragStartEvent) => setDndActiveId(event.active.id), [setDndActiveId]);

  const onDragEnd = useCallback(
    (event: dndCore.DragEndEvent) => {
      if (!event.active || !event.over) return;

      if (event.active.id !== event.over.id && sortableGroups) {
        const newList = Array.from(sortableGroups);
        const oldIndex = newList.findIndex((group: GroupSortable) => group.id === event.active.id);
        const newIndex = newList.findIndex((group: GroupSortable) => group.id === event.over?.id);

        const sortedGroups = dndSortable.arrayMove(newList, oldIndex, newIndex);
        setSortableGroups(sortedGroups);
      }

      setDndActiveId(null);
    },
    [sortableGroups],
  );

  const onSave = useCallback(async () => {
    if (!sortableGroups || sortableGroups.length === 0) return;
    turnOnBackdrop();

    const sortedGroupsList: IChannelsGroup[] = sortableGroups.map((x: GroupSortable) => x.group);
    const result = await groupsApi.ChangeGroupsOrderAsync(sortedGroupsList);

    if (result !== Status.OK) handleError(result);

    refetchAllGroups();
    toastSuccess({ content: t('changeGroupsOrderSuccess') });
    turnOffBackdrop();
  }, [sortableGroups]);

  const groupDragged = useMemo(
    () => sortableGroups.find((x) => x.id === dndActiveId)?.group,
    [sortableGroups.length, dndActiveId],
  );

  return (
    <>
      <ul className="group-list grid-list-16">
        <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={sortableGroups}>
          {sortableGroups.map((groupSortable: GroupSortable) => (
            <GroupItemSortable key={groupSortable.id} sortedGroupId={groupSortable.id} group={groupSortable.group} />
          ))}
          <dndCore.DragOverlay>
            {groupDragged && <GroupItem style={dragOverlayStyle} group={groupDragged} isDraggable />}
          </dndCore.DragOverlay>
        </DragAndDrop>
      </ul>
      <SubmitButton onClick={onSave} />
    </>
  );
};
