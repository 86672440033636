import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateDataType } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';
import { isEmpty } from 'lodash';
import { IconSchedule } from '../../../../../../../components';
import { MeasurementRange } from '../../../../../../../types';
import './index.scss';

type ComponentProps = {
  activeRange: MeasurementRange;
  aggregateData?: Map<AggregateDataType, any>;
  unit?: string;
};

const ChartSummary: React.FC<ComponentProps> = ({ activeRange, aggregateData, unit = '' }) => {
  const { t } = useTranslation('channel-details');

  const summarySectionName = useMemo(() => {
    switch (activeRange) {
      case MeasurementRange.DayInHours:
        return t('summary.daily');
      case MeasurementRange.WeekInDays:
        return t('summary.weekly');
      case MeasurementRange.MonthInDays:
        return t('summary.monthly');
      default:
        return '';
    }
  }, [activeRange]);

  return (
    <>
      {!isEmpty(aggregateData) && (
        <div className="chart-summary p-l-24 p-r-24 max-width-desktop">
          <div className="chart-summary__caption">
            <IconSchedule />
            <span className="chart-summary__caption-text"> {summarySectionName}</span>
          </div>
          <div className="chart-summary__measurement">
            <span>{t('summary.min')}</span>
            <span>{`${aggregateData?.get(AggregateDataType.Min)} ${unit}` || '-'}</span>
          </div>
          <div className="chart-summary__measurement">
            <span>{t('summary.average')}</span>
            <span>{`${aggregateData?.get(AggregateDataType.Avg)} ${unit}` || '-'}</span>
          </div>
          <div className="chart-summary__measurement">
            <span>{t('summary.max')}</span>
            <span>{`${aggregateData?.get(AggregateDataType.Max)} ${unit}` || '-'}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default ChartSummary;
