import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import { IconHeart } from '../../components';
import {
  ActionType,
  AddToFavouritesMutation,
  AddToFavouritesMutationVariables,
  DashboardItemType,
  RemoveFromFavouritesMutation,
  RemoveFromFavouritesMutationVariables,
} from '../../data-access/gql-types/graphql';
import { ADD_TO_FAVOURITES, REMOVE_FROM_FAVOURITES } from '../../data-access/mutations/groups';
import { useInstallation, useApi, useBackdropContext } from '../../hooks';
import { useRefetchData } from '../../hooks/refresh-data';

type PropsInterface = {
  actionId: string;
  actionType: ActionType;
};

const Favourite: React.FC<PropsInterface> = ({ actionId, actionType }) => {
  const { favouriteGroup } = useApi();
  const [isActionInGroup, setIsActionInGroup] = useState<boolean>();
  const { selectedInstallationId } = useInstallation();
  const { refetchDashboard } = useRefetchData();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const [addToFavourite] = useMutation<AddToFavouritesMutation, AddToFavouritesMutationVariables>(ADD_TO_FAVOURITES, {
    onCompleted: (data) => {
      turnOffBackdrop();
      if (data?.addToFavourites?.id) refetchDashboard();
    },
  });

  const [removeFromFavourite] = useMutation<RemoveFromFavouritesMutation, RemoveFromFavouritesMutationVariables>(
    REMOVE_FROM_FAVOURITES,
    {
      onCompleted: (data) => {
        turnOffBackdrop();
        if (data?.removeFromFavourites?.id) refetchDashboard();
      },
    },
  );

  useEffect(() => {
    setIsActionInGroup(
      !!favouriteGroup?.userGroupItems?.find((item) => {
        if ('itemId' in item) return item.itemId === actionId;
      }),
    );
  }, [favouriteGroup, setIsActionInGroup]);

  const toggleFavouriteGroup = useCallback(() => {
    turnOnBackdrop();
    isActionInGroup
      ? removeFromFavourite({
          variables: {
            input: {
              id: actionId,
              itemType: actionType === ActionType.Advanced ? DashboardItemType.Action : DashboardItemType.Central,
              installationId: selectedInstallationId,
            },
          },
        })
      : addToFavourite({
          variables: {
            input: {
              id: actionId,
              itemType: actionType === ActionType.Advanced ? DashboardItemType.Action : DashboardItemType.Central,
              installationId: selectedInstallationId,
            },
          },
        });
  }, [isActionInGroup, actionId, selectedInstallationId]);

  return (
    <IconButton aria-label="favourite" size="large" onClick={toggleFavouriteGroup}>
      <IconHeart isEmpty={!isActionInGroup} />
    </IconButton>
  );
};

export default Favourite;
