export type BatchChannel = {
  deviceId: string;
  channelId: string;
  $type: TypeOfActionType;
};

export type SwitchLightOnOffBatchChannel = BatchChannel & {
  value: boolean;
};

export type SwitchLightSetOnTimeBatchChannel = BatchChannel & {
  timeInMilliseconds: number;
};

export type LightSetBrightnesssBatchChannel = BatchChannel & {
  brightness: number;
};

export type CoverPositionBatchChannel = BatchChannel & {
  position: number;
};

export type CoverDirectionBatchChannel = BatchChannel & {
  direction: number;
};

export type LightBrightnessBatchChannel = BatchChannel & {
  brightness: number;
};

export type LightRGBBatchChannel = BatchChannel & {
  r: number;
  g: number;
  b: number;
};

export type GatePositionBatchChannel = BatchChannel & {
  position: number;
};

export type GateDirectionBatchChannel = BatchChannel & {
  direction: number;
};

export type ActionBatchBody = {
  controls:
    | BatchChannel[]
    | SwitchLightOnOffBatchChannel[]
    | CoverPositionBatchChannel[]
    | CoverDirectionBatchChannel[]
    | LightBrightnessBatchChannel[]
    | LightRGBBatchChannel[]
    | GatePositionBatchChannel[]
    | GateDirectionBatchChannel[];
};

export enum TypeOfActionType {
  BatchLightToggleOnOffRequest = 'BatchLightToggleOnOffRequest',
  BatchLightSetTemperatureRequest = 'BatchLightSetTemperatureRequest',
  BatchLightSetRgbRequest = 'BatchLightSetRgbRequest',
  BatchLightSetBrightnessRequest = 'BatchLightSetBrightnessRequest',
  BatchLightSetOnTimeRequest = 'BatchLightSetOnTimeRequest',
  BatchSwitchSetOnTimeRequest = 'BatchSwitchSetOnTimeRequest',
  BatchSwitchToggleOnOffRequest = 'BatchSwitchToggleOnOffRequest',
  BatchBlindSetPositionRequest = 'BatchBlindSetPositionRequest',
  BatchBlindSetDirectionRequest = 'BatchBlindSetDirectionRequest',
  BatchGateTiltRequest = 'BatchGateTiltRequest',
  BatchGateSetDirectionRequest = 'BatchGateSetDirectionRequest',
  BatchGateSetPositionRequest = 'BatchGateSetPositionRequest',
  BatchGateStepByStepRequest = 'BatchGateStepByStepRequest',
  BatchBaseRequestConverter = 'BatchBaseRequestConverter',
}
