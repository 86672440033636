import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dialog } from '../../../../../../components/dialog/base';

type DelayDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  onSave: (data: number) => void;
  time: number;
};

export const DelayDialog: React.FC<DelayDialogProps> = ({ open, setOpen, title, onSave, time }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const [selectedTime, setSelectedTime] = useState<number>(time);
  const values = Array.from({ length: 120 }, (_, i) => i + 1);

  const onClose = useCallback(() => setOpen(false), []);

  const onSubmit = useCallback(() => {
    onSave(selectedTime);
    onClose();
  }, [selectedTime]);

  return (
    <Dialog setShow={setOpen} show={open} className="dialog--full-width">
      <div className="dialog-time-picker">
        <div className="dialog-time-picker__header">
          <h3 className="dialog-time-picker__heading">{title}</h3>
        </div>
        <div className="dialog-time-picker__time-wrapper">
          <div className="time-picker-wrapper">
            <div className="picker">
              <Swiper
                initialSlide={selectedTime - 1}
                slidesPerView={3}
                direction="vertical"
                grabCursor
                centeredSlides
                loop
                slideToClickedSlide
                onSlideChange={(e) => {
                  setSelectedTime(e.realIndex + 1);
                }}
              >
                {values.map((value) => {
                  return (
                    <SwiperSlide key={value}>
                      <div className="time">{value}</div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="time-picker-unit">{t('trigger.secondsUnitShort')}</div>
          </div>
        </div>
        <div className="dialog-time-picker__buttons">
          <button className="dialog-time-picker__button" onClick={onClose}>
            {tc('buttons.cancel')}
          </button>
          <button onClick={onSubmit} className="dialog-time-picker__button dialog-time-picker__button--active">
            {tc('buttons.save')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
