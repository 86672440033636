import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputSwitcher } from '../../../../components';
import { useLanguageSettings } from '../../hooks/use-language.settings';

export const LanguageSettings: React.FC = () => {
  const { t } = useTranslation('profile');
  const { optionsLocale, optionsLocaleDefaultIndex, changeLanguage } = useLanguageSettings();

  return (
    <InputSwitcher
      options={optionsLocale}
      defaultOptionIndex={optionsLocaleDefaultIndex}
      label={t('app-language')}
      onChange={(inputElement) => changeLanguage(inputElement.value)}
    />
  );
};
