import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputSelect, SelectOptionInterface } from '../../../../../../components';
import HeaderWithHelp from '../../../../../../components/header-with-help';
import { OptimizerModeInternal } from '../../../../../../data-access/gql-types/graphql';
import { VideoWorkMode } from './video-work-mode';

type OptimizerConfigurationWorkModeSelectProps = {
  workModeOptions: SelectOptionInterface<OptimizerModeInternal>[];
  selectedWorkMode: OptimizerModeInternal;
  onChange: (value: OptimizerModeInternal) => void;
};

export const OptimizerConfigurationWorkModeSelect: React.FC<OptimizerConfigurationWorkModeSelectProps> = ({
  workModeOptions,
  selectedWorkMode,
  onChange,
}) => {
  const { t } = useTranslation('channel-details');

  return (
    <>
      <HeaderWithHelp
        kind="sub-header"
        name="workMode"
        headerTitle={t('optimizer.configuration.workMode')}
        helpTitle={t('optimizer.configuration.workMode')}
        helpContent={<VideoWorkMode selectedWorkMode={selectedWorkMode} />}
      >
        <div className="grid-list-24">
          <InputSelect
            onChange={onChange}
            value={selectedWorkMode}
            options={workModeOptions}
            label={t('optimizer.configuration.workMode')}
            hiddenLabel
          />
        </div>
      </HeaderWithHelp>
    </>
  );
};
