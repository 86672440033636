import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SetGeolocationResponseCode } from 'lavva.exalushome/build/js/Services/Controller/IGeolocationService';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { useMutation } from '@apollo/client';
import ArrowButton from '../../../../components/arrow-button';
import {
  ChangeGeolocationMutation,
  ChangeGeolocationMutationVariables,
  InstallationAttachmentError,
  InstallationError,
  InstallationModelResponse,
  IntegrationType,
  UserInstallation,
} from '../../../../data-access/gql-types/graphql';
import { CHANGE_GEOLOCATION } from '../../../../data-access/mutations/installations';
import { useBackdropContext, useInstallation } from '../../../../hooks';
import { useInstallationAttachementsErrors } from '../../../../hooks/backend-errors/use-installation-attachement-errors';
import { useMutationErrors } from '../../../../hooks/backend-errors/use-mutation-errors';
import { MutationErrorType } from '../../../../types';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { useExalusSetGeolocation, useHandleGeolocationError } from '../../../exalus/hooks';
import { CreateInstallationControlForm, LocationCoords, LocationViews } from '../../types';
import LocationLearn from '../location-learn';
import LocationMap from '../location-map';
import LocationsList from '../locations-list';
import { useLocationContext } from './context/location.context';

type ComponentProps = {
  updateInstallation?: (key: keyof UserInstallation, value: string | InstallationModelResponse) => void;
};

const LocationEdit: React.FC<ComponentProps> = ({ updateInstallation }) => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { setValue, watch } = useFormContext<CreateInstallationControlForm>();
  const { setCoords, currPage, goTo, setZoom, setCenter } = useLocationContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate: changeControllerGeolocation } = useExalusSetGeolocation();
  const { handleGeolocationError } = useHandleGeolocationError();
  const installationErrors = useMutationErrors(MutationErrorType.Installation);
  const installationAttachementsErrors = useInstallationAttachementsErrors();
  const { selectedInstallation, integrationType, selectedInstallationId, currentInstallationRefetch } =
    useInstallation();
  const [changeGeolocation] = useMutation<ChangeGeolocationMutation, ChangeGeolocationMutationVariables>(
    CHANGE_GEOLOCATION,
  );
  const { location } = watch();

  const handleLocationSet = useCallback(
    (coords: LocationCoords) => {
      const newLocation = coords.latitude && coords.longitude ? `${coords.latitude}, ${coords.longitude}` : '';

      if (newLocation) setValue('location', newLocation);
      else setValue('location', '');

      if (updateInstallation) {
        const currentLocation =
          selectedInstallation?.payload?.coordinates?.latitude && selectedInstallation?.payload?.coordinates?.longitude
            ? `${selectedInstallation.payload.coordinates.latitude}, ${selectedInstallation.payload.coordinates.longitude}`
            : '';

        if (newLocation !== currentLocation) {
          turnOnBackdrop();
          const Latitude = coords.latitude;
          const Longitude = coords.longitude;

          if (integrationType !== IntegrationType.Exalus) {
            changeGeolocation({
              variables: {
                input: {
                  installationId: selectedInstallationId,
                  coordinates:
                    Latitude && Longitude
                      ? {
                          latitude: Latitude,
                          longitude: Longitude,
                        }
                      : null,
                },
              },
              onCompleted: (data) => {
                turnOffBackdrop();
                if (!data || !data.changeGeolocation) {
                  toastError({ content: tc('errors.somethingWentWrong') });
                  return;
                }

                if (data.changeGeolocation.result?.ok) {
                  currentInstallationRefetch();
                  updateInstallation('payload', {
                    ...selectedInstallation?.payload,
                    ...(Latitude && Longitude
                      ? {
                          coordinates: {
                            latitude: Latitude,
                            longitude: Longitude,
                          },
                        }
                      : { coordinates: null }),
                  } as InstallationModelResponse);

                  toastSuccess({ content: t('geolocationEditSuccess') });
                } else {
                  setValue('location', '');
                  installationErrors.handleErrors((data.changeGeolocation.errors || []) as InstallationError[]);
                  installationAttachementsErrors.handleErrors(
                    (data.changeGeolocation.errors || []) as InstallationAttachmentError[],
                  );
                }
              },
              onError: () => turnOffBackdrop(),
            });
          } else {
            if (Latitude && Longitude) {
              changeControllerGeolocation(
                { Latitude, Longitude },
                {
                  onSuccess: (data: ResponseResult<SetGeolocationResponseCode>) => {
                    if (data.Type === SetGeolocationResponseCode.OK) {
                      updateInstallation('payload', {
                        ...selectedInstallation?.payload,
                        ...(Latitude && Longitude
                          ? {
                              coordinates: {
                                latitude: Latitude,
                                longitude: Longitude,
                              },
                            }
                          : { coordinates: null }),
                      } as InstallationModelResponse);

                      turnOffBackdrop();
                      toastSuccess({ content: t('geolocationEditSuccess') });
                    } else {
                      handleGeolocationError(data.Type);
                    }
                  },
                },
              );
            }
          }
        } else {
          turnOffBackdrop();
        }
      }
    },
    [selectedInstallation, integrationType, selectedInstallationId],
  );

  const goToAddLocation = () => {
    if (!location) {
      goTo(LocationViews.ADD_LOCATION);
    } else {
      goTo(LocationViews.MAP);
      const latLng = location.trim().split(',');
      setCoords({ latitude: parseFloat(latLng[0]), longitude: parseFloat(latLng[1]) });
      setCenter({ lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1]) });
      setZoom(16);
    }
  };

  const renderView = () => {
    switch (currPage) {
      case LocationViews.BUTTON: {
        return (
          <ArrowButton
            label={t('location')}
            title={location}
            onClick={goToAddLocation}
            placeholder={t('locationPlaceholder')}
          />
        );
      }
      case LocationViews.ADD_LOCATION: {
        return <LocationsList />;
      }
      case LocationViews.MAP: {
        return <LocationMap handleLocationSet={handleLocationSet} />;
      }
      case LocationViews.LEARN_NEED_LOCATION: {
        return <LocationLearn type="NEED_LOCATION" />;
      }
      case LocationViews.LEARN_REMOVE_LOCATION: {
        return <LocationLearn type="REMOVE_LOCATION" />;
      }
      default: {
        return (
          <ArrowButton
            label={t('location')}
            title={location}
            onClick={goToAddLocation}
            placeholder={t('locationPlaceholder')}
          />
        );
      }
    }
  };

  return <>{renderView()}</>;
};

export default LocationEdit;
