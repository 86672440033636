import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';

type ComponentProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
};

const CameraControls: React.FC<ComponentProps> = () => {
  return null;
};

export default CameraControls;
