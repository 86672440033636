import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { EmptyStateBox, IconWarning, Page, Tabs } from '../../../../components';
import { DashboardHeader } from '../../../../components/dashboard-header';
import { useInstallation } from '../../../../hooks';
import { useExalusContext } from '../../context';
import { useExalusStorageContext } from '../../context/storage';
import { useExalusChannelStateGroupChanged } from '../../hooks';
import { useLoggedUser } from '../../hooks/common/logged-user';
import { ExalusGroups } from './components/groups';
import { UpdateControllerDialog } from './components/update-controller-dialog';
import { UpdateDevicesDialog } from './components/update-devices-dialog';
import { useDashboard } from './hooks/use-dashboard';
import './index.scss';

const DashboardContent: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const { selectedInstallation, installationList } = useInstallation();
  const { allGroups } = useExalusContext();
  const { popUpNavLinks, tabs } = useDashboard();
  const { user } = useLoggedUser();
  const { setActiveGroup, activeGroupIndex } = useExalusStorageContext();
  const [updateControllerFinished, setUpdateControllerFinished] = useState<boolean>(false);
  useExalusChannelStateGroupChanged();

  return (
    <Page
      isStickyHeader
      containerClassName="page__container--flex-height"
      carouselContent={installationList.length !== 0 || selectedInstallation !== undefined}
      className={classNames('dashboard ', {
        'dashboard--no-selected-installation': selectedInstallation === undefined,
      })}
      header={
        <>
          <DashboardHeader navLinks={popUpNavLinks} />
          {allGroups.length > 0 && (
            <Tabs
              tabList={tabs}
              activeTabIndex={activeGroupIndex}
              setActiveTabIndex={setActiveGroup}
              tabsType="pills"
              isDivider={false}
              moreButton="secondary"
            />
          )}
        </>
      }
    >
      {user ? (
        <>
          {user.AccessLevel !== AccessLevel.Guest ? (
            <>
              <ExalusGroups />
              <UpdateControllerDialog setUpdateControllerFinished={setUpdateControllerFinished} />
              {updateControllerFinished && <UpdateDevicesDialog />}
            </>
          ) : (
            <EmptyStateBox
              header={t('exalus.groupsPermissions.header')}
              content={t('exalus.groupsPermissions.content')}
              icon={<IconWarning />}
            />
          )}
        </>
      ) : null}
    </Page>
  );
};

export default DashboardContent;
