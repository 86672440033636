import React from 'react';
import classNames from 'classnames';
import './index.scss';

type PropsInterface = {
  className?: string;
  width?: number;
  height?: number;
};

export const IconDelay: React.FC<PropsInterface> = ({ className, width = 31, height = 30 }) => {
  return (
    <div
      className={classNames('icon-delay', {
        [className as string]: className,
      })}
    >
      <svg width={width} height={height} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.38132 20.1986C6.36372 20.2097 6.34848 20.2098 6.34005 20.2078C6.33927 20.2076 6.33862 20.2074 6.33809 20.2073C5.32696 18.3234 4.88364 16.1801 5.07144 14.0392C5.28163 11.6431 6.27076 9.38183 7.88761 7.60106C9.50446 5.82029 11.66 4.61805 14.0247 4.17817C16.3894 3.73828 18.8331 4.08497 20.9822 5.16521C23.1312 6.24545 24.8674 7.99979 25.9251 10.16C26.9829 12.3202 27.304 14.7674 26.8395 17.1274C26.3749 19.4874 25.1502 21.6303 23.3527 23.2284C21.7466 24.6564 19.762 25.5793 17.6469 25.8917C17.6464 25.8914 17.6459 25.891 17.6453 25.8905C17.6386 25.8849 17.6296 25.8726 17.628 25.8519C17.6268 25.8356 17.6305 25.816 17.6501 25.7925C17.6714 25.7671 17.7056 25.7457 17.7472 25.7391C19.7912 25.4137 21.7066 24.5103 23.2625 23.127C25.0379 21.5486 26.2475 19.4321 26.7063 17.1012C27.1652 14.7703 26.848 12.3533 25.8032 10.2197C24.7585 8.08609 23.0438 6.35337 20.9213 5.28644C18.7987 4.21952 16.3851 3.87711 14.0495 4.31157C11.714 4.74603 9.58499 5.93345 7.98807 7.69227C6.39115 9.45109 5.41421 11.6845 5.20661 14.0511C5.02468 16.125 5.4424 18.2011 6.40076 20.0357C6.42028 20.073 6.42353 20.1132 6.41578 20.1455C6.40865 20.1752 6.39511 20.1899 6.38132 20.1986Z"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M15.3047 9.125V16.4742H21.919" stroke="#FF4016" strokeWidth="1.5" strokeLinecap="round" />
        <path
          d="M14.3845 23.4563L11.8123 26.0285M11.8123 26.0285L9.42383 23.4563M11.8123 26.0285L11.8123 20.5166"
          stroke="#FF4016"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
