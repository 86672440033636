import React, { Dispatch, SetStateAction, useState } from 'react';
import { Input } from '../../../../../../components';
import { DialogValuePicker } from '../../../../../../components/dialog-value-picker';

type ComponentProps = {
  value: string;
  label: string;
  setValue: (val: string) => void | Dispatch<SetStateAction<string>>;
  inputType?: string;
  step?: string;
  min?: number;
  max?: number;
  percentValue?: boolean;
};

export const InputString: React.FC<ComponentProps> = ({
  value,
  label,
  setValue,
  inputType,
  step,
  max,
  min,
  percentValue,
}) => {
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const handleSetValue = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  return (
    <>
      <Input
        defaultValue={value}
        value={value}
        label={label}
        {...(inputType ? { inputType } : {})}
        {...(step ? { step } : {})}
        {...(!percentValue ? { onChange: handleSetValue } : { onClickInput: () => setShowPicker(true) })}
        min={min}
        max={max}
        required
      />
      {percentValue && (
        <DialogValuePicker
          open={showPicker}
          setOpen={setShowPicker}
          title={label}
          onSave={(value) => setValue(value.toString())}
          value={parseInt(value)}
          unit="%"
          {...(max ? { max } : {})}
        />
      )}
    </>
  );
};
