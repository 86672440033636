import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTimePicker, IconEdit, Input } from '../../../../components';
import { ChannelGateInterface, ChannelInterface } from '../../../../types';
import { millisecondsToTime } from '../../../../utils/helpers';
import Info from '../info';

type ComponentProps = {
  channel: ChannelInterface;
  handleSave: (time: string) => void;
};

const SignalTime: React.FC<ComponentProps> = ({ channel, handleSave }) => {
  const { t } = useTranslation('configuration');
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);

  const signalTime = useMemo(() => {
    return millisecondsToTime((channel?.data as ChannelGateInterface)?.sbsSignalTimeInMs || 0);
  }, [(channel?.data as ChannelGateInterface)?.sbsSignalTimeInMs]);

  return (
    <>
      <Info title={`${t('signalTime')} (s)`} />
      <Input
        defaultValue={signalTime}
        value={signalTime}
        required
        readOnly
        label=""
        onClickInput={() => setShowTimePicker(true)}
        endIcon={<IconEdit />}
      />
      <DialogTimePicker
        header={`${t('signalTime')} (s)`}
        open={showTimePicker}
        setOpen={setShowTimePicker}
        onSave={(value) => handleSave(value)}
        time={signalTime}
        showSeconds
        disableHours
        disableMinutes
        minSeconds={1}
      />
    </>
  );
};

export default SignalTime;
