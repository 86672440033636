import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import { ControlWrapper, CoverButton, IconChevron, IconVent, Slider } from '../../../components';
import { useChannel } from '../../../components/channel/hooks/use-channel';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { IconWoodenBlind } from '../../../components/icons';
import { defaultFavouritePositionList } from '../../../const';
import {
  FavouritePositionType,
  ChannelTypeInternal,
  SetFavouriteChannelPositionsMutation,
  SetFavouriteChannelPositionsMutationVariables,
  BlindStateDirectionInternal,
} from '../../../data-access/gql-types/graphql';
import { CHANNEL_SET_FAVOURITE_POSITIONS } from '../../../data-access/mutations/channels';
import { useChannelsState, useCover } from '../../../hooks';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { ChannelCoverInterface, ChannelCoverStateInterface, ChannelInterface } from '../../../types';
import { parseFavouritePositions } from '../../../utils/channels/helpers';
import { toastError, toastSuccess } from '../../../utils/toast';
import CalibrationInfoDetails from '../controls/calibration';

type PropsInterface = {
  channel: ChannelInterface;
};

const ChannelDetailsCover: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { channelState } = useChannelsState();
  const { handleAction } = useCover(channel);
  const { calibrationNeeded } = useChannel({ channel });
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [targetValue, setTargetValue] = useState<number>(0);
  const { updateChannelDetails, setChannelList } = useDevicesAndChannelsContext();
  const [setFavouritePositions] = useMutation<
    SetFavouriteChannelPositionsMutation,
    SetFavouriteChannelPositionsMutationVariables
  >(CHANNEL_SET_FAVOURITE_POSITIONS);
  const state = channelState[channel.id] as ChannelCoverStateInterface | undefined;

  if (channel.data.type !== ChannelTypeInternal.Blind) return null;

  useEffect(() => {
    if (state?.movingParams?.targetPosition != null) {
      setTargetValue(state.movingParams.targetPosition);
    } else {
      if (
        state?.lavvaStatePosition != null &&
        (state.lavvaStateDirection === BlindStateDirectionInternal.Stopped ||
          state.lavvaStateDirection === BlindStateDirectionInternal.Open ||
          state.lavvaStateDirection === BlindStateDirectionInternal.Closed)
      ) {
        setTargetValue(state.lavvaStatePosition);
      }
    }
  }, [state?.movingParams.targetPosition, state?.lavvaStateDirection]);

  useEffect(() => {
    if (channel.data.type !== ChannelTypeInternal.Blind) return;
    setSliderValue(state?.lavvaStatePosition || 0);
  }, [state?.lavvaStatePosition]);

  const favouritePositionList = useMemo(() => {
    if (channel.data.type === ChannelTypeInternal.Blind) {
      return parseFavouritePositions(channel.data.favouritePositionList, defaultFavouritePositionList, true);
    }

    return defaultFavouritePositionList;
  }, [channel.data, defaultFavouritePositionList]);

  const onFavouriteClick = (favoritePositionIndex: number) => {
    handleAction(favouritePositionList[favoritePositionIndex]);
    setTargetValue(favouritePositionList[favoritePositionIndex]);
  };

  const onFavouriteHold = (favoritePositionIndex: number) => {
    if (!channel) return;
    const newFavouriteList = cloneDeep(favouritePositionList);
    newFavouriteList[favoritePositionIndex] = state?.lavvaStatePosition || 0;

    setFavouritePositions({
      variables: {
        input: {
          favouritePositions: newFavouriteList.map((position) => ({
            favouritePositionType: FavouritePositionType.Position,
            value: position / 100,
          })),
          channelId: channel.id,
        },
      },
      onCompleted: (data) => {
        if (data.setFavouriteChannelPositions.ok) {
          toastSuccess({ content: t('favouriteSetSuccess') });

          setChannelList((prev) => {
            const tempList = cloneDeep(prev);
            const index = tempList.findIndex((x) => x.id === channel.id);

            if (index !== -1) {
              (tempList[index].data as ChannelCoverInterface).favouritePositionList = [...newFavouriteList];
            }

            return tempList;
          });

          updateChannelDetails((prev) => {
            const temp = cloneDeep(prev);
            if (temp) (temp.data as ChannelCoverInterface).favouritePositionList = [...newFavouriteList];

            return temp;
          });
        } else {
          toastError({ content: tc('errors.somethingWentWrong') });
          // TODO errors
        }
      },
    });
  };

  const handleSliderEvent = (event: React.PointerEvent<HTMLInputElement>) => {
    handleAction(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTargetValue(Number(event.target.value));
  };

  return (
    <LavvaDetailsWrapper>
      <div className="cover-detail-view">
        <>
          {!calibrationNeeded ? (
            <ControlWrapper>
              <div className="cover-detail-view__slider">
                <Slider
                  onPointerUp={handleSliderEvent}
                  value={sliderValue}
                  active={!!(state?.movingParams.predictedTimeInMs || state?.movingParams.slatsPredictedTimeInMs)}
                  targetValue={targetValue}
                  onChange={onChange}
                />
              </div>
            </ControlWrapper>
          ) : (
            <CalibrationInfoDetails channel={channel} />
          )}
          <div className="cover-detail-view__controls p-l-24 p-r-24">
            <div
              className="cover-detail-view__controls-down"
              onClick={() => {
                handleAction('close');
                setTargetValue(100);
              }}
            >
              <IconChevron withCircle direction="down" />
            </div>
            <button
              className="cover-detail-view__controls-stop"
              onClick={() => {
                handleAction('stop');
              }}
            >
              {tc('status.stop')}
            </button>
            <div
              className="cover-detail-view__controls-up"
              onClick={() => {
                handleAction('open');
                setTargetValue(0);
              }}
            >
              <IconChevron withCircle direction="up" />
            </div>
          </div>
          {!calibrationNeeded && (
            <div className="favourite p-l-24 p-r-24">
              <div className="favourite__buttons favourite__buttons--cover">
                {[0, 1, 2].map((index) => (
                  <CoverButton
                    key={index}
                    lastPosition={channel.data.type === ChannelTypeInternal.Blind ? state?.lavvaStatePosition : 0}
                    position={favouritePositionList[index]}
                    onClick={() => onFavouriteClick(index)}
                    onHold={() => onFavouriteHold(index)}
                  >
                    <IconWoodenBlind position={favouritePositionList[index]} />
                    <span>{favouritePositionList[index]}%</span>
                  </CoverButton>
                ))}
                <CoverButton
                  lastPosition={state?.lavvaStatePosition}
                  position={99}
                  onClick={() => {
                    handleAction(99);
                    setTargetValue(99);
                  }}
                >
                  <IconVent />
                  <span>{t('microventilation')}</span>
                </CoverButton>
              </div>
            </div>
          )}
        </>
      </div>
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsCover;
