import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import c from 'classnames';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { useApi, useInstallation } from '../../hooks';
import StateSyncButton from '../../modules/exalus/components/state-sync-button';
import { useLoggedUser } from '../../modules/exalus/hooks/common/logged-user';
import { ROUTES } from '../../routes';
import { InstallationIconTypeEnum } from '../../types';
import { isLocalApp } from '../../utils/helpers/local-app';
import { InstallationIcon } from '../edit-installation-icon';
import { IconChevron, IconNotification } from '../icons';
import { PopUpNav, PopUpNavLinkInterface } from '../popup-nav';

type DashboardHeaderProps = {
  navLinks: PopUpNavLinkInterface[];
  loading?: boolean;
};

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({ navLinks, loading }) => {
  const history = useHistory();
  const { t } = useTranslation('dashboard');
  const { notificationList } = useApi();
  const { selectedInstallation, installationsLoading, integrationType } = useInstallation();
  const { user } = useLoggedUser();

  const goToNotificationslist = () => history.push(ROUTES.Notification());

  const notificationHasNew = useMemo(
    () => notificationList.some((x) => !x.wasDisplayed && !x.isHidden),
    [notificationList],
  );

  const isExalus = useMemo(() => integrationType === IntegrationType.Exalus, [integrationType]);

  const handleRedirectToInstallationList = () => {
    history.push(ROUTES.Installation());
  };

  return (
    <div className="dashboard__header">
      <div className={c('dashboard__installation', { exalus: isExalus })} onClick={handleRedirectToInstallationList}>
        <div className="dashboard__installation-default-icon">
          <InstallationIcon
            iconName={selectedInstallation?.iconName || InstallationIconTypeEnum.INSTALLATION_0}
            activeColor={selectedInstallation?.hexColor}
            size={36}
          />
        </div>
        {!(installationsLoading && loading) && (
          <>
            <span className="dashboard__installation-name">
              {selectedInstallation ? selectedInstallation.name : t('noSelectedInstallation')}
            </span>
            <IconChevron direction="right" noSpace size={22} />
          </>
        )}
      </div>
      <div className="notifications_row">
        {!isLocalApp && (
          <button className="dashboard__notification" onClick={goToNotificationslist}>
            <IconNotification isOn={notificationHasNew} />
          </button>
        )}
        {isExalus && <StateSyncButton />}
        {selectedInstallation && user?.AccessLevel !== AccessLevel.Guest && (
          <PopUpNav className="dashboard__popup-nav" links={navLinks} />
        )}
      </div>
    </div>
  );
};
