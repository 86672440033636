import {
  LightTransitionTime,
  MaxLightLevel,
  MinLightLevel,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { FacadeDetection } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import { TypeOfGateChannel } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigParams';
import { LedControllersLightTransitionTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/SlrConfigParameters';
import {
  BrightnessThresholdStep,
  WindThresholdStep,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigParams';

export type DeviceConfigurationDetails = {
  name: string;
};

export type BrightnessThresholdParams = {
  BrightnessThreshold: BrightnessThresholdStep;
};

export type WindThresholdParams = {
  WindThreshold: WindThresholdStep;
};

export type FacadeDetectionParams = {
  FacadeDetection: FacadeDetection;
};

export type DimmingBrightnessTimeParams = {
  dimmingTime: LedControllersLightTransitionTime;
};

export type DimmingTimeParams = {
  dimmingTime: LightTransitionTime;
};

export type TurnOnOffActivationParams = {
  Activation: number;
};

export type BrighteningTimeParams = {
  brighteningTime: LightTransitionTime;
};

export type MinLevelParams = {
  minLevel: MinLightLevel;
};

export type MaxLevelParams = {
  maxLevel: MaxLightLevel;
};

export type GateModeParams = {
  mode: TypeOfGateChannel;
};

export type ReactionWindParams = {
  active: boolean;
  lockTime: number;
  blindLocked: boolean;
};

export type GlobalTimeParams = {
  time: string;
};

export type GateTimeParams = {
  time: string;
};

export enum LedTimeType {
  LedTime = 'LED_TIME',
  FloatingTime = 'FLOATING_TIME',
}
