import { useContext, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useInstallation } from '../installation';
import { UserPermissionsContext } from './provider';

export const usePermissionsContext = (installationId?: string) => {
  const { userPermissions, refetchUserMe } = useContext(UserPermissionsContext);
  const { selectedInstallationId } = useInstallation();

  useEffect(() => {
    if (selectedInstallationId) refetchUserMe();
  }, [selectedInstallationId]);

  const permissions = useMemo(() => {
    if (isEmpty(userPermissions)) return {};
    const id = installationId || selectedInstallationId || '';

    return id ? userPermissions[id] || {} : {};
  }, [selectedInstallationId, installationId, userPermissions]);

  return {
    permissions,
  };
};
