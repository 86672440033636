import React from 'react';
import './index.scss';

type IconScheduleProps = {
  className?: string;
};

export const IconSchedule: React.FC<IconScheduleProps> = ({ className }) => (
  <svg
    className={`icon-schedule ${className}`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M2 12.5V19.875C2 21.0438 2.9 22 4 22H20C21.1 22 22 21.0438 22 19.875V12.5M2 12.5V7.125C2 5.95625 2.9 5 4 5H20C21.1 5 22 5.95625 22 7.125V12.5M2 12.5H22"
      stroke="#FF4016"
    />
    <path d="M7 8V2" stroke="#FF4016" />
    <path d="M12 8V2" stroke="#FF4016" />
    <path d="M17 8V2" stroke="#FF4016" />
  </svg>
);
